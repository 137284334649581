


import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import { mapGetters } from 'vuex';
import store from "@/store";
import { eventHandler } from "@/mixins";

@Component({
  computed: {
    ...mapGetters({
      activeConcept: 'account/activeConcept',
      getLocale: 'app/getLocale',
      getUser: 'account/getUser',
      getUserClient: 'account/getUserClient',
      getSubscription: 'account/getSubscription',
      getIntegration: 'account/getIntegration',
      getUserRole: "account/getUserRole",
      getConceptSettings: 'account/getConceptSettings',

    }),

  },
  mixins: [eventHandler],

})

export default class App extends Vue {
  private getLocale!: Function
  activateScript: any = false;
  public activeConcept!: any
  public getSubscription!: any
  public getConceptSettings!: any
  public getUserClient!: any
  public getUser!: any
  public getIntegration!: any
  public getUserRole!: any
  public $intercom!: any
  onBoard: Boolean = false;
  $notify: any;
  confirm!: Function;
  @Watch("$route.name", {deep: true, immediate: true})
  onCheckRoute(data: any) {
    // if(window.location.host.includes('foodicsonline-dev.getsolo.io') || window.location.host.includes('foodicsonline-staging.getsolo.io') || window.location.host.includes('foodics.getsolo.io') || window.location.host.includes('online.foodics.com')) {
    if (window.location.host.includes('foodics')) {
      if(data == 'account'){
        this.$router.push({name: 'dashboard'})
      }
    }
  }

  @Watch("getUserClient", {deep: true, immediate: true})
  onChangeGetUserClient(data: any) {

    if(data) {

      const api_background = this.getUserClient.attributes.extras;
      const backgroundParse = JSON.parse(api_background);
      document.body.style.cssText+=`background-image:url(${backgroundParse.background})`

      const api_theme = this.getUserClient.attributes.theme;
      const themeParse = JSON.parse(api_theme);
   
      let primary = document.documentElement;
      let header = document.documentElement;
      let secondary = document.documentElement;

      primary.style.setProperty('--primary-color', themeParse.primary_color)  
      header.style.setProperty('--header-color', themeParse.header_bg_color)  
      secondary.style.setProperty('--secondary-color', themeParse.secondary_color) 
      
    }

    if(data && this.getUser) {
      this.$intercom.update({
        ConceptName: this.getUserClient.attributes.label,
      });

    }
  }


  @Watch("getSubscription", {deep: true, immediate: true})
  onChangeGetSubscription(data: any) {
    if(data && this.getUser) {
      this.$intercom.update({
        StripePlan:  this.getSubscription,
      });

    }
  }

  @Watch("getUser", {deep: true, immediate: true})
    onChangeGetUser(data: any) {
    if(data && this.getUser) {
      this.$intercom.update({
        name: this.getUser.attributes.name,
        email: this.getUser.attributes.email,
        phone: this.getUser.attributes.mobile,
        user_id: this.getUser.id
      });
    }
  }

  @Watch('getIntegration', {deep: true, immediate: true})
  onChangeGetIntegration(data: any) {
    this.$intercom.update({
      POS: this.getIntegration
    });
  }

  mounted() {

    const label = this.getUserClient?.attributes?.label;
    const roles = this.getUserRole;
    const result_role = roles.filter(checkRole);

    function checkRole(role: any) {
      return role === 'restaurant';
    }

    if (label && label !== 'Foodics Online' && result_role[0] !== 'restaurant') {
      this.$intercom.boot();
    }
    if(this.getClientUserLabel === 'Foodics Online') {
      this.activateScript = true;
    }
  }

  public get getClientUserLabel() {
    return this.getUserClient?.attributes?.label;
  }

    onremind() {
            if (!this.isMobile()) {} else {
              console.log("reminder");
              this.$notify({
                title: "WARNING!",
                verticalAlign: "bottom",
                horizontalAlign: "left",
                message: "You're about to idle!",
                type: "warning",
                icon: "fas fa-solid fa-bell-exclamation",
              });
            }
    }
    onidle() {
            if (!this.isMobile()) {} else {
              console.log("reminder");
              this.confirm(this.$bvModal, this.deleteMsgBoxText)
                .then((value: boolean) => {
                  if(value) {
                    location.reload();
                  }
                })
            }    
    }
    get deleteMsgBoxText(): String {
      return "Idle time detected, Please click ok to refresh the page!";
    }

    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }

  
}
