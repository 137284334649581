
import {
  Alert,
  Button,
  Checkbox,
  CheckboxGroup,
  Form,
  FormItem,
  Input,
  InputNumber,
  Option,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Upload
} from 'element-ui'
import WeekPicker from "@/views/Admin/pages/SubscriptionMeal/components/WeekPicker.vue"
import {Component, Vue} from "vue-property-decorator"
import subscriptionMealEvent from "@/events/subscriptionMealEvent"
import {SubscriptionMeals} from '@/services/SOLO'

interface PlanLang {
  'en-us': any | string,
  'ar-sa': any | string
}

interface Meal {
  'main-dish': {
    id?: any
  },
  drinks: {
    id?: any
  },
  snacks: {
    id?: any
  }
}

interface SetDay {
  day?: any,
  meals?: any | {
    breakfast?: Meal,
    lunch?: Meal,
    dinner?: Meal
  }
}

interface SetMeals {
  week?: any,
  days?: any | SetDay
}

interface PremadePlanForm {
  plan_name?: PlanLang,
  plan_description?: PlanLang,
  plan_image?: any,
  number_of_weeks?: any,
  number_of_people?: any,
  meals?: any,
  inclusions?: any,
  days_per_week?: any,
  setmeals?: any | SetMeals,
  price?: any,
  enabled?: any
}

@Component({
  components: {
    [Form.name]: Form,
    [Radio.name]: Radio,
    [Alert.name]: Alert,
    [Switch.name]: Switch,
    [Input.name]: Input,
    [Button.name]: Button,
    [Upload.name]: Upload,
    [Select.name]: Select,
    [Option.name]: Option,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [RadioGroup.name]: RadioGroup,
    [InputNumber.name]: InputNumber,
    WeekPicker
  },
  filters: {
    fieldTitle(str: any) {
      return str.split(' ').map((item: any) => {
        return item.charAt(0).toUpperCase() + item.substring(1);
      }).join(' ');
    },
    smallTitle(str: any) {
      return str.split(' ').map((item: any) => {
        return item.charAt(0).toLowerCase() + item.substring(1);
      }).join(' ');
    }
  }
})
export default class CreatePremadePlan extends Vue {
  $notify: any
  arrayLang: Array<string> = ['en-us', 'ar-sa']
  saving: boolean = false
  checked: boolean = true
  enabled: boolean = true
  weekData: any = {}
  planSummary: any = {
    days: 7,
    weeks: 1,
    meals: 15,
      total: '0.00'
  }
  isFileActive: boolean = false
  uploadedFileName: any = ''
  weekCounter: number = 0
  weekArr: any = [
    {
      week: '',
      days: []
    }
  ]
  weekDay: any = []
  emptyMeals: any = {
    breakfast: <Meal> {
      'main-dish': {
        id: ''
      },
      snacks: {
        id: ''
      },
      drinks: {
        id: ''
      }
    },
    lunch: <Meal> {
      'main-dish': {
        id: ''
      },
      snacks: {
        id: ''
      },
      drinks: {
        id: ''
      }
    },
    dinner: <Meal> {
      'main-dish': {
        id: ''
      },
      snacks: {
        id: ''
      },
      drinks: {
        id: ''
      }
    }
  }
  form: any = {
    plan_name: {
      'en-us': '',
      'ar-sa': ''
    },
    plan_description: {
      'en-us': '',
      'ar-sa': ''
    },
    plan_image: '',
    number_of_weeks: 1,
    number_of_people: 1,
    meals: ['breakfast'],
    // meals: ['breakfast', 'lunch', 'dinner'],
    inclusions: ['main-dish', 'snacks', 'drinks'],
    days_per_week: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    setmeals: [],
    price: '0.00',
    enabled: true
  }
  fileList: any = ''
  imageBinHolder: any = ''

  mounted() {
    for (let step = 0; step < this.form.number_of_weeks; step++) {
      this.weekArr = {
        week: 1,
        days: []
      }

      this.form.days_per_week.map((d: any) => {
        this.weekDay = <SetDay>{
          day: d,
          meals: this.emptyMeals
        }
        this.weekArr.days.push(this.weekDay)
      })
      this.form.setmeals.push(this.weekArr)
    }

    let insertItem = {
      'is_new_plan': true
    }

    subscriptionMealEvent.$emit('change', {...insertItem, ...this.form})
    // subscriptionMealEvent.$emit('change', this.form)
  }

  async handleChange(file: any) {

    let imgForm = new FormData()
    imgForm.append('plan_image', file.raw)

    await SubscriptionMeals.uploadToS3(imgForm).then((response: any) => {
      this.uploadedFileName = file.name
      this.imageBinHolder = response.data
    }).catch((err: any) => {
      this.$notify({
        title: "Unauthorized",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: err.response.data.error.detail,
        type: "danger",
        icon: "fas fa-bomb",
      })
    })
  }

  weekPayload(data: any, type: any) {

    if (type === 'number_of_weeks') {
      this.form.setmeals.splice(0, this.form.setmeals.length) // clearing array
      let removeFirstEl = this.form.setmeals.shift()

      for (let step = 0; step < data; step++) {
        this.weekArr = {
          week: step + 1,
          days: []
        }

        this.form.days_per_week.map((d: any) => {
          this.weekDay = <SetDay> {
            day: d,
            meals: this.emptyMeals
          }
          this.weekArr.days.push(this.weekDay)
        })

        this.form.setmeals.push(this.weekArr)
      }

    }
    if (type === 'days_per_week') {
      this.form.setmeals.map((v: any) => {
        v.days = []
        data.map((d: any) => {
          this.weekDay = <SetDay> {
            day: d,
            meals: this.emptyMeals
          }
          v.days.push(this.weekDay)
        })

      })

    }

    this.weekData = {
      [type]: data
    }
    subscriptionMealEvent.$emit('change', this.weekData)

    this.planSummary.days = (this.form.days_per_week.length * this.form.number_of_weeks)
    this.planSummary.weeks = this.form.number_of_weeks
    this.planSummary.meals = ((this.form.meals.length * this.form.number_of_people) * this.form.days_per_week.length) * this.planSummary.weeks
  }

  premadePlanData(data: any) {
    this.form = data
    this.form.plan_image = data.plan_image
  }

  createPremadePlan() {
    this.form.plan_image = this.imageBinHolder
    const finalForm = Object.assign({}, this.form)
    SubscriptionMeals.create(finalForm).then((response: any) => {
      this.$notify({
        title: "PREMADE PLAN CREATED",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "You have successfully created a premade plan.",
        type: "success",
        icon: "fas fa-check",
      })

      this.$router.push({ name: 'premade-plans-list' });

    }).catch((err: any) => {
      this.$notify({
        title: "MISSING DATA",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: err.response.data.error.status === 500 ? 'Plan Image is required.' : err.response.data.error.detail,
        type: "danger",
        icon: "fas fa-bomb",
      })
    })
  }

}
