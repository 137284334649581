
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { translations } from '@/mixins'
import { mapGetters } from "vuex";
@Component({
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    }),
  },
  mixins: [translations],
})
export default class NewAppHeader extends Vue {
  translate!: Function;
  public getLocale!: Function;
}
