
import Modal from '@/components/Modal.vue'
import selectedComponentItems from './SelectedComponentItems.vue'
import AvailableComponentItems from './AvailableComponentItems.vue'
import { Select, Option } from 'element-ui'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import { Menu as MenuApi, Settings } from '@/services/SOLO';
import { GlobalIng, GlobalAllergens as GlobalAllergensModel, ModifierGroupItem, ComboComponent, ComponentName } from '@/models'
import BaseButton from '@/components/BaseButton.vue'
import BaseInput from '@/components/Inputs/BaseInput.vue'

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    selectedComponentItems,
    ValidationProvider,
    AvailableComponentItems,
    BaseButton,
    BaseInput
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    }),
  },
  mixins: [eventHandler, translations]
})
export default class ComboMealsModal extends Vue {        
  imageType: number = 0
  arrayLang: Array<string> = []
  selectedComponentItems: Array<Object> = []
  availableComponentItems: Array<Object> = []
  selectedItemIsLoading: Boolean = false
  availableItemIsLoading: Boolean = false
  itemName: string = ''
  // size: any = 
  //   {
  //     combo_size: '',
  //     item_size: '',
  //     price: '',
  //   }
  sizes: any = []
  itemSizes: any = []
  comboNames: any = []
  menuId: any = ''
  comboId: any = ''
  componentId: any = ''
  itemId: any = ''

  componentName: ComponentName = {}

  $notify: any;
  getLocale!: string;

  $refs!: {  
    file: any
  }

  constructor() {
    super()
    for(let i in this.arrayLang)
      this.componentName[this.arrayLang[i]] = ''
  }


  @Prop() open!: Boolean
  @Prop() updateData!: any
  @Prop() isUpdate!: Boolean
  

  created() {
  }

  @Watch('updateData', {immediate: true, deep: true})
  onUpdateDate(newVal: any) {    
        
    console.log("newvalaa", newVal);
    this.sizes = [];
    for(let i in newVal.sizes) {
      // /* @ts-ignore */
      // this.size.combo_size = newVal.sizes[i].combo_modifier.id;
      // /* @ts-ignore */
      // this.size.item_size = newVal.sizes[i].item_modifier.id;
      // /* @ts-ignore */
      // this.size.price = newVal.sizes[i].price;

      this.sizes.push({
        combo_size: newVal.sizes[i].combo_modifier.id,
        item_size: newVal.sizes[i].item_modifier.id,
        price: newVal.sizes[i].price,
      });      
      
      // this.itemSizes = newVal.item.sizes;
      this.itemName = newVal.item.name;
      this.comboNames = newVal.combo.combo_sizes;
    
      this.itemId = newVal.item.id;
      this.comboId = newVal.combo.id;
      this.componentId = newVal.component.id;
      this.menuId = newVal.component.id

      this.itemSizes = newVal.item.sizes.map((item: any) => {
        return {
          id: item.id,
          name: item.name
        }
      })
    } 
    console.log('sizes', this.sizes);   
  }

  get getAppLocale(): any {
    if (this.getLocale === 'en_US') {
      return 'en-us'
    }
    if (this.getLocale === 'ar_SA') {
      return 'ar-sa'
    }
    if (this.getLocale === 'fr_FR') {
      return 'fr'
    }
  }

  get isOpen(): Boolean {    
    return this.open
  }

  close() {
    this.$emit('modal:close', false)
  }

  set isOpen(newVal: Boolean) {    
    this.$emit('modal:update', newVal)
    if(!newVal) {

    }
  }

  async updateComboItem(e: any, reset: any) {
    try {
      await MenuApi.updateComboItem({'component_item_size': this.sizes }, this.menuId, this.comboId, this.componentId, this.itemId)
      this.$notify({
          title: "COMPONENT ITEM!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Component item updated successfully!",
          type: "success",
          icon: "fas fa-check",
        });
      this.close();
      // this.$emit('updateComponent', this.size.combo_size, this.size.item_size, this.size.price);
      this.$emit('updateComponent', this.sizes);
      this.$emit('getComponentData');
    } catch (error) {
      console.error('Error in Updating Combo Item', error);
    }
  }
}
