
import AppConceptLogo from './components/AppConceptLogo'
import {Select, Option} from "element-ui";
import {Component, Vue, Watch} from "vue-property-decorator";
import {SettingsConcepts, Applications, Menu, Slider, Integrations} from "@/services/SOLO";
import {translations, notificationAlerts} from "@/mixins";
import {mapGetters} from 'vuex'
import { ValidationObserver, ValidationProvider } from "vee-validate";

interface CreateAppForm {
  application_type?: any
  label?: any
  default_menu_id?: any
  hosts?: any
  slider_id?: any
  homescreen_slider_id: any
  order_status_slider_id: any
  'menu_only'?: any
  'payment-gateway'?: string | any
  'allowed_order_types'?: string | any
  'allowed_payments_pickup'?: string | any
  'allowed_payments_deliver'?: string | any
  theme: {
    primary_color?: string | any
    secondary_color?: string | any
  }
  settings: {
    category_display?: string | any
    nav_bar_logo?: string | any
  }
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    AppConceptLogo,
    ValidationProvider
  },
  computed: {
    ...mapGetters({
      getConceptSettings: "account/getConceptSettings",
      getLocale: 'app/getLocale',
      getUserClient: 'account/getUserClient',
      getSubscription: 'account/getSubscription'
    }),

  },
  mixins: [translations, notificationAlerts],
})
export default class CreateApp extends Vue {
  appTypeLabel: any = ''
  getLocale!: any
  fileBarLogo: any = ''
  imageType: any = 1
  getConceptSettings!: any
  getUserClient!: any;
  getSubscription!: any;
  special_char: any = false
  menus: any = [];
  sliders: any = [];
  loadingMenu: Boolean = false
  form: CreateAppForm = {
    application_type: "",
    label: "",
    default_menu_id: null,
    hosts: "",
    slider_id: null,
    homescreen_slider_id: null,
    order_status_slider_id: null,
    menu_only:  0,
    'payment-gateway': '',
    'allowed_order_types': 'deliver,to-go',
    'allowed_payments_pickup': 'cash,card-on-pickup',
    'allowed_payments_deliver': window.location.host === 'online.foodics.com' ? 'card':'cash,card-on-delivery',
    theme: {
      primary_color: '#000000',
      secondary_color: '#FFFFFF'
    },
    settings: {
      category_display: 'text',
      nav_bar_logo: ''
    }
  }
  payment_gateway: any = []
  $notify: any;
  selectedMenu: any = '';
  selectedSlider: any = '';
  selectedHomescreen: any = '';
  selectedOrderStatusBanner: any = '';
  app: any = '';
  web: any = '';
  kiosk: any = '';

  saving: boolean = false;
  currentType: string = '';
  reseller_app_type: any= {}

  isMobileDisplay: boolean = false
  isDisplay: boolean = false
  isModifiedFormHosts: boolean = false

  freeMenuOption: any = [
    {text: 'solo.menu', value: 'solo-menu'}
  ]

  imageTypes: any = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Upload Image",
    }
  ];

  translate!: Function;
  successNotification!: Function;
  systemErrorNotification!: Function;
  getPackageType: any = {}
  isFreePackage: any = {}

  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
  };

  mounted() {
    this.getPackageType = this.getConceptSettings
    this.isFreePackage = this.getPackageType.attributes['is-free-package']
    this.form.application_type = this.isFreePackage ? 'solo-menu' : this.$route.query.subscribed ? this.web : this.web
    this.form['menu_only'] = (this.getConceptSettings.attributes['billing-type'] == 'foodics' && this.getConceptSettings.attributes['is-free-package']) ? true : !!this.isFreePackage
    this.getIntegratedPayments()
    this.getMenus()
    this.getSliders()
    this.getAppTypes()
    this.showCreateAppMobileMode()
    console.log('check subss',this.getSubscription.attributes['paid-features']);
  }

  @Watch('form.hosts', {deep: true})
  onDetailsChanged(newVal: any) {
    this.isModifiedFormHosts = true;

    const rExp : RegExp = /(?:[^\w\/\\-]|_)/;
    console.log(rExp.test(newVal));

    if(rExp.test(newVal)) {
      this.special_char = true;
    } else {
      this.special_char = false;
    }
  }
  removeBeforeDot(e: any) {
    let sp = e.split(".");
    return sp[1].charAt(0).toUpperCase() + sp[1].slice(1);
  }

  showCreateAppMobileMode() {
    if (!this.isMobile()) {
      this.isDisplay = true;
    } else {
      this.isDisplay = false;
    }
  }

  checkSize(text: any) {

    if(this.getLocale === 'en_US') {
        if(text === 'The image field size must be less than 2000KB') {
          return 'The image file size must be less than 2MB'
        }
    }
    if(text === 'The image field size must be less than 2000KB') {
          return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }

  }


  public get getClientUserLabel() {
    return this.getUserClient?.attributes?.label;
  }


  async getIntegratedPayments() {
    await Integrations.getAllIntegrations()
        .then((response: any) => {
          this.payment_gateway = response.data.data.filter((payment: any) => payment.attributes.type === 'payment')
          this.form['payment-gateway'] = this.payment_gateway[0].attributes.provider
        }).catch((err: any) => {})
  }

  validateDomain(data: any) {
    let domain = data.target.value
    if (domain.length >= 3) {
      // if(this.getUserClient?.attributes?.label === 'Foodics Online') {
      //    domain = domain + '.foodicsonline.com'
      // } else {
      //    domain = domain + '.ordernosh.com'
      // }

      domain = domain + (this.getConceptSettings.attributes['billing-type'] == 'foodics' ? '.foodics.online' : '.ordernosh.com');


      Applications.validateHost(domain).then((response: any) => {
        if (Object.keys(response.data).length !== 0) {
          this.$notify({
            title: "System Error",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: 'Your desired domain name is already in use.',
            type: "danger",
            icon: "fas fa-bomb",
          })
        }
      })
    }
  }

  getAppTypes() {

    if(this.getUserClient !== null)
    {
        if(this.getUserClient.attributes.label !== 'Solo Portal')
        {
            this.appTypeLabel = 'Foodics ';
            Applications.getClient(window.location.host).then((response: any) => {
              this.reseller_app_type = Object.values(response.data.data.attributes).filter((k:any) => {
                return typeof k === 'object' && k !== null
              })
              this.reseller_app_type.shift() // remove first value of array


              let res_app_type = this.reseller_app_type;

              this.app = response.data.data.attributes.app.label;
              this.web = response.data.data.attributes['social-ordering'].label
              this.kiosk = response.data.data.attributes.kiosk.label;

              console.log("check foodics app type", this.reseller_app_type);

            if(this.getSubscription?.attributes?.name === 'Standard') {

                this.reseller_app_type = res_app_type.filter((rs: any) => {
                  return rs.label === this.web;
                });
              // this.form.application_type = response.data.data.attributes['social-ordering'].label; // default web in application type
              if(this.getSubscription.attributes['paid-features'].filter((pd: any) => {
                if(pd.feature === 'kiosk') {

                  this.reseller_app_type.push({
                      'label': 'foodics.kiosk',
                      'url': ''
                    });
                } 
              }))
              return;
            } else {
              this.form.application_type = response.data.data.attributes['social-ordering'].label; // default web in application type
            }   

            }).catch((err: any) => {})
            return;
        } else {
            this.appTypeLabel = 'Solo ';
            Applications.getClient(window.location.host).then((response: any) => {
              this.reseller_app_type = Object.values(response.data.data.attributes).filter((k:any) => {
                return typeof k === 'object' && k !== null
              })
              this.reseller_app_type.shift() // remove first value of array


              let res_app_type = this.reseller_app_type;

              this.app = response.data.data.attributes.app.label;
              this.kiosk = response.data.data.attributes.kiosk.label;
              this.web = response.data.data.attributes['social-ordering'].label

            if(this.getSubscription?.attributes?.name === 'Standard') {

                this.reseller_app_type = res_app_type.filter((rs: any) => {
                  return rs.label === this.web;
                });
              this.form.application_type = response.data.data.attributes['social-ordering'].label; // default web in application type

              if(this.getSubscription.attributes['paid-features'].filter((pd: any) => {
                if(pd.feature === 'kiosk') {

                  this.reseller_app_type.push({
                      'label': 'solo.kiosk',
                      'url': ''
                    });
                } 
              }))
              return;
            } else {
              this.form.application_type = response.data.data.attributes['social-ordering'].label; // default web in application type
            }   

            }).catch((err: any) => {})
        }
        return;

    } else {
          this.appTypeLabel = 'Solo ';

          Applications.getClient(window.location.host).then((response: any) => {
            this.reseller_app_type = Object.values(response.data.data.attributes).filter((k:any) => {
              return typeof k === 'object' && k !== null
            })
            this.reseller_app_type.shift() // remove first value of array


            let res_app_type = this.reseller_app_type;

            this.app = response.data.data.attributes.app.label;
            this.web = response.data.data.attributes['social-ordering'].label
            this.kiosk = response.data.data.attributes.kiosk.label;

            if(this.getSubscription?.attributes?.name === 'Standard') {

              this.reseller_app_type = res_app_type.filter((rs: any) => {
                return rs.label === this.web;
              });
              this.form.application_type = response.data.data.attributes['social-ordering'].label; 

              if(this.getSubscription.attributes['paid-features'].filter((pd: any) => {
                if(pd.feature === 'kiosk') {

                  this.reseller_app_type.push({
                      'label': 'solo.kiosk',
                      'url': ''
                    });
                } 
              }))

              return;

            } else {
              this.form.application_type = response.data.data.attributes['social-ordering'].label; // default web in application type
            }    
          }).catch((err: any) => {})
    }

  }

  isMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  updateSelectedMenu(e: any) {
    this.form.default_menu_id = Number(e);
  }

  updateSelectedSlider(e: any) {
    this.form.slider_id = Number(e);
  }

  updateSelectedStatusBanner(e: any) {
    this.form.order_status_slider_id = Number(e);
  }

  updateSelectedHomescreen(e: any) {
    this.form.homescreen_slider_id = Number(e);
  }

  async getMenus() {
    this.loadingMenu = true
    await Menu.all().then((response: any) => {
      this.menus = response.data.data;
      this.loadingMenu = false
    });
    this.selectedMenu = this.menus[0]
    this.form.default_menu_id = this.selectedMenu.id
  }

  getSliders() {
    Slider.all().then((response: any) => {
      this.sliders = response.data.data;
    });
  }

  selectedAppType(e: any) {
    this.form.application_type = e;
  }

  get appForm(): FormData {
    let formData = new FormData()

    // formData.append('application_type', this.form.application_type)
    formData.append('label', this.form.label)
    formData.append('default_menu_id', this.form.default_menu_id)
    formData.append('hosts', this.form.hosts)
    formData.append('slider_id', this.form.slider_id)
    formData.append('homescreen_slider_id', this.form.homescreen_slider_id)
    formData.append('order-status-slider-id', this.form.order_status_slider_id)
    formData.append('menu_only', this.form.menu_only)
    formData.append('payment-gateway', this.form['payment-gateway'])
    formData.append(`theme[primary_color]`, this.form.theme.primary_color)
    formData.append('theme[secondary_color]', this.form.theme.primary_color)
    formData.append('settings[category_display]', this.form.settings.category_display)
    formData.append('allowed_order_types', this.form['allowed_order_types'])
    formData.append('allowed_payments_pickup', this.form['allowed_payments_pickup'])
    formData.append('allowed_payments_deliver', this.form['allowed_payments_deliver'])
    if(this.imageType == 0) {
      formData.append(`settings[nav_bar_logo]`, this.form.settings.nav_bar_logo)
    } else {
      formData.append('settings[nav_bar_logo]', this.fileBarLogo)
    }
      formData.append('menu-only', this.form['menu_only'] === true ? '1' : '0')

    if (this.form.application_type === this.web) {
        formData.append('hosts', this.form.hosts + (this.getConceptSettings.attributes['billing-type'] == 'foodics' ? '.foodics.online' : '.ordernosh.com'))
        formData.append('application_type', 'social-ordering');
        formData.append('allowed_order_types', 'deliver,to-go');
        formData.append('allowed_payments_pickup', 'cash,card-on-pickup');
        formData.append('allowed_payments_deliver', 'cash,card-on-delivery');

    }
    if (this.form.application_type === this.app) {
      formData.append('application_type', 'mobile')
      formData.append('allowed_order_types', 'deliver,to-go');
      formData.append('allowed_payments_pickup', 'cash,card-on-pickup');
      formData.append('allowed_payments_deliver', 'cash,card-on-delivery');
    }
    if (this.form.application_type === this.kiosk) {
      formData.append('application_type', 'kiosk')
    }

    return formData
  }

  async createApp() {

    let formData = new FormData()

    if (this.saving) {
      return
    }

    if(this.special_char) {
        this.$notify({
          title: "System Error",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: 'Special character is not allowed!',
          type: "danger",
          icon: "fas fa-bomb",
        })
        return
    }

    this.saving = true

    await Applications.createApplication(this.appForm).then((response: any) => {

      this.$notify({
        title: "RECORDS SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "New application created successfully.",
        type: "success",
        icon: "fas fa-check",
      })

      /* @ts-ignore */
      analytics.track('create_app', {
        has_rms: true,
        online_business_plan: this.getSubscription?.attributes?.name,
        app_type: this.form.application_type,
      });

      this.saving = false
      this.$router.back()

    }).catch((err: any) => {
      let errMsg: any = {}
      for (let error in err.response.data.errors) {
        errMsg = err.response.data.errors[error]
        this.$notify({
          title: "System Error",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: errMsg[0],
          type: "danger",
          icon: "fas fa-bomb",
        })
      }
      this.saving = false
      this.form.application_type = this.web
      this.form["hosts"] = ''
    })

  }

  uploadLogoConcept(data: any) {
    let formData = new FormData()
    formData.append("logo-image", data)
    SettingsConcepts.update(this.getPackageType.id, formData)
  }

  resetForm() {
    /* @ts-ignore */
    this.form = {};
    this.selectedMenu = "";
    this.selectedSlider = "";
    this.selectedHomescreen = "";
    this.selectedOrderStatusBanner = "";
    return;
  }
}
