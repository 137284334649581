
  import { mapGetters, mapMutations } from 'vuex';
  import { Component, Vue } from "vue-property-decorator";

  const IFramContainerProps = Vue.extend({
    props: {
      dashboard: {
        type: Number,
        default: () => 5,
      },
    },
  });

  @Component({
    computed: {
      ...mapGetters({
        getMetabaseReportURL: 'account/getMetabaseReportURL',
      }),
    },
    methods: {
      ...mapMutations({
        setMetabaseDashboard: 'account/setMetabaseDashboard',
      })
    },
  })
  export default class IFrameContainer extends IFramContainerProps {
    public setMetabaseDashboard!: Function
    public dashboard!: number;

    created() {
      this.setMetabaseDashboard(this.dashboard);
    }
  }
