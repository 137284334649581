
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Upsell } from "@/services/SOLO";
import { Select, Option, Autocomplete } from "element-ui";
import { translations, eventHandler } from '@/mixins'
import { mapGetters } from 'vuex'

@Component({
  components: {
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Autocomplete.name]: Autocomplete,
  },
  mixins: [translations, eventHandler],
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale'
    }),
  },
})
export default class MenuUpsells extends Vue {
    upsells: Array<any> = [];
    items: Array<any> = [];
    item: string = '';
    itemSelected: any = null;
    menuId: string = '';
    confirm!: Function;
    translate!: Function;
    $notify: any;
    getLocale!: any;

    created() {
        this.menuId = this.$route.params.id;
        this.loadUpsells();
    }

    handleSelect(e: any) {
        this.itemSelected = e;
    }

    onFetch(queryString: string, cb: Function) {
        Upsell.loadItems(this.menuId, queryString)
            .then((response) => {
                const data = [...response.data.data];
                console.log('mapped: ', data.map((d: any) => {
                    return {
                        value: d.attributes.name, 
                        id: d.id,
                    };
                }));
                const mapped = data.map((d: any) => {
                    return {
                        value: d.attributes.name, 
                        id: d.id,
                    };
                });
                cb(mapped);
            });
    }

    loadUpsells() {
        Upsell.loadUpsells(this.menuId)
            .then((response) => {
                const included = response.data.included;
                this.upsells = [...response.data.data];
                this.upsells = this.upsells.map((u: any) => {
                    return {
                        id: u.id,
                        item: included.find((i: any) => i.id === u.attributes['item-id'].toString())
                    };
                });
            });
    }

    onAddUpsell() {
        Upsell.addUpsell(this.menuId, this.itemSelected.id).then(() => this.loadUpsells());
    }

    confirmationMessageTitle(toDelete: any) {
        const h = this.$createElement

        const message = h('div', 
        { domProps: 
            { innerHTML: this.translate('Are you sure') + ` <span class="font-weight-bold">${toDelete.attributes['name']}${this.translate('?')}</span><br><br>${this.translate('Please note')}`}
        })
        
        return message
    }

    async removeUpsell(upsellItem: any, idx: number) {
        try {
            const isDelete = await this.confirm(this.$bvModal, this.confirmationMessageTitle(upsellItem.item),
                {
                    title: this.translate('Please Confirm'),
                    okTitle: this.translate('OK'),
                    cancelTitle: this.translate('Cancel'),
                    footerClass: this.getLocale === 'ar_SA' ? 'd-flex flex-row-reverse flex-gap' : '',
                }
            )
            if (isDelete) {
                await Upsell.removeUpsell(this.menuId, upsellItem.id)
                await this.upsells.splice(idx, 1);
                this.$notify({
                    title: this.translate("UPSELL ITEM DELETED"),
                    verticalAlign: "bottom",
                    horizontalAlign: "left",
                    message: this.translate("Upsell Successfully Deleted."),
                    type: "success",
                    icon: "fas fa-check",
                });
            }
        } catch (error) {
            this.$notify({
                title: this.translate("SYSTEM ERROR!"),
                verticalAlign: "bottom",
                horizontalAlign: "left",
                message: this.translate("Something went wrong, please try again!"),
                type: "danger",
                icon: "fas fa-bomb",
            });
        }
    }
}
