
import { Order as OrderApi, Integrations, Location } from "@/services/SOLO";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import moment from "moment";
import "moment/locale/pt-br";
@Component
export default class buttonAssignDriver extends Vue {
  @Prop() index!: number
  @Prop() orderId!: string
  @Prop() locationId!: string
  @Prop() driverId!: string
  @Prop() driverName!: string
  @Prop() isDisabled!: boolean
  loading: boolean = false

  mounted() {
    console.log('isDisabled: ', this.isDisabled);
  }

  initAssign() {
    this.getLocationDriver()
  }

  getLocationDriver() {
    this.loading = true
    Location.fetchDrivers(this.locationId)
      .then(response => {
        console.log(this.driverId)
        const data = {
          drivers: response.data.data, orderId: this.orderId, driverId: this.driverId, index: this.index
        };


        if (data.drivers) {
          data.drivers = data.drivers.filter((driver: any) => (driver.attributes.status === 'active'));
        }

        console.log("driver da", data);
        this.$emit('show:drivers', data)
        this.loading = false
      })
  }
}
