
// npm package
import { mapGetters } from 'vuex';
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";

// our package
import { translations, notificationAlerts } from "@/mixins";
import Translations from '@/views/Admin/pages/GMB/Translations.vue';
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import { GMB } from "@/services/SOLO";
import { Select, Option, Form, FormItem } from "element-ui";
import _ from 'lodash';

@Component({
  components: {
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
  },
  computed: {
  ...mapGetters({
    getLocale: 'app/getLocale',
  }),
},
  mixins: [translations, notificationAlerts],
})
export default class Reports extends Vue {
  categories: any = [];
  mainCategory: any = [];
  subCategories: any = [];
  isEnabled: boolean = true;
  syncing: boolean = false;
  $notify: any;
  lang: string = '';
  loadingCategories: boolean = false;

  mounted() {
    /* @ts-ignore */
    if (this.getLocale == 'en_US') {
      this.lang = 'en';
    /* @ts-ignore */
    } else if (this.getLocale == 'ar_SA') {
      this.lang = 'ar';
    /* @ts-ignore */
    } else if (this.getLocale == 'fr') {
      this.lang = 'fr';
    }

    GMB.getCategories({
      country: 'SA',
      lang: this.lang,
      search_query: "restaurant"
    }).then((response: any) => {
      response.data.data.map((item: any) => {
        this.categories.push({
          value: item.gmb_id,
          text: item.name[this.lang] ? item.name[this.lang] : item.name['en']
        });
      });
    }).catch((err: any) => {
      console.log(err);
    });
  }

  getCategoriesDebounced = _.debounce(this.getCategories, 300)

  async getCategories(searchQuery: string) {
    this.loadingCategories = true;
    GMB.getCategories({
      country: 'SA',
      lang: this.lang,
      search_query: searchQuery
    }).then((response: any) => {
      this.categories = [];
      response.data.data.forEach((item: any) => {
        this.categories.push({
          value: item.gmb_id,
          text: item.name[this.lang] ? item.name[this.lang] : item.name['en']
        });
      });
    }).catch((err: any) => {
      console.log(err);
    }).finally(() => this.loadingCategories = false);
  }

  syncLocations() {
    this.syncing = true;

    let categories = [this.mainCategory, ...this.subCategories];
    let payload = {
      categories: categories
    };
    GMB.syncLocations(payload).then((response: any) => {
      this.$notify({
        title: "SYNC SUCCESS",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Sync Locations success",
        type: "success",
        icon: "fas fa-check",
      });
    }).catch((err: any) => {
      console.log(err);
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Sync Locations failed",
        type: "danger",
        icon: "fas fa-bomb",
      });

    }).finally(() => this.syncing = false);
  }

  resetChanges() {
    console.log('resetChanges goes here...');
    alert('RESET CHANGES button is clicked');
  }

  applyChanges() {
    console.log('applyChanges goes here...');
    alert('APPLY CHANGES button is clicked');
  }
}
