    
  import moment from "moment"
  import billingCyleModal from './components/billingCyleModal.vue'
  import { mapGetters, mapMutations } from 'vuex'
  import {Select, Option} from 'element-ui'    
  import { translations } from '@/mixins'
  import PaymentDescription from './components/PaymentDescription.vue'
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
  import { Subscription as SubscriptionApi } from '@/services/SOLO'  
  import { mixins } from 'vue-class-component';

  @Component({
    components: {
      billingCyleModal,
      RouteBreadCrumb,
      [Select.name]: Select,
      [Option.name]: Option,
      PaymentDescription
    },
    filters: {
      formatDate(date: string) {
        return moment
          .utc(date, "YYYY-MM-D hh:mm:ss")
          .locale("en-us")
          .local()
          .format("D/MM/YYYY");
      }      
    },    
    computed: {
      ...mapGetters({
        getUser: 'account/getUser',
        getLocale: 'app/getLocale',
        getSubscription: 'account/getSubscription',
        getConceptSettings: 'account/getConceptSettings'
      })
    },
    methods: {
      ...mapMutations({
        setSubscription: 'account/setSubscription'
      })
    },
    mixins: [translations],
  })
  export default class Account extends Vue {

    private getUser: any
    private getLocale: any
    private getSubscription: any
    private getConceptSettings: any
    private plan: number = 0
    private showBillingModal: Boolean = false
    private subscription: any = null
    private translateNumber!: Function
    private translate!: Function
    private planId: number | null = null
    private paymentMethod: any = null
    
    get account() {
      return this.getUser.attributes
    }

    mounted() {
      // if(!this.getSubscription && !this.getConceptSettings.attributes['is-free-package']) {
      //   return this.$router.go(-1)
      // }
      // this.getSubscriptionFn() 
      this.getPaymentMethodFn()         
    }
    
    @Watch('getSubscription', {immediate: true, deep: true})
    onsubscriptionChange() {
      // if(!this.getSubscription && !this.getConceptSettings.attributes['is-free-package']) {
      //   return this.$router.push({name: 'dashboard'})
      // }
    }

    private modalState(show: Boolean) {
      this.showBillingModal = show
    }

    private upgradeSubscription(planId: number) {
      this.planId = planId
      this.showBillingModal = true
    }

    private changeSubscription() {
      this.$router.push({ name: 'customer-subscription' })
    }

    private getDeliverySystem() {    
      if(this.getSubscription.attributes.features) {        
        return this.getSubscription.attributes.features.includes('delivery-system')
      }
      return false
    }

    private get isCurrentPlan() {
      if(this.getSubscription) {
        return this.plan === this.getSubscription.attributes['plan-id']
      }
      return false
    }

    private setSubscription!: Function

    private getPlan() {
      SubscriptionApi.getPlan(this.plan).then(response => {
        console.log(response.data.data)
      })
    }

    private formatSubscription(num: number) {
      let d = 0
      if(!num) {
        if(!this.getSubscription) {                            
          return this.translateNumber(0);
        } else {
          return this.getSubscription.attributes['branch-quantity'] || this.getSubscription.attributes.quantity
        }
      } else if(num === 1) {
        if(!this.getSubscription) {          
          return this.translate('SAR') + ` ${this.translateNumber(d.toFixed(2))}`
        } else {
          return this.getSubscription.attributes['latest-invoice-amount-paid']
        }
      } else if(num === 2) {
        if(!this.getSubscription) {          
          return this.translate('SAR') + ` ${this.translateNumber(d.toFixed(2))}`
        } else {
          return this.getSubscription.attributes['next-invoice-amount']
        }
      } else {
        if(!this.getSubscription) {          
          return this.translate('N/A')
        } else {                
          return moment
            .utc(this.getSubscription.attributes['next-invoice-at'], "YYYY-MM-D hh:mm:ss")
            .locale("en-us")
            .local()
            .format("MM/DD/YYYY")
        }
      }
    }

    private getSubscriptionFn() {
      SubscriptionApi.getSubscription().then(response => {        
        this.setSubscription(response.data.data)
        this.plan = this.getSubscription.attributes['plan-id']
      }).catch(err => {
        console.log(err.response)
      })
    }

    private getPaymentMethodFn() {
      SubscriptionApi.getPaymentMethod().then(response => {        
        this.paymentMethod = response.data.data[0]
      }).catch(err => {
        console.log(err.response)
      })
    }
  }
