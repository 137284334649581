
// {"_token":"Sria9BsJnFWJiFVz8EbT8UaNCG6FXVcL2V6drSZc","entity":"item","entity-id":"693","value":"asdasd"}

import CustomFieldsModal from './CustomFieldsModal.vue'
import CustomFieldsTable from './CustomFieldsTable.vue'
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import { Settings, Menu } from '@/services/SOLO';
import { AttrCustomField } from '@/models'

@Component({
  components: {
    CustomFieldsModal,
    CustomFieldsTable    
  },
  mixins: [eventHandler, translations]
})
export default class CustomFields extends Vue {
  
  open: Boolean = false
  itemCustomFields: Array<AttrCustomField> = []
  globalCustomFields: Array<AttrCustomField> = []
  translate!: Function
  modifyField: any = null;

  mounted() {
    this.getCustomFields()
    this.getGlobalCustomFields()
  }

  get mappedItemItemCustomFields() {        
    return this.itemCustomFields.map(field => { return field['custom-field-id'].toString() })
  }
  
  modalOpenState(open: Boolean = true) {    
    this.open = open
  }
  
  getCustomFields() {
    Menu.items(this.$route.params.id, this.$route.params.catId, this.$route.params.itemId)
      .then((response: any) => {                
        this.itemCustomFields = response.data.data.attributes.hasOwnProperty('custom-fields') ? response.data.data.attributes['custom-fields'] : []        
      })
      .catch((err: any) => {
        console.error(err)
      })
  }
  modifyCustomField(item: any) {    
    this.modifyField = item;
    this.modalOpenState(true);
  }

  clearModal() {
    this.modifyField = null;
  }

  getGlobalCustomFields() {
    Settings.customFields()
      .then((response: any) => {
        this.globalCustomFields = response.data.data        
      })
      .catch((err: any) => {
        console.error(err)
      })
  }
}
