
import { Component, Prop, Mixins, Watch, Emit } from "vue-property-decorator";
import Translations from "@/mixins/translations";
import ConnectionDetails from "./components/ConnectionDetails.vue"
import ConnectionCustomer from "./components/ConnectionCustomer.vue"
import {
  Connect as ConnectService,
  Location as LocationService
} from "@/services/SOLO";

@Component<EditConnection>({
  components: {
    ConnectionDetails,
    ConnectionCustomer
  }
})
export default class EditConnection extends Mixins(Translations) {
  tab: number = 1;
  id: any;
  connection: any = null;
  // aggregators: any[] = [];

  mounted() {
    this.getConnection();
    // this.getAggregators();
  }

  async getConnection() {
    let { data } = await ConnectService.getConnection(
      this.connectionId,
      {
        include: "excluded-locations,excluded-items"
      }
    );

    this.connection = data.data;

    // let locations: any[] = data.data.relationships["excluded-locations"].data;
    // let items: any[] = data.data.relationships["excluded-items"].data;

    // this.selectedLocations = locations.map(v => v.id.toString());
    // this.selectedItems = items.map(v => v.id.toString());
  }

  // async getAggregators() {
  //   try {
  //     let { data } = await ConnectService.getlAggregators();
  //     this.aggregators = data.data;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  get connectionId(): number {
    return parseInt(this.$route.params.id);
  }

  get dropDownText() {
    if (this.tab === 1) {
      return this.translate("Connection Details") || '';
    } else if (this.tab === 2) {
      return this.translate("Customer") || '';
    }

    return '';
  }
}
