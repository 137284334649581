
import Modal from '@/components/Modal.vue'
import { Category, Settings } from '@/services/SOLO'
import {Select, Option, Menu} from 'element-ui'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Category as CategoryModel, CustomField, Menus as MenuModel } from "@/models"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import Translations from '../../../Translations.vue'
import TabHeader from '@/views/Admin/pages/Messaging/components/TabHeader';

interface customData {
  label: string
  type: string
}

@Component({
  components: {
    Translations,
  },
  mixins: [translations, eventHandler]
})
export default class CreateCustomFieldModal extends Vue {
  public translate!: Function
  open: boolean = false
  parent: any
  data: customData = {
    label: '',
    type: ''
  }
  $notify: any
  btnLoading!: Function
  btnRestore!: Function
  constructor() {
    super()
    this.parent = this.$parent?.$parent
  }

  create(e: any) {
    const defaultText = e.target.innerHTML
    this.btnLoading(e, '<i class="fas fa-spinner fa-spin"></i> Updating...')
    Settings.createCustomFields(this.data).then((response: any) => {
      this.parent.customFields.push(response.data.data)
      this.btnRestore(e, defaultText)
      this.data.label = ''
      this.data.type = ''
      this.open = false
      this.$notify({
        title: "DATA SAVED",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Custom field successfully created",
        type: "success",
        icon: "fas fa-check",
      })
    }).catch((err: any) => {
      this.open = false
      this.btnRestore(e, defaultText)
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb",
      });
    })
  }
  closeMe() {
    this.open = false
  }
}
