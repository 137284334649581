
import { Select, Option } from "element-ui";
import { Order as OrderApi } from "@/services/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
})
export default class OrderStatus extends Vue {
  code: string = "";
  @Prop() orderId!: string;
  @Prop() statuses!: Array<Object>;
  @Prop() orderStatusCode!: string;
  @Prop() orderType!: string;
  @Prop() orderAllStatuses!: Boolean;

  $notify: any;
  updateStatus: any = ''

  mounted() {
    this.code = this.orderStatusCode;
    this.filterStatuses()

  }

  filterStatuses() {
    let someStatuses
    if (this.orderAllStatuses !== true) {
      someStatuses = this.statuses.filter((status:any) => !['dispatched', 'delivery-in-progress', 'arrived-at-customer'].includes(status.attributes.code))

      this.updateStatus = someStatuses
    } else {
      this.updateStatus = this.statuses
    }
    console.log('this.updateStatus: ', this.updateStatus);
  }

  updateOrderStatus() {
    let payload = {
      "order-status": this.code,
    };

    OrderApi.updateStatus(payload, this.orderId)
      .then((response: any) => {
        this.$notify({
          title: "STATUS CHANGED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Status have been successfully changed",
          type: "success",
          icon: "fas fa-check",
        });
      })
      .catch((err: any) => {
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }
}
