
import moment from "moment";
import {notificationAlerts, translations, eventHandler} from "@/mixins";
import Translations from "../components/Translations.vue";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import { Route } from 'vue-router';
import { mixins } from "vue-class-component";
import { Promotion, Coupon } from "@/services/SOLO";
import {Table, TableColumn, Select, Button, Option, Checkbox} from 'element-ui'
import DigitalCouponCollapse from "../components/DigitalCouponCollapse.vue";
import CouponGroupCollapse from "../components/CouponGroupCollapse.vue";
import { mapGetters } from 'vuex'

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    DigitalCouponCollapse,
    Translations,
    CouponGroupCollapse
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getConceptSettings: 'account/getConceptSettings'
    }),
  },

  mixins: [translations, notificationAlerts, eventHandler]
})
export default class CouponGroupsEditList extends Vue {
  getLocale! : any;
  getConceptSettings! : any;
  couponMeta: any = ""
  loading: Boolean = false
  isLoading: Boolean = false
  coupons: Array<any> = [];
  activeCoupons: Array<any> = [];
  futureCoupons: Array<any> = [];
  expiredCoupons: Array<any> = [];
  page: any = 1;
  promotions: Array<any> = [];
  tab: number = 1;
  successNotification!: Function;
  currentPage: number = 1;
  perPage: number = 0;
  totalPage: number = 0;
  total: number = 0;
  confirm!: Function;
  selectType: Array<any> = [
    {
      text: 'Standard',
      value: 'standard'
    },
    {
      text: 'In-Store',
      value: 'in-store'
    }
  ]
  couponType: any = ''
  arrayLang: Array<string> = []
  getLanguages!: Function
  numberOfLoaded: number = 0
  filter: any = {
    name: '',
  }
  collapseKey: number = 1;

  systemErrorNotification!: Function;
  selectedCoupons: Array<any> = []
  isSelectedCoupons: boolean = false
  isExportingCoupons: boolean = false
  isDeletingCoupons: boolean = false
  $refs!: {
    tableCouponsRef: Table
  }

  mounted() {
    this.arrayLang.push(this.getConceptSettings.attributes['primary-language'])
    if (this.getConceptSettings.attributes['secondary-language']) {
      this.arrayLang.push(this.getConceptSettings.attributes['secondary-language'])
    }
    this.displayList();
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.coupons = [];
  }

  @Watch('tab', { immediate: true, deep: true})
  onChangeTab(newTab: any, oldTab: any) {
    this.collapseKey++
  }

  @Watch("couponMeta", { deep: true })
  onFeedbackMetaChanged(newVal: any) {
    this.total = newVal.total;
    this.currentPage = newVal.current_page;
    this.perPage = newVal.per_page;
  }

  get computedActiveCoupons(): Array<any>{
    if (this.coupons.length) {
      return this.coupons.filter((coupon: any) => 'active' === coupon.attributes.status);
    }
    return []
  }

  get computedUpComingCoupons(): Array<any>{
    if (this.coupons.length) {
      return this.coupons.filter((coupon: any) => 'future' === coupon.attributes.status);
    }
    return []
  }

  get computedExpiredCoupons(): Array<any>{
    if (this.coupons.length) {
      return this.coupons.filter((coupon: any) => 'expired' === coupon.attributes.status);
    }
    return []
  }

  applyFilter(data: any) {
    this.filter.name = data.name;
    this.getCoupons()
  }

  onClickTabActive () {
    this.filter.name = ''
    this.page = 1
    if (this.$route.query.digital) {
      this.filterCouponsGroupActive(this.page)
    } else {
      this.getAllCouponsActive(this.page)
    }
  }

  onClickTabUpComing () {
    this.filter.name = ''
    this.page = 1
    if (this.$route.query.digital) {
      this.filterCouponsGroupFuture(this.page)
    } else {
      this.getAllUpComingCoupons(this.page)
    }
  }

  onClickTabExpired () {
    this.filter.name = ''
    this.page = 1
    if (this.$route.query.digital) {
      this.filterCouponsGroupExpired(this.page)
    } else {
      this.getAllExpiredCoupons(this.page)
    }
  }

  getCoupons (){
    if (this.filter.name !== '') {
      this.page = 1
    }

    if (!this.$route.query.digital) {
      if (this.tab === 1) {
        this.getAllCouponsActive(this.page)
      }
      if (this.tab === 2) {
        this.getAllUpComingCoupons(this.page)
      }
      if (this.tab === 3) {
        this.getAllExpiredCoupons(this.page)
      }
    } else {
      if (this.tab === 1) {
        this.filterCouponsGroupActive(this.page)
      }
      if (this.tab === 2) {
        this.filterCouponsGroupFuture(this.page)
      }
      if (this.tab === 3) {
        this.filterCouponsGroupExpired(this.page)
      }
    }
  }

  reset() {
    this.filter.name = '';
    this.getCoupons()
  }

  displayList() {
    if (this.$route.query.digital) {
      this.filterCouponsGroupActive(this.page);
    } else {
      this.getAllCouponsActive(this.page);
    }
  }

  pageChangeExpired(page: any) {
    console.log('Page - Params: Expired ', page)
    this.selectedCoupons = []
    this.isSelectedCoupons = false
    if (this.$route.query.digital) {
      this.filterCouponsGroupExpired(page);
    } else {
      this.getAllExpiredCoupons(page);
    }

    this.page = page;
  }

  pageChangeUpcoming(page: any) {
    console.log('Page - Params: Upcoming ', page)
    this.selectedCoupons = []
    this.isSelectedCoupons = false
    if (this.$route.query.digital) {
      this.filterCouponsGroupFuture(page);
    } else {
      this.getAllUpComingCoupons(page);
    }

    this.page = page;
  }

  pageChangeActive(page: any) {
    console.log('Page - Params: Active ', page)
    this.selectedCoupons = []
    this.isSelectedCoupons = false
    if (this.$route.query.digital) {
      this.filterCouponsGroupActive(page);
    } else {
      this.getAllCouponsActive(page);
    }

    this.page = page;
  }

  allDigitalCoupons() {
    Coupon.allDigitalCoupons().then(response => {
      this.coupons = response.data.data;
      this.promotions = response.data.included;
    });
  }

  getCouponsbyCouponGroupId() {
    const id = this.$route.params.id;
    Coupon.getCouponsbyCouponGroupId(id).then(response => {
      this.coupons = response.data.data;
      this.promotions = response.data.included;
    });
  }

  async getAllCouponsActive(p: number) {
    try {
      this.isLoading = true
      this.numberOfLoaded = 0;
      const { id } = this.$route.params
      const responseActiveCoupons = await Coupon.allCouponGroupsActive(id, this.arrayLang[0], this.filter.name, p)
      
      this.coupons = responseActiveCoupons.data.data;
      this.promotions = responseActiveCoupons.data.included;
      this.couponMeta = responseActiveCoupons.data.meta.pagination
      
      console.log('Coupons Length - Active: ', responseActiveCoupons.data.data.length)

      responseActiveCoupons.data.data.forEach((element: any, index: number) => {
        this.coupons[index].attributes.description = {
          [this.arrayLang[0]]: element.attributes.description
        };
      })

      this.numberOfLoaded += 1;

      if (this.arrayLang[1]) {
        const response = await Coupon.allCouponGroupsActive(id, this.arrayLang[1], this.filter.name, p)
        this.promotions = response.data.included;
          response.data.data.forEach((element: any, index: number) => {
            this.coupons[index].attributes.description = {
              ...this.coupons[index]['attributes']['description'],
              [this.arrayLang[1]]: element.attributes.description
            };
          });
        this.numberOfLoaded += 1;
      }

      this.isLoading = false
      this.tab = 1
    } catch (error) {
      console.error('Error in Getting Active Coupons', error)
    }
  }

  async getAllUpComingCoupons(p: number) {
  try {
      this.isLoading = true
      this.numberOfLoaded = 0;
      const { id } = this.$route.params
      const responseUpComingCoupons = await Coupon.allCouponGroupsFuture(id, this.arrayLang[0], this.filter.name, p)
      this.isLoading = false
      this.coupons = responseUpComingCoupons.data.data;
      this.promotions = responseUpComingCoupons.data.included;

      console.log('Coupons Length - Up coming: ', responseUpComingCoupons.data.data.length)

      this.couponMeta = responseUpComingCoupons.data.meta.pagination
      responseUpComingCoupons.data.data.forEach((element: any, index: number) => {
        this.coupons[index].attributes.description = {
          [this.arrayLang[0]]: element.attributes.description
        };
      });

      this.numberOfLoaded += 1;

      if (this.arrayLang[1]) {
        const response = await Coupon.allCouponGroupsFuture(id, this.arrayLang[1], this.filter.name, p)
        this.promotions = response.data.included;
          response.data.data.forEach((element: any, index: number) => {
            this.coupons[index].attributes.description = {
              ...this.coupons[index]['attributes']['description'],
              [this.arrayLang[1]]: element.attributes.description
            };
          });
        this.numberOfLoaded += 1;
      }
      this.tab = 2
    } catch (error) {
      console.error('Error in Getting Up Coming Coupons', error)
    }
  }

  async getAllExpiredCoupons(p: number) {
    try {
      this.isLoading = true
      this.numberOfLoaded = 0;
      const { id } = this.$route.params
      const responseExpiredCoupons = await Coupon.allCouponGroupsExpired(id, this.arrayLang[0], this.filter.name, p)
      this.isLoading = false
      this.coupons = responseExpiredCoupons.data.data;
      this.promotions = responseExpiredCoupons.data.included;

      console.log('Coupons Length - Expired: ', responseExpiredCoupons.data.data.length)

      this.couponMeta = responseExpiredCoupons.data.meta.pagination
      responseExpiredCoupons.data.data.forEach((element: any, index: number) => {
        this.coupons[index].attributes.description = {
          [this.arrayLang[0]]: element.attributes.description
        };
      });

      this.numberOfLoaded += 1;

      if (this.arrayLang[1]) {
        const response = await Coupon.allCouponGroupsExpired(id, this.arrayLang[1], this.filter.name, p)
        this.promotions = response.data.included;
          response.data.data.forEach((element: any, index: number) => {
            this.coupons[index].attributes.description = {
              ...this.coupons[index]['attributes']['description'],
              [this.arrayLang[1]]: element.attributes.description
            };
          });
        this.numberOfLoaded += 1;
      }
      this.tab = 3
    } catch (error) {
      console.error('Error in Getting Up Expired Coupons', error)
    }
  }
  
  onEdit(coupon: any) {
    console.log('onEdit', coupon);
    if (this.$route.query.digital) {
      this.$router.push({ path: `/digital-coupons/edit/${coupon.id}?digital=true` });
    } else {
      this.$router.push({ path: `/coupon-groups/coupon-edit/${this.$route.params.id}/coupon/${coupon.id}` });
    }
  }

  isObject(obj: Boolean){ // should return true if argument is an object else false
    return Object.prototype.toString.call(obj) === '[object Object]';
  };

  async onDelete(coupon: any) {
    try {
      if (this.$route.query.digital) {
        let removeSpecialCharLocale = '', couponName = '', appLocale = this.getLocale.toLowerCase().replace(/[^a-zA-Z ]/g, "-");
        if (this.isObject(coupon.attributes.name)) {
          for (const key in coupon.attributes.name) {
            removeSpecialCharLocale = key.replace(/[^a-zA-Z ]/g, "-") // get the key of the object key-value pair, e.g 'en-us''
            if (Object.prototype.hasOwnProperty.call(coupon.attributes.name, key)) {
              if (removeSpecialCharLocale === appLocale) { // compare to current locale of the application
                couponName = coupon.attributes.name[key];
              }
            }
          }
        } else {
          couponName = coupon.attributes.name;
        }

        const isConfirmedDeletion = await this.confirm(this.$bvModal ,`Are you sure you want to delete this digital coupon "${couponName}"?`);
        if (isConfirmedDeletion) {
          await Coupon.deleteDigitalCoupon(coupon.id);
          const idx = this.coupons.findIndex((coup: any) => coup.id === coupon.id);
          this.coupons.splice(idx, 1);
          this.successNotification('COUPONS DELETED!', 'Coupon successfully deleted!');
        };

      } else {
        const isConfirmedDeletion = await this.confirm(this.$bvModal ,`Are you sure you want to delete this coupon?`);
        if (isConfirmedDeletion) {
          await Coupon.deleteCoupon(this.$route.params.id, coupon.id)
          const idx = this.coupons.findIndex((coup: any) => coup.id === coupon.id);
          this.coupons.splice(idx, 1);
          this.successNotification('COUPONS DELETED!', 'Coupon successfully deleted!');
        };
      }
    } catch (error) {
      console.error({ error });
    }
  }

  filterCouponsGroup(tabType: string) {
    return this.coupons.filter((c: any) => (tabType === c.attributes.status));
  }


  returnSelectedTypeActive() {
    return this.coupons.filter((c: any) => (this.couponType === c.attributes.type && 'active' === c.attributes.status));
  }

  returnSelectedTypeUpcoming() {
    return this.coupons.filter((c: any) => (this.couponType === c.attributes.type && 'future' === c.attributes.status));
  }

  returnSelectedTypeExpired() {
    return this.coupons.filter((c: any) => (this.couponType === c.attributes.type && 'expired' === c.attributes.status));
  }

  async filterCouponsGroupActive(p: number) {
    this.numberOfLoaded = 0;
    this.loading = true;
    this.page = 1;
    await Coupon.allDigitalCouponsActive(p, this.arrayLang[0], this.filter.name).then(response => {
      this.coupons = response.data.data;
      this.promotions = response.data.included;

      this.couponMeta = response.data.meta.pagination
      this.loading = false;
      response.data.data.forEach((element: any, index: number) => {
        this.coupons[index].attributes.name = {
          [this.arrayLang[0]]: element.attributes.name
        };
      });
    });
    this.numberOfLoaded += 1;

    if (this.arrayLang[1]) {
      await Coupon.allDigitalCouponsActive(p, this.arrayLang[1], this.filter.name).then(response => {
        response.data.data.forEach((element: any, index: number) => {
          this.coupons[index].attributes.name = {
            ...this.coupons[index]['attributes']['name'],
            [this.arrayLang[1]]: element.attributes.name
          };
        });
      });
      this.numberOfLoaded += 1;
    }
    this.tab = 1
    console.log("active digital coupon", this.coupons);
  }

  async filterCouponsGroupFuture(p: number) {
    this.numberOfLoaded = 0;
    this.page = 1;
    this.loading = true;
    await Coupon.allDigitalCouponsFuture(p, this.arrayLang[0], this.filter.name).then(response => {
      this.coupons = response.data.data;
      this.promotions = response.data.included;

      this.couponMeta = response.data.meta.pagination
      this.loading = false;
      response.data.data.forEach((element: any, index: number) => {
        this.coupons[index].attributes.name = {
          [this.arrayLang[0]]: element.attributes.name
        };
      });
    });
    this.numberOfLoaded += 1;

    if (this.arrayLang[1]) {
      await Coupon.allDigitalCouponsFuture(p, this.arrayLang[1], this.filter.name).then(response => {
        response.data.data.forEach((element: any, index: number) => {
          this.coupons[index].attributes.name = {
            ...this.coupons[index]['attributes']['name'],
            [this.arrayLang[1]]: element.attributes.name
          };
        });
      });
      this.numberOfLoaded += 1;
    }
    this.tab = 2
    console.log("upcoming digital coupon", this.coupons);
  }

  async filterCouponsGroupExpired(p: number) {
    this.numberOfLoaded = 0;
    this.loading = true;
    this.page = 1;
    await Coupon.allDigitalCouponsExpired(p, this.arrayLang[0], this.filter.name).then(response => {
      this.coupons = response.data.data;
      this.promotions = response.data.included;

      this.couponMeta = response.data.meta.pagination
      this.loading = false;
      response.data.data.forEach((element: any, index: number) => {
        this.coupons[index].attributes.name = {
          [this.arrayLang[0]]: element.attributes.name
        };
      });
    });
    this.numberOfLoaded += 1;

    if (this.arrayLang[1]) {
      await Coupon.allDigitalCouponsExpired(p, this.arrayLang[1], this.filter.name).then(response => {
        response.data.data.forEach((element: any, index: number) => {
          this.coupons[index].attributes.name = {
            ...this.coupons[index]['attributes']['name'],
            [this.arrayLang[1]]: element.attributes.name
          };
        });
      });
      this.numberOfLoaded += 1;
    }
    this.tab = 3
    console.log("expired digital coupon", this.coupons);
  }

  formatDate(date: Date) {
    return moment
      .utc(date)
      .locale("en-us")
      .local()
      .format("lll");
  }

  getPromotion(coupon: any) {
    return this.promotions.find((promotion: any) => {
      return Number(promotion.id) === Number(coupon.relationships.promotion.data.id);
    });
  }

  refreshCouponsTable (status: string) {
    if (status === 'active') {
      this.getAllCouponsActive(this.page)
    }

    if (status === 'future') {
      this.getAllUpComingCoupons(this.page)
    }

    if (status === 'expired') {
      this.getAllExpiredCoupons(this.page)
    }
  }

  refreshDigitalCouponsTable (status: string) {
    if (status === 'active') {
      this.filterCouponsGroupActive(this.page)
    }

    if (status === 'future') {
      this.filterCouponsGroupFuture(this.page)
    }

    if (status === 'expired') {
      this.filterCouponsGroupExpired(this.page)
    }
  }

  handleSelectionChange (val : any) {
    this.selectedCoupons = val.map((coupon: any) => +coupon.id)
    this.isSelectedCoupons = val.length > 0 || false
  }

  handleSelectedCoupons (action : string, couponStatus: string = 'active') {
    if (action === 'export') {
      this.exportSelectedCoupons(this.selectedCoupons)
    }

    if (action === 'delete') {
      this.deleteSelectedCoupons(this.selectedCoupons, couponStatus)
    }
  }

  exportSelectedCoupons (coupons: Array<any>) {
    let payload: any = {};
    if (this.$route.query.digital) {
      payload = {
        ids: coupons
      }
      this.handleExportCoupons('digital', payload)
    } else {
      const { id : coupon_group_id } = this.$route.params
      payload = {
        ids: coupons
      }
      this.handleExportCoupons('coupons', payload, coupon_group_id)
    }
  }

  deleteSelectedCoupons (coupons: Array<any>, couponStatus: string) {
    let payload: any = {};
    if (this.$route.query.digital) {
      payload = {
        ids: coupons
      }
      this.handleDeleteCoupons('digital', payload, '', couponStatus)
    } else {
      const { id : coupon_group_id } = this.$route.params
      payload = {
        ids: coupons,
      }
      this.handleDeleteCoupons('coupons', payload, coupon_group_id, couponStatus)
    }
  }

  async handleExportCoupons (couponType: string, couponIds : any, coupon_group_id: any = '') {
    try {
      this.isExportingCoupons = true
      const response = couponType === 'digital' ? await Coupon.exportDigitalCoupons(couponIds) : await Coupon.exportCoupons(couponIds, coupon_group_id)
      window.open(response.data.data.attributes['csv-uri'], '_blank');
      this.successNotification('Exporting Success', 'Coupons Exported Susccessfully'); 
    } catch (error) {
      this.systemErrorNotification('Exporting Coupons Error', error);
    } finally {
      this.selectedCoupons = []
      this.isSelectedCoupons = false
      this.isExportingCoupons = false
      this.$refs.tableCouponsRef.clearSelection();
    }
  }

  async handleDeleteCoupons (couponType: string, couponIds : any, coupon_group_id: any = '', couponStatus: string = '') {
    try {
      this.isDeletingCoupons = true
      couponType === 'digital' ? await Coupon.deleteDigitalCoupons(couponIds) : await Coupon.deleteCoupons(couponIds, coupon_group_id)
      couponType === 'digital' ? this.refreshDigitalCouponsTable(couponStatus) : this.refreshCouponsTable(couponStatus)
      this.successNotification('Deleting Success', 'Coupons Deleted Susccessfully');
    } catch (error) {
      this.systemErrorNotification('Deleting Coupons Error', error);
    } finally {
      this.selectedCoupons = []
      this.isSelectedCoupons = false
      this.isDeletingCoupons = false
      this.$refs.tableCouponsRef.clearSelection();
    }
  }
}
