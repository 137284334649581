
import * as Ably from 'ably';
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {Select, Button, Option} from 'element-ui'
import DriversModal from './components/DriversModal.vue'
import LogisticsModal from './components/LogisticsModal.vue'
import OrderStatus from './components/OrderStatus.vue'
import buttonAssignLogistics from './components/buttonAssignLogistics.vue'
import buttonAssignDriver from './components/buttonAssignDriver.vue'
import {Order as OrderApi, Integrations, Order, Location as LocationApi, Employee} from "@/services/SOLO";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { ValidationObserver, configure } from 'vee-validate'
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { Driver, DriverAttributes, Location, Logistics } from '@/models'
import moment from "moment";
import "moment/locale/pt-br";
import { eventHandler } from '@/mixins'
import { mapGetters } from 'vuex';
import sound from '../../../../../public/sound/default_notif.wav'
import { BButton } from 'bootstrap-vue';
import { translations } from '@/mixins'
import Translations from './components/Translations.vue'

// interface Filter {
//   order: string
//   from: string
//   to: string
//   customer: string
//   email: string
//   mobile: string
//   location: Array<string>
//   status: string
//   today: Boolean
//   'curbside-status': string
// }
interface Filter {
  order: string
  from: string
  to: string
  customer: string
  email: string
  mobile: string
  location: any
  status: string
  today: Boolean
  'curbside-status': string,
  type: string
}
@Component({
  components: {
    RouteBreadCrumb,
    buttonAssignDriver,
    DriversModal,
    buttonAssignLogistics,
    LogisticsModal,
    OrderStatus,
    [Select.name]: Select,
    [Option.name]: Option,
     [Button.name]: Button,
    flatPicker
  },
  computed: {
    ...mapGetters({
      activeConcept: 'account/activeConcept',
      userRole: 'account/getUserRole',
      user: 'account/getUser',
    })
  },
  filters: {
    datetime(value: any, format: string = "YYYY-MM-DD HH:mm") {
      return moment.utc(value).locale("en-us").fromNow();
    },
    circleColor(finishedTime: any, promisedTime: any) {
      let start = (finishedTime ? moment.utc(finishedTime).local() : moment().utc().local());
      let end = moment.utc(promisedTime);
      let diff = moment.duration(start.diff(end)).asMinutes();
      if (diff > 2) {
        return 'text-danger'
      } else if (diff > 0) {
        return 'text-warning';
      } else {
        return 'text-success';
      }
    },
    formatDate(date: string) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("MMM D YYYY hh:mm");
    }
  },
  mixins: [eventHandler,translations]
})
export default class Curbside extends Vue {
  private activeConcept: any
  private driverOpen: Boolean = false
  private logisticsOpen: Boolean = false
  private page: number = 1;
  private size: number = 20;
  private source: string = "Source"
  private posTooltip: string = ""
  private paymentMethod: string = "Payment"
  private type: string = "Type"
  private orders: any = [];
  private driverData: Object = {}
  private logisticData: Object = {}
  private logistics: Array<Logistics> = []
  private statuses: Array<Object> = []
  private storeLocations: Array<Object> = []
  private isLoading: Boolean = false
  private loaded: Boolean = false
  visible: Boolean = false
  allStatuses: Boolean = false
  private filter: Filter = {
    order: '',
    from: '',
    to: '',
    customer: '',
    email: '',
    mobile: '',
    location: [],
    status: '',
    today: true,
    'curbside-status': 'customer-arrived',
    type: ''
  }
  private curbsideStatus: Array<Object> = [
    {
      value: 'customer-arrived,employee-acknowledged',
      text: 'All'
    },
    {
      value: 'customer-arrived',
      text: 'Customer Arrived'
    },
    {
      value: 'employee-acknowledged',
      text: 'Employee Acknowledged'
    }
  ]
  flag: Boolean = true;
  rows: number = 0
  perPage: number = 50
  currentPage: number = 1

  private confirm!: Function
  // private broadcasted!: Function
  broadcastedSoloOrder!: Function
  broadcastedSoloOrderStatus!: Function
  public $notify: any
  private notif!: Function
  public translate!: Function
  $refs!: {
    sound: HTMLAudioElement,
    playNewOrderAudio: any
  }
  isVisible: boolean = false;
  userRole: any
  user: any;
  canPlayNotificationSound: Boolean = !!window.localStorage.getItem('playSound') || false;
  employeeLocations: any = [];
  isMobile: boolean = false;
  isPlay: boolean = false;
  webNotif: any;
  // ablyUnsubscribe: any;
  ablyUnsubscribeSoloOrder: any;
  ablyUnsubscribeSoloOrderStatus: any;

  @Watch("canPlayNotificationSound", { immediate: true, deep: true })
  onCanPlaySoundChanged(val: any) {
    if (val) {
      if (!this.isPlay) {
        let audio = document.getElementsByTagName("audio")[0];
        audio.play().then(() => {
        }).catch(() => {
        });
        this.isPlay = true;
      }
    }
  }

  private get isMobileDevice(): Boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  mounted() {
    if (!('Notification' in window)) {
      console.error('This browser does not support desktop notification');
    } else if (Notification.permission === 'default' || Notification.permission === 'denied') {
      Notification.requestPermission();
    }

    window.addEventListener('resize', this.screensizeHandler);

    if (this.isMobileDevice) {
      this.isMobile = true;
      this.confirm(
        this.$bvModal,
        'Do you want to play a sound when an order is received?',
        {
          title: 'Attention!',
          okTitle: 'YES',
          cancelTitle: 'NO',
        },
      ).then(async (value: boolean) => (this.canPlayNotificationSound = value));
    }

    this.getStoreLocations()
      .then(() => {
        this.getLogisticPartners();
        this.pushedNotification();
        return this.getOrderStatuses();
      })
      .then(() => (this.getOrders()));
  }

  destroyed() {
    if (this.webNotif) {
      this.webNotif.close();
      this.webNotif = null;
    }
    // if (this.ablyUnsubscribe) {
    //   this.ablyUnsubscribe.unsubscribe();
    //   this.ablyUnsubscribe = null;
    // }
    if (this.ablyUnsubscribeSoloOrder) {
      this.ablyUnsubscribeSoloOrder.unsubscribe();
      this.ablyUnsubscribeSoloOrder = null;
    }
    if (this.ablyUnsubscribeSoloOrderStatus) {
      this.ablyUnsubscribeSoloOrderStatus.unsubscribe();
      this.ablyUnsubscribeSoloOrderStatus = null;
    }
    window.removeEventListener('resize', this.screensizeHandler);
  }

  screensizeHandler() {
    if (this.isMobileDevice) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.$forceUpdate();
  }

  disabled(check: any) {
    if(check === true) {
      return 'pointer-events: none';
    }
    return 'pointer-events: auto';
  }

  getEmployeeLocations() {
    if (!this.employeeLocations.length) {
      return Employee.find(this.user.id)
        .then((response) => {
          if (response.data.included) {
            this.employeeLocations = response.data.included;
          }
        });
    }
    return Promise.resolve();
  }

  getFirstName(customerName: string) {
    return customerName ? customerName.split(' ')[0]: 'N/A';
  }

  closeOrder() {
    this.flag = true;
    this.visible = true;
  }

  openOrder() {
    this.flag = true;
  }

  circleTooltip(finishedTime: any, promisedTime: any) {
    let start = (finishedTime ? moment.utc(finishedTime).local() : moment().utc().local());
    let end = moment.utc(promisedTime);
    let diff = moment.duration(start.diff(end)).asMinutes();
    if (diff > 2) {
      return 'Past the promised time'
    } else if (diff > 0) {
      return 'Slightly past the promised time';
    } else {
      if (!finishedTime) {
        return 'Not finished';
      } else {
        return 'Finished on time';
      }
    }
  }

  posColor(code: number, posResponse: any) {
    if (code && posResponse) {
      this.posTooltip = "Order successfully sent to POS";
      return 'success'
    } else if (!code && posResponse) {
      this.posTooltip = "Order failed in POS";
      return 'danger'
    } else {
      this.posTooltip = "";
      return 'secondary'
    }
  }

  tooltip(code: number, posResponse: any) {
    if (code && posResponse) {
      return 'Order successfully sent to POS'
    } else if (!code && posResponse) {
      return 'Order failed in POS'
    } else {
      return ''
    }
  }

  paginate(page: number) {
    this.page = page
    this.getOrders()
  }

  resetForm(reset: any) {

    this.filter.order = ''
    this.filter.from = ''
    this.filter.to = ''
    this.filter.customer = ''
    this.filter.email = ''
    this.filter.mobile = ''
    this.filter.location = []
    this.filter.status = ''
    this.filter.today = true
    this.filter['curbside-status']
  }

  async pushedNotification() {
    // this.ablyUnsubscribe = this.broadcasted(this.activeConcept.id);
    // this.ablyUnsubscribe.subscribe((payload: any) => {
    this.ablyUnsubscribeSoloOrder = await this.broadcastedSoloOrder(this.activeConcept.id);
    await this.ablyUnsubscribeSoloOrder.subscribe(async(payload: any) => {
      const order = payload?.data?.order;
      console.log('broadcasting curbside new solo order: ', payload);

      // await this.findOrder(order.id);
      // if (
      //   payload.data.order.type === 'curbside'
      //   && payload.data.order['curbside-status'].code === 'customer-arrived'
      // ) {
        // switch (payload.name) {
        //   case 'Solo\\Order':
        //     // this.findOrder(payload.data.order.id);
        //     break;
        //   case 'Solo\\Order\\Status':
        //     this.findOrder(payload.data.order.id);
        //     break;
        // }

      /**
       * UPDATE ORDER STATUS
       */
      this.ablyUnsubscribeSoloOrderStatus = await this.broadcastedSoloOrderStatus(this.activeConcept.id, order.id);
      await this.ablyUnsubscribeSoloOrderStatus.subscribe(async(payload2: any) => {
        const order2 = payload2?.data?.order;
        console.log('broadcasting curbide update solo order status: ', payload2);

        await this.findOrder(order2.id);
      });
      // }
    });
  }

  DriverModalOpenState(open: Boolean = true) {
    this.driverOpen = open
  }

  LogisticsModalOpenState(open: Boolean = true) {
    this.logisticsOpen = open
  }

  showDriver(data: Object) {
    this.driverData = data
    this.DriverModalOpenState(true)
  }

  showLogistics(data: Object) {
    this.logisticData = data
    this.LogisticsModalOpenState(true)
  }

  getOrders() {
    let tmpFilter: any = [];
    this.orders = []
    this.isLoading = true
    this.loaded = false
    // if (!this.filter.location.length) {
    if (!this.filter.location.length && this.userRole === 'supervisor') {
      this.filter.location = this.storeLocations.map((s: any) => s.id).join();
    } else {
      tmpFilter = [...this.filter.location];
      this.filter.location = this.filter.location.join();
    }

    OrderApi.all(this.page, this.size, this.filter).then((response) => {
      this.filter.location = [...tmpFilter];
      this.isLoading = false
      this.loaded = true
      this.orders = response.data.data;
      this.rows = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page
      this.currentPage = response.data.meta.pagination.current_page
      this.getOrderStatuses()
    });
    this.isVisible = false
  }

  getStoreLocations() {
    return LocationApi.fetchLocations()
      .then((response: any) => {
        this.storeLocations = response.data.data
      })
  }

  playNotificationSound() {
    let audio = document.getElementsByTagName("audio")[0];
    if (this.canPlayNotificationSound) {
      audio.play().then(() => {
      }).catch(() => {
      });
    }
  }

  async findOrder(orderId: string) {
    await OrderApi.find(orderId)
      .then((response: any) => {
        // this.updateOrders(response.data.data);
        let res = response.data.data;
        res['live'] = true;
        this.updateOrders(res);
      });
  }

  async refreshStatus(data: any) {
    await OrderApi.getOrderStatusSync(data)
      .then((response: any) => {
        let item = response.data.data
        this.orders.some((v: any, i: any) => {
          if (item.id === v.id) {
            this.orders[i].attributes['current-status'].description = item.attributes.code
            return true
          }
        })
      })
  }

  notify(title: string, message: string, order: any) {
    if (!this.isMobile) {
      this.webNotif = new Notification(title, {
        body: message,
      });
      this.webNotif.onclick = () => {
        this.webNotif = null;
        this.$router.push({ name: 'order-details', params: { id: order.id } });
      };
    } else {
      let config: any = { body: message };
      navigator.serviceWorker.register('/sw.js');
      navigator.serviceWorker.ready.then(function (registration) {
        config.data = order.id;
        registration.showNotification(title, config);
      });
    }
  }

  showNotification(title: string, message: string, order: any) {
    if (('Notification' in window)) {
      if (Notification.permission === 'granted') {
        this.notify(title, message, order);
      } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            this.notify(title, message, order);
          }
        });
      }
    }
  }

  updateOrder(data: any) {
    const { id, attributes } = data;
    const sr = this.translate('SAR');
    const old = !!this.orders.find((ord: any) => Number(ord.id) === Number(id));
    const title = 'Customer has Arrived';
    const message = `New order #${id} for ${sr} ${attributes.total} at ${attributes['location-name']}.`;
    const findOrderIdx = this.orders.findIndex((ord: any) => Number(ord.id) === Number(id));
    // Play notification
    if (
      attributes
      && attributes?.['curbside-status']
      && attributes?.['curbside-status']?.code === 'customer-arrived'
    ) {
      this.playNotificationSound();
      this.showNotification(title, message, data);
    }

    if (findOrderIdx > -1) {
      this.orders[findOrderIdx] = data;
      this.$forceUpdate();
      // this.orders.splice(findOrderIdx, 1);
    } else {
      this.orders.unshift(data);
    }
  }

  updateOrders(data: any) {
    const isAdmin = this.userRole.includes('administrator');
    // GET employee locations
    // if already exist do not call API for performance
    this.getEmployeeLocations()
      .then(() => {
        const found = this.employeeLocations
          .find((loc: any) => Number(loc.id) === Number(data.attributes['location-id']));
        if (isAdmin || found) {
          this.updateOrder(data);
        }
      });
  }

  updateOrderIndex(data: any) {
    this.orders[data.index] = data.data;
  }

  iconSrc(src: string) {
    return `/img/order icons/${src.toLowerCase()}.png`;
  }

  getLogisticPartners() {
    Integrations.logisticsPartners('food-logistics')
      .then(response => (this.logistics = response.data.data));
  }

  getOrderStatuses() {
    return OrderApi.statusList()
      .then((response: any) => (this.statuses = response.data.data));
  }

  exportOrder(orderId: string) {
    if (true) {
      this.$notify({
        title: "PLEASE WAIT!",
        horizontalAlign: "right",
        message: 'Please wait, the file will be downloaded in few seconds.',
        type: "success",
        icon: "fas fa-spinner fa-spin",
      })
      OrderApi.exportOrder(orderId)
        .then(response => {
          window.open(response.data.data.attributes['csv-uri'], '_blank');
        })
    }
  }

  exportAllOrder() {
    if(true) {
      this.$notify({
        title: "PLEASE WAIT!",
        horizontalAlign: "right",
        message: 'Please wait, the file will be downloaded in few seconds.',
        type: "success",
        icon: "fas fa-spinner fa-spin",
      })
      OrderApi.exportAllOrder()
        .then(response => {
          window.open(response.data.data.attributes['csv-uri'], '_blank');
        })
    }
  }

  onAcknowledge(ev: any, orderId: string) {
    const newStatus = 'employee-acknowledged';
    ev.preventDefault();
    ev.stopPropagation();

    this.confirm(
      this.$bvModal,
      'Do you acknowledge this order?',
      {
        title: 'Confirm',
        okTitle: 'YES',
        cancelTitle: 'NO',
      },
    ).then(async (value: boolean) => {
      if (value) {
        OrderApi.updateStatus({ 'order-status': newStatus }, orderId)
          .then(() => {
            this.orders = this.orders.map((order: any) => {
              if (Number(order.id) === Number(orderId)) {
                order.attributes['curbside-status'].code = newStatus;
              }
              return order;
            });
            this.$forceUpdate();
          });
      }
    });
  }

  filterActiveDrivers(drivers: any) {
    return drivers && drivers.filter((driver: any) => (driver.attributes.status === 'active'));
  }
}
