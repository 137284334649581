
import {BanAttributes, Banner, Slide} from "@/models";
import {translations} from '@/mixins'
import {Component, ProvideReactive, Prop, Vue, Watch, Emit,} from "vue-property-decorator";
import { mapGetters } from "vuex";

@Component({
  components: {
  },
  computed: {
    ...mapGetters({
      getUser: 'account/getUser'
    })
  },
  mixins: [translations],
})
export default class ExportAllOrderNoti extends Vue {
  
  
  @Prop() open!: Boolean
  get isOpen(): Boolean {
    return this.open;
  }

  set isOpen(newVal: Boolean) {
    this.$emit("modal:new", newVal);
    if (!newVal) {
    }
  }

  constructor() {
    super();
  }

  close() {
    this.$emit("modal:close", false);
  }

 
}
