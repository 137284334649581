
import _ from "lodash";
import {
  Component,
  PropSync,
  Mixins
} from "vue-property-decorator";
import { Form, Select, Option } from "element-ui";
import Translations from "@/mixins/translations";
import {
  Connect as ConnectService,
  Location as LocationService
} from "@/services/SOLO";
import { Location as LocationModel, Item as ItemModel } from "@/models";

@Component<ConnectionDetails>({
  components: {
    [Form.name]: Form,
    [Select.name]: Select,
    [Option.name]: Option
  }
})
export default class ConnectionDetails extends Mixins(Translations) {
  // @Prop({ type: Array, required: true }) readonly aggregators!: any[];
  @PropSync("connection", { type: Object, default: null })
  syncedConnection!: any;

  form: any = {
    "aggregator-id": null,
    "menu-id": null,
    options: {} as any,
    "excluded-locations": [] as any[],
    "is-production": true
  };

  locations: LocationModel[] = [];
  selectedLocations: any[] = [];
  items: ItemModel[] = [];
  selectedItems: any[] = [];
  searchedCustomers = [];
  oldQuery: string = "";
  customerId = null;
  saving: boolean = false;
  syncingMenu: boolean = false;
  syncingBranches: boolean = false;

  mounted() {
    this.getLocations();
    this.resetForm();
  }

  resetForm() {
    const connection = _.cloneDeep(this.syncedConnection);

    this.form["aggregator-id"] = connection.attributes[
      "aggregator-id"
    ].toString();
    this.form["menu-id"] = connection.attributes[
      "menu-id"
    ].toString();
    this.form["is-production"] = connection.attributes[
      "is-production"
    ];

    if (this.syncedConnection.attributes.options == null) {
      this.syncedConnection.attributes.options = {};
    }

    this.form.options = connection.attributes.options || {};

    for (let key in connection.attributes.options) {
      this.$set(
        this.form.options,
        key,
        connection.attributes.options[key]
      );
    }

    let locations: any[] = connection.relationships[
      "excluded-locations"
    ].data;
    // let items: any[] = data.data.relationships["excluded-items"].data;

    this.selectedLocations = locations.map(v => v.id.toString());
    // this.selectedItems = items.map(v => v.id.toString());
  }

  // async getConnection() {
  //   let { data } = await ConnectService.getConnection(
  //     this.syncedConnection.id,
  //     {
  //       include: "excluded-locations,excluded-items"
  //     }
  //   );
  //   let locations: any[] = data.data.relationships["excluded-locations"].data;
  //   let items: any[] = data.data.relationships["excluded-items"].data;

  //   this.selectedLocations = locations.map(v => v.id.toString());
  //   this.selectedItems = items.map(v => v.id.toString());
  // }

  async onSave() {
    // if (this.syncedConnection) {
    await this.updateConnection();
    // } else {
    // await this.createConnection();
    // }

    // this.closeModal();
    // this.resetForm();
  }

  async updateConnection() {
    const updateData: any = {};

    for (let key in this.form) {
      if (
        !["options", "excluded-locations"].includes(key) &&
        this.form[key] == this.syncedConnection.attributes[key]
      ) {
        continue;
      }

      if (
        key == "options" &&
        JSON.stringify(this.form[key]) == JSON.stringify(this.syncedConnection.attributes[key])
      ) {
        continue;
      }

      switch (key) {
        case "menu-id":
          updateData["menu-id"] = parseInt(this.form["menu-id"]);
          break;
        default:
          updateData[key] = this.form[key];
          break;
      }
    }

    if (_.isEmpty(updateData)) {
      return;
    }

    this.saving = true;

    updateData["excluded-locations"] = this.selectedLocations.map(v =>
      parseInt(v)
    );

    try {
      let { data } = await ConnectService.updateConnection(
        this.syncedConnection.id,
        updateData
      );

      for (let key in updateData) {
        if (typeof this.syncedConnection.attributes[key] != "undefined") {
          if (key == "options") {
            for (let optionKey in updateData[key]) {
              this.$set(
                this.syncedConnection.attributes.options,
                optionKey,
                updateData.options[optionKey]
              );
            }

            continue;
          }
          this.syncedConnection.attributes[key] = updateData[key];
        }
      }

      this.$notify({
        title: `UPDATE DATA`,
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: `Connection data was updated successfully!`,
        type: "success"
      } as any);
    } catch (e) {
      console.log(e);

      this.$notify({
        title: `UPDATE DATA`,
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: (e as any).response.data.error[0].detail,
        type: "danger"
      } as any);
    } finally {
      this.saving = false;
    }
  }

  async syncMenu() {
    this.syncingMenu = true;

    try {
      await ConnectService.syncMenu(this.syncedConnection.id);

      this.$notify({
        title: this.translate("MENU SYNCED!"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate("Menu successfully synced!"),
        type: "success",
        icon: "fas fa-check",
      } as any);
    } catch (e) {
      this.$notify({
        title: this.translate("SYSTEM ERROR!"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate("Something went wrong, please try again!"),
        type: "danger",
        icon: "fas fa-bomb",
      } as any);
    } finally {
      this.syncingMenu = false;
    }
  }

  async syncBranches() {
    this.syncingBranches = true;

    try {
      let { data } = await ConnectService.syncBranches(this.syncedConnection.id);

      if (data.data.type == 'export') {
        window.open(data.data.attributes['csv-uri'], '_blank');
      } else {
          this.$notify({
          title: this.translate("BRANCHES SYNCED!"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate("Branches successfully synced!"),
          type: "success",
          icon: "fas fa-check",
        } as any);
      }
    } catch (e) {
      this.$notify({
        title: this.translate("SYSTEM ERROR!"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate("Something went wrong, please try again!"),
        type: "danger",
        icon: "fas fa-bomb",
      } as any);
    } finally {
      this.syncingBranches = false;
    }
  }

  async getLocations() {
    try {
      let { data } = await LocationService.fetchLocations();
      this.locations = data.data;
    } catch (e) {
      console.log(e);
    }
  }

  get menus(): any[] {
    return this.$store.getters["menu/getMenus"];
  }

  get availableOptions(): any[] {
    return (
      this.syncedConnection.attributes.aggregator["available-options"] || []
    );
  }
}
