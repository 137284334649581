
import { CustomField, Menus as MenuModel } from "@/models";
import DetachCustomFieldModal from "./DetachCustomFieldModal";
import CreateCustomFieldModal from "./CreateCustomFieldModal.vue";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "element-ui";
import { Settings } from "@/services/SOLO";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    DetachCustomFieldModal,
    CreateCustomFieldModal,
    Translations,
  },
  computed: {
    ...mapGetters({
      getUser: "account/getUser",
      getMenus: "menu/getMenus",
    }),
  },
  mixins: [translations],
})
export default class CustomFieldTable extends Vue {
  getUser!: any;
  currentPage: Number = 1;
  openCustomFieldModal: boolean = false;
  customField: CustomField = { id: "", attributes: null };
  getMenus!: Array<MenuModel>;
  $refs!: {
    fieldModal: typeof DetachCustomFieldModal;
    createModal: CreateCustomFieldModal;
  };
  translate!: Function;
  @Prop() customFields!: Array<CustomField>;
  @Prop() itemLimit!: Number;
  @Prop() active!: Boolean

  initCreate() {
    this.$refs.createModal.open = true;
  }

  initDetach(row: CustomField) {
    this.$refs.fieldModal.open = true;
    this.customField = row;
  }
}
