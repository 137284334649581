
// npm packages
import { Component, Vue } from "vue-property-decorator";

// our components
import IFrameContainer from '@/views/Admin/pages/Reports/components/IFrameContainer';

@Component({
  components: {
    IFrameContainer,
  },
})
export default class ReportsCustomers extends Vue {
  mounted() {
    /* @ts-ignore */
    analytics.track('view_customers_report', {
      
    });
  }
}
