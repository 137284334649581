
import {Component, Prop, Watch, Vue} from "vue-property-decorator"
import {Item} from '@/services/SOLO'
import { translations } from '@/mixins'
import { Table, TableColumn, Button } from "element-ui"

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
  mixins: [translations],
})
export default class SubscriberItemList extends Vue {
  @Prop() list!: []
  @Prop() loading: boolean = false
  color: string = 'white'
  currentPage: number = 1
  customer: any = {
    id: 0,
    attributes: {

    }
  }

  @Watch('loading', {deep: true})
  onLoadingChanged(newVal: any) {
    this.loading = newVal
  }

}
