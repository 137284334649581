
import {translations} from '@/mixins'
import {mapGetters} from 'vuex'
import {Select, Option} from "element-ui";
import { Integrations } from '@/services/SOLO'
import {
ValidationProvider,
} from "vee-validate";
import {Component, Prop, Vue, Watch, Emit,} from "vue-property-decorator";

@Component({
components: {
ValidationProvider,
[Select.name]: Select,
[Option.name]: Option,
},
computed: {
...mapGetters({
  getConceptSettings: "account/getConceptSettings",
  getUser: 'account/getUser',
  getUserClient: 'account/getUserClient'

}),

},
mixins: [translations],
})
export default class WebAddressSetModal extends Vue {
$notify: any;
getConceptSettings!: any
getUserClient!: any
getUser!: any
$refs!: {
file: any;
}
isLoading: Boolean = false;
deliveryCharge: any = "";
deliveryChargeID: any = "";
v_radio: any = "skip";
menu_grp: any = "";
payload: any = {}
@Prop() open!: Boolean
@Prop() deliveryCharges!: any
@Prop() menuGroups!: any
get isOpen(): Boolean {
return this.open;
}
set isOpen(newVal: Boolean) {
if (!newVal) {
}
}

constructor() {
super();
}

mounted() {
}

returnValueCharge(e: any) {
    this.deliveryChargeID = e.id;
    return e.value;
}

async nextToLogo() {
   this.isLoading = true;  
   console.log("check radio data", this.v_radio);


   if(this.v_radio == "skip") {
     this.payload = {
        'action': 'skip'
    }
   }
   if(this.v_radio == "import") {
     this.payload = {
        'action': 'menu',
        'label': this.menu_grp.name,
        'code': this.menu_grp.id
     }
   }
   if(this.v_radio == "all") {
    this.payload = {
        "action": "all"
    }
   }

   await Integrations.storeOnBoardingMenu(this.payload)
        .then((response) => {
          this.$notify({
            title: "Menu Setup",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Menu Setup successfully installed.",
            type: "success",
            icon: "fas fa-check",
          });
          this.isLoading = false;
          this.$emit("modal:close", false, this.v_radio);
        })
        .catch((err) => {
          this.isLoading = false;
          if(err.response.data.error[0]?.detail) {
            this.$notify({
              title: "Menu Setup",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: err.response.data,
              type: "danger",
              icon: "fas fa-bomb",
            });
          }
          
        })
}




}
