
import Modal from '@/components/Modal.vue'
import {Select, Option} from 'element-ui'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import { Settings } from '@/services/SOLO';
import { GlobalIng, Ingredient, ModifierGroupItem } from '@/models'

interface itemIngredient {
  ingredient: string,
  quantity: number
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  mixins: [eventHandler, translations]
})
export default class GlobalIngredientsModal extends Vue {
    
  itemIngredient: itemIngredient = {
    ingredient: '',
    quantity: 1
  }
  
  @Prop() open!: Boolean
  @Prop() globalIngredients!: Array<GlobalIng>
  @Prop() itemIngredients!: Array<GlobalIng>

  waiting!: Function
  restore!: Function
  translate!: Function
  $notify: any

  get filteredGlobalIngredients(): Array<GlobalIng> {
    return this.globalIngredients.filter((ing: any) => {            
      return !this.itemIngredients.includes(ing.id)
    })
  }

  get isOpen(): Boolean {    
    return this.open
  }

  set isOpen(newVal: Boolean) {    
    this.$emit('modal:update', newVal)
  }

  dec() {
    if(this.itemIngredient.quantity) {
      this.itemIngredient.quantity-- 
    }
  }

  inc() {
    this.itemIngredient.quantity++
  }

  addIngredient(e: HTMLFormElement) {
    const defaultText = e.submitter.innerHTML
    this.waiting(e, '<i class="fas fa-spinner fa-spin"></i> Saving')
    Settings.addIngredient(this.itemIngredient, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId)
      .then((response: any) => {
        // this.restore(e, defaultText)
        this.$emit('ingredient:saved')
        this.$emit('modal:update', false)
        this.$notify({
          title: "DATA SAVED",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Modifier has been added!",
          type: "success",
          icon: "fas fa-check",
        })
      })
      .catch((err: any) => {
        console.error(err)
        this.restore(e, defaultText)
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb",
        });
      })
  }
}
