
import {mapGetters, mapMutations} from 'vuex';
import { Option, Select } from 'element-ui';
import Translations from './Translations.vue'
import { translations } from '@/mixins'
import MenuCategoryModal from "./MenuCategoryModal";
import MenuCreateModal from "./MenuCreateModal";
import { Category, Integrations, Menu as MenuApi } from "@/services/SOLO";
import { Category as CategoryModel } from "@/models";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import VueRouter from "vue-router";
import { eventHandler } from "@/mixins";
import menuRoles from '@/directives/menuRoles';
import * as Ably from 'ably';
import LoadingPanel from "@/components/LoadingPanel";
import draggable from "vuedraggable";
import DisableCategoryModal from './categoryComponents/DisableCategoryModal';

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    RouteBreadCrumb,
    MenuCategoryModal,
    MenuCreateModal,
    Translations,
    LoadingPanel,
    draggable,
    DisableCategoryModal,
  },
  computed: {
    ...mapGetters({
      activeConcept: 'account/activeConcept',
      getMenus: 'menu/getMenus',
      getLocale: 'app/getLocale',
      userRole: 'account/getUserRole',
      getUserClient: 'account/getUserClient',
    })
  },
  directives: {
    menuRoles
  },
  methods: {
    ...mapMutations({
      setMenus: 'menu/setMenus',
    }),
  },
  mixins: [eventHandler, translations],
})
export default class Menu extends Vue {
  getLocale!: any;
  private getUserClient!: any;
  open: Boolean = false;
  loading: Boolean = false;
  isDisplay: Boolean = false;
  categories: Array<CategoryModel> = [];
  $notify: any;
  logo: string = '/img/menu_default.png';
  defaultIMG: string = "https://solo.skylinedynamics.com/images/default-image.png";
  public translate!: Function;
  advancedTools: string = '';
  mobileButtons: string = '';
  advancedToolsOptions: Array<any> = [
    {
      label: 'Hard Reset',
      value: 'hard_reset',
    },
    {
      label: 'Add Menu',
      value: 'add_menu',
    },
    {
      label: 'Delete Current Menu',
      value: 'delete_current_menu',
    },
  ];
  mobileButtonsList: Array<any> = [
    {
      label: 'Add Category',
      value: 'add_category',
    },
        {
      label: 'Sync POS',
      value: 'sync_pos',
    },
        {
      label: 'Upsells',
      value: 'upsells',
    },
    {
      label: 'Hard Reset',
      value: 'hard_reset',
    },
    {
      label: 'Add Menu',
      value: 'add_menu',
    },
    {
      label: 'Delete Current Menu',
      value: 'delete_current_menu',
    },
  ];
  openCreateMenu: boolean = false;
  private activeConcept!: any;
  private getMenus!: any;
  private setMenus!: any;

  page: number = 1
  total: number = 0;
  currentPage: number = 1;
  perPage: number = 0;
  loadingConcept: Boolean = true;
  userRole!: any;
  isDisableCategoryOpen: Boolean = false;
  categoryId: any = 0;
  showGripIconItem: any = null;
  uploadImgCategoryId: any = 0;
  indexImgCategoryId: any = 0;
  $refs!: {
    file: any;
  };

  @Watch('advancedTools', { immediate: true, deep: true })
  onAdvancedToolsChange(newVal: string) {
    switch (newVal) {
      case 'hard_reset': {
        this.resetMenu();
        this.advancedTools = '';
        break;
      }
      case 'add_menu': {
        this.openCreateMenu = true;
        this.advancedTools = '';
        break;
      }
      case 'delete_current_menu': {
        this.deleteMenu();
        this.advancedTools = '';
        break;
      }
    }
  }
  @Watch('mobileButtons', { immediate: true, deep: true })
  onMobileButtonChange(newVal: string) {
    switch (newVal) {
      case 'add_category': {
        this.modalOpenState(true);
        this.mobileButtons = '';
        break;
      }
      case 'sync_pos': {
        this.syncPos();
        this.mobileButtons = '';
        break;
      }
      case 'upsells': {
        this.redirectToUpsells();
        this.mobileButtons = '';
        break;
      }
      case 'hard_reset': {
        this.resetMenu();
        this.mobileButtons = '';
        break;
      }
      case 'add_menu': {
        this.openCreateMenu = true;
        this.mobileButtons = '';
        break;
      }
      case 'delete_current_menu': {
        this.deleteMenu();
        this.mobileButtons = '';
        break;
      }
    }
  }

  @Watch("menuId", { immediate: true, deep: true })
  onMenuIDchange(newVal: string, oldVal: string) {
    this.loadingConcept = true;
    this.getCategory();
  }

  confirm!: Function;
  ablyUnsubscribe: any;
  // broadcasted!: Function
  broadcastedSoloMenu!: Function

  mounted() {
    // this.getCategory();
    this.hideDeskbutton();
    console.log("checkSize", window.screen.width);

    // this.ablyUnsubscribe = this.broadcasted(this.activeConcept.id);
    this.ablyUnsubscribe = this.broadcastedSoloMenu(this.activeConcept.id);
    this.ablyUnsubscribe.subscribe((payload: any) => {
      console.log('payload', payload)
      this.loading = false;
      if (payload.name == 'Solo\\Menu') {
        this.$notify({
          title: this.translate("MENU SYNC!"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate("Sync completed!"),
          type: "success",
          icon: "fas fa-check",
        });
        setTimeout(() => {
          location.reload();
        }, 2000);
      }
    });
  }

  sortCategories() {
    const promiseArr: any = [];
    let dynamicIndex = ((this.currentPage - 1) * this.perPage) + 1;
    this.categories.forEach((item: any) => {
      MenuApi.update({'display-order': dynamicIndex}, this.menuId, item.id);
      dynamicIndex += 1;
    });
  }

  get menuId(): string {
    return this.$route.params.id;
  }

  @Emit()
  @Watch("categories", { immediate: true, deep: true })
  onCategoriesChanged(newVal: Array<CategoryModel>) {
    return newVal;
  }

  onHoverCard(ind: any){
    this.showGripIconItem = ind
  }

  uploadImage(categoryId: any, index: any) {
    this.uploadImgCategoryId = categoryId;
    this.indexImgCategoryId = index;
    /* @ts-ignore */
    document.getElementById('upload').click();
  }

  filesUploadChange() {
    let formData = new FormData();
    formData.append('image', this.$refs.file.files[0]);
    MenuApi.updateFormdata(
      formData,
      this.menuId,
      this.uploadImgCategoryId
    )
    .then((response: any) => {
      this.categories[this.indexImgCategoryId].attributes['image-uri'] = response.data.data.attributes['image-uri'];
      this.$notify({
        title: "UPDATES SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Image have been upload successfully",
        type: "success",
        icon: "fas fa-check",
      });
    })
    .catch((err: any) => {
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb",
      });
    });
  }

  checkSize(text: any) {
    if(this.getLocale === 'en_US') {
        if(text === 'The image field size must be less than 2000KB') {
          return 'The image file size must be less than 2MB'
        }
    }
    if(text === 'The image field size must be less than 2000KB') {
          return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }
  }

  pageChange(data: any) {
    this.page = data;
    console.log('page', data);
    this.getCategory();
  }

  get deleteMsgBoxText(): String {
    return this.translate("Are you sure you want to delete this Category? Deleted Category will not show when you sync");
  }

  confirmationMessageTitle(toDelete: any) {
    const h = this.$createElement
    const message = h('div', 
      { domProps: 
        { innerHTML: this.translate('Are you sure') + ` <span class="font-weight-bold">${toDelete.attributes['name']}${this.translate('?')}</span><br><br>${this.translate('Please note')}`}
      })
    
    return message
  }

  checkScreenWith() {
    if(window.screen.width == 1366) {
      return 3;
    }
      return 2;
  }

  modalOpenState(open: Boolean = true) {
    this.open = open
  }

  modalCloseState(open: Boolean = false) {
    this.open = open
  }

  hideDeskbutton() {
    if (!this.isMobile()) {
      this.isDisplay = true;
    } else {
      this.isDisplay = false;
    }
  }

  isMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }


  getApps () {
    console.log('getApps: ', this.activeConcept);
    if (this.activeConcept) {
      MenuApi.all().then((response: any) => {
        console.log('response: ', response);
        this.setMenus(response.data.data);
      }).catch((err: any) => {
        console.log(err);
      });
    }
  }

  handleCloseCreateMenu(): void {
    this.openCreateMenu = false;
    this.getApps();
  }

  async removeCategory(category: any) {
    try {
      const isDelete = await this.confirm(this.$bvModal, this.confirmationMessageTitle(category),
        {
          title: this.translate('Please Confirm'),
          okTitle: this.translate('OK'),
          cancelTitle: this.translate('Cancel'),
          footerClass: this.getLocale === 'ar_SA' ? 'd-flex flex-row-reverse flex-gap' : '',
        }
      )
      if (isDelete) {
        await Category.removeCategory(this.menuId, category.id)
        await this.findAndUpdateItem(category.id, 'remove');
        this.$notify({
          title: this.translate("CATEGORY DELETED"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate("Category Successfully Deleted."),
          type: "success",
          icon: "fas fa-check",
        });
      }
    } catch (error) {
      this.$notify({
        title: this.translate("SYSTEM ERROR!"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate("Something went wrong, please try again!"),
        type: "danger",
        icon: "fas fa-bomb",
      });
    }
  }

  enableCategory(enabled: Boolean, categoryId: string) {
    let payload = {
      enabled: enabled,
    };
    MenuApi.update(payload, this.menuId, categoryId).then((response) => {
      this.findAndUpdateItem(categoryId, 'enableDisable', payload.enabled);
    });
  }

  enableNonAdminCategory(categoryId: string) {
    let payload = {
      'schedule-disabled-hours': '__enabled__',
    };
    MenuApi.update(payload, this.menuId, categoryId).then((response) => {
      this.findAndUpdateItem(categoryId, 'enableNonAdminCategory', true);
    });
  }

  disableCategoryModal(open: any, categoryId: string) {
    this.isDisableCategoryOpen = !!open;
    this.categoryId = categoryId;

    if (!open) {
      this.categories.forEach(element => {
        if (element.id == categoryId) {
          element.attributes['is-enabled-for-employee'] = open;
        }
      });
    }
  }

  closeCategoryModal(close: any) {
    this.isDisableCategoryOpen = !!close;
  }

  findAndUpdateItem(categoryId: string, action: string, value: Boolean = true) {
    switch (action) {
      case 'enableDisable':
        this.categories.forEach(element => {
          if (element.id == categoryId) {
            element.attributes.enabled = (value == true ? 1 : 0);
          }
        });
        break;
      case 'remove':
        this.categories.forEach((element: any, index: any) => {
          if (element.id == categoryId) {
            this.categories.splice(index, 1);
          }
        });
        break;
      case 'enableNonAdminCategory':
        this.categories.forEach(element => {
          if (element.id == categoryId) {
            element.attributes['is-enabled-for-employee'] = true;
          }
        });
        break;
    }
  }

  public get isFoodics() {
    return this.getUserClient?.attributes?.label === 'Foodics Online';
  }

  getCategory() {
    let lang = '';
    if (this.getLocale == 'en_US') {
      lang = 'en-us';
    }
    if (this.getLocale == 'ar_SA') {
      lang = 'ar-sa';
    }if (this.getLocale == 'fr_FR') {
      lang = 'fr';
    }
    Category.all(this.menuId, this.page).then((response: any) => {
      this.categories = response.data.data;
      this.loadingConcept = false;

      this.total = response.data.meta.pagination.total;
      this.currentPage = response.data.meta.pagination.current_page;
      this.perPage = response.data.meta.pagination.per_page;
    }).catch((err: any) => {
      console.log(err.response)
    });
  }

  async resetMenu() {
    this.confirm(
      this.$bvModal,
      "Performing this action will delete all of your menu categories and items. You will also be logged out from the system.",
      {
        title: 'Are you sure you want to reset the menu?',
        okTitle: 'CONFIRM',
        cancelTitle: 'CANCEL',
      },
    ).then(async (value: boolean) => {
      if (value) {
        let currentMenuId = this.$route.params.id
        await MenuApi.hardReset(currentMenuId)
        setTimeout(() => {
          location.reload()
          localStorage.clear()
        }, 500);
      }
    })
  }
  async deleteMenu() {
    this.confirm(
      this.$bvModal,
      "Performing this action will delete all of your menu categories and items.",
      {
        title: 'Are you sure you want to delete current menu?',
        okTitle: 'CONFIRM',
        cancelTitle: 'CANCEL',
      },
    ).then(async (value: boolean) => {
      if (value) {
        let currentMenuId = this.$route.params.id;
        await MenuApi.deleteMenu(currentMenuId);
        // this.getApps();
        setTimeout(() => {
          // this.$router.push({ name: 'menus', params: { id: this.getMenus[0].id } });
          location.reload()
          localStorage.clear()
        }, 500);
      }
    })
  }

  async syncPos() {
    this.confirm(
      this.$bvModal,
      "You are going to synchronize with POS. Do you want to proceed?",
      {
        title: 'Attention!',
        okTitle: 'YES',
        cancelTitle: 'NO',
      },
    ).then(async (value: boolean) => {
      if (value) {
        this.loading = true;
        try {
          this.$notify({
            title: this.translate("SYNC!"),
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: this.translate("Sync started!"),
            type: "success",
            icon: "fas fa-check",
          });
          const response = await Integrations.sync('pos', this.menuId);
          /* @ts-ignore */
          analytics.track('sync_menu', {
            menud_id: this.menuId,
          });
          // this.loading = false;
          // location.reload();
        } catch (error) {
          this.$notify({
            title: this.translate("SYSTEM ERROR!"),
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: this.translate("Something went wrong, please try again!"),
            type: "danger",
            icon: "fas fa-bomb",
          });
        }

      }
    });
  }

  redirectToUpsells() {
    this.$router.push({
      name: "MenuUpsells"
    })
  }
}
