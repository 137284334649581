
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler } from '@/mixins'
import { Menu as MenuApi, Settings } from '@/services/SOLO';
import { Item as ItemModel } from '@/models'

@Component({
  components: {
    
  },
  mixins: [eventHandler]
})
export default class ComboMealItems extends Vue {
  @Prop() items!: Array<Object>

  $notify: any

  initCreate(item: any) {
    this.$emit('init:update', item)
  }

  async remove(componentId: string) {
    try {
      await MenuApi.deleteComboItem(this.$route.params.id, this.$route.params.itemId, componentId)
      this.$emit('item:delete')
      this.$notify({
        title: "COMPONENT DELETED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Component deleted successfully!",
        type: "success",
        icon: "fas fa-trash",
      });
    } catch (error) {
      console.error('Error in remove combo item', error)
    }
  }
}
