
import { Component, Prop, Mixins } from "vue-property-decorator";
import Translations from "@/mixins/translations";

@Component
export default class LogDetailsModal extends Mixins(Translations) {
  @Prop({type: Boolean, default: false}) readonly value!: boolean;
  @Prop({ type: Object }) readonly logDetails!: any;

  // get formattedOrderDetails() {
  //   return JSON.stringify(this.logDetails);
  // }

  get isOpen() {
    return this.value;
  }

  set isOpen(value) {
    this.$emit("input", value);
  }
}
