
import { mapGetters } from 'vuex'
import { Component, Vue } from "vue-property-decorator"

@Component({
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getUserClient: 'account/getUserClient',
    }),
  },
})
export default class ContentFooter extends Vue {
  public year = new Date().getFullYear();
  public getLocale!: any
  private getUserClient!: any

  public get isFoodicsOnline(): boolean {
    if (this.getUserClient) {
      return true
    }
    return false
  }

  public get getLabel() {
    if (this.getUserClient !== null) {
      const copyRight = JSON.parse(this.getUserClient.attributes.extras)
      return copyRight.copyright
    }
    return '© 2021 Solo Technology Services LLC'
  }
};
