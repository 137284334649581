
import { mapGetters, mapMutations } from "vuex";
import { eventHandler, translations } from "@/mixins";
import LangSwitcher from '@/components/LangSwitcher';
import {
  Component,
  Vue,
} from "vue-property-decorator";
import { Auth } from '@/services/core';

@Component({
  components: {
    LangSwitcher
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
  },
  mixins: [eventHandler, translations],
})
export default class AuthenticationStep1 extends Vue {
  translate: any
  constructor() {
    super();
  }

  mounted() {}
}
