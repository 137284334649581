
  import AllergenItems from './components/allergens/AllergenItems.vue'
  import CreateAllergen from './components/allergens/CreateAllergen.vue'
  import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator"
  import { translations } from '@/mixins';
  import Translations from '../Translations.vue';

  @Component({
    components: {
      AllergenItems,
      CreateAllergen,
      Translations,
    },
    mixins: [translations],
  })

  export default class Allergens extends Vue {
    translate!: Function;
    allergens: Array<Object> = []

    addAllergen(obj: any) {
      this.allergens = obj
    }
  }

  