
import Modal from '@/components/Modal.vue'
import {Select, Option} from 'element-ui'
import { translations } from '@/mixins'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler } from '@/mixins'
import {Menu as MenuApi, Settings, Item, Location, Employee, Category as CategoryApi} from '@/services/SOLO';
import {Ingredient, Location as LocationModel, ModifierGroupItem} from '@/models'

interface nameObj {
  [x: string]: any
}

interface ModgroupItem {
  name: nameObj
  'calorie-count': any
  code: string
  'display-order': any
  enabled: number
  'image-uri': string
  maximum: any
  minimum: any
  price: 0
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationObserver,
    ValidationProvider,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      userRole: 'account/getUserRole',
      getUser: 'account/getUser',
      getConceptSettings: 'account/getConceptSettings',

    }),
  },
  mixins: [eventHandler, translations]
})
export default class ModgroupModifierItem extends Vue {
  getLanguages!: Function;
  getConceptSettings!: any
  private arrayLang: Array<string> = [];
  private imageType: number = 1;
  stockImages: any = [];
  searchStock: any = '';
  isLoading: Boolean = false;
  private imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Upload Image",
    },
    {
      value: 2,
      text: "Stock Images"
    }
  ]
  private item: ModgroupItem = {
    name: {},
    'display-order': '',
    'calorie-count': '',
    code: '',
    enabled: 1,
    'image-uri': '',
    maximum: '',
    minimum: '',
    price: 0
  }

  $refs!: {
    file: any
  }
  $notify: any;
  getLocale!: any
  selectedLocations: any = [];
  disabledLocations: any = [];
  notBelongLocations: any = [];
  locations: Array<LocationModel> = [];
  employeeLocations: any = [];
  userRole!: any;
  getUser!: any;

  @Prop() private modifierGroupItems!: Array<Object>

  @Watch("imageType", {deep: true, immediate: true})
    onChangeGetSotckImages(data: any) {
        if(data === 2) {
          this.getStockImages();
        }
    }

  constructor() {
    super();
    for (let i in this.arrayLang) {
      this.item.name[this.arrayLang[i]] = "";
    }
  }

  created() {
    /* @ts-ignore */
    this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
    /* @ts-ignore */
    if (this.getConceptSettings.attributes['secondary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
    }
  }

  mounted() {

    if(this.$route.params.modifierGroupId != 'create') {
      for (let i in this.arrayLang) {
        this.getModifierItemDetails(i)
      }
    }
    this.getLocations();
  }




  selectStockImage(stockImage: any) {
    this.item['image-uri']
     = stockImage;
  }
  searchStockImages(e: any) {
    this.isLoading = true;
      CategoryApi.searchStockImgs(this.searchStock)
      .then((response) => {
        this.stockImages = response.data.data;
        this.isLoading = false;
        console.log("search stock images", this.stockImages);
      })
  }
  getStockImages() {
    CategoryApi.getStockImgs()
      .then((response) => {
        this.stockImages = response.data.data;
        console.log("stock images", this.stockImages);
      })
  }

  checkSize(text: any) {

    if(this.getLocale === 'en_US') {
        if(text === 'The image field size must be less than 2000KB') {
          return 'The image file size must be less than 2MB'
        }
    }
    if(text === 'The image field size must be less than 2000KB') {
          return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }

  }



  get isEnabled() {
    return this.item.enabled === 1 ? true : false
  }

  set isEnabled(newValue: Boolean) {
    this.item.enabled = newValue ? 1 : 0
  }

  private getModifierItemDetails(i: any) {
    MenuApi.getModifierItemDetails(this.$route.params.modifierId, this.$route.params.modifierGroupId, this.arrayLang[i])
      .then((response: any) => {
        let data = response.data.data
        const included = response.data.included;
        this.item.name[this.arrayLang[i]] = data.attributes.name
        if(!Number(i)) {
          this.item['display-order'] = data.attributes['display-order']
          this.item['calorie-count'] = data.attributes['calorie-count']
          this.item.code = data.attributes.code
          this.item.enabled = data.attributes.enabled
          this.item['image-uri'] = data.attributes['image-uri']
          this.item.maximum = data.attributes.maximum
          this.item.minimum = data.attributes.minimum
          this.item.price = data.attributes.price

          if (included.length) {
            const locationsData = included;
            const mapData = (data: any) => data.id;
            if (this.isMenuRestaurant) {
              this.getEmployeeLocations()
                .then(() => {
                  this.selectedLocations = locationsData
                    .filter((l: any) => this.employeeLocations.find((lo: any) => l?.id === lo?.id))
                    .map(mapData);
                  this.disabledLocations = [...this.selectedLocations];
                  this.notBelongLocations = locationsData
                    .map(mapData)
                    .filter((lo: any) => !this.selectedLocations.includes(lo));
                });
            } else {
              this.selectedLocations = locationsData.map(mapData);
              this.disabledLocations = locationsData.map(mapData);
            }
          }
        }
      })
  }

  private updateData(e: any) {
    let form = new FormData();
    Object.keys(this.item.name).map((key: any) => {
      form.append(`name[${key}]`, this.item.name[key]);
    })

    form.append(`display-order`, this.item['display-order']);
    form.append(`calorie-count`, this.item['calorie-count']);
    form.append(`code`, this.item.code);
    form.append(`minimum`, this.item.minimum);
    form.append(`maximum`, this.item.maximum);
    form.append(`price`, this.item.price.toString());
    form.append(`enabled`, this.item.enabled.toString());
    if (!this.imageType || this.imageType === 2) {
        form.append(`image-uri`, this.item['image-uri']);
    } else if (this.imageType) {
        form.append(`image`, this.$refs.file.files[0]);
    }
    if(this.$route.params.modifierGroupId != 'create') {
      this.updateModifierItem(e, form, this.$route.params.modifierId, this.$route.params.modifierGroupId)
    } else {
      this.storeModifierItem(e, form, this.$route.params.modifierId)
    }
  }

  private storeModifierItem(e: any, payload: FormData, modifierId: string) {
    let defaultText = e.target.innerHTML
    e.target.innerHTML = '<i class="fas fa-spinner fa-spin"></i> saving ...'
    e.target.disabled = true
    MenuApi.storeModifierItem(payload, modifierId)
      .then((response: any) => {
        e.target.innerHTML = defaultText
        e.target.disabled = false
        this.$router.push({name: 'modifier'})
      })
  }

  private updateModifierItem(e: any, payload: FormData, modifierId: string, modifierGroupId: string) {
    let defaultText = e.target.innerHTML
    e.target.innerHTML = '<i class="fas fa-spinner fa-spin"></i> saving ...'
    e.target.disabled = true
    MenuApi.updateModifierItem(payload, modifierId, modifierGroupId)
      .then((response: any) => {
        const filtered = this.selectedLocations.filter((l: any) => !this.disabledLocations.find((k: any) => k === l));
        const toBeRemoved = this.disabledLocations.filter((d: any) => !this.selectedLocations.find((s: any) => s === d)) || [];
        const promiseArray = filtered.map((l: any) => {
          const payload = {
            modifiers: [this.$route.params.modifierGroupId],
          };
          return Location.locationInactiveModifiers(payload, l);
        });
        if (toBeRemoved.length) {
          toBeRemoved.forEach((t: any) => {
            promiseArray.push(Location.removeInactiveModifiers(t, this.$route.params.modifierGroupId))
          });
        }

        e.target.innerHTML = defaultText
        e.target.disabled = false
        return Promise.all(promiseArray);
      })
      .then((response: any) => {
        // this.$router.push({ name: 'modifier' })
        location.reload()
      })
      .catch((err: any) => {
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: err.response.data.error.detail,
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }

  getEmployeeLocations() {
    if (!this.employeeLocations.length) {
      return Employee.find(this.getUser.id)
        .then((response) => {
          if (response.data.included) {
            this.employeeLocations = response.data.included;
          }
        });
    }
    return Promise.resolve();
  }

  private get isMenuRestaurant(): boolean {
    const filterRole = ['menu', 'restaurant'];
    const roles = this.userRole.filter((role: string) => filterRole.find((frole: string) => role === frole));
    return !!roles?.length;
  }

  getLocations() {
    Location.fetchLocations().then((response: any) => {
      // filter locations for menu and restaurant
      // SPO-94
      // [Web] Users :: Restaurant and Menu - Disable Locations
      this.locations = response.data.data;
      if (this.isMenuRestaurant) {
        this.getEmployeeLocations()
          .then(() => {
            this.locations = this.locations.filter((l: any) => this.employeeLocations.find((lo: any) => l?.id === lo?.id));
          });
      }
    });
  }
}
