
import { Vue, Component } from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";

import { translations } from "@/mixins";
import {
  WhatsApp as WhatsAppService
} from "@/services/SOLO";

@Component({
  mixins: [translations],
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
    }),
  },
  methods: {
    ...mapMutations({
      setIsWhatsAppVerified: 'page/setIsWhatsAppVerified',
    })
  },
})
export default class Step2 extends Vue {
  isLoading: boolean = false;
  isWABA: boolean = false;
  isFbWABA: boolean = false;
  isNumberActivated: boolean = false;
  isAllDone: boolean = false;
  getConceptSettings!: any;
  isAccountVerified: boolean = false
  private setIsWhatsAppVerified!: Function

  mounted() {
    this.checkIfAccountIsVerified()
  }

  async checkIfAccountIsVerified() {
    try {
      this.isLoading = true;
      const { id } = this.getConceptSettings;
      const { data } = await WhatsAppService.checkAccountIfVerified(id);
      
      if (data.data[0] !== undefined) {
        const { attributes } = data.data[0];

        if (attributes['is-verified']) {
          this.isWABA = true;
          this.isFbWABA = true;
          this.isNumberActivated = true;
          this.isAllDone = true;
          this.isAccountVerified = true
          this.setIsWhatsAppVerified(true);
          this.$emit('step-2-done', true)
        }
      }
    } catch (err) { 
      if (err instanceof Error) {
        console.log(err.message);
      }

      console.log('Unexpected error', err);
    }
    this.isLoading = false;
  }
}
