import config from './config'
import store from '@/store'
import { Headers } from '@/interfaces/SOLO'
import axios, { AxiosInstance, AxiosResponse } from 'axios'
import router from '@/router'

let bearerToken = ''
let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = []

const baseURL: any = process.env.VUE_APP_API_DOMAIN
const baseURL1: any = process.env.VUE_APP_API_DOMAIN

const http = axios.create();
http.interceptors.request.use((config: any) => {
    return config
})
http.interceptors.response.use((response) => {
        return response;
    }, error => {
        const { config, response: { status } } = error
        const originalRequest = config;
  
        if (status === 401) {
            if (!isRefreshing) {
                isRefreshing = true;
                if (store.getters['account/getConceptSettings'].attributes['is-2fa-enabled']) {
                    axios.get(`${baseURL}tfa/refresh-token`, config).then(response => {
                        isRefreshing = false
                        console.log(refreshSubscribers)
                        refreshSubscribers.map(cb => cb(response.data.token))
                    }).catch(() => {
                        // force logout user
                        store.commit('account/setUser', null)
                        router.push({ name: 'login' })
                    })
                }
            }
        
            return new Promise((resolve) => {
                refreshSubscribers.push((token: string) => {
                    console.log('decoded',token)
                    originalRequest.headers['Authorization'] = 'Bearer ' + token
                    const user = store.getters['account/getUser']
                    user.attributes.token = token
                    store.commit('account/setUser', user)
                    resolve(axios(originalRequest));
                });
            });
        } else {
            return Promise.reject(error);
        }
    });

let baseService = class BaseService {
    baseURL: any = baseURL
    baseURL1: any = baseURL1
    translationURL: string = ''
    headers: Headers
    $http: AxiosInstance
    conceptId: any
    activeDomain: string = ''

    constructor() {
        const key = store.getters['account/activeConcept'] && store.getters['account/activeConcept'].key || ''
        this.headers = {
            'Content-Type': 'application/json',
            'Solo-Concept': store.getters['account/isLoggedIn'] ? key : '',
            'Accept-Language': store.getters['app/getLocale'].replace('_', '-').toLowerCase()
        }

        this.$http = http;
    }



    getHeaders(additionalHeaders = {}, multipart = false) {

        this.headers = {
            'Content-Type': 'application/json',
            'Solo-Concept': '',
            'Accept-Language': store.getters['app/getLocale'].replace('_', '-').toLowerCase()
        }
        
        let defaultHeaders = this.headers;

        if (store.getters['account/isLoggedIn']) {
            defaultHeaders['Solo-Concept'] = store.getters['account/activeConcept'].key
            defaultHeaders['Authorization'] = 'Bearer ' + store.getters['account/getUser'].attributes.token
        }

        return {
            ...defaultHeaders,
            ...additionalHeaders
        }
    }


    get sendGridHeaders(): Headers {
        return {
            "content-type": "application/x-www-form-urlencoded",
        }
    }

    postMail(uri: string, data: {}, additionalHeaders = {}) {
        return this.$http.post(uri, data, {
            headers: this.getHeaders(this.sendGridHeaders),
        })
    }

    prepareUrl(url: string, params: any) {
        for (let index in params) {
            let identifier = ':' + index;
            url = url.replace(identifier, params[index]);
        }
        return url;
    }

    getQueryString(params: { [x: string]: string | number | boolean; }) {
        return (
            Object
                .keys(params)
                .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
                .join('&')
        )
    }

    post(uri: string, data: {}, additionalHeaders = {}) {
        return this.$http.post(uri, data, {
            headers: this.getHeaders(additionalHeaders),
        })
    }

    put(uri: any, data: any, additionalHeaders = {}) {
        return this.$http.put(uri, data, {
            headers: this.getHeaders(additionalHeaders),
        })
    }

    patch(uri: any, data: any, additionalHeaders = {}) {
        return this.$http.patch(uri, data, {
            headers: this.getHeaders(additionalHeaders),
        })
    }

    remove(uri: any, data: any, additionalHeaders = {}) {
        return this.$http(uri, {
            method: 'DELETE',
            headers: this.getHeaders(additionalHeaders), 
            data: data
        })
    }

    get(uri: string, data = {}, additionalHeaders = {}) {
        if (Object.keys(data).length > 0) {
            uri = `${uri}?${this.getQueryString(data)}`
        }

        return this.$http.get(uri, {
            headers: this.getHeaders(additionalHeaders),
        })
    }

    mcxGet(uri: string, data = {}, additionalHeaders = {}) {
        if (Object.keys(data).length > 0) {
            uri = `${uri}?${this.getQueryString(data)}`
        }

        return this.$http(uri, {
            method: 'GET',
            headers: {
                'Accept-Ranges': 'bytes',
                'Content-Type': 'application/json',
                'Connection': 'keep-alive'
            },
        })
    }
};

export default baseService