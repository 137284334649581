
import { translations } from '@/mixins'
import { Concept as ConceptApi, Subscription } from '@/services/SOLO'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import { ValidationObserver, configure, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import {loadStripe} from '@stripe/stripe-js';
import { mapGetters, mapMutations } from 'vuex';
@Component({
  components: {
    RouteBreadCrumb
  },
  methods: {
    ...mapMutations({
      setSubscription: 'account/setSubscription',
      setSubscriptionPaymentId: 'account/setSubscriptionPaymentId'
    })
  },
  mixins: [translations]
})
export default class Payment extends Vue {
  private payment: any = {}
  private stripe: any = null
  private paymentIntent: any
  private cardElement: any = null
  private cardHolder: string = ''

  private requiresPaymentMethod: Boolean = false
  private requiresAction: Boolean = true

  $notify: any;

  private translate!: Function
  private setSubscription!: Function
  private setSubscriptionPaymentId!: Function

  mounted() {
    
    this.initStripe()
    this.getPayment()
    this.getPaymentIntent()
    this.getSubscriptionFn()
  }

  private async initStripe() {
    let pubKey: any = process.env.VUE_APP_STRIPE_KEY    
    this.stripe = await loadStripe(pubKey);  
  }

  private getPayment() {
    Subscription.getPayment(this.$route.params.id).then(response => {      
      this.payment = response.data.data
      this.requiresPaymentMethod = this.payment.attributes['requires-payment-method']
      this.requiresAction = this.payment.attributes['requires-action']      
    })
  }

  private confirmPaymentMethod() {
    let self = this
    this.stripe.confirmCardPayment(
      this.payment.attributes['client-secret'], {
          payment_method: this.payment.attributes['payment-method']
      }
    ).then((response: any) => {
      this.getPayment()
      this.getSubscriptionFn()
      this.$notify({
        title: "INFO",
        verticalAlign: "top",
        horizontalAlign: "right",
        message: this.translate('The Payment was successful'),
        type: "success",
        icon: "fas fa-spinner fa-spin",
      });
    })
  }

  private configureStripe() {
    this.requiresPaymentMethod = true
    const elements = this.stripe.elements();
    this.cardElement = elements.create('card');
    this.cardElement.mount('#card-element');
  }

  private getPaymentIntent() {
    Subscription.getPaymentIntent().then((response: any) => {      
      this.paymentIntent = response.data.data      
    })
  }

  private getSubscriptionFn() {
      Subscription.getSubscription().then(response => {              
        this.setSubscription(response.data.data)
      }).catch(err => {
        console.log(err.response)
      })
    }
}
