
import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import Modal from '@/components/Modal.vue'
@Component({
  components: { Modal }
})
export default class PermissionWarning extends Vue {
  notify: boolean = false
  success: boolean | null = null
  closeModal() {
    this.notify = false
    if(this.success)
      this.$router.push({ name: 'dashboard' })
  }
}
