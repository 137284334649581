
import { Table, TableColumn, Button, Form, FormItem, Select, Option, Checkbox } from 'element-ui'
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Employee, Topic } from '@/services/SOLO'
import { translations, notificationAlerts } from '@/mixins'
import Translations from './Translations.vue'
import {
  ValidationProvider,
} from "vee-validate";

interface CustomerDetails {
  'account-type'?: string,
  'code'?: string,
  'created-at'?: string,
  'email'?: string,
  'first-name'?: string,
  'last-name'?: string,
  'mobile'?: string,
  'pincode'?: number,
  'status'?: string,
  'updated-at'?: string
}

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    ValidationProvider,
  },
  mixins: [translations, notificationAlerts],
})
export default class CustomerUpdateModal extends Vue {
  @Prop() openEdit: boolean = false;
  @Prop() topic?: any;
  @Prop() topicIndex?: any;
  public translate!: Function;
  modalForm: any =  {
    name: {
      'en-us': '',
      'ar-sa': '',
    },
    type: 1,
  };
  successNotification!: Function;
  systemErrorNotification!: Function;
  public getLanguages!: Function;
  public arrayLang: Array<string> = [];

  mounted() {
    this.arrayLang = this.getLanguages();
  }

  @Watch('topic', { deep: true })
  onTopicChanged(newVal: any) {
    if (newVal) {
      this.modalForm.name = newVal.attributes.name;
      
      if (newVal.attributes.type == 'delivery') {
        this.modalForm.type = 1;
      } else if (newVal.attributes.type == 'non-delivery') {
        this.modalForm.type = 2;
      }
    }
  }

  handleClose() {
    this.clearForm();
    this.$emit('handleClose', false);
  }

  clearForm() {
    this.modalForm.name[this.arrayLang[0]] = '';
    this.modalForm.name[this.arrayLang[1]] = '';
    this.modalForm.type = 1;
  }

  onUpdateTopic() {
    if (this.modalForm.name[this.arrayLang[0]] && this.modalForm.name[this.arrayLang[1]]) {
      const payload = {
        name: {
          [this.arrayLang[0]]: this.modalForm.name[this.arrayLang[0]],
          [this.arrayLang[1]]: this.modalForm.name[this.arrayLang[1]],
        },
        type: this.modalForm.type,
      };
      if (this.topic) {
        Topic.updateTopic(this.topic.id, payload)
          .then(() => {
            this.clearForm();
            this.$emit('handleClose', false);
            this.successNotification('TOPIC UPDATED!', 'Topic successfully updated!');
          })
      }
    } else {
      alert('You need to fill in all fields!');
    }
  }
}
