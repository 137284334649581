
import ModGroup from "./modifiercomponents/Modgroup.vue";
import GlobalIngredients from "./ingredients/GlobalIngredients.vue";
import GlobalAllergens from "./allergens/GlobalAllergens.vue";
import CustomFields from "./customfields/CustomFields.vue";
import ItemTimedEvents from "./timedevents/ItemTimedEvents.vue";
import ItemUpdate from "./ItemUpdate.vue";
import AdvanceSettings from "./AdvanceSettings.vue";
import ComboMeals from "./combomeals/ComboMeals.vue"
import { ElNotification } from "element-ui/types/notification";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Menu } from "@/services/SOLO";
import { Item as ItemModel } from "@/models";
import { translations } from '@/mixins'
import menuRoles from '@/directives/menuRoles';
import { mapGetters } from "vuex";
import ItemAuditLogs from "./ItemAuditLogs.vue"

@Component({
  components: {
    ModGroup,
    ItemUpdate,
    GlobalIngredients,
    GlobalAllergens,
    CustomFields,
    ItemTimedEvents,
    ComboMeals,
    ItemAuditLogs,
    AdvanceSettings
  },
  directives: {
    menuRoles
  },
  computed: {
    ...mapGetters({
      getUser: 'account/getUser'
    })
  },
  mixins: [translations]
})
export default class itemModifier extends Vue {
  tab: number = 2
  item: ItemModel = {
    attributes: {
      'is-combo': null
    },
    id: undefined,
    links: undefined,
    relationships: undefined,
    type: undefined
  };
  translate!: Function

  created() {
    this.getItem();
  }

  mounted() {
    if (this.$route.params.hasOwnProperty("tab")) {
      this.tab = Number(this.$route.params.tab);
    }
  }

  get dropDownText(): string {
    if (this.tab === 1) {
      return this.translate('Modifier Groups')
    } else if (this.tab === 2) {
      return this.translate('Items Ingredients')
    } else if (this.tab === 3) {
      return this.translate('Allergens')
    } else if (this.tab === 4) {
      return this.translate('Custom Fields')
    } else if (this.tab === 5) {
      return this.translate('Timed Events')
    } else if (this.tab === 6) {
      return this.translate('Change Logs')
    } else if (this.tab === 7) {
      return this.translate('Update Item')
    } else if (this.tab === 8) {
      return this.translate('Combo Components')
    } else {
      return this.translate('Advance Settings')
    }
  }

  async getItem() {
    try {
      const response = await Menu.items(this.$route.params.id, this.$route.params.catId, this.$route.params.itemId);
      this.item = response.data.data
    } catch (error) {
      console.error('Error in getting item', error);
    }
  }
}
