
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import { Table, TableColumn, Button, Form, FormItem, Select, Option } from 'element-ui';
import { translations } from '@/mixins'
import { Customer } from '@/services/SOLO';
import moment from 'moment';

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },
mixins: [translations],
})
export default class BlacklistSingleModal extends Vue {
  @Prop() blacklistSingle?: Object
  public translate!: Function
  isOpen: boolean = false
  form: any = {
    attributes: {
      value: '',
      type: '',
      'created-at': '',
      'updated-at': ''
    }
  }
  filterTypeOptions: Array<any> = [
    {
      label: 'Phone number',
      value: 'phone_number',
    },
    {
      label: 'Email',
      value: 'email',
    },
  ];
  formStatus: string = ''

  standardDateFormat(value: any)  {
    if (! value) return '---';
    return moment(value).format('DD/MM/YYYY');
  }

  @Watch('openEdit', {deep: true})
  onOpenEditChanged(newVal: any) {
    this.isOpen = newVal
  }
  @Watch('blacklistSingle', { deep: true })
  onBlacklistSingleChanged(newVal: any) {
    if (newVal) {
      this.form = newVal;
      this.formStatus = 'Update'
    } else {
      this.formStatus = 'Add'
    }
    this.isOpen = true;
  }

  onSave(): void {
    const payload = {
      type: this.form.attributes.type,
      value: this.form.attributes.value,
    };
    const notifyOptions: any = {
      title: `${this.form?.id ? 'UPDATE' : 'CREATE'} DATA`,
      verticalAlign: 'bottom',
      horizontalAlign: 'left',
      message: `Blacklist data was ${this.form?.id ? 'updated' : 'created'} successfully!`,
      type: 'success',
    }
    if (this.form?.id) {
      Customer.updateBlacklist(this.form.id ,payload)
        .then(() => {
          this.$notify(notifyOptions);
          this.isOpen = false;
          this.$emit('close');
        });
    } else {
      Customer.createBlacklist(payload)
        .then(() => {
          this.$notify(notifyOptions);
          this.isOpen = false;
          this.$emit('close');
        });
    }
  }

  handleClose() {
    this.isOpen = false;
  }
}
