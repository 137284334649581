
import { mapGetters, mapMutations } from 'vuex'
import { Concept } from '@/interfaces/SOLO'
import { Sidebar } from '@/interfaces/Landing'
import { Concept as ConceptApi, Menu } from '@/services/SOLO'
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator'
import { Menus } from '@/models'
@Component({
  computed: {
    ...mapGetters({
      getConcepts: 'account/getConcepts',
      activeConcept: 'account/activeConcept',
      getMenus: 'menu/getMenus'
    })
  },
  methods: {
    ...mapMutations({
      setUserConcept: 'account/setUserConcept',
      setMenus: 'menu/setMenus'
    })
  }
})
export default class ConceptListing extends Vue {
  getConcepts!: Array<Concept>
  getMenus!: Array<Menus>
  conceptIsVisible: boolean = false
  activeConcept!: Concept
  filterText: string = ''
  menuRoutes: Array<any> = ['menus', 'menu', 'updatecategory', 'items', 'itemlist', 'itemUpdate', 'itemModifier']

  setUserConcept!: Function
  setMenus!: Function

  $sidebar!: Sidebar
  $root!: any

  parent!: any

  constructor() {
    super()

  }

  mounted () {
    this.parent = this.$parent?.$parent
    this.setDefaultConcept()
  }

  get filtered(): Array<Concept> {
    if(this.filterText === '')
      return this.getConcepts
    else
      return this.getConcepts.filter((concept: Concept) => {
        return concept.label.toLowerCase().includes(this.filterText.toLowerCase())
      })
  }

  showConcepts (allow: boolean) {
    this.$root.conceptIsVisible = allow
  }

  setDefaultConcept () {
    if(this.getConcepts.length && !this.activeConcept) {
      this.setUserConcept(this.getConcepts[0])
      this.getApps()
    }
  }

  setConcept (concept: Concept) {
    this.setUserConcept(concept)
    this.showConcepts(false)
    this.getApps()
  }

  getApps () {
    if(this.activeConcept) {
      this.$root.isLoading = true
      Menu.all().then((response: any) => {
        console.log('getApps: ', response);
        this.setMenus(response.data.data)
        this.$root.isLoading = false
        console.log(this.menuRoutes.includes(this.$route.name) && this.$route.path != this.parent.$refs.menus[0].link.path)
        if(this.menuRoutes.includes(this.$route.name) && this.$route.path != this.parent.$refs.menus[0].link.path) {
          this.$router.push({ path: this.parent.$refs.menus[0].link.path })
        }else {
          window.location.reload()
        }
      }).catch((err: any) => {
        console.log(err)
      })
    }
  }

  test(e: HTMLElement) {
    console.log(e)
  }
}
