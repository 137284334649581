
import { translations } from "@/mixins";
import VueTimepicker from "vue2-timepicker";
import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css' 
import moment from "moment";
import {mapGetters} from "vuex";
@Component({
  components: {
    VueTimepicker,
  },
  computed: {
    ...mapGetters({
    }),
  },
  mixins: [translations]
})
export default class StoreTimes extends Vue {
  days: Array<any> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  @Prop() openingHours!: Array<any>;
  @Prop() openingHoursDelivery!: Array<any>;
  @Prop() openingHoursPickup!: Array<any>;
  toggle: Boolean = false;
  hasStoreTimes: Boolean = false;
  

  @Watch('toggle', { immediate: true, deep: true })
  onToggle(value: Boolean) {
    console.log('hasStoreTimes toggle', this.toggle, this.hasStoreTimes);
    this.$emit("setDefaultHours", value, this.hasStoreTimes);
  }

  // @Watch('openingHoursDelivery', { immediate: true, deep: true })
  // onOpeningHoursDeliveryChanged(value: any) {
  //   console.log(value)
  // }

  @Watch('openingHours', { immediate: true, deep: true })
  onOpeningHoursChanged(hours: any) {
    console.log('hasStoreTimes openingHours', hours);
    hours.map(
      (item: any) => {
        item.open = moment(`${item.open}`, "hh:mm A").format("HH:mm");
        item.closed = moment(`${item.closed}`, "hh:mm A").format("HH:mm");

        return item;
      }
    );
  }

  @Watch('openingHoursDelivery', { immediate: true, deep: true })
  onOpeningHoursDeliveryChanged(hours: any) {
    console.log('hasStoreTimes openingHoursDelivery', hours);
    hours.map(
      (item: any) => {
        item.open = moment(`${item.open}`, "hh:mm A").format("HH:mm");
        item.closed = moment(`${item.closed}`, "hh:mm A").format("HH:mm");

        return item;
      }
    );
  }

  @Watch('openingHoursPickup', { immediate: true, deep: true })
  onOpeningHoursPickupChanged(hours: any) {
    console.log('hasStoreTimes openingHoursPickup', hours);
    hours.map(
      (item: any) => {
        item.open = moment(`${item.open}`, "hh:mm A").format("HH:mm");
        item.closed = moment(`${item.closed}`, "hh:mm A").format("HH:mm");

        return item;
      }
    );
  }

  mounted() {
    this.openingHours         = this.openingHours;
    this.openingHoursDelivery = this.openingHoursDelivery;
    this.openingHoursPickup   = this.openingHoursPickup;
  }

  get hasDeliveryAndPickup(): Boolean {
    return this.toggle
  }

  set hasDeliveryAndPickup(value: Boolean) {
    this.toggle = value
  }

  // private timeValidationRules() {
  //   return { regex: /^[0-9][0-9]:[0-9][0-9] [AaPp][Mm]/ };
  // }
}
