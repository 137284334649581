
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { mapGetters } from "vuex";
import {Order as OrderApi, Location} from "@/services/SOLO";
import Translations from "./components/Translations.vue";
import { translations, eventHandler } from "@/mixins";

@Component({
  computed: {
    ...mapGetters({
      getLocale: "app/getLocale",
    }),
  },
  mixins: [translations, eventHandler],

})
export default class LocationItems extends Vue {

  orders: any = [];
  @Prop() locations?: any;
  private getLocale!: Function;
  $notify: any;
  public translate!: Function;

  private arrayLang: Array<string> = ["en-us", "ar-sa"];

  private enable(status: string, id: string) {
    let payload = {
      status: status === "active" ? "inactive" : "active",
    };
    this.$emit("enable", payload, id);
  }

  public remove(id: string) {
    this.$emit("remove", id);
  }

  private pan(lat: number, long: number) {
    let payload = {
      lat: lat,
      long: long,
    };
    this.$emit("pan", payload, 16);
  }

  iconSrc(src: string) {
    return `/img/order icons/${src.toLowerCase()}.png`
  }

  private updateIconStatus(enabled: boolean, locationId: string, locationIndex: any, type: any) {

    switch (type) {
      case 'curbside':
        Location.updateLocation({'curbside-enabled': enabled}, locationId)
          .then(response => {
            this.$notify({
              title: "SUCCESS!",
              horizontalAlign: "right",
              message: `Curbside is ${enabled ? 'enabled': 'disabled'}`,
              type: "success",
              // icon: "fas fa-spinner fa-spin",
            })
            this.locations[locationIndex]['attributes']['curbside-enabled'] = response.data.data.attributes['curbside-enabled']
        })
        break
      case 'delivery':
        Location.updateLocation({'delivery-enabled': enabled}, locationId)
          .then(response => {
            this.$notify({
              title: "SUCCESS!",
              horizontalAlign: "right",
              message: `Delivery is ${enabled ? 'enabled': 'disabled'}`,
              type: "success",
              // icon: "fas fa-spinner fa-spin",
            })
            this.locations[locationIndex]['attributes']['delivery-enabled'] = response.data.data.attributes['delivery-enabled']
        })
        break
      case 'pickup':
        Location.updateLocation({'pickup-enabled': enabled}, locationId)
          .then(response => {
            this.$notify({
              title: "SUCCESS!",
              horizontalAlign: "right",
              message: `Pickup is ${enabled ? 'enabled': 'disabled'}`,
              type: "success",
              // icon: "fas fa-spinner fa-spin",
            })
            this.locations[locationIndex]['attributes']['pickup-enabled'] = response.data.data.attributes['pickup-enabled']
        })
        break
    }
  }
}
