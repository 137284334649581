
import Modal from '@/components/Modal.vue'
import {Select, Option} from 'element-ui'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import { Settings } from '@/services/SOLO';
import { GlobalIng, GlobalAllergens as GlobalAllergensModel, ModifierGroupItem } from '@/models'

interface itemAllergen {
  allergen: string  
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  mixins: [eventHandler, translations]
})
export default class GlobalAllergensModal extends Vue {
    
  itemAllergen: itemAllergen = {
    allergen: ''    
  }
  
  @Prop() open!: Boolean
  @Prop() globalAllergens!: Array<GlobalAllergensModel>
  @Prop() itemAllergens!: Array<GlobalAllergensModel>

  waiting!: Function
  restore!: Function
  $notify: any

  get filteredGlobalAllergens(): Array<GlobalAllergensModel> {
    return this.globalAllergens.filter((ing: any) => {            
      return !this.itemAllergens.includes(ing.id)
    })
  }

  get isOpen(): Boolean {    
    return this.open
  }

  set isOpen(newVal: Boolean) {    
    this.$emit('modal:update', newVal)
  }
  

  addAllergen(e: HTMLFormElement, reset: any) {
    const defaultText = e.submitter.innerHTML
    this.waiting(e, '<i class="fas fa-spinner fa-spin"></i> Saving')
    Settings.addAllergen(this.itemAllergen, this.$route.params.itemId)
      .then((response: any) => {
        this.restore(e, defaultText)
        this.$emit('allergen:saved')
        reset()
        this.itemAllergen = {
          allergen: ''          
        }
        this.$notify({
          title: "DATA SAVED",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Allergen has been added!",
          type: "success",
          icon: "fas fa-check",
        })
      })
      .catch((err: any) => {
        console.error(err)
        this.restore(e, defaultText)
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb",
        });
      })
  }
}
