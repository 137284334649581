
import {
  Employee,
  Location,
  Concept as ConceptService,
  Integrations as IntegrationService, Location as LocationService, Subscription as SubscriptionApi 
} from "@/services/SOLO";
import SearchItem from "./components/SearchItem.vue";
import LocationItems from "./components/LocationItems.vue";
import DriverItems from "./components/DriverItems.vue";
import ChangeAreaNameModal from "./components/ChangeAreaNameModal.vue";
import LocationModal from "./components/LocationModal.vue";
import TemporaryDeliveryModal from "./components/TemporaryDeliveryModal.vue";
import GoogleMapsLoader from "google-maps";
import {Component, Vue, Watch} from "vue-property-decorator";
import {notificationAlerts, translations, eventHandler} from "@/mixins";
import ConfirmCard from "./components/ConfirmCard.vue";
import {mapGetters, mapMutations} from "vuex";
import {Location as LocationModel} from "@/models";
import moment from "moment";
import Translations from "./components/Translations.vue";

GoogleMapsLoader.LIBRARIES = ["drawing"];
GoogleMapsLoader.KEY = "AIzaSyBGrCkKHoHh3nvofpx3OhbVZpkG9cOSf9Q";

interface Enable {
  branches: Boolean;
  delivery: Boolean;
  drivers: Boolean;
}

interface Store {
  [x: string]: any;
}

interface DeliveryAreaObjectAttributes {
  coordinates: Array<string>;
}

interface DeliveryAreaObject {
  id: "string";
  attributes: DeliveryAreaObjectAttributes;
  type: "string";
}

interface ojbKey {
  [x: string]: string;
}

interface ConceptAttributes {
  "default-opening-hours": string;
  "default-promised-time-delta-delivery": any;
  "default-promised-time-delta-pickup": any;
  "default-delivery-charge": number;
  country: string;
  "default-pos": string;
  "delivery-charge-per-km": number;
  "delivery-maximum-distance": number;
}

interface ConceptObject {
  id: string;
  attributes: ConceptAttributes;
}

interface LocationObject {
  id: string;
  name: any;
  status: string;
  telephone: string;
  pos: string;
  email: string;
  country: string;
  lat: string;
  long: string;
  "delivery-enabled": boolean;
  "pickup-enabled": boolean;
  "curbside-enabled": boolean;
  "open-24-hours": boolean;
  "is-inventory-sync-enabled": boolean;
  "is-drive-thru-enabled": boolean;
  "has-prompt-for-table-number": boolean;
  "delivery-charge": number;
  logistics: any;
  'logistic-selection-type': any;
  line1: any;
  "promised-time-delta": ojbKey;
  "opening-hours": Array<any>;
  "opening-hours-deliver": Array<any>;
  "opening-hours-pickup": Array<any>;
  "image-uri": any;
  "schedule-disabled-location-hour": string;
  "delivery-charge-per-km": number;
  "delivery-maximum-distance": number;
}

let defaultLocationObject = {
  id: '',
  name: {},
  status: "active",
  telephone: "",
  pos: "",
  email: "",
  country: "",
  lat: "",
  long: "",
  "delivery-enabled": true,
  "pickup-enabled": true,
  "curbside-enabled": true,
  "open-24-hours": false, 
  "is-inventory-sync-enabled": true,
  "is-drive-thru-enabled": true,
  "has-prompt-for-table-number": false,
  "delivery-charge": 0,
  logistics: [],
  'logistic-selection-type': null,
  line1: {},
  "promised-time-delta": {
    delivery: "",
    pickup: "",
  },
  "opening-hours": [],
  "opening-hours-deliver": [],
  "opening-hours-pickup": [],
  "image-uri": "",
  "schedule-disabled-location-hour": "",
  "delivery-charge-per-km": 0,
  "delivery-maximum-distance": 99
};

@Component({
  components: {
    SearchItem,
    LocationItems,
    DriverItems,
    ConfirmCard,
    ChangeAreaNameModal,
    LocationModal,
    TemporaryDeliveryModal,
    Translations
  },
  computed: {
    ...mapGetters({
      getLocale: "app/getLocale",
      getSubscription: 'account/getSubscription',
      getConceptSettings: 'account/getConceptSettings',
      getUserRole: "account/getUserRole",

    }),
  },
  methods: {
    ...mapMutations({
      setSubscription: 'account/setSubscription'
    })
  },
  mixins: [translations, notificationAlerts, eventHandler],
})
export default class LocationsMap extends Vue {
  private gmap: any;
  private google: any;
  private controller: Object = {
    width: 400,
  };
  private activeLocation!: LocationModel;
  private activeLocationDeliveryAreas: Array<Object> = [];
  private searchLocationText: String = "";
  private searchDriverText: String = "";
  private locations: Array<Object> = [];
  private locationsArr: Array<Object> = [];
  private newlocationsArr: Array<Object> = [];
  private included: Array<any> = [];
  private drivers: Array<Object> = [];
  private driversMarkers: Array<any> = [];
  private driversIncluded: Array<Object> = [];
  private enable: Enable = {
    branches: true,
    delivery: false,
    drivers: false,
  };
  private deliveryAreas: Array<any> = [];
  private deliveryArea: any;
  private deliveryAreaIsUpdating: Boolean = false;
  private tempDeliveryArea: Store = {};
  private activeDeliveryArea: Store = {};
  private activeDeliveryAreaId: String = "";
  private activeDeliveryAreaName: String = "";
  private markers: Array<any> = [];
  private areaNameModal: Boolean = false;
  private driverInfowWindows: Array<any> = [];
  private infoWindows: Array<any> = [];
  private shapeDrawn: any;
  private getLocale!: any;
  private getSubscription!: any;
  private getConceptSettings!: any;
  private getUserRole!: any;
  public translate!: Function;
  private systemErrorNotification!: Function;
  private successNotification!: Function;
  public isSaving: Boolean = false;
  public isAdding: Boolean = false;
  public isShowing: Boolean = false;
  public drawArea: any;
  public confirm!: Function;
  public $refs!: {
    confirmCard: any;
    locationInfoFormModal: any;
    temporaryDeliveryFormModal: any;
  };
  private locationInfoFormModalKey: number = 1;
  private temporaryDeliveryFormModalKey: number = 1;

  private concept!: ConceptObject;
  private isOpeningHoursSet: Boolean = false;
  private location: LocationObject = {...defaultLocationObject};
  private locationClone!: any;
  private arrayLang: Array<string> = ["en-us", "ar-sa"];

  private totalDriversCount: number | null = null;
  private totalLocationsCount: number | null = null;
  private isPolygonDisplayed: boolean = false;
  private deliveryPolygon: any = null;
  public height: number = 0;
  isDisplay: boolean = false
  loadingLocation: boolean = false
  public canvas: any;
  private activeBranches: number = 0;

  private openingHours: Array<any> = [
    { day: 0, open: "01:00 PM", closed: "01:00 AM" },
    { day: 1, open: "01:00 PM", closed: "01:00 AM" },
    { day: 2, open: "01:00 PM", closed: "01:00 AM" },
    { day: 3, open: "01:00 PM", closed: "01:00 AM" },
    { day: 4, open: "01:00 PM", closed: "01:00 AM" },
    { day: 5, open: "01:00 PM", closed: "01:00 AM" },
    { day: 6, open: "01:00 PM", closed: "01:00 AM" },
  ];

  private openingHoursDeliver: Array<any> = [
    { day: 0, open: "", closed: "" },
    { day: 1, open: "", closed: "" },
    { day: 2, open: "", closed: "" },
    { day: 3, open: "", closed: "" },
    { day: 4, open: "", closed: "" },
    { day: 5, open: "", closed: "" },
    { day: 6, open: "", closed: "" },
  ];

  private openingHoursPickup: Array<any> = [
    { day: 0, open: "", closed: "" },
    { day: 1, open: "", closed: "" },
    { day: 2, open: "", closed: "" },
    { day: 3, open: "", closed: "" },
    { day: 4, open: "", closed: "" },
    { day: 5, open: "", closed: "" },
    { day: 6, open: "", closed: "" },
  ];

  private setSubscription!: Function

  enableSyncLocation: any = ['foodics-f5', 'sdm', 'ocost', 'revel', 'shawarmer']

  constructor() {
    super();
  }

  @Watch("enable.drivers", {immediate: true, deep: true})
  onShowDriver(show: Boolean = false) {
    if (!show) {
      this.driversMarkers.map((driver: any) => {
        driver.setMap(null);
      });
    } else {
      this.driversMarkers = [];

      this.drivers.map((driver: any) => {
        let driverId: null = null,
            orderId: null = null;
        if (driver.relationships.bearing.data) {
          driverId = driver.relationships.bearing.data.id;
        }
        if (driver.relationships.order.data) {
          orderId = driver.relationships.order.data.id;
        }
        let bearing: any = this.driversIncluded.filter((include: any) => {
          return include.id === driverId && include.type == "bearing";
        });
        let order: any = this.driversIncluded.filter((include: any) => {
          return include.id === orderId && include.type == "order";
        });
        if (bearing.length) {
          if (bearing[0].attributes.lat && bearing[0].attributes.long) {
            this.addDriverMarker(
                {
                  lat: bearing[0].attributes.lat,
                  long: bearing[0].attributes.long,
                },
                order
            );
          }
        }
      });

      this.driversMarkers.map((driver: any) => {
        driver.setMap(this.gmap);
      });
    }
  }

  @Watch("enable.delivery", {immediate: true, deep: true})
  onShowDelivery(show: Boolean = false) {
    this.refreshDeliveryAreas(show);
  }

  @Watch("enable.branches", {immediate: true, deep: true})
  onShowBranches(show: Boolean = false) {
    if (!show) {
      this.markers.map((marker: any) => {
        marker.setVisible(false);
      });
    } else {
      this.markers.map((marker: any) => {
        marker.setVisible(true);
      });
    }
  }
//..
  created() {
    window.addEventListener("resize", this.resizeHandler);

    this.setDefaultData();

    console.log("test role", this.getUserRole[0]);
  }

  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  }

  resizeHandler(ev: any) {
    this.height = ev?.target?.innerHeight || window.innerHeight;
  }

  mounted() {

    this.showlocationMap();

    this.showConfirmationCard();

    this.canvas = document.getElementById("map-custom");

    setTimeout(() => {
      // canvas.style.height =
      //     (window.outerHeight - 283 > 730 ? window.outerHeight - 250 : 730) +
      //     "px";
      this.canvas.style.height = window.innerHeight;
      // console.log(height, window.outerHeight > 720 ? true : false)

      GoogleMapsLoader.load((google: any) => {
        this.google = google;
        let lat = 24.774265,
            lng = 46.738586;
        this.initMap(lat, lng);
      });
    }, 100);
    this.resizeHandler(null);
  }

  showlocationMap() {
    if (!this.isMobile()) {
      this.isDisplay = true;
    } else {
      this.isDisplay = false;
    }
  }

  showConfirmationCard() {
    if (!this.isMobile()) {
      this.isDisplay = true;
    } else {
      this.isDisplay = false;
    }
  }

  isMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }


  private hideDeliveryAreas() {
    this.deliveryAreas.map((delivery: any) => {
      delivery.setMap(null);
    });
  }

  private refreshDeliveryAreas(show: Boolean) {
    if (!show) {
      this.deliveryAreas.map((delivery: any) => {
        delivery.setMap(null);
      });
    } else {
      this.deliveryAreas.map((delivery: any) => {
        delivery.setMap(this.gmap);
      });
    }
  }

  private addNewLocation() {
    this.location['opening-hours'] = this.openingHours
    this.location['opening-hours-deliver'] = this.openingHoursDeliver
    this.location['opening-hours-pickup'] = this.openingHoursPickup
    this.$refs.locationInfoFormModal.open = true;
  }

  private onLocationUpdate(location: any) {
    this.location = location;
    console.log('update location', this.location)
    this.$refs.locationInfoFormModal.open = true;
  }

  private addTemporaryDelivery(location: any) {
    this.location = location;
    this.$refs.temporaryDeliveryFormModal.open = true;
  }

  public refreshList(): void {
    const lat = 24.774265, lng = 46.738586;
    // close modal
    this.$refs.locationInfoFormModal.open = false;
    // clear data
    this.locations = [];
    this.drivers = [];
    this.clearAllMarker();
    // init map
    this.initMap(lat, lng);
  }

  private showDriver(bearing: any, order: any) {
    if (bearing.lat && bearing.long) {
    }
  }

  private enableStatus(payload: any, id: string) {
    Location.updateLocation(payload, id).then((response: any) => {
      /* @ts-ignore */
      analytics.track('enable_disable_location', {
        status: payload.status,
      });
      let location = this.locations.find(
          (item: any) => item.id === response.data.data.id
      ) as LocationModel;
      location.attributes.status = response.data.data.attributes.status;

      if (response.data.data.attributes.status == 'active') {
        this.activeBranches += 1;
      } else {
        this.activeBranches -= 1;
      }
      this.successNotification(
          'RECORD SAVED!',
          'Location Status successfully saved!',
      );

      SubscriptionApi.getSubscription().then(response => {        
        this.setSubscription(response.data.data)
      }).catch(err => {
        console.log(err.response)
      })
    }).catch(err => {
      console.log(err.response)
      console.log(err.response.data.error[0].detail)
      this.systemErrorNotification(
        'Oops...',
        'Please upgrade your account to add more branches!'
      );    });
  }

  public async syncLocations() {
    this.confirm(
        this.$bvModal,
        this.translate('Are you sure you want to sync locations?')
    ).then(async (value: boolean) => {
      if (value) {
        try {
          await IntegrationService.syncLocations('pos');
          /* @ts-ignore */
          analytics.track('sync_location', {
            status: 'synced',
          });
          this.successNotification(
              'LOCATION SYNCED!',
              'Locations successfully sycned',
          );
        } catch (error) {
          console.log(error.response);
          this.systemErrorNotification(
            "SYNC LOCATION ERROR!",
            error.response.data.error[0].detail
          );
        }
      }
    });
  }

  public deleteLocation(id: string) {
    const r = confirm('Are you sure you want to delete this location?');
    if (r) {
      Location.removeLocation(id)
          .then(() => this.getLocations(false, 1))
          .catch(() => this.systemErrorNotification());
    }
  }

  private updateAreaNameModalState(open: Boolean = true) {
    if (!this.deliveryAreaIsUpdating) {
      this.initUpdate();
    }
    this.areaNameModal = open;
  }

  private filterLocation(text: String) {
    this.searchLocationText = text;
  }

  private filterDriver(text: String) {
    this.searchDriverText = text;
  }

  get filteredLocations() {
    return this.locations.filter((location: any, key: any) => {
      return (location.attributes.name
        && typeof location.attributes.name === 'string'
        && location.attributes.name.length
        && location.attributes.name
          .toLowerCase()
          .match(this.searchLocationText)) || 
        (location.attributes.name === null 
        && this.searchLocationText === "");
    });
  }

  get filteredDrivers() {
    return this.drivers.filter((driver: any) => {
      let driverName: any = `${driver.attributes['first-name']} ${driver.attributes["last-name"]}`;
      return driverName.toLowerCase().match(this.searchDriverText);
    });
  }

  private updateAreaName(areaName: String) {
    this.activeDeliveryAreaName = areaName;
    this.areaNameModal = false;
    if (!this.deliveryAreaIsUpdating) {
      this.deliveryAreaIsUpdating = true;
    }
  }

  private resetDefault() {
    this.isAdding = false;
    this.deliveryAreaIsUpdating = false;
    this.activeDeliveryArea = {};
    this.disbleDraw();
    this.removeActiveLocationDeliveryAreasFromMap();
  }

  private saveNewArea(areaName: String) {
    let coordinates = this.getCoordinates(this.shapeDrawn);

    if (coordinates.length < 3) {
      this.resetDefault();
      this.isSaving = false;
      this.systemErrorNotification(
        this.translate('Insuffient coordinates'),
        this.translate('Delivery areas must contain atleast 3 coordinates')
      );   
    }

    if (coordinates[0][0] != coordinates[coordinates.length-1][0] && coordinates[0][1] != coordinates[coordinates.length-1][1]) {
      coordinates.push(coordinates[0]);
    }
    

    let payload = {
      label: areaName,
      coordinates: coordinates,
    };
    this.isSaving = true;
    Location.createArea(payload, this.activeDeliveryArea.id).then(
        (response: any) => {
          if (this.deliveryArea) {
            this.deliveryArea.setMap(null);
          }
          this.deliveryAreaIsUpdating = false;
          this.activeDeliveryArea = {};
          this.isSaving = false;
          // this.deliveryAreas = [];
          this.removeActiveLocationDeliveryAreasFromMap();

          // hack refreshes delivery areas on the map
          this.enable.delivery = !this.enable.delivery;
          this.enable.delivery = !this.enable.delivery;

          this.refreshLocationMap(this.activeLocation, response.data.data.pop());
          this.resetDefault();
          if (this.enable.delivery) {
            this.refreshDeliveryAreas(true);
          }

          this.$refs.confirmCard.areaName = '';

          this.successNotification(
              'RECORDS SAVED!',
              'New Delivery Area successfully added!',
          );
        }
    ).catch(err => {
      this.resetDefault();
      this.isSaving = false;
      console.log(err.response)
      this.systemErrorNotification(
        'Oops...',
        err.response.data.error[0].detail
      );    
    });
  }

  private async refreshLocationMap(
      location: LocationModel,
      deliveryArea: any,
      isUpdate: boolean = false,
  ) {
    if (isUpdate) {
      let existingDeliveryArea = this.included.find(
          (item: any) => item.id === deliveryArea.id
      );
      existingDeliveryArea = deliveryArea;
    } else {
      this.included.push(deliveryArea);
    }

    if (!isUpdate) {
      this.activeLocation.relationships["delivery-areas"].data.push(
          deliveryArea
      );
      let delivery = this.addCoordinatesAsPolygon(
          deliveryArea.attributes.coordinates
      );
      this.deliveryAreas.push(delivery);
    }

    this.addMarker(this.activeLocation);
  }

  private getCoordinates(data: any) {
    return data
        .getPath(this.gmap)
        .getArray()
        .map((latLang: any, index: number) => {
          return [latLang.lat(), latLang.lng()];
        });
  }

  private updateCoordinates() {
    let coordinates = this.getCoordinates(this.deliveryArea);
    
    if (coordinates[0][0] != coordinates[coordinates.length-1][0] && coordinates[0][1] != coordinates[coordinates.length-1][1]) {
      coordinates.push(coordinates[0]);
    }
    
    let payload = {
      label: this.activeDeliveryAreaName,
      coordinates: coordinates,
    };

    this.isSaving = true;

    Location.updateDeliveryArea(
        payload,
        this.activeLocation.id,
        this.activeDeliveryAreaId
    ).then(() => {
      this.deliveryArea.setVisible(false);
      this.deliveryAreaIsUpdating = false;
      this.activeDeliveryArea = {};
      this.isSaving = false;

      this.getLocations(false, 1);
      this.locations = []
      this.addDeliveryAreas(this.included);
      this.refreshDeliveryAreas(true);
    });
  }

  private async getLocations(recenterMap: Boolean = true, page: 1) {
    let lang = this.getLocale
    let google = this.google
    this.loadingLocation = true;
    await Location.fetchLocations(lang, page)
        .then((response: any) => {
          if (response?.data?.data && Array.isArray(response?.data?.data)) {
            this.locations = [...this.locations, ...response?.data?.data];

            response?.data?.data.forEach((item: any) => {
              if (item.attributes.status == 'active') {
                this.activeBranches += 1;
              }
            });
          }

          if (response?.data?.included && Array.isArray(response?.data?.included)) {
            this.included = [...response?.data?.included.filter((include: any) => {
              return include.type === 'delivery-area';
            }), ...this.included];
          }

          this.addDeliveryAreas(this.included);
          this.locations.map((location: any, i: number) => {
            this.addMarker(location);
            if (i === 0 && recenterMap) {
              let latLng = new google.maps.LatLng(
                  location.attributes.lat,
                  location.attributes.long
              );
              this.pan({
                lat: location.attributes.lat,
                long: location.attributes.long,
              });
            }
          });

          if (page === 1) {
            this.totalLocationsCount = response.data.meta.pagination.total;
          }

          if (
              response.data.meta.pagination.current_page <
              response.data.meta.pagination.total_pages
          ) {
            this.getLocations(false, response.data.meta.pagination.current_page + 1);
          }
          this.loadingLocation = false;
          this.$forceUpdate();
        })
        .catch((err: any) => {
          console.log(err);
        });
  }

  private pan(latLng: any, zoom: number = 11) {
    let google = this.google;
    let LatLng = new google.maps.LatLng(latLng.lat, latLng.long);
    this.gmap.panTo(LatLng);
    this.gmap.setOptions({zoom: zoom});
    this.panBy(this.gmap);
  }

  private getDrivers(page: number = 1) {

    Employee.getDrivers(page).then((response: any) => {
      let google = this.google;
      let activeDrivers: any = {}
      response.data.data.map((data: any) => {
        this.drivers.push(data);
      });

      if (response.data.included) {
        response.data.included.map((data: any) => {
          this.driversIncluded.push(data);
        })
      }

      if (page === 1) {
        this.totalDriversCount = response.data.meta.pagination.total;
      }

      if (
          response.data.meta.pagination.current_page <
          response.data.meta.pagination.total_pages
      ) {
        this.getDrivers(response.data.meta.pagination.current_page + 1)
      }
    });
  }

  private addDriverMarker(LatLang: any, order: any) {
    let google = this.google;
    let myLatlng = {
      lat: LatLang.lat,
      lng: LatLang.long,
    };

    let icon = "";

    if (!order.length) {
      icon = `/img/icons/bikes/marker-bike-green-sm.png`;
    } else if (
        order[0].attributes["current-status"].code !== "delivery-in-progress"
    ) {
      icon = `/img/icons/bikes/marker-bike-yellow-sm.png`;
    } else if (
        order[0].attributes["current-status"].code === "delivery-in-progress"
    ) {
      icon = `/img/icons/bikes/marker-bike-red-sm.png`;
    } else {
      icon = `/img/icons/bikes/marker-bike-red-sm.png`;
    }

    let marker = new google.maps.Marker({
      position: myLatlng,
      map: this.gmap,
      animation: google.maps.Animation.DROP,
      title: 'Locations',
      icon: icon,
    });

    if (order.length) {
      let infowindow = new google.maps.InfoWindow({
        content: this.driverInfow(order[0]),
      });
      let self = this;
      google.maps.event.addListener(marker, "click", () => {
        self.driverInfowWindows.map((infoWin: any) => {
          infoWin.close();
        });
        infowindow.open(self.gmap, marker);
      });
      this.driverInfowWindows.push(infowindow);
    }

    this.driversMarkers.push(marker);
  }

  private driverInfow(order: any) {
    return `
        <h5 class="default-text">${order.attributes["driver-name"]}</h5>
        <small class="text-muted">Mobile #: ${order.attributes.mobile}</small><br />
        <small class="text-muted">Order #: ${order.id}</small>
      `;
  }

  private addDeliveryAreas(deliveryAreas: Array<Object>) {
    // clear delivery areas
    this.deliveryAreas.map((delivery: any) => {
      delivery.setMap(null);
    });
    this.deliveryAreas = [];

    deliveryAreas.map((area: any) => {
      this.addDelivery(area.attributes.coordinates);
    });
    this.enable.delivery = !this.enable.delivery;
    this.enable.delivery = !this.enable.delivery;
  }

  private addCoordinatesAsPolygon(coordinates: Array<Object>) {
    let google = this.google;
    let delivery = new google.maps.Polygon({
      paths: coordinates.map((coordinate: any) => {
        return {
          lat: coordinate[0],
          lng: coordinate[1],
        };
      }),
      geodesic: true,
      strokeColor: "#5E72E4",
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: "#5E72E4",
      fillOpacity: 0.35,
    });

    return delivery;
  }

  private addDelivery(coordinates: Array<Object>) {
    let google = this.google;

    let delivery = new google.maps.Polygon({
      paths: coordinates.map((coordinate: any) => {
        return {
          lat: coordinate[0],
          lng: coordinate[1],
        };
      }),
      geodesic: true,
      strokeColor: "#5E72E4",
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: "#5E72E4",
      fillOpacity: 0.35,
    });

    this.deliveryAreas.push(delivery);
  }

  private initMap(lat: number, lng: number, color: String = "#5e72e4") {
    let google = this.google;
    let map = document.getElementById("map-custom");

    let myLatlng = new google.maps.LatLng(lat, lng);
    let mapOptions = {
      zoom: 12,
      scrollwheel: true,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      streetViewControl: false,
      style: this.styles(color),
      zoomControl: true,
      rotateControl: false,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP,
      }
    };

    this.gmap = new google.maps.Map(map as HTMLElement, mapOptions);
    this.panBy(this.gmap);

    // Set user location hide for now
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.gmap.setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
        this.gmap.panBy(position.coords.latitude, position.coords.longitude);
      }, () => {
        console.log('Cannot find location.');
      });
    }

    this.activeBranches = 0;
    this.initDraw();
    this.getLocations(true, 1);
    this.getDrivers();
  }

  private panBy(gmap: any) {
    if (this.getLocale === "en_US") {
      gmap.panBy(-200, 0);
    } else {
      gmap.panBy(150, 0);
    }
  }

  private addMarker(location: any) {
    let google = this.google;
    let myLatlng = {
      lat: location.attributes.lat,
      lng: location.attributes.long,
    };

    let marker = new google.maps.Marker({
      position: myLatlng,
      map: this.gmap,
      animation: google.maps.Animation.DROP,
      title: this.translate("Click the pin to add or show delivery areas."),
    });

    let infowindow = new google.maps.InfoWindow({
      content: this.contentString(location),
    });

    this.markers.push(marker);

    let self = this;

    google.maps.event.addListener(marker, "click", () => {
      self.activeLocation = location;

      self.infoWindows.map((infoWin: any) => {
        infoWin.close();
      });
      infowindow.open(self.gmap, marker);
      let DOM = new DOMParser().parseFromString(infowindow.content, "text/xml");

      setTimeout(() => {
        document
            .querySelectorAll("p.delivery-area, i.edit-delivery-area")
            .forEach((p) => {
              p.addEventListener("click", (e: any) => {
                self.isAdding = false;
                self.activeDeliveryAreaId = e.target.dataset.id;
                self.activeDeliveryAreaName = e.target.dataset.name;
                self.activeDeliveryArea = this.included.find(
                    (include: any) => include.id === this.activeDeliveryAreaId
                );
                infowindow.close();
              });
            });

        document.querySelectorAll("button.btn-add-area").forEach((btn: any) => {
          btn.addEventListener("click", (e: any) => {
            self.deliveryAreaIsUpdating = false;
            self.tempDeliveryArea = location;
            if (self.deliveryArea) {
              self.deliveryArea.setMap(null);
            }
            self.enableDraw();
            infowindow.close();

            this.activeLocation = location;
            this.prepareMapForAddingNewDeliveryArea(location);
          });
        });

        document.querySelectorAll("button.btn-show-area").forEach((btn: any) => {
          btn.addEventListener("click", (e: any) => {
            infowindow.close();
            this.removeShowDeliveryAreasFromMap()
            this.prepareMapForAddingNewDeliveryArea(location);
          });
        });
      }, 0);
    });

    this.infoWindows.push(infowindow);
  }

  private removeShowDeliveryAreasFromMap() {
    this.activeLocationDeliveryAreas.map((delivery: any) => {
      delivery.setMap(null);
    });
  }

  private removeActiveLocationDeliveryAreasFromMap() {
    this.activeLocationDeliveryAreas.map((delivery: any) => {
      delivery.setMap(null);
    });
  }

  private async prepareMapForAddingNewDeliveryArea(location: LocationModel) {
    try {
      let _this = this;
      let google = _this.google;
      let gmap = _this.gmap;
      let response = await Location.deliveryAreas(location.id);
      let deliveryAreas = response.data.data;
      let myLatlng = new google.maps.LatLng(
          location.attributes.lat,
          location.attributes.long
      );

      _this.gmap.panTo(myLatlng);
      _this.gmap.setOptions({zoom: 12});
      _this.panBy(_this.gmap);

      deliveryAreas.map((area: any) => {
        let delivery = _this.addCoordinatesAsPolygon(
            area.attributes.coordinates
        );
        delivery.setMap(gmap);
        this.activeLocationDeliveryAreas.push(delivery);
      });
      self.addEventListener('keydown', (e: any) => {
        if (e.code === 'Escape') {
          this.removeShowDeliveryAreasFromMap()
        }
      })
      this.filteredLocations.find(
        (item: any) => {
          if (item.id === location.id) {
            item.relationships["delivery-areas"].data = deliveryAreas;
          }
        }
      );
    } catch (error) {
      this.systemErrorNotification(
          "LOADING DELIVERY AREAS ERROR!",
          "Something went wrong while loading Location Delivery Areas, please try again."
      );
    }
  }

  private initDraw() {
    let google = this.google;
    this.drawArea = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ["polygon"],
      },
      polygonOptions: {
        strokeWeight: 0.5,
        fillColor: "#ff4d4d",
        strokeColor: "#ff1a1a",
        editable: true,
        supressUndo: true,
      },
    });

    let self = this;

    google.maps.event.addListener(this.drawArea, "overlaycomplete", function (
        e: any
    ) {
      if (e.type == google.maps.drawing.OverlayType.POLYGON) {
        self.drawArea.setDrawingMode(null);
        self.shapeDrawn = e.overlay;
        self.isAdding = true;
        self.activeDeliveryArea = self.tempDeliveryArea;
        self.drawArea.setOptions({
          drawingControl: false,
        });
      }
    });
  }

  private enableDraw() {
    let google = this.google;
    this.drawArea.setOptions({
      drawingControl: true,
    });
    this.drawArea.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    this.drawArea.setMap(this.gmap);
  }

  private disbleDraw() {
    let google = this.google;
    this.drawArea.setOptions({
      drawingControl: false,
      drawingMode: null,
    });
    this.drawArea.setDrawingMode(null);
    this.drawArea.setMap(null);
    if (this.shapeDrawn) {
      this.shapeDrawn.setMap(null);
    }
    if (this.deliveryArea) this.deliveryArea.setMap(null);
  }

  private clearAllMarker() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
  }

  private initUpdate(bol: Boolean = true) {
    const {google} = this;

    if (this.deliveryPolygon) {
      this.deliveryPolygon.setMap(null);
      this.deliveryPolygon = null;
    }

    this.deliveryPolygon = new google.maps.Polygon({
      paths: this.activeDeliveryArea.attributes.coordinates.map(
          (coordinate: any) => {
            return {
              lat: coordinate[0],
              lng: coordinate[1],
            };
          }
      ),
      strokeColor: "#FF6B00",
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: "#FF6B00",
      fillOpacity: 0.35,
    });

    this.deliveryAreaIsUpdating = true;
    this.deliveryPolygon.setMap(this.gmap);

    this.deliveryArea = this.deliveryPolygon;
    this.deliveryArea.setOptions({editable: bol});
  }

  private deleteArea() {
    Location
        .deleteDeliveryArea(this.activeLocation.id, this.activeDeliveryArea.id)
        .then((response) => {
          const deliveryAreaIdx = this.included.findIndex((d: any) => d.id === this.activeDeliveryArea.id);
          const deliveryAreaRelIdx = this.activeLocation.relationships["delivery-areas"].data.findIndex((d: any) => d.id === this.activeDeliveryArea.id);
          if (deliveryAreaIdx > -1) {
            this.included.splice(deliveryAreaIdx, 1);
          }
          if (deliveryAreaRelIdx > -1) {
            this.activeLocation.relationships["delivery-areas"].data.splice(deliveryAreaRelIdx, 1);
          }
          this.refreshLocationMap(this.activeLocation, this.activeDeliveryArea, true);
          this.resetDefault();
          this.addDeliveryAreas(this.included);
          this.refreshDeliveryAreas(true);
        });
  }

  private contentString(location: any) {
    return `<div class="info-window-content" style="min-width: 300px">
                <h2 class="location-name">${location.attributes.name}</h2>
                ${this.contentDeliveryAreas(
        location.relationships["delivery-areas"].data
    )}
                <button type="button" class="btn btn-sm btn-warning btn-add-area mt-4" style="background-color: var(--primary-color) !important;
      border-color: var(--primary-color) !important;">
                  <i class="ni ni-fat-add"></i> ${this.translate(
        "Add Delivery Area"
    )}
                </button>
                <button type="button" class="btn btn-sm btn-warning btn-show-area mt-4" style="background-color: var(--primary-color) !important;
      border-color: var(--primary-color) !important;">
                  ${this.translate(
        "Show Delivery Area"
    )}
                </button>
              </div>`;
  }

  private contentDeliveryAreas(data: Array<Object>) {
    let content = "";
    let deliveryAreas: any[] = [];

    data.map((area: any) => {
      let filteredArea = this.included
          .filter((include: any) => {
            return include.type === "delivery-area";
          })
          .filter((include: any) => {
            return area.id === include.id;
          });
      deliveryAreas.push(filteredArea[0]);
    });

    if (deliveryAreas.length) {
      deliveryAreas.map((area) => {
        content += `<p class="delivery-area py-0 my-0" data-id="${area && area.id}" data-name="${area && area.attributes && area.attributes.label}">
                        ${area && area.attributes && area.attributes.label} <i class="far fa-edit edit-delivery-area" data-id="${area && area.id}" data-name="${area && area.attributes && area.attributes.label}"></i>
                      </p>`;
      });
    }

    return content;
  }

  private styles(color: String = "#5e72e4") {
    return [
      {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [{color: "#444444"}],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [{color: "#f2f2f2"}],
      },
      {
        featureType: "poi",
        elementType: "all",
        stylers: [{visibility: "on"}],
      },
      {
        featureType: "road",
        elementType: "all",
        stylers: [{saturation: -100}, {lightness: 45}],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [{visibility: "simplified"}],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [{visibility: "on"}],
      },
      {
        featureType: "transit",
        elementType: "all",
        stylers: [{visibility: "on"}],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [{color: color}, {visibility: "on"}],
      },
    ];
  }

  onModalClose() {
    defaultLocationObject["pos"] = this.concept.attributes["default-pos"];
    defaultLocationObject["country"] = this.concept.attributes["country"];
    defaultLocationObject["delivery-charge"] = this.concept.attributes["default-delivery-charge"];

    let openingHours = JSON.parse(this.concept.attributes["default-opening-hours"]);
    let openingHoursDelivery = JSON.parse(this.concept.attributes["default-opening-hours"]);
    let openingHoursPickup = JSON.parse(this.concept.attributes["default-opening-hours"]);
    defaultLocationObject['opening-hours'] = openingHours.map((item: any) => {
      defaultLocationObject["promised-time-delta"] = {
        delivery: this.concept.attributes["default-promised-time-delta-delivery"],
        pickup: this.concept.attributes["default-promised-time-delta-pickup"],
      };
      return {
        day: item.day,
        open: moment(`${item.open}`, "hh:mm").format("hh:mm A"),
        closed: moment(`${item.closed}`, "hh:mm").format("hh:mm A"),
      };
    });
    defaultLocationObject['opening-hours-deliver'] = openingHoursDelivery.map((item: any) => {
      defaultLocationObject["promised-time-delta"] = {
        delivery: this.concept.attributes[
            "default-promised-time-delta-delivery"
            ],
        pickup: this.concept.attributes["default-promised-time-delta-pickup"],
      };
      return {
        day: item.day,
        open: moment(`${item.open}`, "hh:mm").format("hh:mm A"),
        closed: moment(`${item.closed}`, "hh:mm").format("hh:mm A"),
      };
    });
    defaultLocationObject['opening-hours-pickup'] = openingHoursPickup.map((item: any) => {
      defaultLocationObject["promised-time-delta"] = {
        delivery: this.concept.attributes[
            "default-promised-time-delta-delivery"
            ],
        pickup: this.concept.attributes["default-promised-time-delta-pickup"],
      };
      return {
        day: item.day,
        open: moment(`${item.open}`, "hh:mm").format("hh:mm A"),
        closed: moment(`${item.closed}`, "hh:mm").format("hh:mm A"),
      };
    });
    this.location = {...defaultLocationObject};
  }

  private async setDefaultData() {
    let response = await ConceptService.default();
    this.concept = response.data.data;
    let openingHours = JSON.parse(
        this.concept.attributes["default-opening-hours"]
    );
    let openingHoursDelivery = JSON.parse(
        this.concept.attributes["default-opening-hours"]
    );
    let openingHoursPickup = JSON.parse(
        this.concept.attributes["default-opening-hours"]
    );

    this.location["pos"] = this.concept.attributes["default-pos"];
    this.location["country"] = this.concept.attributes["country"];
    this.location["delivery-charge-per-km"] = this.concept.attributes["delivery-charge-per-km"];
    this.location["delivery-maximum-distance"] = this.concept.attributes["delivery-maximum-distance"];
    this.location["delivery-charge"] = this.concept.attributes[
        "default-delivery-charge"
        ];
    const hours = openingHours.map((item: any) => {
      this.location["promised-time-delta"] = {
        delivery: this.concept.attributes[
          "default-promised-time-delta-delivery"
          ],
        pickup: this.concept.attributes["default-promised-time-delta-pickup"],
      };
      return {
        day: item.day,
        open: moment(`${item.open}`, "hh:mm").format("hh:mm A"),
        closed: moment(`${item.closed}`, "hh:mm").format("hh:mm A"),
      };
    });
    this.location["opening-hours-deliver"] = openingHoursDelivery.map((item: any) => {
      this.location["promised-time-delta"] = {
        delivery: this.concept.attributes[
            "default-promised-time-delta-delivery"
            ],
        pickup: this.concept.attributes["default-promised-time-delta-pickup"],
      };
      return {
        day: item.day,
        open: moment(`${item.open}`, "hh:mm").format("hh:mm A"),
        closed: moment(`${item.closed}`, "hh:mm").format("hh:mm A"),
      };
    });
    this.location["opening-hours-pickup"] = openingHoursPickup.map((item: any) => {
      this.location["promised-time-delta"] = {
        delivery: this.concept.attributes[
            "default-promised-time-delta-delivery"
            ],
        pickup: this.concept.attributes["default-promised-time-delta-pickup"],
      };
      return {
        day: item.day,
        open: moment(`${item.open}`, "hh:mm").format("hh:mm A"),
        closed: moment(`${item.closed}`, "hh:mm").format("hh:mm A"),
      };
    });

    this.isOpeningHoursSet = true;
    this.locationClone = {...this.location};
  }
}
