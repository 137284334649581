
import Modal from "@/components/Modal.vue";
import {PageAttributes} from "@/models";
import {Page as PageApi} from "@/services/SOLO";
import {translations} from '@/mixins'
import {Select, Option} from "element-ui";
import {
  ValidationProvider,
} from "vee-validate";
import {Component, ProvideReactive, Prop, Vue, Watch, Emit,} from "vue-property-decorator";

interface objKey {
  [x: string]: string;
}
@Component({
  components: {
    ValidationProvider,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mixins: [translations],
})
export default class NewPageModal extends Vue {
  page: PageAttributes = {
    label: '',
    url: '',
    title: {},
    type: ''
  }
  pageTitle: objKey = {};
  pageLabel: objKey = {};
  translate!: any
  $notify: any;
  arrayLang: Array<string> = [];
  Types: any = [
    {text: 'Page', value: 'page'},
    {text: 'Link', value: 'link'}
  ]
  @Prop() open!: Boolean
  get isOpen(): Boolean {
    return this.open;
  }

  set isOpen(newVal: Boolean) {
    this.$emit("addNewPage", newVal);
    if (!newVal) {
    }
  }

  constructor() {
    super();
  }

  mounted() {
        /* @ts-ignore */
    if(this.getConceptSettings.attributes['primary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
    }

    /* @ts-ignore */
    if(this.getConceptSettings.attributes['secondary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
    }

    console.log('arrayLang pages', this.arrayLang);

  }

  get pageData(): any {
        for (let i in this.arrayLang) {
          this.pageTitle[this.arrayLang[i]] = this.page.title[this.arrayLang[i]]
        }  

        return {
          title: this.pageTitle,
          label: this.page.label,
          type: this.page["type"],
          url: this.page["url"]
        };
    }

  close() {
    this.$emit("closeNewPage", false);
  }

  resetFields() {
    this.page = {
      label: '',
      url: '',
      title: {},
      type: ''
    }
  }

  createPage(e: any, reset: any) {
    console.log("check da", this.pageData);
    let defaultText = e.target.innerHTML;
    e.target.innerHTML =
        '<i class="fas fa-spinner fa-spin"></i> Creating Page';
    e.target.disabled = true;
    PageApi.add(this.pageData)
      .then((response) => {
        this.$notify({
          title: "PAGE CREATED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Page has been created successfully",
          type: "success",
          icon: "fas fa-check",
        });
        this.page.label = "";
        this.page.title = {};
        this.page.url = "";
        this.page.type = "";
        e.target.innerHTML = defaultText;
        e.target.disabled = false;
        this.$emit("getPages", 1, 10);
        this.$emit("addNewPage", false);
        reset();
      })
      .catch((err) => {
        e.target.innerHTML = defaultText;
        e.target.disabled = false;
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }
}
