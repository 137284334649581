import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Admin from '@/views/Admin/MainLayout.vue'
import RoadmapIdeas from '@/views/Admin/pages/Dashboard/Roadmap.vue'
import DashboardNew from '@/views/Admin/pages/Dashboard/Dashboard-new.vue'
// import Dashboard from '@/views/Admin/pages/Dashboard/Dashboard.vue'
// import DashboardDefault from '@/views/Admin/pages/Dashboard/Dashboard-default.vue'
import Prices from '@/views/Admin/pages/Prices/Prices.vue'
import Account from '@/views/Admin/pages/Account/Account.vue'

import Promotions from '@/views/Admin/pages/Promotions/Promotions.vue'
import PromotionsCreate from '@/views/Admin/pages/Promotions/pages/PromotionsCreate.vue'
import PromotionsList from '@/views/Admin/pages/Promotions/pages/PromotionsList.vue'

import Segments from '@/views/Admin/pages/Promotions/Segments.vue'
import SegmentsCreate from '@/views/Admin/pages/Promotions/pages/SegmentsCreate.vue'
import SegmentsUpdate from '@/views/Admin/pages/Promotions/pages/SegmentsUpdate.vue'
import SegmentsList from '@/views/Admin/pages/Promotions/pages/SegmentsList.vue'
import SegmentsCustomerList from '@/views/Admin/pages/Promotions/pages/SegmentsCustomerList.vue'

import Coupons from '@/views/Admin/pages/Promotions/Coupons.vue'
import CouponGroupsList from '@/views/Admin/pages/Promotions/pages/CouponGroupsList.vue'
import CouponGroupsCouponList from '@/views/Admin/pages/Promotions/pages/CouponGroupsCouponList.vue'
import CouponGroupsCouponCreate from '@/views/Admin/pages/Promotions/pages/CouponGroupsCouponCreate.vue'

import Smiles from '@/views/Admin/pages/Promotions/Smiles.vue';
// import CouponsCreate from '@/views/Admin/pages/Promotions/pages/PromotionsCreate.vue'

import SlidingBannerSlides from '@/views/Admin/pages/CMS/pages/SlidingBannerUpdateSlide.vue'
import Feedback from '@/views/Admin/pages/Feedback/Feedback.vue'
import FeedbackItem from "@/views/Admin/pages/Feedback/components/FeedbackItem.vue"
import LoyaltyItem from "@/views/Admin/pages/Loyalty/components/LoyaltyItem.vue"
import LoyaltyTiers from "@/views/Admin/pages/Loyalty/components/Tiers.vue"
import LoyaltyCollection from "@/views/Admin/pages/Loyalty/components/Collection.vue"
import LandingPage from '@/views/Landing/MainLayout.vue'
import Login from '@/views/Landing/pages/Login/Login.vue'
import AccountProtected from '@/views/Landing/pages/Login/2fa/AccountProtected.vue'
import AuthenticationStep1 from '@/views/Landing/pages/Login/2fa/AuthenticationStep1.vue'
import AuthenticationStep2 from '@/views/Landing/pages/Login/2fa/AuthenticationStep2.vue'
import AuthenticationStep3 from '@/views/Landing/pages/Login/2fa/AuthenticationStep3.vue'
import CodeVerifier from '@/views/Landing/pages/Login/2fa/CodeVerifier.vue'
import Register from '@/views/Landing/pages/Register/Register.vue'
import Forgot from '@/views/Landing/pages/Forgot/Forgot.vue'

import CustomerSubscription from '@/views/Landing/pages/Subscription/Subscription.vue'
import CustomerPayment from '@/views/Landing/pages/Subscription/Payment.vue'

import Menus from '@/views/Admin/pages/Menus/Menus.vue'
import Menu from '@/views/Admin/pages/Menus/pages/Menu.vue'
import MenuAdd from '@/views/Admin/pages/Menus/pages/MenuAdd.vue'
import Orders from '@/views/Admin/pages/Orders/Orders.vue'
import OrdersNew from '@/views/Admin/pages/Orders/OrdersNew.vue'
import Curbside from '@/views/Admin/pages/Curbside/Curbside.vue'
import Order from '@/views/Admin/pages/Orders/Order.vue'
import MenuUpdateCategory from '@/views/Admin/pages/Menus/pages/MenuUpdateCategory.vue'
import MenuCategory from '@/views/Admin/pages/Menus/pages/MenuCategory.vue'
import MenuUpsells from '@/views/Admin/pages/Menus/pages/MenuUpsells.vue'
import MenuItems from '@/views/Admin/pages/Menus/pages/MenuItems.vue'
import ItemList from '@/views/Admin/pages/Menus/pages/itemPages/ItemList.vue'
import ItemCreate from '@/views/Admin/pages/Menus/pages/itemPages/itemCreate.vue'
import itemModifier from '@/views/Admin/pages/Menus/pages/itemPages/itemModifier.vue'
import EditModgroupItem from '@/views/Admin/pages/Menus/pages/itemPages/modifiercomponents/EditModgroupItem.vue'
import ModgroupModifiers from '@/views/Admin/pages/Menus/pages/itemPages/modifiercomponents/ModgroupModifiers.vue'
import ModgroupModifierCreate from '@/views/Admin/pages/Menus/pages/itemPages/modifiercomponents/ModgroupModifierCreate.vue'
import ModgroupModifierItem from '@/views/Admin/pages/Menus/pages/itemPages/modifiercomponents/ModgroupModifierItem.vue'

import Report from '@/views/Admin/pages/Reports/Reports.vue'
import ReportGeneral from '@/views/Admin/pages/Reports/pages/General.vue'
import ReportOrders from '@/views/Admin/pages/Reports/pages/Orders.vue'
import ReportLocations from '@/views/Admin/pages/Reports/pages/Locations.vue'
import ReportCustomers from '@/views/Admin/pages/Reports/pages/Customers.vue'
import ReportProducts from '@/views/Admin/pages/Reports/pages/Products.vue'
import ReportLoyalty from '@/views/Admin/pages/Reports/pages/Loyalty.vue'

import Locations from '@/views/Admin/pages/Locations/Locations.vue'
import LocationsMap from '@/views/Admin/pages/Locations/LocationsMap.vue'

import Settings from '@/views/Admin/pages/Settings/Settings.vue'
import Concepts from '@/views/Admin/pages/Settings/pages/Concepts.vue'
import MoneyHash from '@/views/Admin/pages/Settings/pages/MoneyHash.vue'
import Globals from '@/views/Admin/pages/Settings/pages/Globals.vue'
import Facilities from '@/views/Admin/pages/Settings/pages/Facilities.vue'
import Allergens from '@/views/Admin/pages/Settings/pages/Allergens.vue'
import OrderStatuses from '@/views/Admin/pages/Settings/pages/OrderStatuses.vue'
import QueueBuster from '@/views/Admin/pages/QROrdering/QueueBuster.vue'
import IntegrationSettings from '@/views/Admin/pages/Settings/pages/IntegrationSettings.vue'

import NotFound from '@/views/General/NotFound.vue'

import Apps from "@/views/Admin/pages/Apps/Apps.vue";
import EditAppItem from "@/views/Admin/pages/Apps/EditAppItem.vue"
import CreateApp from "@/views/Admin/pages/Apps/CreateApp.vue"
import AppsItemList from "@/views/Admin/pages/Apps/AppItems.vue"

import Test from "@/views/Admin/pages/Customers/test.vue"
import Customers from "@/views/Admin/pages/Customers/Customers.vue"
import Employees from "@/views/Admin/pages/Employees/Employees.vue"
import CustomerItem from "@/views/Admin/pages/Customers/components/CustomerItem.vue"
import CustomerSingle from "@/views/Admin/pages/Customers/components/CustomerSingle.vue"
import Blacklists from "@/views/Admin/pages/Customers/Blacklists.vue"
import BlacklistList from "@/views/Admin/pages/Customers/components/BlacklistList.vue"
import Loyalty from "@/views/Admin/pages/Loyalty/Loyalty.vue"

import store from '@/store'

import Messaging from "@/views/Admin/pages/Messaging/Messaging.vue";
import Notification from "@/views/Admin/pages/Messaging/pages/Notification.vue";
import MessagingStatuses from "@/views/Admin/pages/Messaging/pages/OrderStatuses.vue";
import StarRatings from "@/views/Admin/pages/Messaging/pages/StarRatings.vue";
import OAuthCallback from "@/views/Landing/pages/OAuthCallback.vue";
import IntegrationProviderRedirect from "@/views/Landing/pages/IntegrationProviderRedirect.vue";

import auth from '@/middleware/auth'
import log from '@/middleware/log'
import admin from '@/middleware/admin'
import subscription from '@/middleware/subscription'
import SlidingBannerIndex from "@/views/Admin/pages/CMS/banners/SlidingBannerIndex.vue";
import SlidingBanners from "@/views/Admin/pages/CMS/banners/SlidingBanners.vue";
import EditBannerItem from "@/views/Admin/pages/CMS/banners/EditBannerItem.vue";
import CreateBannerItem from "@/views/Admin/pages/CMS/banners/CreateBannerItem.vue";
import Pages from "@/views/Admin/pages/CMS/pages2/Pages.vue";
import Popups from "@/views/Admin/pages/CMS/popups/Popups.vue";
import CreatePopup from "@/views/Admin/pages/CMS/popups/CreatePopup.vue";
import EditPopup from "@/views/Admin/pages/CMS/popups/EditPopup.vue";

import PremadePlans from "@/views/Admin/pages/SubscriptionMeal/PremadePlans.vue";
import SubscriptionSettings from "@/views/Admin/pages/SubscriptionMeal/SubscriptionSettings.vue";
import PremadePlanIndex from "@/views/Admin/pages/SubscriptionMeal/PremadePlanIndex.vue";
import EditPremadePlan from "@/views/Admin/pages/SubscriptionMeal/EditPremadePlan.vue";
import CreatePremadePlan from "@/views/Admin/pages/SubscriptionMeal/CreatePremadePlan.vue";
import SubscriptionIndex from "@/views/Admin/pages/SubscriptionMeal/SubscribersIndex.vue";
import SubscriberItems from "@/views/Admin/pages/SubscriptionMeal/SubscriberItems.vue";
import SubscriberSingle from "@/views/Admin/pages/SubscriptionMeal/components/SubscriberSingle.vue";

import Connect from "@/views/Admin/pages/Connect/Connect.vue"
import ConnectConnections from "@/views/Admin/pages/Connect/components/Connections.vue"
import ConnectOrders from "@/views/Admin/pages/Connect/components/Orders.vue"
import ConnectSyncAuditLogs from "@/views/Admin/pages/Connect/components/SyncAuditLogs.vue"
import EditConnection from "@/views/Admin/pages/Connect/EditConnection.vue"
import Franchisee from "@/views/Admin/pages/Franchisee/Franchisee.vue"

import WhatsApp from "@/views/Admin/pages/WhatsApp/WhatsApp.vue"

import GMB from "@/views/Admin/pages/GMB/GMB.vue"
import GMBPages from "@/views/Admin/pages/GMB/pages/Pages.vue"
// import GMBManageLocations from '@/views/Admin/pages/GMB/pages/ManageLocations.vue'
// import GMBListingPage from '@/views/Admin/pages/GMB/pages/ListingPage.vue'
// import GMBPartnerConnect from '@/views/Admin/pages/GMB/pages/PartnerConnect.vue'
// import GMBGooglePost from '@/views/Admin/pages/GMB/pages/GooglePost.vue'
import GMBSettings from '@/views/Admin/pages/GMB/pages/Settings.vue'

Vue.use(VueRouter)

const label = store?.getters?.['account/getUserClient']?.attributes?.label;

const extras = store?.getters?.['account/getUserClient']?.attributes?.extras;

let  favico = '';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'landing',
    component: LandingPage,
    meta: {
      middleware: [auth, subscription],
      icon: favico,
    },
    children: [
      {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
          title: 'Login',
          noBodyBackground: true,
          isLoginPage: true,
          hideFooter: true,
          icon: favico,
        }
      },
      {
        path: '/login/2fa/account-protected',
        name: 'account-protected',
        component: AccountProtected,
        meta: {
          title: 'Account Protected',
          noBodyBackground: true,
          isLoginPage: true,
          hideFooter: true,
          icon: favico,
        }
      },
      {
        path: '/login/2fa/authentication/1',
        name: 'authentication1',
        component: AuthenticationStep1,
        meta: {
          title: 'Get the App',
          noBodyBackground: true,
          isLoginPage: true,
          hideFooter: true,
          icon: favico,
        }
      },
      {
        path: '/login/2fa/authentication/2',
        name: 'authentication2',
        component: AuthenticationStep2,
        meta: {
          title: 'Scan QR Code',
          noBodyBackground: true,
          isLoginPage: true,
          hideFooter: true,
          icon: favico,
        }
      },
      {
        path: '/login/2fa/authentication/3',
        name: 'authentication3',
        component: AuthenticationStep3,
        meta: {
          title: 'Verification Code',
          noBodyBackground: true,
          isLoginPage: true,
          hideFooter: true,
          icon: favico,
        }
      },{
        path: '/login/2fa/code-verifier',
        name: 'code-verifier',
        component: CodeVerifier,
        meta: {
          title: 'Code Verifier',
          noBodyBackground: true,
          isLoginPage: true,
          hideFooter: true,
          icon: favico,
        }
      },
      {
        path: '/forgot',
        name: 'Forgot',
        component: Forgot,
        meta: {
          title: 'Forgot',
          noBodyBackground: true,
          isLoginPage: true,
          icon: favico,
        }
      },
      {
        path: '/register/:planId?',
        name: 'register',
        component: Register,
        meta: {
          title: 'Register',
          noBodyBackground: true,
          isLoginPage: true,
          hideFooter: true,
          icon: favico,
        },
      },
      {
        path: '/customer-subscription/:planId?',
        name: 'customer-subscription',
        component: CustomerSubscription,
        meta: {
          title: 'Customer Subscription',
          noBodyBackground: true,
          isLoginPage: true,
          middleware: [auth, admin, log, subscription],
          hideFooter: true,
          icon: favico,
        }
      },
      {
        path: '/payment/:id',
        name: 'stripePayment',
        component: CustomerPayment,
        meta: {
          title: 'Payment',
          middleware: [auth, admin, log],
          hideFooter: true,
          icon: favico,
        }
      },
    ]
  },
  {
    path: '/oauth/callback',
    name: 'oauthCallback',
    component: OAuthCallback,
    meta: {
      noBodyBackground: true,
      isLoginPage: true,
      icon: favico,
    }
  },
  {
    path: '/settings/integrations/:provider',
    name: 'IntegrationProviderRedirect',
    component: IntegrationProviderRedirect,
    meta: {
      noBodyBackground: true,
      isLoginPage: true,
      icon: favico,
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    meta: {
      hideFooter: false,
      icon: favico,
    },
    children: [
      {
        path: '/kiosks',
        name: 'Kiosks',
        component: () => import('@/views/Admin/pages/Kiosks/Kiosks.vue'),
        meta: {
          title: 'Kiosk',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        }
      },
      {
        path: '/kiosk-details/:id',
        name: 'Kiosk_details',
        component: () => import('@/views/Admin/pages/Kiosks/KioskDetails.vue'),
        meta: {
          title: 'KioskDetails',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        }
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardNew,
        meta: {
          title: 'Dashboard',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        }
      },
      {
        path: '/whats-app',
        name: 'Whats_App',
        component: WhatsApp,
        meta: {
          title: 'Whats App',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        }
      },
      // {
      //   path: '/dashboard-new',
      //   name: 'dashboard-new',
      //   component: DashboardNew,
      //   meta: {
      //     middleware: [auth, admin, log, subscription],
      //     hideFooter: false
      //   }
      // },
      // {
      //   path: '/dashboard-default',
      //   name: 'dashboard-default',
      //   component: DashboardDefault,
      //   meta: {
      //     middleware: [auth, admin, log, subscription],
      //     hideFooter: false,
      //   },
      // },
      {
        path: '/roadmap-ideas',
        name: 'roadmap-ideas',
        component: RoadmapIdeas,
        meta: {
          title: 'Roadmap',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        }
      },
      {
        path: '/prices',
        name: 'prices',
        component: Prices,
        meta: {
          title: 'Prices',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        }
      },
      {
        path: '/account',
        name: 'account',
        component: Account,
        meta: {
          title: 'Account',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        }
      },
      {
        path: '/promotions',
        name: 'Promotions',
        component: Promotions,
        meta: {
          title: 'Promostions',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        },
        redirect: '/promotions/list',
        children: [
          {
            path: '/promotions/list',
            name: 'PromotionsList',
            component: PromotionsList,
            meta: {
              title: 'Promotion List',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/promotions/create',
            name: 'PromotionsCreate',
            component: PromotionsCreate,
            meta: {
              title: 'Promotion Create',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/promotions/edit/:id',
            name: 'PromotionsEdit',
            component: PromotionsCreate,
            meta: {
              title: 'Promotion Edit',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
        ]
      },
      {
        path: '/segments',
        name: 'Segments',
        component: Segments,
        meta: {
          title: 'Segments',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        },
        redirect: '/segments/list',
        children: [
          {
            path: '/segments/list',
            name: 'SegmentsList',
            component: SegmentsList,
            meta: {
              title: 'Segment List',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/segments/create',
            name: 'SegmentsCreate',
            component: SegmentsCreate,
            meta: {
              title: 'Create',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/segments/edit/:id',
            name: 'SegmentsUpdate',
            component: SegmentsUpdate,
            meta: {
              title: 'Edit',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/segments/customers/:id',
            name: 'SegmentsCustomerList',
            component: SegmentsCustomerList,
            meta: {
              title: 'Customers',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            },
          },
        ],
      },
      {
        path: '/coupon-groups',
        name: 'CouponGroups',
        component: Coupons,
        meta: {
          title: 'Coupon Groups',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        },
        redirect: '/coupon-groups/list',
        children: [
          {
            path: '/coupon-groups/list',
            name: 'CouponGroupsList',
            component: CouponGroupsList,
            meta: {
              title: 'Coupon Group List',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/coupon-groups/coupon-list/:id',
            name: 'CouponGroupsCouponList',
            component: CouponGroupsCouponList,
            meta: {
              title: 'Coupon Group List',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/coupon-groups/coupon-create/:id',
            name: 'CouponGroupsCouponCreate',
            component: CouponGroupsCouponCreate,
            meta: {
              title: 'Coupon Group Create',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/coupon-groups/coupon-edit/:id/coupon/:couponId',
            name: 'CouponGroupsCouponEdit',
            component: CouponGroupsCouponCreate,
            meta: {
              title: 'Coupon Group Edit',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
        ]
      },
      {
        path: '/digital-coupons',
        name: 'CouponGroups',
        component: Coupons,
        meta: {
          title: 'Digital Coupons',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        },
        redirect: '/digital-coupons/list',
        children: [
          {
            path: '/digital-coupons/list',
            name: 'CouponGroupsCouponList',
            component: CouponGroupsCouponList,
            meta: {
              title: 'Digital Coupon List',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/digital-coupons/create',
            name: 'CouponGroupsCouponCreate',
            component: CouponGroupsCouponCreate,
            meta: {
              title: 'Digital Coupon Create',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/digital-coupons/edit/:couponId',
            name: 'CouponGroupsCouponEdit',
            component: CouponGroupsCouponCreate,
            meta: {
              title: 'Digital Coupon Edit',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
        ]
      },
      {
        path: '/smiles',
        name: 'Smiles',
        component: Smiles,
        meta: {
          title: 'Smiles',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        },
      },
      {
        path: '/cms',
        name: 'cms',
        component: SlidingBannerIndex,
        meta: {
          title: 'In-App Content',
          hideFooter: false,
          icon: favico,
        },
        children: [
          {
            path: '/cms/banners/list',
            name: 'sliding banners',
            component: SlidingBanners,
            meta: {
              title: 'Banner List',
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/cms/banners/edit/:banner_id/slides/:slide_id',
            name: 'edit',
            component: EditBannerItem,
            meta: {
              title: 'Banner Edit',
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/cms/banners/create/:id',
            name: 'create',
            component: CreateBannerItem,
            meta: {
              title: 'Banner Create',
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/cms/pages/list',
            name: 'pages',
            component: Pages,
            meta: {
              title: 'Page List',
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/cms/popups/list',
            name: 'popups',
            component: Popups,
            meta: {
              title: 'Popup List',
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/cms/popups/create',
            name: 'create-popup',
            component: CreatePopup,
            meta: {
              title: 'Popup Create',
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/cms/popups/:popupId',
            name: 'edit-popup',
            component: EditPopup,
            meta: {
              title: 'Popup Edit',
              hideFooter: false,
              icon: favico,
            }
          },
        ],
      },
      {
        path: '/apps',
        name: 'apps',
        component: Apps,
        meta: {
          title: 'Apps',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        },
        children: [
          {
            path: '/apps',
            name: 'list',
            component: AppsItemList,
            meta: {
              title: 'App List',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/cms/sliding-banners/:bannerID/slides/:slidesID',
            name: 'update-slide',
            component: SlidingBannerSlides,
          },
          {
            path: '/apps/:id',
            name: 'edit-app',
            component: EditAppItem,
            meta: {
              title: 'App Edit',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/apps/create',
            name: 'create-app',
            component: CreateApp,
            meta: {
              title: 'App Create',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
        ]
      },
      {
        path: '/connect',
        name: 'connect',
        component: Connect,
        meta: {
          title: 'Connect',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        },
        children: [
          {
            path: '/connect/connections',
            name: 'connect-connections',
            component: ConnectConnections,
            meta: {
              title: 'Connection List',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/connect/connections/:id',
            name: 'edit-connection',
            component: EditConnection,
            meta: {
              title: 'Connection Edit',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/connect/orders',
            name: 'connect-orders',
            component: ConnectOrders,
            meta: {
              title: 'Connect Orders',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/connect/sync-audit-logs',
            name: 'connect-sync-audit-logs',
            component: ConnectSyncAuditLogs,
            meta: {
              title: 'Connection Sync Audit Logs',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
        ]
      },
      {
        path: '/qr-ordering',
        name: 'qr-ordering',
        component: QueueBuster,
        meta: {
          title: 'QR-Ordering',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        },
      },
      {
        path: '/test',
        name: 'test',
        component: Test,
        meta: {
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        },
      },
      {
        path: '/customers',
        name: 'customers',
        component: Customers,
        meta: {
          title: 'Customers',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        },
        children: [
          {
            path: '/customers',
            name: 'list',
            component: CustomerItem,
            meta: {
              title: 'Customers List',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/customers/:id',
            name: 'details',
            component: CustomerSingle,
            meta: {
              title: 'Customer Details',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          }
        ]
      },
      {
        path: '/employees',
        name: 'employees',
        component: Employees,
        meta: {
          title: 'Employees',
          middleware: [auth, admin, log],
          hideFooter: false,
          icon: favico,
        },
      },
      {
        path: '/blacklists',
        name: 'blacklists',
        component: Blacklists,
        meta: {
          title: 'Blacklist',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        },
        children: [
          {
            path: '/blacklists',
            name: 'list',
            component: BlacklistList,
            meta: {
              title: 'Blacklist List',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          // {
          //   path: '/blacklists/:id',
          //   name: 'blacklist-single',
          //   component: BlacklistItem,
          //   meta: {
          //     hideFooter: false
          //   }
          // }
        ]
      },
      {
        path: '/loyalty',
        name: 'loyalty',
        component: Loyalty,
        meta: {
          title: 'Loyalty',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        },
        children: [
          {
            path: '/loyalty',
            name: 'loyalty-item',
            component: LoyaltyItem,
            meta: {
              title: 'Loyalty Item',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/loyalty/tiers',
            name: 'loyalty-tiers',
            component: LoyaltyTiers,
            meta: {
              title: 'Loyalty Tiers',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
          path: '/loyalty/collection',
          name: 'loyalty-collection',
          component: LoyaltyCollection,
          meta: {
            title: 'Loyalty Points Collection',
            middleware: [auth, admin, log, subscription],
            hideFooter: false,
            icon: favico,
          }
        },
        ]
      },
      {
        path: '/orders',
        name: 'orders',
        component: Orders,
        meta: {
          title: 'Orders',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        }
      },
      {
        path: '/orders-new',
        name: 'orders-new',
        component: OrdersNew,
        meta: {
          title: 'Orders New',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        }
      },
      {
        path: '/curbside',
        name: 'curbside',
        component: Curbside,
        meta: {
          title: 'Curbside',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        }
      },
      {
        path: '/feedback',
        name: 'feedback',
        component: Feedback,
        meta: {
          title: 'Feedback',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        },
        children: [
          {
            path: '/feedback',
            name: 'feedback-list',
            component: FeedbackItem,
            meta: {
              title: 'Feedback List',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
        ]
      },
      {
        path: '/reports',
        name: 'reports',
        component: Report,
        redirect: '/reports/general',
        meta: {
          title: 'Reports',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        },
        children: [
          {
            path: '/reports/general',
            name: 'reports_general',
            component: ReportGeneral,
            meta: {
              title: 'General Reports',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/reports/orders',
            name: 'reports_orders',
            component: ReportOrders,
            meta: {
              title: 'Reports Orders',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/reports/locations',
            name: 'reports_locations',
            component: ReportLocations,
            meta: {
              title: 'Reports Locations',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/reports/customers',
            name: 'reports_customers',
            component: ReportCustomers,
            meta: {
              title: 'Reports Customers',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/reports/products',
            name: 'reports_products',
            component: ReportProducts,
            meta: {
              title: 'Reports Products',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/reports/loyalty',
            name: 'reports_loyalty',
            component: ReportLoyalty,
            meta: {
              title: 'Reports Loyalty',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          }
        ]
      },
      {
        path: '/messaging',
        name: 'messaging',
        component: Messaging,
        redirect: '/messaging/notification',
        meta: {
          title: 'Messaging',
          middleware: [auth, admin, log, subscription],
          icon: favico,
        },
        children: [
          {
            path: '/messaging/notification',
            name: 'messaging_notification',
            component: Notification,
            meta: {
              title: 'Messaging Notification',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/messaging/order-statuses',
            name: 'messaging_order_statuses',
            component: MessagingStatuses,
            meta: {
              title: 'Messaging Order Statuses',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/messaging/star-ratings',
            name: 'star-ratings',
            component: StarRatings,
            meta: {
              title: 'Messaging Star Ratings',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
        ]
      },
      {
        path: '/orders/:id',
        name: 'order-details',
        component: Order,
        meta: {
          title: 'Order Details',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        }
      },
      {
        path: '/online-presence',
        name: 'online-presence',
        component: GMB,
        meta: {
          title: 'Online Presence',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        },
        children: [
          {
            path: '/online-presence/pages/:page',
            name: 'page',
            component: GMBPages,
            meta: {
              title: 'Online Presence',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          // {
          //   path: '/gmb/manage-locations',
          //   name: 'gmb_manageLocations',
          //   component: GMBManageLocations,
          //   meta: {
          //     title: 'Manage Locations',
          //     middleware: [auth, admin, log, subscription],
          //     hideFooter: false,
          //     icon: favico,
          //   }
          // },
          // {
          //   path: '/gmb/listing-page',
          //   name: 'gmb_listingPage',
          //   component: GMBListingPage,
          //   meta: {
          //     title: 'Listing Page',
          //     middleware: [auth, admin, log, subscription],
          //     hideFooter: false,
          //     icon: favico,
          //   }
          // },
          // {
          //   path: '/gmb/partner-connect',
          //   name: 'gmb_partnerConnect',
          //   component: GMBPartnerConnect,
          //   meta: {
          //     title: 'Partner Connect',
          //     middleware: [auth, admin, log, subscription],
          //     hideFooter: false,
          //     icon: favico,
          //   }
          // },
          // {
          //   path: '/gmb/google-post',
          //   name: 'gmb_googlePost',
          //   component: GMBGooglePost,
          //   meta: {
          //     title: 'Google and FB Post',
          //     middleware: [auth, admin, log, subscription],
          //     hideFooter: false,
          //     icon: favico,
          //   }
          // },
          {
            path: '/online-presence/settings',
            name: 'settings',
            component: GMBSettings,
            meta: {
              title: 'Settings',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
        ]
      },
      {
        path: '/Locations',
        name: 'locations',
        component: Locations,
        children: [
          {
            path: '/Locations',
            name: 'store locations',
            component: LocationsMap,
            meta: {
              title: 'Store Locations',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          }
        ],
        meta: {
          title: 'Locations',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        }
      },
      {
        path: '/menus',
        name: 'menus',
        component: Menus,
        meta: {
          title: 'Menus',
          middleware: [auth, admin, log, subscription],
          icon: favico,
        },
        children: [
          {
            path: '/menus/:id',
            name: 'menus',
            component: Menu,
            meta: {
              title: 'Menus',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/add-menu/:id',
            name: 'add-menu',
            component: MenuAdd,
            meta: {
              title: 'Menus',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/menus/:id/categories/:catId/:tab?',
            name: 'MenuCategory',
            component: MenuCategory,
            meta: {
              title: 'Menu Categories',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/menus/:id/categories/:catId',
            name: 'updatecategory',
            component: MenuUpdateCategory,
            meta: {
              title: 'Menu Category Update',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/menus/:id/upsells',
            name: 'MenuUpsells',
            component: MenuUpsells,
            meta: {
              title: 'Menu Up Sells',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/menus/:id/categories/:catId/items',
            name: 'MenuCategory',
            component: MenuItems,
            redirect: '/menus/:id/categories/:catId',
            children: [
              {
                path: '/menus/:id/categories/:catId/items/list',
                name: 'itemlist',
                component: ItemList,
                meta: {
                  title: 'Item List',
                  middleware: [auth, admin, log, subscription],
                  hideFooter: false,
                  icon: favico,
                }
              },
              {
                path: '/menus/:id/categories/:catId/items/create',
                name: 'itemCreate',
                component: ItemCreate,
                meta: {
                  title: 'Item Create',
                  middleware: [auth, admin, log, subscription],
                  hideFooter: false,
                  icon: favico,
                }
              },
              {
                path: '/menus/:id/categories/:catId/items/:itemId/modifiers/:tab?',
                name: 'itemModifier',
                component: itemModifier,
                children: [
                  {
                    path: '/menus/:id/categories/:catId/items/:itemId/modifiers/create',
                    name: 'modifierGroupCreate',
                    component: ModgroupModifierCreate,
                    meta: {
                      title: 'Create Modifier Group',
                      middleware: [auth, admin, log, subscription],
                      hideFooter: false,
                      icon: favico,
                    }
                  },
                  {
                    path: '/menus/:id/categories/:catId/items/:itemId/modifiers/:modifierId/items/:tab?',
                    name: 'modifier',
                    component: ModgroupModifiers,
                    children: [
                      {
                        path: '/menus/:id/categories/:catId/items/:itemId/modifiers/:modifierId/update/:tab?',
                        name: 'EditModgroupItem',
                        component: EditModgroupItem,
                        meta: {
                          title: 'Item Modifier Edit',
                          middleware: [auth, admin, log, subscription],
                          hideFooter: false,
                          icon: favico,
                        }
                      },
                      {
                        path: '/menus/:id/categories/:catId/items/:itemId/modifiers/:modifierId/items/:modifierGroupId',
                        name: 'modifierGroupItem',
                        component: ModgroupModifierItem,
                        meta: {
                          title: 'Item Modifier Group',
                          middleware: [auth, admin, log, subscription],
                          hideFooter: false,
                          icon: favico,
                        }
                      }
                    ],
                    meta: {
                      title: 'Item Modifier List',
                      middleware: [auth, admin, log, subscription],
                      hideFooter: false,
                      icon: favico,
                    }
                  },
                ],
                meta: {
                  title: 'Item Modifier',
                  middleware: [auth, admin, log, subscription],
                  hideFooter: false,
                  icon: favico,
                }
              },
              {
                path: '/menus/:id/categories/:catId/items/:itemId/modifiers/:modifierId/items/:tab?',
                name: 'modifier',
                component: ModgroupModifiers,
                meta: {
                  title: 'Item Modifier List',
                  middleware: [auth, admin, log, subscription],
                  hideFooter: false,
                  icon: favico,
                }
              },
            ],
            meta: {
              title: 'Menu Category Item List',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          }
        ]
      },
      {
        path: '/premade-plans',
        name: 'premade-plans',
        component: PremadePlanIndex,
        meta: {
          title: 'Premade Plans',
          middleware: [auth, admin, subscription],
          hideFooter: false,
          icon: favico,
        },
        children: [
          {
            path: '/premade-plans',
            name: 'premade-plans-list',
            component: PremadePlans,
            meta: {
              title: 'Premade Plan List',
              middleware: [auth, admin, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/premade-plans/create',
            name: 'create-premade-plans',
            component: CreatePremadePlan,
            meta: {
              title: 'Premade Plan Create',
              middleware: [auth, admin, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/premade-plans/:id',
            name: 'edit-premade-plans',
            component: EditPremadePlan,
            meta: {
              title: 'Premade Plan Edit',
              middleware: [auth, admin, subscription],
              hideFooter: false,
              icon: favico,
            }
          },

        ]
      },
      {
        path: '/subscribers',
        name: 'subscribers',
        component: SubscriptionIndex,
        meta: {
          title: 'Subcribers',
          middleware: [auth, admin, subscription],
          hideFooter: false,
          icon: favico,
        },
        children: [
          {
            path: '/subscribers',
            name: 'subscribers-list',
            component: SubscriberItems,
            meta: {
              title: 'Subscriber List',
              middleware: [auth, admin, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/subscribers/:id',
            name: 'subscribers-details',
            component: SubscriberSingle,
            meta: {
              title: 'Subscriber Details',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          }
        ]
      },
      {
        path: '/subscription-settings',
        name: 'subscription-settings',
        component: SubscriptionSettings,
        meta: {
          title: 'Subscriber Settings',
          middleware: [auth, admin, subscription],
          hideFooter: false,
          icon: favico,
        }
      },
      {
        path: '/franchisee',
        name: 'franchisee',
        component: Franchisee,
        meta: {
          title: 'Franchisee',
          middleware: [auth, admin, log, subscription],
          hideFooter: false,
          icon: favico,
        }
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {
          title: 'Settings',
          middleware: [auth, admin, log, subscription],
          icon: favico,
        },
        children: [
          {
            path: '/settings/concepts',
            name: 'SettingsConcepts',
            component: Concepts,
            meta: {
              title: 'Settings Concepts',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/settings/money-hash',
            name: 'MoneyHash',
            component: MoneyHash,
            meta: {
              title: 'Settings Money Hash',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/settings/globals',
            name: 'globals',
            component: Globals,
            meta: {
              title: 'Settings Globals',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/settings/facilities',
            name: 'facilities',
            component: Facilities,
            meta: {
              title: 'Facilities',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/settings/allergens',
            name: 'allergens',
            component: Allergens,
            meta: {
              title: 'Settings Allergens',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/settings/order-statuses',
            name: 'OrderStatuses',
            component: OrderStatuses,
            meta: {
              title: 'Settings Order Statuses',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
          {
            path: '/settings/integrations',
            name: 'Integration-Settings',
            component: IntegrationSettings,
            meta: {
              title: 'Settings Integrations',
              middleware: [auth, admin, log, subscription],
              hideFooter: false,
              icon: favico,
            }
          },
        ]
      },
    ]
  }
]

Vue.prototype.$scrollTop = function () {
  document.getElementById('topDiv')?.scrollIntoView();
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
})

function nextFactory(context: { from?: import("vue-router").Route; next: any; router?: VueRouter; to?: import("vue-router").Route }, middleware: { [x: string]: any }, index: number) {
  const subsequentMiddleware = middleware[index]

  if (!subsequentMiddleware) return context.next

  return (...parameters: any) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  };
}

router.beforeEach((to: any, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()

})
router.afterEach((to: any, from) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609

    // let favi = JSON.parse("{\"favico\": \"http://cdn.getsolo.io/Foodics_Fav-1-50x48.png\", \"copyright\": \"© 2021 Solo Technology Services LLC\", \"help_desk\": \"http://help.getsolo.io/en/\"}");


    Vue.nextTick(() => {
      setTimeout(() => {

        const favi = JSON.parse(extras);
        favico = favi.favicon

        const DEFAULT_TITLE = label === 'Foodics Online' ? label : 'Solo Portal';
        document.title = `${DEFAULT_TITLE} | ${to.meta.title}`;

        const link = document.querySelector("[rel='icon']")!
        link.setAttribute('href', favico)

      }, 1000);
    });
});

export default router

