
import { Vue, Component, Prop } from "vue-property-decorator";
import { translations } from "@/mixins";

@Component({
  mixins: [translations],
})

export default class WhatsAppModal extends Vue {
  @Prop() title!: String;
  @Prop() body!: String;
}
