import BaseService from '../base.service'

let authService = class AuthService extends BaseService {

  constructor() {
    super();

  }

  getHostname() {
    if(process.env.NODE_ENV === 'development') {
      return process.env.VUE_APP_HOSTNAME;
    } else {
      return window.location.hostname;
    }
  }

  getClient() {
    let url = this.baseURL + `clients?filter[url]=${this.getHostname()}`;
    return super.get(url);
  }

  forgot(username: string) {
    const url = this.baseURL + 'forgot-password/employee';
    return super.post(url, { username });
  }

  login(params: Object = {}) {
    let url = this.baseURL + 'login';
    return super.post(url, params);
  }

  logout() {
    let url = this.baseURL + 'logout';

    return super.post(url, {});
  }

  facebookLogin(params: {}) {
    let url = this.baseURL + 'auth/facebook';

    return super.post(url, params);
  }

  register(params: Object = {}) {
    let url = this.baseURL + 'register'
    return super.post(url, params);
  }

  getFoodicsToken(code: string) {
    let url = this.baseURL + 'foodics-f5/token'
    return super.post(url, {
      code,
      host: location.hostname
    });
  }
  
  async fetchCountriesData() {
    try {
      const response = await fetch(this.baseURL + 'countries');
      const data = await response.json();
      const countryData = data.data;
      return countryData;
    } catch (error) {
      throw new Error('Error fetching country data: ' + (error as Error).message);
    }
  }

  getTOTP() {
    let url = this.baseURL + `tfa/setup/totp`
    return super.get(url);
  }

  verifySetup(params: Object = {}) {
    let url = this.baseURL + 'tfa/verify-setup'
    return super.post(url, params);
  }

  authenticate(params: Object = {}) {
    let url = this.baseURL + 'tfa/authenticate'
    return super.post(url, params);
  }

  async refreshToken() {
    let url = this.baseURL + 'tfa/refresh-token'
    return super.get(url);
  }
};

export default authService
