
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator"
@Component({
  components: {
    RouteBreadCrumb
  }
})
export default class Settings extends Vue {

}
