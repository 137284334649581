
import {Integration as Integrated} from '@/models';
import IntegratedItems from './components/integrations/IntegratedItems'
import IntegrationAvailable from './components/integrations/IntegrationAvailable'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import {mapGetters, mapMutations} from 'vuex'

import {Component, Vue} from "vue-property-decorator"
import {Integrations} from '@/services/SOLO'

@Component({
  components: {
    IntegratedItems,
    IntegrationAvailable,
    RouteBreadCrumb,
  },
  computed: {
    ...mapGetters({
      getClient: "account/getUserClient",
      userRole: "account/getUserRole",
      getMoneyHash: 'page/getMoneyHash'
    }),
  },
  methods: {
    ...mapMutations({
      setMoneyHash: 'page/setMoneyHash',
      setSoloLoyalty: 'page/setSoloLoyalty',
      setPartooGmb: 'page/setPartooGmb'
    })
  },
})

export default class IntegrationSettings extends Vue {
  integrations: Array<Integrated> = []
  providers: Array<Integrated> = []
  mixAndMatch: any = []
  userRole: any
  setMoneyHash!: Function
  getMoneyHash!: Function
  setSoloLoyalty!: Function
  setPartooGmb!: Function

  mounted() {
    this.getProviders()
  }

  async getIntegrations(data: any) {
    await Integrations.getAllIntegrations()
      .then((response: any) => {
        response.data.data.map((v: any) => {
          return this.mixAndMatch.some((providers: any) => {
            if(providers.attributes.label === v.attributes.provider) {
              return v.attributes['logo'] = providers.attributes.logo
            }
          })
        })
        this.integrations = response.data.data

        this.setMoneyHash(response.data.data.find((item: any) => item.attributes.provider == 'money-hash'))
        this.setSoloLoyalty(response.data.data.find((item: any) => item.attributes.provider == 'solo-loyalty'))
        this.setPartooGmb(response.data.data.find((item: any) => item.attributes.provider == 'partoo'))
      })
  }

  async getProviders () {
    let host = {
      'reseller': window.location.host
    }

    await this.getIntegrations(this.mixAndMatch)

    await Integrations.getAllProviders(host)
      .then((response: any) => {
        let availableProviders = response.data.data
        this.providers = availableProviders.filter((available: any) => {
          let integrations = this.integrations.some((providers: any) => providers.attributes.provider === available.attributes.label)
          if (!integrations) {
            return available
          }
        })
        this.mixAndMatch = this.providers
      })
  }
}
