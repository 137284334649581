
import { translations, notificationAlerts } from '@/mixins'
import { Select, Option } from "element-ui";
import { Menu as MenuApi } from '@/services/SOLO';
import {
  ValidationProvider,
} from "vee-validate";
import {
  Component,
  Prop,
  Vue,
} from "vue-property-decorator";
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    }),
  },
  mixins: [translations, notificationAlerts]
})
export default class MenuCategoryModal extends Vue {
  menu: string = '';
  $notify: any;
  translate!: Function;
  successNotification!: Function;
  systemErrorNotification!: Function;

  @Prop() open!: Boolean;

  get isOpen(): Boolean {
    return this.open;
  }

  constructor() {
    super();
  }

  createMenu(menu: string): void {
    MenuApi.create({
      label: this.menu,
      key: uuidv4(),
    }).then((response) => {
      this.$notify({
        title: 'MENU SAVED',
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message: 'Menu was saved successfully!',
        type: 'success',
        icon: 'fas fa-check',
      });
      console.log("check response menu", response);
        this.$router.push({
                  path: `/menus/${response.data.data.id}`,
            })
      this.$emit('modal:close', false);
    })
    .catch((err: any) => {
    console.log("errerer", err.response.data.error[0].detail);
    this.$notify({
      title: "SYSTEM ERROR!",
      verticalAlign: "bottom",
      horizontalAlign: "left",
      message: err.response.data.error[0].detail,
      type: "danger",
      icon: "fas fa-bomb",
    });
      });
  }

  close() {
    this.$emit('modal:close', false);
  }
}
