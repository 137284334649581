
import {
  Applications,
  Banner as BannerApi
} from '@/services/SOLO'
import {eventHandler, translations} from '@/mixins'
import BannerSet from './components/BannerSetModal'
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import {mapGetters} from "vuex";

@Component({
  components: {
    BannerSet
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale'
    })
  },
  mixins: [eventHandler, translations]
})
export default class SlidingBanners extends Vue {
  isBannerSetOpen: Boolean = false
  banners: Array<Object> = []
  sliders: Array<Object> = []
  translate!: Function
  $notify: any
  confirm!: Function
  public getLocale: any;

  mounted() {
    this.getBanner()
  }

  getBanner() {
    BannerApi.banners().then((response: any) => {
      this.banners = response.data.data
      this.sliders = response.data.included
      console.log(this.banners.length)
    });
  }

  addNewBannerSet(open: any) {
    this.isBannerSetOpen = open
  }

  closeNewBannerSet(close: any) {
    this.isBannerSetOpen = close
  }

  get deleteMsgBoxText(): String {
    return "Are you sure you want to delete this Banner Set?";
  }

  get deleteMsgBoxTextSlide(): String {
    return "Are you sure you want to delete this slide?";
  }

  removeSlide(bannerID: any, sliderID: any) {

    this.confirm(this.$bvModal, this.deleteMsgBoxTextSlide).then(
      (value: boolean) => {
        if(value) {
          BannerApi.removeslide(
            bannerID,
            sliderID
          ).then((response) => {
            this.getBanner();
            this.$notify({
              title: "SLIDER DELETED!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "slide has been deleted successfully",
              type: "success",
              icon: "fas fa-check",
            });
          })
          .catch((err) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Something went wrong, please try again!",
              type: "danger",
              icon: "fas fa-bomb",
            });
          });
        }
      }
    );
  }

  removeBanner(bannerId: Number) {
    this.confirm(this.$bvModal, this.deleteMsgBoxText).then(
      (value: boolean) => {
        if(value) {
          BannerApi.removeBannerSet(bannerId).then((response) => {
            this.getBanner();
            this.$notify({
              title: "BANNER DELETED!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Banner Set has been deleted successfully",
              type: "success",
              icon: "fas fa-check",
            });
          });
        }
      }
    );
  }

  getSliders(link: any) {
    return this.sliders && this.sliders.filter((slider: any) => link === slider.relationships.slider.links.self) || [];
  }
}
