
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { translations } from "@/mixins";
import { mapGetters } from "vuex"
import Multiselect from 'vue-multiselect'
import { Select, Option } from "element-ui";
import { WhatsApp as WhatsAppService } from "@/services/SOLO";

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Multiselect
  },
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
      getLocale: 'app/getLocale'
    }),
  },
  mixins: [translations]
})

export default class Step3 extends Vue {
  @Prop() accountDetails!: any;
  translate!: Function;
  messageNo: number = 1;

  /** Getters From Vuex Store */
  getConceptSettings: any
  getLocale: any

  /** Loading Indicator for API Call */
  loadingNextButton: boolean = false;

  /** Receiver For Messges in API Response */
	whatsAppMessages: Array<any> = []
  /** Payload For Update Messages */
  customMessages: Array<any> = []

  /** Options For Dropdown Selection Ordering */
	orderingOptions : Array<any> = [] 
  /** Payload For Updated Options For Ordering */
  selectedOrderingOptions: any = []; 

  /** Options For Dropdown Selection Branch */
	branchOptions : Array<any> = [] 
  /** Payload For Updated Options For Branches */
  selectedBranchOptions: any = [];

  /** Others */
  isSelectedAllOrderOptions: boolean = false
  isSelectedAllBranchOptions: boolean = false

  orderStatuses: Array<any> = []
  isGettingMessages: boolean = false

  get getCurrentMessageOnDisplay() {
    return this.customMessages[this.messageNo - 1].attributes.body[this.getLocale === 'en_US' ? 'en-us' : this.getLocale === 'ar_SA' ? 'ar-sa' : 'en-us']
  }

  @Watch("selectedOrderingOptions", { immediate: true, deep: true })
  onChangeSelectedOrderingOptions(newVal: any) {
    this.isSelectedAllOrderOptions = newVal.length === this.orderingOptions.length
    this.$emit('on-change-order-options', newVal)
  }

  @Watch("selectedBranchOptions", { immediate: true, deep: true })
  onChangeSelectedBranchOptions(newVal: any) {
    this.isSelectedAllBranchOptions = newVal.length === this.branchOptions.length
    this.$emit('on-change-branch-options', newVal)
  }

  @Watch("customMessages", { deep: true })
  onChangeCustomMessages(newVal: any) {
    let arr_msg = newVal.map((msg: any) => {
      return ['order_type', 'location'].includes(msg.attributes['button-model']) ? {
          ...msg,
          attributes: {
            ...msg['attributes'],
            simulationOption: msg.attributes['button-model'] === 'location' ? this.selectedBranchOptions.map((val : any) => val.label) : this.selectedOrderingOptions.map((val: any) => val.label)
          }
        } : msg
    })
    this.$emit('on-change-messages', arr_msg)
  }

  async created() {
    this.isGettingMessages = true
    await this.getWhatsAppMessages()
  }

	getOrderTypeOptions(options: Array<any>, defaultOptions: Array<any>) {
    this.selectedOrderingOptions = options
    this.orderingOptions = defaultOptions
	}

  getLocationOptions(options: Array<any>, defaultOptions: Array<any>) {
    this.selectedBranchOptions = options.map((option : any) => {
      return {
        ...option,
        value: +option.value
      }
    })
    this.branchOptions = defaultOptions
	}

  handleMessage(currentMessageNo: number) {
    if (currentMessageNo !== this.customMessages.length) {
      if (this.getLocale === 'en_US') {
        if (this.customMessages[currentMessageNo - 1].attributes.body['en-us'] === '') {
          const notifyOptions: any = {
            title: 'Message is Required',
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: 'Failed to saved message.',
            type: "warning",
            icon: "fas fa-info-circle"
          }
          return this.$notify(notifyOptions);
        }
      }
      if (this.getLocale === 'ar_SA') {
        if (this.customMessages[currentMessageNo - 1].attributes.body['ar-sa'] === '') {
          const notifyOptions: any = {
            title: 'Message is Required',
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: 'Failed to saved message.',
            type: "warning",
            icon: "fas fa-info-circle"
          }
          return this.$notify(notifyOptions);
        }
      }

      if (this.selectedOrderingOptions.length === 0 && currentMessageNo === 1 && this.customMessages[currentMessageNo - 1].attributes['button-model'] === 'order_type') {
        const notifyOptions: any = {
          title: 'Ordering Options Required',
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Failed to saved message.',
          type: "warning",
          icon: "fas fa-info-circle"
        }
        return this.$notify(notifyOptions);
      }

      if (this.selectedBranchOptions.length === 0 && currentMessageNo === 2  && this.customMessages[currentMessageNo - 1].attributes['button-model'] === 'location') {
        const notifyOptions: any = {
          title: 'Branches is Required',
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Failed to saved message.',
          type: "warning",
          icon: "fas fa-info-circle"
        }
        return this.$notify(notifyOptions);
      }

      let payload: Object = {
        body : this.customMessages[currentMessageNo - 1].attributes.body,
      }

      let orderTypeOptions = []
      let branchOptions = []

      if (currentMessageNo === 1) {
        orderTypeOptions = this.selectedOrderingOptions.map((opt: any) => {
          return {...opt, label: opt.label['en-us']}
        })
      }

      if (currentMessageNo === 2) {
        branchOptions = this.selectedBranchOptions.map((opt: any) => {
          return {...opt, label: opt.label['en-us']}
        })
      }

      if (['order_type', 'location'].includes(this.customMessages[currentMessageNo - 1].attributes['button-model'])) {
        payload = {...payload, buttons: currentMessageNo === 1 ? orderTypeOptions : currentMessageNo === 2 ? branchOptions : []}
      }

      if (this.customMessages[currentMessageNo - 1].attributes['is-active-editable']) {
        payload = {...payload, 'is-active': this.customMessages[currentMessageNo - 1].attributes['is-active']}
      }
      this.handleUpdateMessage(this.customMessages[currentMessageNo - 1].id, payload)
    } else {
      this.handleOrderStatusMessage(this.customMessages[currentMessageNo - 1].attributes['is-sending-order-status'])
    }
  }

  async handleOrderStatusMessage(payload : any) {
    try {
      this.loadingNextButton = true
      const { id: conceptId } = this.getConceptSettings
      const { id: vonageId } = this.accountDetails
      await WhatsAppService.updateWhatsAppAccount(conceptId, vonageId, { 'is-sending-order-status': payload })
      this.$emit('done-customizing-messages', true)
    } catch (error) {
      if (error.response) {
      // Error with response from the server
        const { error: err } = error.response.data
        console.log("GET THE ERROR", err);
      }
    } finally {
      this.loadingNextButton = false
    }
  }

  handleSelectOrderOptions() {
    this.selectedOrderingOptions = this.isSelectedAllOrderOptions ? [] : [...this.orderingOptions]
  }

  handleSelectBranchOptions() {
    this.selectedBranchOptions = this.isSelectedAllBranchOptions ? [] : [...this.branchOptions]
  }

  handleNextButton() {
    this.handleMessage(this.messageNo)
  }

  handleBackButton() {
    this.messageNo -= 1;
    this.$emit('on-change-message-order', this.messageNo)
  }

  async getWhatsAppMessages() {
    try {
      const { id } = this.getConceptSettings
      const { data } = await WhatsAppService.getMessages(id)
			this.whatsAppMessages = data.data
      if (this.whatsAppMessages.length > 0) {
        let sortedMessageArr = this.whatsAppMessages.sort((message1, message2) => message1.attributes['display-order'] - message2.attributes['display-order']);
        this.customMessages = sortedMessageArr.map((msg : any) => {
          if (msg.attributes['button-model'] === 'order_type') {
            this.getOrderTypeOptions(msg.attributes['buttons'], msg.attributes['default-buttons'])
          }
          if (msg.attributes['button-model'] === 'location') {
            this.getLocationOptions(msg.attributes['buttons'], msg.attributes['default-buttons'])
          }
          return msg
        })

          const orderStatusesMessage = {
            'is-sending-order-status' : this.accountDetails.attributes['is-sending-order-status'],
            title: 'Send Order Status Update',
            messages: this.orderStatuses
          }

          this.customMessages.push({ attributes: orderStatusesMessage, type: 'whatsapp-messages-order-status' })
        }
    } catch (error) {
      if (error.response) {
      // Error with response from the server
        const { error: err } = error.response.data
        console.log("GET THE ERROR", err);
      }
    } finally {
      this.isGettingMessages = false
    }
  }

  async handleUpdateMessage(messageId: any, payload: any) {
    try {
      this.loadingNextButton = true
      const { id } = this.getConceptSettings
      await WhatsAppService.updateMessage(id, messageId, payload)
      if (this.messageNo === this.customMessages.length) {
        this.$emit('done-customizing-messages', true)
      }
      if (this.messageNo < this.customMessages.length) {
        this.messageNo += 1;
        this.$emit('on-change-message-order', this.messageNo)
      }
    } catch (error) {
      if (error.response) {
      // Error with response from the server
        const { error: err } = error.response.data
        console.log("GET THE ERROR", err);
      }
    } finally {
      this.loadingNextButton = false
    }
  }

  async getOrderStatuses() {
    try {
      const { id } = this.getConceptSettings
      const { data } = await WhatsAppService.getOrderStatus(id)
      this.orderStatuses = data.data.filter((val: any) => ['accepted', 'ready', 'finished'].includes(val.attributes.code)).map((val : any) => {
        const { code } = val.attributes
        return {
          message: {...val.attributes['pickup-description']},
          label: code === 'accepted' ? 'Order Received Status' : code === 'ready' ? 'Ready For Collection Order Status' : 'Collected Order Status'
        }
      })
    } catch (error) {
      if (error.response) {
      // Error with response from the server
        const { error: err } = error.response.data
        console.log("GET THE ERROR", err);
      }
    }
  }
}
