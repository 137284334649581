
import VueImage from 'vue-image-effect'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import {Component, Vue} from "vue-property-decorator"
import {SubscriptionMeals} from '@/services/SOLO'

@Component({
  components: {
    RouteBreadCrumb,
    VueImage
  }
})
export default class PremadePlans extends Vue {
  premadeplans: any = []
  premadeStatus: any = ''
  mounted() {
    this.getPremadePlans()
  }

  getPremadePlans() {
    SubscriptionMeals.index()
        .then((response: any) => {
          this.premadeplans = response.data.data
        })
  }

  deletePremadePlan(id: number) {
    let targetIndex = this.premadeplans.map((i: any) => {
          return i.id;
      }).indexOf(id);
    SubscriptionMeals.delete(id)
      .then((response: any) => {
        this.premadeplans.splice(targetIndex, 1);
      })
  }

  enableDisablePremadePlan(id: number, key: any) {

    let payload = {
      'enabled': !this.premadeplans[key].attributes.enabled
    }
    SubscriptionMeals.update(id, payload)
      .then((response: any) => {
        this.premadeplans[key].attributes.enabled = response.data.data.attributes.enabled
      })
  }
}

