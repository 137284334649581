
import { Settings } from '@/services/SOLO';
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { eventHandler, translations } from '@/mixins'
import { mapGetters } from 'vuex';

@Component({
  mixins: [eventHandler, translations],
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    })
  }
})
export default class CustomFieldsTable extends Vue {
  
  @Prop() items!: Array<Object>;
  
  btnLoading!: Function
  btnRestore!: Function
  confirm!: Function
  translate!: Function
  $notify: any
  getLocale!: any

  @Watch("items", {immediate: true, deep: true})
  openChanged(newVal: any) {
    
  }

  get deleteMsgBoxText(): String {
    return 'Are you sure you want to delete this custom field?'
  }

  modifyField(item: any) {    
    this.$emit('fields:modify', item);
  }

  confirmationMessageTitle(toDelete: any) {
    const h = this.$createElement
    const message = h('div', 
      { domProps: 
        { innerHTML: this.translate('Are you sure') + ` <span class="font-weight-bold">${toDelete['custom-field']}${this.translate('?')}</span><br><br>${this.translate('Please note')}`}
      })
    
    return message
  }

  async detachField(item: any) {
    try {
      const isDelete = await this.confirm(this.$bvModal, this.confirmationMessageTitle(item),
        {
          title: this.translate('Please Confirm'),
          okTitle: this.translate('OK'),
          cancelTitle: this.translate('Cancel'),
          footerClass: this.getLocale === 'ar_SA' ? 'd-flex flex-row-reverse flex-gap' : '',
        }
      )
      if (isDelete) {
        const payload = {         
          entity: 'item',
          'entity-id': this.$route.params.itemId
        }   
        const response = await Settings.detachField(item['custom-field-id'], payload)
        this.$emit('fields:update')
        this.$notify({
          title: this.translate("CUSTOM FIELD DELETED"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate("Custom field successfully deleted."),
          type: "success",
          icon: "fas fa-check",
        });
      }
    } catch (error) {
      this.$notify({
        title: this.translate("SYSTEM ERROR!"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate("Something went wrong, please try again!"),
        type: "danger",
        icon: "fas fa-bomb",
      });
    }
  }
}
