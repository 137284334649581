
import {Select, Option, Form,
  FormItem,} from "element-ui";
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {Applications, Menu, Slider, Popup} from "@/services/SOLO";
import {translations} from '@/mixins'
import {mapGetters} from 'vuex'
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";

interface AppDetailsForm {
  application_type?: any
  key?: any
  label?: any
  default_menu_id?: any
  hosts?: any
  selectedPhoneNumber?: any
  slider_id?: any
  marketing_slider_id?: any
  homescreen_slider_id?: any
  order_status_banner_id?: any
  'fb_pixel_id'?: any
  'gtm_id'?: any
  'popup-id'?: any
  sub_domain?: any
  'menu_only'?: any
  'payment-gateway'?: string | any
  'allowed-order-types'?: string | any
  'allowed-payments-pickup'?: string | any
  'allowed-payments-delivery'?: string | any
  theme: {
    primary_color?: string | any
    secondary_color?: string | any
  }
  settings: {
    category_display?: string | any
    nav_bar_logo?: string | any
  }
  'order-type-menus': any
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    ValidationProvider
  },
  computed: {
    ...mapGetters({
      getConceptSettings: "account/getConceptSettings",
      getLocale: 'app/getLocale',
      getUserClient: 'account/getUserClient',
      getSubscription: 'account/getSubscription',
    }),
  },
  mixins: [translations]
})
export default class AppDetails extends Vue {
  getConceptSettings!: any
  @Prop() details!: any
  @Prop() popups!: any
  special_char: any = false
  saving: boolean = false
  $notify: any
  defaultMenus: any = ''
  sliders: any = '';
  getLocale!: any;
  fileBarLogo: any = '';
  imageTypes: any = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Upload Image",
    }
  ];
  categoryHeader: Array<any> = [
    {
      value: 'image_text',
      text: "Image & Text",
    },
    {
      value: 'text',
      text: "Text",
    },
  ];
  phoneNumber: Array<any> = [
    {
      value: 'optional',
      text: "Optional",
    },
    {
      value: 'mandatory',
      text: "Mandatory",
    },
  ];
  navabarLogo: any = ''
  form: AppDetailsForm = {
    application_type: '',
    key: '',
    label: '',
    default_menu_id: null,
    hosts: '',
    sub_domain: null,
    slider_id: null,
    marketing_slider_id: null,
    homescreen_slider_id: null,
    order_status_banner_id: null,
    'gtm_id': null,
    'fb_pixel_id': null,
    'popup-id': null,
    'menu_only': false,
    'payment-gateway': '',
    'allowed-order-types': '',
    'allowed-payments-pickup': '',
    'allowed-payments-delivery': '',
    selectedPhoneNumber: '',
    theme: {
      primary_color: '',
      secondary_color: '',
    },
    settings: {
      category_display: '',
      nav_bar_logo: ''
    },
    'order-type-menus': []
  }
  orderTypeMenuDelivery : any = ''
  orderTypeMenuPickup: any = ''
  applicantDetails: any = {}
  appMenu: any = ''
  appSlider: any = ''
  appSliderHome: any = ''
  appSliderBanner: any = ''
  appMarketingBanner: any = ''
  appPopup: any = []
  appDefaultMenu: any = ''
  appDefaultSlider: any = ''
  appDefaultSliderHome: any = ''
  appDefaultSliderBanner: any = ''
  appDefaultMarketingBanner: any = ''
  defaultData: any = {}
  selectedMenu: any = []
  selectedSlider: any = []
  selectedStatusBanner: any = []
  selectedHomescreen: any = []
  selectedMarketingBanner: any = []
  selectedPopup: any = []
  getPackageType: any = {}
  isFreePackage: any = {}
  imageType: any = 0
  translate!: Function
  getUserClient!: any;
  getSubscription!: any;
  isToggleOnForSeparatedMenu: any = false

 @Watch('form.sub_domain', {deep: true})
  onDetailsForm(newVal: any) {
    const rExp : RegExp = /(?:[^\w\/\\-]|_)/;
    if(rExp.test(newVal)) {
      this.special_char = true;
    } else {
      this.special_char = false;
    }
  }

  @Watch('isToggleOnForSeparatedMenu', { immediate: true })
  onChangeToggleSeparateMenu(newVal: any) {
    if (!newVal) {
      this.form['order-type-menus'] = []
      this.orderTypeMenuDelivery = null
      this.orderTypeMenuPickup = null
    }
  }

  @Watch('form', { immediate: true, deep: true }) 
  onChangeFormOrderTypeMenus(newVal: any) {
    console.log('onChangeFormOrderTypeMenus', newVal['order-type-menus']);
  }

  @Watch('orderTypeMenuDelivery', { deep: true })
  onChangeMenuDelivery(newVal: any) {
    if (newVal) {
      if (this.form['order-type-menus'].length > 0) {
        if (this.form['order-type-menus'].length === 1) {
          if (this.form['order-type-menus'][0].type === 'deliver') {
            this.form['order-type-menus'][0].id = Number(newVal)
          } else {
            this.form['order-type-menus'].push({ type: 'deliver', id: Number(newVal) })
          }
        } 
        if (this.form['order-type-menus'].length === 2) {
          this.form['order-type-menus'] = this.form['order-type-menus'].map((obj: any) => {
            if(obj.type === 'deliver' ) {
              return { type: 'deliver', id: Number(newVal) }
            }
            return obj
          })
        }
      } else {
        this.form['order-type-menus'].push({ type: 'deliver', id: Number(newVal) })
      }
    }
  }

  @Watch('orderTypeMenuPickup', { deep: true })
  onChangeMenuPickup(newVal: any) {
    if (newVal) {
      if (this.form['order-type-menus'].length > 0) {
        // check if only one element in the array
        if (this.form['order-type-menus'].length === 1) {
          // check for the existing pickup object and update the id
          if (this.form['order-type-menus'][0].type === 'pickup') {
            this.form['order-type-menus'][0].id = Number(newVal)
          } else {
          // or just push the pickup object since the first object is deliver object
            this.form['order-type-menus'].push({ type: 'pickup', id: Number(newVal) })
          }
        } 
      
        // check if array contains already the deliver and pickup object
        if (this.form['order-type-menus'].length === 2) {
          this.form['order-type-menus'] = this.form['order-type-menus'].map((obj: any) => {
            if(obj.type === 'pickup' ) {
              // just update the pickup object
              return { type: 'pickup', id: Number(newVal) }
            }
            // just return the deliver object
            return obj
          })
        }
      } else {
        this.form['order-type-menus'].push({ type: 'pickup', id: Number(newVal) })
      }
    }
  }

  @Watch('details', {deep: true})
  onDetailsChanged(newVal: any) {
    let data = newVal.attributes
    this.processApplicantDetails(data)
    this.getMenus()
    this.getSliders()
    this.getPopups()
    this.defaultData = data

    this.getPackageType = this.getConceptSettings
    this.isFreePackage = this.getPackageType.attributes['is-free-package']
    this.form['menu_only'] = newVal.attributes['menu-only']
  }

  @Watch('popups', {deep: true})
  onPopupsChanged(newVal: any) {
    this.selectedPopup = newVal[0].id
    this.form['popup-id'] = this.selectedPopup
  }

  public get getSoloAppUrl() {
    return this.getUserClient?.attributes?.app?.['logo-url'] || '';
  }

  public get getSoloSocialUrl() {
    return this.getUserClient?.attributes?.['social-ordering']?.['logo-url'] || '';
  }

  public get getSoloWebUrl() {
    return this.getUserClient?.attributes?.kiosk?.['logo-url'] || '';
  }

  public get getSoloMenu() {
    return this.getUserClient?.attributes?.web?.['logo-url'] || '';
  }

  async getMenus() {
    await Menu.all()
        .then((response: any) => {
          this.defaultMenus = response.data.data
          this.processMenus(this.defaultMenus)
        })
  }

  async getPopups() {
    await Popup.all()
      .then((response: any) => {
        let data = {id: null, attributes: {name: 'None'}}
        this.appPopup = response.data.data.concat([data])
      })
  }

  async getSliders() {
    await Slider.all()
        .then((response: any) => {
          this.sliders = response.data.data
          this.processSliders(this.sliders)
        })
  }

  get separateMenuText() : string {
    return this.getLocale === 'ar_SA' ? 'قائمة منفصلة لنوع الطلب' : 'Separate Menu Per Order Type'
  }

  processApplicantDetails(data: any) {
    this.form.application_type = data['application-type']
    this.form.key = data['key']
    this.form.label = data['label']
    this.form.hosts = data['hosts']
    this.form.sub_domain = data['sub_domain']
    this.form.default_menu_id = data['default-menu-id']
    this.form.slider_id = data['slider-id']
    this.form.marketing_slider_id = data['marketing-slider-id']
    this.form.homescreen_slider_id = data['homescreen-slider-id']
    this.form.order_status_banner_id = data['order-status-slider-id']
    this.form.fb_pixel_id = data['fb-pixel-id']
    this.form.gtm_id = data['gtm-id']
    this.form['menu_only'] = data['menu_only']
    this.form.settings.category_display = data.settings.category_display
    this.form.settings.nav_bar_logo = data.settings.nav_bar_logo
    this.form.selectedPhoneNumber = data['phone-number-entry']

    if (data['order-type-menus'].length > 0) {
      this.isToggleOnForSeparatedMenu = true
      this.form['order-type-menus'] = data['order-type-menus']
      this.orderTypeMenuDelivery = data['order-type-menus'].find((item: any) => item.type === 'deliver').id
      this.orderTypeMenuPickup = data['order-type-menus'].find((item: any) => item.type === 'pickup').id
    }
  }

  checkSize(text: any) {

    if(this.getLocale === 'en_US') {
        if(text === 'The image field size must be less than 2000KB') {
          return 'The image file size must be less than 2MB'
        }
    }
    if(text === 'The image field size must be less than 2000KB') {
          return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }

  }


  processMenus(data: any) {
    let payload = data
    this.appMenu = payload
    payload.filter((v: any) => {
      return v.attributes.code != null
    })
        .reduce((a: any, b: any) => {
          this.appDefaultMenu = b.attributes.code == this.form.default_menu_id
              ? b.attributes.label : null
        }, [])

    this.appDefaultMenu = this.appMenu.filter((active: any) => active.id == this.form.default_menu_id)
    // this.selectedMenu = this.appDefaultMenu[0].attributes.label
    this.selectedMenu = this.appDefaultMenu[0]?.id
  }

  processSliders(data: any) {

    let payload = data
    let payload1 = data
    let payload2 = data
    let payload3 = data
    this.appSlider = payload
    this.appSliderHome = payload1
    this.appSliderBanner = payload2
    this.appMarketingBanner = payload3

    payload.filter((v: any) => v.attributes.label)
        .reduce((a: any, b: any) => {
          if (b.id == this.form.slider_id) {
            this.appDefaultSlider = b.attributes.label
            return true
          }
        }, [])

        payload1.filter((v: any) => v.attributes.label)
        .reduce((a: any, b: any) => {
          if (b.id == this.form.homescreen_slider_id) {
            this.appDefaultSliderHome = b.attributes.label
            return true
          }
        }, [])

        payload2.filter((v: any) => v.attributes.label)
        .reduce((a: any, b: any) => {
          if (b.id == this.form.order_status_banner_id) {
            this.appDefaultSliderBanner = b.attributes.label
            return true
          }
        }, [])

        payload3.filter((v: any) => v.attributes.label)
          .reduce((a: any, b: any) => {
            if (b.id == this.form.marketing_slider_id) {
              this.appDefaultMarketingBanner = b.attributes.label
              return true
            }
          }, [])

    this.appDefaultSlider = this.appSlider.filter((active: any) => active.id == this.form.slider_id)
    this.appDefaultSliderHome = this.appSliderHome.filter((active: any) => active.id == this.form.homescreen_slider_id)
    this.appDefaultSliderBanner = this.appSliderBanner.filter((active: any) => active.id == this.form.order_status_banner_id)
    this.appDefaultMarketingBanner = this.appMarketingBanner.filter((active: any) => active.id == this.form.marketing_slider_id)
    this.selectedSlider = this.appDefaultSlider[0] && this.appDefaultSlider[0]?.id
    this.selectedHomescreen = this.appDefaultSliderHome[0] && this.appDefaultSliderHome[0]?.id
    this.selectedStatusBanner = this.appDefaultSliderBanner[0] && this.appDefaultSliderBanner[0]?.id
    this.selectedMarketingBanner = this.appDefaultMarketingBanner[0] && this.appDefaultMarketingBanner[0]?.id
  }

  get appForm(): FormData {
    let formData = new FormData()

      formData.append(
        `_method`,
        'PATCH'
      )
    formData.append('application_type', this.form.application_type ?? '')
    formData.append('key', this.form.key ?? '')
    formData.append('label', this.form.label ?? '')
    formData.append('default_menu_id', this.form.default_menu_id ?? '')
    formData.append('hosts', this.form.hosts ?? '')
    formData.append('sub_domain', this.form.sub_domain ?? '')
    formData.append('slider_id', this.form.slider_id ?? '')
    formData.append('marketing-slider-id', this.selectedMarketingBanner ?? '');
    formData.append('homescreen_slider_id', this.form.homescreen_slider_id ?? '')
    formData.append('order_status_banner_id', this.form.order_status_banner_id ?? '')
    formData.append('gtm_id', this.form.gtm_id ?? '')
    formData.append('fb_pixel_id', this.form.fb_pixel_id ?? '')
    formData.append('popup-id', this.form['popup-id'] ?? '')
    formData.append('menu_only', this.form.menu_only == false ? '0':'1')
    formData.append('payment-gateway', this.form['payment-gateway'] ?? '')
    formData.append('allowed-order-types', this.form['allowed-order-types'] ?? '')
    formData.append('allowed-payments-pickup', this.form['allowed-payments-pickup'] ?? '')
    formData.append('allowed-payments-delivery', this.form['allowed-payments-delivery'] ?? '')
    formData.append('phone_number_entry', this.form.selectedPhoneNumber)
    

    /** New Payload */
    if (this.form['order-type-menus'].length > 0) {
      formData.append('order-type-menus[0][type]', this.form['order-type-menus'][0].type);
      formData.append('order-type-menus[0][id]', this.form['order-type-menus'][0].id);
      formData.append('order-type-menus[1][type]', this.form['order-type-menus'][1].type);
      formData.append('order-type-menus[1][id]', this.form['order-type-menus'][1].id);
    } else {
      formData.append('order-type-menus', this.form['order-type-menus']);
    }
    /** End here */

    formData.append(`theme[primary_color]`, this.form.theme.primary_color ?? '')
    formData.append('theme[secondary_color]', this.form.theme.primary_color ?? '')
    formData.append('settings[category_display]', this.form.settings.category_display ?? '')
    if(this.imageType == 0) {
      formData.append(`settings[nav_bar_logo]`, this.form.settings.nav_bar_logo ?? '')
    } else {
      formData.append('settings[nav_bar_logo]', this.fileBarLogo ?? '')
    }

    if(this.form.application_type === 'social-ordering') {
        formData.append('hosts', this.form.sub_domain + (this.getConceptSettings.attributes['billing-type'] == 'foodics' ? '.foodics.online' : '.ordernosh.com'));
        formData.append('default_menu_id', this.selectedMenu ?? '');
        formData.append('slider_id', this.selectedSlider ?? '');
        formData.append('order-status-slider-id', this.selectedStatusBanner ?? '');
        formData.append('popup-id', this.selectedPopup ?? '');
    } else {
        formData.append('hosts', this.form.hosts ?? '');
        formData.append('default_menu_id', this.selectedMenu ?? '');
        formData.append('slider_id', this.selectedSlider ?? '');
        formData.append('order-status-slider-id', this.selectedStatusBanner ?? '');
        formData.append('homescreen_slider_id', this.selectedHomescreen ?? '');
        formData.append('popup-id', this.selectedPopup ?? '');
    }

    return formData
  }



  updateApp() {
    if (this.isToggleOnForSeparatedMenu) {
      if (!this.orderTypeMenuDelivery) {
        return this.$notify({
          title: this.translate("Empty Delivery Menu"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: 'Please select an option for Delivery Menu.',
          type: "danger",
          icon: "fas fa-bomb",
        });
      }

      if (!this.orderTypeMenuPickup) {
        return this.$notify({
          title: this.translate("Empty Pickup Menu"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: 'Please select an option for Pickup Menu.',
          type: "danger",
          icon: "fas fa-bomb",
        });
      }
    }

    let formData = new FormData()
    this.saving = true

    if(this.special_char) {
        this.$notify({
          title: "System Error",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: 'Special character is not allowed!',
          type: "danger",
          icon: "fas fa-bomb",
        })
        this.saving = false
        return
    }
    Applications.updateApplication(Number(this.$route.params.id), this.appForm)
      .then((response: any) => {
        this.$notify({
          title: this.translate("RECORD UPDATED!"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate("Application details has been updated successfully"),
          type: "success",
          icon: "fas fa-check"
        })
        this.saving = false

        /* @ts-ignore */
        analytics.track('app_details_update', {
          app_type: this.form.application_type,
        });

      })
      .catch((err: any) => {
        console.log(err.response);
        const errors: any = [];
        if (err.response.data
          && err.response.data.errors
        ) {
          Object.keys(err.response.data.errors).forEach((e: any) => {
            errors.push(err.response.data.errors[e][0]);
          })
        }
        this.$notify({
          title: this.translate("SYSTEM ERROR!"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: errors.length && errors.join('\n') || '',
          type: "danger",
          icon: "fas fa-bomb",
        });
        this.saving = false
    });
    
  }

  resetForm() {
    this.form.application_type = this.defaultData['application-type']
    this.form.key = this.defaultData['key']
    this.form.label = this.defaultData['label']
    this.form.hosts = this.defaultData['hosts']
    this.form['default_menu_id'] = this.defaultData.default_menu_id
    this.form.slider_id = this.defaultData.slider_id
    this.saving = false
    return
  }
}

