
import { Select, Option } from 'element-ui';
import { mapGetters } from 'vuex';
import { Integrations as IntegrationService, Order as OrderApi } from '@/services/SOLO';
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { eventHandler, translations } from '@/mixins';

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    })
  },
  mixins: [eventHandler, translations]
})
export default class OrderStatus extends Vue {
  public getLocale!: string;
  confirm!: Function;
  translate!: Function;
  description: string = ''
  payment_method: any = ''

  updatePaymentMethod: any = []

  convertStatuses: any = ''
  @Prop() orderId!: string;
  @Prop() orderPaymentMethod!: string;
  @Prop() orderType!: string;

  $notify!: any;

  mounted() {
    this.payment_method = this.orderPaymentMethod;
    this.filterPaymentMethod();
  }

  @Watch("orderPaymentMethod", { deep: true })
  onStatusesCodeChanged(newVal: string, oldVal: string) {
    this.payment_method = newVal
  }

  filterPaymentMethod() {
    let somePaymentMethod
    if (this.orderType === 'deliver') {
      somePaymentMethod = this.updatePaymentMethod = [
      {text: { 'en-us' : 'Card on Delivery', 'ar-sa' : 'البطاقة عند التسليم' }, value: 'card-on-delivery'},
      {text: { 'en-us' : 'Cash', 'ar-sa' : 'نقدي'}, value: 'cash'}
    ]
      this.updatePaymentMethod = somePaymentMethod
    } else {
      somePaymentMethod = this.updatePaymentMethod = [
        {text: { 'en-us' : 'Card on Pickup', 'ar-sa' : 'بطاقة على بيك اب'}, value: 'card-on-pickup'},
        {text: { 'en-us' : 'Cash', 'ar-sa' : 'نقدي'}, value: 'cash'},
        {text: { 'en-us' : 'Loyalty', 'ar-sa' : 'وفاء'}, value: 'loyalty'}
      ]
      this.updatePaymentMethod = somePaymentMethod
    }
  }


  updatePaymentMethodChange(payload: any) {
    OrderApi.updatePaymentMethod(payload, this.orderId)
      .then((response: any) => {
        this.$emit('refresh-order')
        this.$notify({
          title: "STATUS CHANGED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Payment method have been successfully changed",
          type: "success",
          icon: "fas fa-check",
        });
      })
      .catch((err: any) => {
        const message = JSON.parse(err?.response?.data?.messages)?.errors?.status[0]
          || JSON.parse(err?.response?.data?.messages)?.message
          || 'Something went wrong, please try again!';
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message,
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }

  updateOrderPaymentMethod(data: any) {

    this.payment_method = data

    let payload = {
        'method': this.payment_method,
    };

    if (payload) {
      this.confirm(
        this.$bvModal,
        this.translate('Are you sure you want to do this?'),
        {
          title: 'Attention!',
          okTitle: 'YES',
          cancelTitle: 'NO',
          okVariant: 'danger',
        },
      ).then(async (value: boolean) => {
        if (value) {
          this.updatePaymentMethodChange(payload);
        } else {
          this.payment_method = this.orderPaymentMethod;
        }
      });
    }
  }
}
