
import ModgroupModal from './ModgroupModal.vue'
import ModgroupItems from './ModgroupItems.vue'
import ModgroupItemsList from './ModgroupItemsList.vue'
import { Settings } from '@/services/SOLO';
import { translations } from '@/mixins'
import menuRoles from '@/directives/menuRoles';
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { mapGetters } from 'vuex';
import LoadingPanel from "@/components/LoadingPanel"
@Component({
  components: {
    ModgroupModal,
    ModgroupItems,
    ModgroupItemsList,
    LoadingPanel,
  },
  directives: {
    menuRoles
  },
  computed: {
    ...mapGetters({
      getUser: 'account/getUser',
      getUserClient: 'account/getUserClient',
    })
  },
  mixins: [translations]
})
export default class ModGroup extends Vue {
  modifierGroups: Array<Object> = []
  modifierGroupItems: Array<Object> = [];
  modifierGroupItemsAR: Array<Object> = [];
  public getUserClient!: any;
  meta: any;
  $router: any
  $refs!: {
    modgroupModal: ModgroupModal
  }
  translate!: Function
  view: string = 'grid';
  private arrayLang: Array<string> = [];
  getLanguages!: Function;

  page: number = 1
  total: number = 0;
  currentPage: number = 1;
  perPage: number = 0;
  loadingConcept: Boolean = true;
  currentPageModifierGroups: number = 1;
  totalPageModifierGroups: number = 0;
  created() {
    this.arrayLang = this.getLanguages();
  }

  pageChange(data: any) {
    for (let i in this.arrayLang) this.getItemModifiers(this.arrayLang[i], data);
    this.page = data;
  }

  public get isFoodics() {
    return this.getUserClient?.attributes?.label === 'Foodics Online';
  }

  mounted() {
    this.getModifierGroups(1);
    for (let i in this.arrayLang) this.getItemModifiers(this.arrayLang[i], this.page);
  }

  openModal() {
    this.$refs.modgroupModal.open = true
  }

  async getModifierGroups(page: 1) {
    await Settings.modifierGroups(page).then((response: any) => {
      this.modifierGroups = [...this.modifierGroups, ...response?.data?.data];
      this.currentPageModifierGroups += 1;
      this.totalPageModifierGroups = response.data.meta.pagination.total_pages;
    })
  }

  getItemModifiers(lang: string, page: any = 1) {
    console.log('getItemModifiers - lang: ', lang);
    Settings.itemModifiers(this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, lang, page).then((response: any) => {
      if (lang === 'en-us') {
        this.modifierGroupItems = response.data.data;
        this.total = response.data.meta.pagination.total;
        this.currentPage = response.data.meta.pagination.current_page;
        this.perPage = response.data.meta.pagination.per_page;
      } else if (lang === 'ar-sa') {
        this.modifierGroupItemsAR = response.data.data;
      } else {
        this.modifierGroupItems = response.data.data;

      }
      this.loadingConcept = false;
    });
  }

  getItemList(page: any) {
    this.$refs.modgroupModal.open = false;
    for (let i in this.arrayLang) this.getItemModifiers(this.arrayLang[i], page);
  }

  onChangeView(view: string) {
    this.view = view;
  }

  findAndUpdateItem(modifierGroupItemId: string, action: string, value: Boolean = true) {
    switch (action) {
      case 'enableDisable':
        this.modifierGroupItems.forEach(element => {
          /* @ts-ignore */
          if (element.id == modifierGroupItemId) {
            /* @ts-ignore */
            element.attributes.enabled = (value == true ? 1 : 0);
          }
        });
        break;
      case 'remove':
        this.modifierGroupItems.forEach((element: any, index: any) => {
          if (element.id == modifierGroupItemId) {
            this.modifierGroupItems.splice(index, 1);
          }
        });
        break;
      case 'enableNonAdminModGroup':
        this.modifierGroupItems.forEach(element => {
          /* @ts-ignore */
          if (element.id == modifierGroupItemId) {
            /* @ts-ignore */
            element.attributes['is-enabled-for-employee'] = true;
          }
        });
        break;
    }
  }
}
