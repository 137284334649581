
import { Button } from "element-ui"
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { Component, Vue } from "vue-property-decorator"
import { translations } from '@/mixins'
import BlacklistSingleModal from '@/views/Admin/pages/Customers/components/BlacklistSingleModal.vue';
@Component({
  components: {
    BlacklistSingleModal,
    RouteBreadCrumb,
    [Button.name]: Button,
  },
  mixins: [translations]
})
export default class Blacklists extends Vue {
  public translate!: Function
  public singleBlacklist: any = {}

  public onCreate(): void {
    if (this.singleBlacklist === null) {
      this.singleBlacklist = false;
    } else {
      this.singleBlacklist = null;
    }
  }

  public onSaveClose(): void {
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }
}
