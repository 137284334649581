import { render, staticRenderFns } from "./CodeVerifier.vue?vue&type=template&id=22d15204&scoped=true"
import script from "./CodeVerifier.vue?vue&type=script&lang=ts"
export * from "./CodeVerifier.vue?vue&type=script&lang=ts"
import style0 from "./CodeVerifier.vue?vue&type=style&index=0&id=22d15204&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22d15204",
  null
  
)

export default component.exports