
import {translations} from '@/mixins'
import {Popup as PopupApi, Banner as BannerApi, Category as CategoryApi, Item as ItemApi} from "@/services/SOLO"
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import {mapGetters} from "vuex"
import {Select, Option} from "element-ui"
import {ValidationProvider} from "vee-validate"

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getConceptSettings: 'account/getConceptSettings',
    }),
  },
  mixins: [translations]
})
export default class EditPopup extends Vue {
  getLocale!: any
  // langChecked: Array<string> = []
  arrayLang: Array<string> = []
  imageType: any = []
  idle_seconds: any = ''
  imageValuePrimary: string = ''
  imageValueSecondary: string = ''
  imageTypes: Array<Object> = [
    {value: 0, text: "Upload IMG",},
    {value: 1, text: "Image URL",},
  ]
  buttonType: string = ''
  buttonTypes: Array<Object> = [
    {value: "product", text: "Product"},
    {value: "category", text: "Category"},
    {value: "url", text: "URL"},
  ]
  buttonMenu: string = ''
  buttonMenus: any = []
  buttonItem: string = ''
  buttonItems: any = []
  label: string = ''
  imageUri: any = {}
  tmpImageUri: string = ''
  // button: any = {}
  buttonValuePrimary: string = ''
  buttonValueSecondary: string = ''
  buttonUri: string = ''
  $notify: any
  fileValue: any = {}
  saving: Boolean = false
  numOfLoadedData: number = 0

  @Watch('buttonType', {immediate: true, deep: true})
  onButtonTypeChange(newVal: any) {
    console.log('buttonType: ', newVal)

    if (newVal == 'product') {
      if (this.buttonMenu) {
        BannerApi.linkValueSearch(this.buttonMenu, 'items', 1)
        .then((response) => (this.buttonItems = response.data.data))
      }
    }

    if (newVal == 'category') {
        BannerApi.linkValueSearch(this.buttonMenu, 'categories', 1)
        .then((response) => (this.buttonItems = response.data.data))
    }

    if (newVal) {
      if (newVal == 'product') {
        let catId = '';
        ItemApi.getItem(this.buttonItem).then(async (response: any) => {
          catId = response.data.data.attributes['category-id'].toString()
          CategoryApi.allCategory().then(async (response: any) => {
            this.buttonMenu = response.data.data.find((item: any) => item.id == catId).menu_id.toString()
            this.changeButtonMenu(newVal)
          })
        })
      }
      if (newVal == 'category') {
        CategoryApi.allCategory().then(async (response: any) => {
          this.buttonMenu = response.data.data.find((item: any) => item.id == this.buttonItem).menu_id.toString()
          this.changeButtonMenu(newVal)
        })
      }
    }
  }

  mounted() {
    /* @ts-ignore */
    this.arrayLang.push(this.getConceptSettings.attributes['primary-language'])
    /* @ts-ignore */
    this.arrayLang.push(this.getConceptSettings.attributes['secondary-language'])

    for (let i in this.arrayLang) {
      this.imageUri[this.arrayLang[i]] = ""
    }

    this.initializeForms()
    // this.getApplications()
    this.getMenus()
  }
  
  initializeForms() {
    for (let i in this.arrayLang) this.findPopup(this.arrayLang[i])
  }

  findPopup(lang: string) {
    PopupApi.find(
      this.$route.params.popupId,
      lang
    ).then((response: any) => {
      let data = response.data.data

      this.idle_seconds = data.attributes['idle-seconds'];
      this.label = data.attributes.label
      this.buttonType = data.attributes['action-type']
      this.buttonMenu = data.attributes['action-menu-id']
      if (data.attributes['action-type'] != 'url') {
        this.buttonItem = data.attributes['action-value']
      } else {
        this.buttonUri = data.attributes['action-value']
      }

      this.imageType[lang] = 1;
      if (this.numOfLoadedData == 0) {
        this.imageValuePrimary = data.attributes['image-uri']
        this.buttonValuePrimary = data.attributes['button-label']
      } else if (this.numOfLoadedData == 1){
        this.imageValueSecondary = data.attributes['image-uri']
        this.buttonValueSecondary = data.attributes['button-label']
      }

      this.numOfLoadedData++
    });
  }

  // getApplications() {
  //   PopupApi.getApplications().then((response: any) => {
  //     this.applications = response.data.data;
  //   });
  // }
  getMenus() {
    
    BannerApi.menus().then((response: any) => {
      this.buttonMenus = response.data.data;
      console.log('check menus in update', this.buttonMenus);
    });
  }

  updateButtonType(e: any) {
    if (e == 'product') {
      if (this.buttonMenu) {
        BannerApi.linkValueSearch(this.buttonMenu, 'items', 1)
        .then((response) => (this.buttonItems = response.data.data))
      }
    }
    if (e == 'category') {
      BannerApi.linkValueSearch(this.buttonMenu, 'categories', 1)
        .then((response) => (this.buttonItems = response.data.data))
    }
  }

  changeButtonMenu(type: any) {
    this.updateButtonType(type)
  }

  resetImage(type: number, lang: string) {
    // if (type == 0) {
    //   /* @ts-ignore */
    //   if (lang == this.getConceptSettings.attributes['primary-language']) {
    //     this.tmpImageUri = this.imageValuePrimary;
    //     this.imageValuePrimary = '';
    //   }
    //   /* @ts-ignore */
    //   if (lang == this.getConceptSettings.attributes['secondary-language']) {
    //     this.tmpImageUri = this.imageValueSecondary;
    //     this.imageValueSecondary = '';
    //   }
    // } 
    // if (type == 1) {
    //   /* @ts-ignore */
    //   if (lang == this.getConceptSettings.attributes['primary-language']) {
    //     this.imageValuePrimary = this.tmpImageUri;
    //     this.fileValue[lang] = null;
    //   }
    //   /* @ts-ignore */
    //   if (lang == this.getConceptSettings.attributes['secondary-language']) {
    //     this.imageValueSecondary = this.tmpImageUri;
    //     this.fileValue[lang] = null;
    //   }
    // }
  }

  async editPopup() {
    this.saving = true
    let formData = new FormData()

    formData.append(
      `popup[label]`,
      this.label
    )
    formData.append(`popup[idle-seconds]`, this.idle_seconds || '')

    
    for (let i in this.arrayLang) {
      formData.append(
        `_method`,
        'PATCH'
      )

      if (this.fileValue && this.fileValue[this.arrayLang[i]]) {
        formData.append(`popup[image-uri][${this.arrayLang[i]}]`, this.fileValue[this.arrayLang[i]]);
      } else {
        /* @ts-ignore */
        if (this.arrayLang[i] == this.getConceptSettings.attributes['primary-language']) {
          /* @ts-ignore */
          formData.append(`popup[image-uri][${this.arrayLang[i]}]`, this.imageValuePrimary);
        }
        /* @ts-ignore */
        if (this.arrayLang[i] == this.getConceptSettings.attributes['secondary-language']) {
          /* @ts-ignore */
          formData.append(`popup[image-uri][${this.arrayLang[i]}]`, this.imageValueSecondary);
        }
      }

      /* @ts-ignore */
      if (this.arrayLang[i] == this.getConceptSettings.attributes['primary-language']) {
        /* @ts-ignore */
        formData.append(`popup[button-label][${this.getConceptSettings.attributes['primary-language']}]`, this.buttonValuePrimary);
      }
      /* @ts-ignore */
      if (this.arrayLang[i] == this.getConceptSettings.attributes['secondary-language']) {
        /* @ts-ignore */
        formData.append(`popup[button-label][${this.getConceptSettings.attributes['secondary-language']}]`, this.buttonValueSecondary);
      }
    }

    // if (this.buttonType) {
    //   formData.append(
    //     `popup[action-type]`,
    //     this.buttonType
    //   )
    // }
    formData.append(
  `popup[action-type]`,
    this.buttonType
    );
    if (this.buttonType != 'url') {
      if (this.buttonItem) {
        formData.append(
          `popup[action-value]`,
          this.buttonItem
        )
      }
    } else {
      if (this.buttonUri) {
        formData.append(
          `popup[action-value]`,
          this.buttonUri
        )
      }
    }

    await PopupApi.update(formData, this.$route.params.popupId)
      .then((response) => {
        this.saving = false
        this.showNotifyStatus(true)
        this.$router.back()
      })
      .catch((err) => {
        this.saving = false
        this.showNotifyStatus(false, err)
      })
  }

  showNotifyStatus(status: Boolean, err: any = null) {
    if (status) {
      this.$notify({
        title: "POPUP SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Popup updated successfully.",
        type: "success",
        icon: "fas fa-check",
      })
    } else {
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: err.response.data.error[0].detail,
        type: "danger",
        icon: "fas fa-bomb",
      })
    }
  }

  checkSize(text: any) {
    if(this.getLocale === 'en_US') {
        if(text === 'The image field size must be less than 2000KB') {
          return 'The image file size must be less than 2MB'
        }
    }
    if(text === 'The image field size must be less than 2000KB') {
          return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }
  }

  cancelUpdate() {
    this.$router.go(-1)
  }
}
