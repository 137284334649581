
import moment from "moment";
import { mapGetters } from "vuex";
import { Select, Option } from "element-ui";
import { notificationAlerts, translations } from "@/mixins";
import StoreTimes from "../components/StoreTimes.vue";
import Translations from "../components/Translations.vue";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import CriteriaFields from "./CriteriaFields";
import Promotions from "../Promotions.vue";
import { Promotion } from "@/services/SOLO";

@Component({
  components: {
    CriteriaFields,
  },
  mixins: [notificationAlerts, translations]
})
export default class SegmentsCreate extends Vue {
  isLoading: boolean = false;
  successNotification!: Function;
  segment: any = {
    name: null,
    criteria: [],
    condition: 'all',
  };

  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };

  dataForm: any = {};
  systemErrorNotification!: Function;

  submitForm(e: any) {
      e.preventDefault();
      const criteria = this.segment.criteria.map((criterion: any) => {
        return {
          provider: criterion.provider,
          condition: criterion.condition,
          value: criterion.value,
        };
      });
      const body = { ...this.segment };
      body.criteria = criteria;
      this.isLoading = true;
      console.log('body: ', body);

      Promotion.saveSegment(body)
        .then(() => {
          /* @ts-ignore */
          analytics.track('create_segment', {
            
          });
          this.successNotification('SEGMENT CREATED!', 'segment successfully created!');
          this.$router.push({ name: 'SegmentsList' });
        })
        .catch((err: any) => {
          let error = '';
          for (const prop in err.response.data.error) {
            if (err.response.data.error[prop] && Array.isArray(err.response.data.error[prop])) {
              err.response.data.error[prop].forEach((e: any) => {
                error += e + '\n';
              });
            }
          }
          if (err.response.data.error.detail) {
            error += err.response.data.error.detail;
          }
          this.systemErrorNotification('CREATE ERROR!', error);
          this.isLoading = false;
        });
    }
}
