
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { translations } from '@/mixins'
import Translations from './Translations.vue'
import { Order } from "@/services/SOLO"
import PaymentHistoryModal from './PaymentHistoryModal.vue'
@Component({
  components: {
    PaymentHistoryModal,
  },
  mixins: [translations],
})
export default class PaymentDetails extends Vue {
  @Prop() isLoaded!: Boolean
  @Prop() payment!: Object  
  @Prop() order!: Object  
  public translate!: Function
  openHistory: boolean = false
  paymentHistories: Array<Object> = []
  mounted() {
    this.getPaymentHistories();
  }

  getPaymentHistories() {
    Order.orderPayment(this.$route.params.id).then((response: any) => {
      this.paymentHistories = response.data.data.reverse();
    });
  }

  returnDeliveryCharge(e: any) {
    return e.find((ch: any) => ch.name == 'Delivery');
  }

  handleOpen(open: any) {
    this.openHistory = open
  }

  handleClose(value: any) {
    this.openHistory = value
  }
}
