export default {
  getQRUri(state: { qr_uri: string }) {
    return state.qr_uri;
  },
  getSecretKey(state: { secret_key: string }) {
    return state.secret_key;
  },
  getTfaToken(state: { tfa_token: string }) {
    return state.tfa_token;
  },
}
