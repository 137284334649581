
import moment from 'moment'
import EmployeeCollapse from "./components/EmployeeCollapse.vue"
import EmployeeList from "./components/EmployeeList.vue"
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { Customers as CustomerService } from "@/models"
import { Component, Vue } from "vue-property-decorator"
import { Employee } from '@/services/SOLO'
import { Button } from 'element-ui'
import EmployeeCreateModal from './components/EmployeeCreateModal';
import { translations } from '@/mixins'
import { debounce } from 'vue-debounce';

@Component({
  components: {
    [Button.name]: Button,
    RouteBreadCrumb,
    EmployeeList,
    EmployeeCollapse,
    EmployeeCreateModal,
  },
  mixins: [translations],
})

export default class Employees extends Vue {
  customers: Array<CustomerService> = [];
  employeeMeta: any = {};
  targetKeyword: string = '';
  isLoaded: boolean = false;
  page: number = 1;
  loading: boolean = false;
  employees: Array<any> = [];
  openCreate: boolean = false

  mounted() {
    this.getEmployees(this.page);
  }

  onNew() {
    this.openCreate = true;
  }

  handleClose(value: any) {
    this.openCreate = value;
  }

  standardDateFormat(value: any)  {
    if (! value) return '---';
    return moment(value).format('DD/MM/YYYY');
  }

  async getEmployees(page: number) {
    this.loading = true;
    await Employee.all(page)
      .then((response: any) => {
        this.loading = false;
        this.setupEmployees(response.data);
      })
  }

  setupEmployees(employees: any) {
    let filter = employees.data;
    this.employeeMeta = employees.meta.pagination;
    filter.map((c: any) => {
      let formatted = c.attributes;
      formatted['created-at'] = this.standardDateFormat(c.attributes['created-at']);
      return formatted;
    });

    let em = filter;

    let filter_emp = em;
    filter_emp.map((e: any) => {
      let emp = e.attributes;
      emp['email'] = this.checkUsername(e.attributes.email);
      return emp;
    })

    this.employees = filter_emp;

  }

  checkUsername(e: any) {
    if (! e)  {
      return '';
    } else if(!e.match("@getsolo") && !e.match("@skylinedynamics")) {
      return e;
    } 
  }

  findEmployees(keyword: any) {
    if (keyword && keyword.length > 2) {
      debounce(() => {
        this.loading = true;
        Employee.search(keyword)
          .then((response) => {
            this.loading = false;
            this.setupEmployees(response.data);
          })
          .catch(() => (this.loading = false));
      }, 700)();
    } else {
      this.getEmployees(1);
    }
  }
}
