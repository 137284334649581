
import moment from "moment";
import flatPicker from "vue-flatpickr-component";
import PromisedTimeModal from './PromisedTimeModal';
import "flatpickr/dist/flatpickr.css";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Order } from "@/services/SOLO";
import {
  OrderDetailAttributes,
  OrderDetail as OrderDetailModel,
} from "@/models";
import { translations } from '@/mixins'
import Translations from './Translations.vue'
import { mapGetters, mapMutations } from 'vuex'

@Component({
  components: {
    flatPicker,
    PromisedTimeModal,
  },
  computed: {
    ...mapGetters({
      activeConcept: 'account/activeConcept',
      getLocale: 'app/getLocale',
    })
  },
mixins: [translations],
})
export default class OrderDetails extends Vue {
  @Prop() isLoaded!: Boolean;
  @Prop() order!: Object;
  @Prop() customerAddress!: any;
  public translate!: Function
  public getLocale!: string;
  public openCreate: boolean = false;

  created() {
    console.log('getLocale: ', this.getLocale);
  }

  public handleClose(value: any): void {
    console.log('handleClose: ', value);
    this.openCreate = value;
  }

  formatDate(date: string, format: string = 'MMM D YYYY hh:mm A') {
    return date && moment
      .utc(date, "YYYY-MM-D hh:mm:ss")
      .locale(this.getLocale || 'en_US')
      .local()
      .format(format) || '';
  }

  appVersion(app: any, concept: any, source: any) {

    let s = JSON.stringify(app);
    let p = JSON.parse(s);
    let l = p.length;

    if(concept == 'Kudu' && source == 'iOS') {

        return p[5] + p[6] + p[7] + p[8] + p[9];

    } else if(concept =='Kudu' && source == 'Android') {

      return p[l-12] + p[l-11] + p[l-10] + p[l-9] + p[l-8] + p[l-7] + p[l-6] + p[l-5] + p[l-4] + p[l-3];
    }


      return p[l-7] + p[l-6] + p[l-5] + p[l-4] + p[l-3];


  }

  tooltip(code: any, posResponse: any){
    if(code && posResponse) {
      return 'Order successfully sent to POS'
    } else if(!code && posResponse) {
      return 'Order failed in POS'
    } else {
      return ''
    }
  }
}
