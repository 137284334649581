import { render, staticRenderFns } from "./Events.vue?vue&type=template&id=fd6d9982&scoped=true"
import script from "./Events.vue?vue&type=script&lang=ts"
export * from "./Events.vue?vue&type=script&lang=ts"
import style0 from "./Events.vue?vue&type=style&index=0&id=fd6d9982&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd6d9982",
  null
  
)

export default component.exports