
import { FadeTransition, ScaleTransition } from "vue2-transitions";
import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
@Component({
  components: {
    FadeTransition,
    ScaleTransition
  }
})
export default class Locations extends Vue {
  $scrollTop: any;
  
  mounted() {
    this.$scrollTop()
  }
}
