
import {
  Table,
  TableColumn  
} from "element-ui"    
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator"
@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  }
})
export default class Modifiers extends Vue {
  @Prop() modifiers!: Array<Object>

  initUpdate(row: any) {
    // this.$emit('update:modifier', row)
    console.log(row)
  }
}
