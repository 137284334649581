
import { Select, Option } from "element-ui";
import {Menu as MenuApi, Location, Concept, Employee, Category as CategoryApi} from "@/services/SOLO";
import {
  Category as CategoryModel,
  Location as LocationModel,
  Application as ApplicationModel,
} from "@/models";
import { Imagetype } from "@/interfaces/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { eventHandler, translations } from "@/mixins";
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
import MenuRoles from '@/directives/menuRoles'
import { ElNotification } from "element-ui/types/notification";
import { required, email } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";


interface objKey {
  [x: string]: string | number;
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  computed: {
    ...mapGetters({
      getUser: 'account/getUser',
      getLocale: 'app/getLocale',
      userRole: 'account/getUserRole',
      getConceptSettings: 'account/getConceptSettings',

    })
  },
  directives: {
    MenuRoles
  },
  mixins: [eventHandler, translations],
})
export default class Menu extends Vue {
  @Prop() menuId!: string;
  @Prop() categoryId!: string;
  @Prop() categories!: Array<CategoryModel>;
  @Prop() numOfLoadedData!: number;
  @Prop() arrayLang!: Array<string>;
  @Prop() active!: Boolean
  getConceptSettings!: any
  stockImages: any = [];
  searchStock: any = '';
  isLoading: Boolean = false;
  selectedLocations: any = [];
  notBelongLocations: any = [];
  selectedApplications: any = [];
  defaultLocations: Array<LocationModel> = [];
  defaultApplications: Array<ApplicationModel> = [];
  locations: Array<LocationModel> = [];
  applications: Array<any> = [];
  catNames: objKey = {};
  catDesc: objKey = {};
  imageType: number = 0;
  $notify: any;
  file: any;
  waiting!: Function;
  restore!: Function;
  getLang!: Function;
  getLanguages!: Function
  getLocale!: any;
  getUser!: any;
  userRole!: any;
  employeeLocations: any = [];

  imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Image",
    },
    {
      value: 2,
      text: "Stock Images",
    },
  ];
  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };

  @Watch("imageType", {deep: true, immediate: true})
    onChangeGetSotckImages(data: any) {
      if(data === 2) {
        // this.getStockImages();
        console.log("check stcck images", this.categories[0].attributes.name);
        this.searchStock = this.categories[0].attributes.name;
        this.searchStockImages(this.searchStock);
    }
  }

  @Watch("file", { immediate: true, deep: true })
  onFileChange(val: any) {
    console.log(val);
  }
  @Watch("numOfLoadedData", { immediate: true, deep: true })
  onLoad(data: number) {
    if (data === 2) {
      this.categories[0].relationships.apps.data.map((data: any) => {
        this.selectedApplications.push(data.id);
      });

      if (this.categories[0]?.relationships?.locations?.data?.length) {
        const locationsData = this.categories[0]?.relationships?.locations?.data;
        const mapData = (data: any) => data?.id;
        if (this.isMenuRestaurant) {
          this.getEmployeeLocations()
            .then(() => {
              this.selectedLocations = locationsData
                .filter((l: any) => this.employeeLocations.find((lo: any) => l?.id === lo?.id))
                .map(mapData);
              this.notBelongLocations = locationsData
                .map(mapData)
                .filter((lo: any) => !this.selectedLocations.includes(lo));
            });
        } else {
          this.selectedLocations = locationsData.map(mapData);
        }
      }
    }
  }
  mounted() {
    this.getApps();
    this.getLocations();
  }
  get isEnabled(): boolean {
    if (this.numOfLoadedData === this.arrayLang.length)
      return !!this.categories[0].attributes.enabled;
    else return false;
  }
  set isEnabled(newVal: boolean) {
    this.categories[0].attributes.enabled = newVal ? 1 : 0;
  }
  searchStockImages(e: any) {
    this.isLoading = true;
      CategoryApi.searchStockImgs(e)
      .then((response) => {
        this.stockImages = response.data.data;
        this.isLoading = false;
        console.log("search stock images", this.stockImages);
      })
  }

  selectStockImage(stockImage: any) {
    this.categories[0].attributes['image-uri'] = stockImage;
  }

  // getStockImages() {
  //   CategoryApi.getStockImgs()
  //     .then((response) => {
  //       this.stockImages = response.data.data;
  //       console.log("stock images", this.stockImages);
  //     })
  // }
  getApps() {
    Concept.find().then((response: any) => {
      this.applications = response.data.data.map((data: any) => {
        return {
          id: data.id.toString(),
          label: data.label,
        };
      });
    });
  }

  checkSize(text: any) {

    if(this.getLocale === 'en_US') {
        if(text === 'The image field size must be less than 2000KB') {
          return 'The image file size must be less than 2MB'
        }
    }
    if(text === 'The image field size must be less than 2000KB') {
          return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }

  }

  getEmployeeLocations() {
    if (!this.employeeLocations.length) {
      return Employee.find(this.getUser.id)
        .then((response) => {
          if (response.data.included) {
            this.employeeLocations = response.data.included;
          }
        });
    }
    return Promise.resolve();
  }

  private get isMenuRestaurant(): boolean {
    const filterRole = ['menu', 'restaurant'];
    const roles = this.userRole.filter((role: string) => filterRole.find((frole: string) => role === frole));
    return !!roles?.length;
  }

  getLocations(page: number = 1) {
    let lang = this.getLocale
    Location.fetchLocations(lang,page).then((response: any) => {
        // filter locations for menu and restaurant
        // SPO-94
        // [Web] Users :: Restaurant and Menu - Disable Locations
        this.locations = this.locations.concat(response.data.data);
        console.log('These are all the locations:', this.locations);
        
        if (response.data.meta.pagination.current_page < response.data.meta.pagination.total_pages) {
            this.getLocations(response.data.meta.pagination.current_page + 1);
        } 
        if (this.isMenuRestaurant) {
            this.getEmployeeLocations()
                .then(() => {
                    this.locations = this.locations.filter((l: any) => this.employeeLocations.find((lo: any) => l?.id === lo?.id));
                });
        }
    });
}

  // updateCategory(e: HTMLFormElement, isvalid: boolean) {
  updateCategory() {
    // if (!isvalid) {
      if (!this.imageType || this.imageType === 2) {
        // const defaultText = e.submitter.innerHTML;
        // this.waiting(
        //   e,
        //   '<i class="fas fa-spinner fa-spin"></i> Saving changes'
        // );
        MenuApi.update(
          this.setData(),
          this.menuId,
          this.categoryId
        )
          .then((response: any) => {
            // this.restore(e, defaultText);
            this.$notify({
              title: "UPDATES SAVED!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Changes have been saved successfully",
              type: "success",
              icon: "fas fa-check",
            });
            /* @ts-ignore */
            analytics.track('update_menu', {
              menud_id: this.menuId,
            });

            // this.$router.go(-1);
            this.$router.push({
                  path: `/menus/${this.$route.params.id}/categories/${this.$route.params.catId}/1`,
            })
            // location.reload()
          })
          .catch((err: any) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: err.response.data.error.detail,
              type: "danger",
              icon: "fas fa-bomb",
            });
          });
      }else {
        // const defaultText = e.submitter.innerHTML;
        // this.waiting(
        //   e,
        //   '<i class="fas fa-spinner fa-spin"></i> Saving changes'
        // );
        MenuApi.updateFormdata(
          this.formData(),
          this.menuId,
          this.categoryId
        )
          .then((response: any) => {
            this.imageType = 0;
            this.categories[0].attributes["image-uri"] =
              response.data.data.attributes["image-uri"];
            // this.restore(e, defaultText);
            this.$notify({
              title: "UPDATES SAVED!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Changes have been saved successfully",
              type: "success",
              icon: "fas fa-check",
            });
            /* @ts-ignore */
            analytics.track('update_menu', {
              menud_id: this.menuId,
            });
            // this.$router.go(-1);
            this.$router.push({
                  path: `/menus/${this.$route.params.id}/categories/${this.$route.params.catId}/1`,
            })
          })
          .catch((err: any) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Something went wrong, please try again!",
              type: "danger",
              icon: "fas fa-bomb",
            });
          });
      }
    // }
  }

  formData() {
    let formData = new FormData();
    formData.append("code", this.categories[0].attributes.code);
    formData.append(
      "display-order",
      this.categories[0].attributes["display-order"].toString()
    );
    formData.append(
      "enabled",
      this.categories[0].attributes.enabled.toString()
    );
    for (let i in this.categories) {
      formData.append(`name[${this.categories[i].lang}]`, this.categories[i].attributes.name ? this.categories[i].attributes.name : '' )
      formData.append(`description[${this.categories[i].lang}]`, this.categories[i].attributes.description ? this.categories[i].attributes.description : '')
    }
    for(let i in this.selectedApplications) {
      formData.append(`apps[${i}]`, this.selectedApplications[i])
    }

    let locations = this.selectedLocations
    if (this.isMenuRestaurant) {
      locations = [...this.selectedLocations, ...this.notBelongLocations];
    }

    for(let i in locations) {
      formData.append(`locations[${i}]`, locations[i])
    }
    formData.append('image', this.$refs.file.files[0])
    return formData
  }

  setData() {
    for (let i in this.categories) {
      this.catNames[this.categories[i].lang] = this.categories[
        i
      ].attributes.name;
      this.catDesc[this.categories[i].lang] = this.categories[
        i
      ].attributes.description;
    }

    let locations = this.selectedLocations;
    if (this.isMenuRestaurant) {
      locations = [...this.selectedLocations, ...this.notBelongLocations];
    }

    return {
      code: this.categories[0].attributes.code,
      "display-order": this.categories[0].attributes["display-order"],
      enabled: this.categories[0].attributes.enabled,
      name: this.catNames,
      description: this.catDesc,
      apps: this.selectedApplications,
      locations,
      "image-uri": this.categories[0].attributes["image-uri"],
    };
  }

  filesChange(e: any, errors: any) {
    console.log(errors);
    if (typeof e.target.files[0] != undefined) {
      this.file = e.target.files[0];
      console.log(this.file);
    }
  }
}
