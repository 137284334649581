
import flatPicker from "vue-flatpickr-component";
import { Component, PropSync, Mixins, Watch } from "vue-property-decorator";
import { Table, TableColumn, Button, Select, Option, Tag } from "element-ui";
import { Connect as ConnectService } from "@/services/SOLO";
import Translations from "@/mixins/translations";
import LogDetailsModal from "./LogDetailsModal.vue";

@Component<ConnectSyncAuditLogs>({
  components: {
    LogDetailsModal,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Tag.name]: Tag,
    flatPicker
  }
})
export default class ConnectSyncAuditLogs extends Mixins(Translations) {
  color: string = 'white'
  loading: boolean = false;
  logs: any[] = [];
  connections: any[] = [];
  selectedLog: any = null;
  selectedConnection: any = null;

  visible: boolean = false;
  flag: boolean = true;

  filter: any = {
    today: null,
    connectionId: null
  };

  page: number = 1;
  rows: number = 0;
  perPage: number = 50;
  currentPage: number = 1;

  logDetails: any = null;
  syncedShowCreateModal: boolean = false;

  mounted() {
    this.getConnections();
    this.getLogs();
  }

  async getConnections() {
    try {
      let { data } = await ConnectService.getConnections();
      this.connections = data.data;
    } catch (e) {
      console.log(e);
    }
  }

  connectionLabel(connection: any): string {
    return `${connection.attributes.aggregator.name} - ${connection.attributes.menu.label}`;
  }

  async getLogs() {
    this.loading = true;

    try {
      // if (this.filter.connectionId) {
        let { data } = await ConnectService.getAuditLogs(
          this.filter.connectionId,
          {
            page: this.page,
          }
        );
        this.logs = data.data;

        this.rows = data.meta.pagination.total
        this.perPage = data.meta.pagination.per_page
        this.currentPage = data.meta.pagination.current_page
      // }
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  handleRowClick(row: any) {
    this.selectedLog = row;
    // this.syncedShowCreateModal = true;
  }

  showFilters() {
    this.flag = false;
    this.visible = true;
  }

  hideFilters() {
    this.flag = true;
    this.visible = true;
  }

  applyFilters() {
    this.getLogs();
  }

  resetFilters() {
    this.filter = {
      today: null,
      connectionId: null
    };
    this.getLogs();
  }

  disabled(check: any) {
    if (check === true) {
      return "pointer-events: none";
    }
    return "pointer-events: auto";
  }

  paginate(page: number) {
    this.page = page
    this.getLogs()
  }

  viewPayload(row: any) {
    this.logDetails = row.attributes.payload;
    this.syncedShowCreateModal = true;
  }

  viewResponse(row: any) {
    this.logDetails = row.attributes['api-response'];
    this.syncedShowCreateModal = true;
  }

  @Watch("syncedShowCreateModal")
  onSyncedShowCreateModalChange(newVal: boolean) {
    if (!newVal) {
      this.logDetails = null;
    }
  }
}
