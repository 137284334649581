
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
import {
  Component,
  ProvideReactive,
  Prop,
  Vue,
  Watch,
  Emit,
} from "vue-property-decorator";
import { ElNotification } from "element-ui/types/notification";
import Datepicker from "vuejs-datepicker";
import { mapGetters } from "vuex";
import { eventHandler, translations } from "@/mixins";
import { TimeEvent } from "@/models";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import { Table, TableColumn } from "element-ui";
import { Select, Option } from "element-ui";
import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.css';
@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    VueTimepicker,
    Datepicker,
    flatPickr
  },
  mixins: [eventHandler, translations],
})
export default class TimeEventModal extends Vue {
  translate!: Function
  options: any = [
    {
      value: 1,
      text: "Fixed Price",
    },
    {
      value: 2,
      text: "Reduce Price by Amount",
    },
    {
      value: 3,
      text: "Reduce Price by Percent",
    },
    {
      value: 4,
      text: "Increase Price by Amount",
    },
    {
      value: 5,
      text: "Increase Price by Percent",
    },
    {
      value: 6,
      text: "Activate Item",
    },
    {
      value: 7,
      text: "Deactivate Item",
    },
  ];
  @Prop() days!: Array<String>;
  @Prop() timeEvent!: TimeEvent;

  submit(event: TimeEvent) {
    this.$emit("submit:event", event);
  }

  deleteEvent(event: any) {
    this.$emit("delete:event", event);
  }

  timeValidationRules(time: String) {
    if (!time) {
      return {};
    }

    return { regex: /^[0-9][0-9]:[0-9][0-9] [AaPp][Mm]/ };
  }
}
