
import { User } from "@/interfaces/Landing";
import { Auth, FoodicsAuth } from "@/services/core";
import { ValidationObserver, configure } from "vee-validate";
import { mapGetters, mapMutations } from "vuex";
import { eventHandler } from "@/mixins";
import WelcomeModal from "./Login/components/WelcomeModal.vue";
import PermissionModal from "./Login/components/PermissionModal.vue";
import store from "@/store";
import { Concept } from "@/models";
import {
  Component,
  Prop,
  Vue,
  Watch,
  Ref,
  Mixins,
} from "vue-property-decorator";
import authService from "@/services/core/auth.service";
import { translations, notificationAlerts } from "@/mixins";
import {
  Concept as ConceptApi,
  Menu,
  Subscription,
  Integrations,
} from "@/services/SOLO";

import { ZoomCenterTransition } from 'vue2-transitions';

interface AuthObject {
  provider: number;
  token: string;
}

@Component({
  components: { WelcomeModal, PermissionModal, ZoomCenterTransition },
  computed: {
    ...mapGetters({
      getUser: "account/getUser",
      getUserRole: "account/getUserRole",
      isLoggedIn: "account/isLoggedIn",
      activeConcept: "account/activeConcept",
      getUserClient: "account/getUserClient",
    }),
  },
  methods: {
    ...mapMutations({
      setUser: "account/setUser",
      setUserRole: "account/setUserRole",
      setSubscription: "account/setSubscription",
      setConceptSettings: "account/setConceptSettings",
      setUserConcept: "account/setUserConcept",
      setMenus: "menu/setMenus",
    }),
  },
  mixins: [eventHandler, translations, notificationAlerts],
})
export default class OAuthCallback extends Vue {
  isLoggedIn!: Boolean;
  setUser!: Function;
  setUserRole!: Function;
  translate!: Function;
  systemErrorNotification!: Function;
  successNotification!: Function;
  waiting!: Function;
  restore!: Function;
  setSubscription!: Function;
  setConceptSettings!: Function;
  setUserConcept!: Function;
  setMenus!: Function
  activeConcept!: Concept;
  getUser!: typeof mapGetters;
  getUserRole!: typeof mapGetters;
  notify: boolean = false;
  logo: string = "";
  model: User = {
    username: "",
    password: "",
    rememberMe: false,
  };
  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    welcomeModal: WelcomeModal;
    permissionModal: PermissionModal;
  };

  constructor() {
    super();
  }

  async created() {
    // document.body.classList.add("bg-foodics");
    let code: string = this.$route.query.code as string;
    if (code) {
      try {
        let response = await Auth.getFoodicsToken(code);
        if (!this.isLoggedIn) {
          this.authenticate({ provider: 8, token: response.data.access_token });
        } else {
          this.saveIntegration(response.data.access_token);
        }
      } catch (error) {
        this.systemErrorNotification();
      }
    }
  }

  mounted() {
    this.getLogo();
  }

  async saveIntegration(token: string) {
    // const provider = "foodics";
    const provider = "foodics-f5"; // rename to foodics-f5
    const response = await Integrations.store(
      provider,
      JSON.stringify({
        type: "pos",
        provider: provider,
        options: [
          { key: "access-token", value: token },
          {
            key: "resource-url",
            value: process.env.VUE_APP_FOODICS_URL + "/v5",
          },
          {
            key: "fields",
            value: JSON.stringify({
              category: ["name", "enabled", "image_uri"],
              item: [
                "name",
                "description",
                "image_uri",
                "enabled",
                "price",
                "calories",
              ],
              "modifier-group": ["name"],
              modifier: ["name", "price", "calorie_count", "enabled"],
              ingredient: ["name", "enabled"],
              locations: [
                "lat",
                "long",
                "name",
                "country",
                "telephone",
                "promised_time_delta_pickup",
                "promised_time_delta_delivery",
                "delivery_enabled",
                "delivery_charge",
                "opening_hours",
                "status",
                "delivery_zones",
              ],
            }),
          },
        ],
      })
    );

    this.$router.push({ name: "Integration-Settings" });
  }

  getLogo() {
    Auth.getClient().then((response: any) => {
      this.logo = response.data.data.attributes["logo-uri"];
    });
  }

  getApps() {
    Menu.all()
      .then((response: any) => {
        this.setMenus(response.data.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }

  private getAccountConcept(data: any, roles: any, subscription: any) {
    ConceptApi.get(this.activeConcept.id).then((response) => {
      console.log(response.data.data);
      this.setSubscription(subscription);
      this.setConceptSettings(response.data.data);
      this.getApps();
      // this.$refs.welcomeModal.notify = true;
      // this.$refs.welcomeModal.success = true;
      this.$router.push({name: 'dashboard'})

    });
  }

  private getSubscriptionFn(data: any, roles: any) {
    this.setUserConcept(data.attributes.concepts[0]);
    this.setUser(data);
    this.setUserRole(roles);
    Subscription.getSubscription()
      .then((response) => {
        this.getAccountConcept(data, roles, response.data.data);
      })
      .catch((err) => {
        this.getAccountConcept(data, roles, null);
        // this.$refs.welcomeModal.notify = true;
        // this.$refs.welcomeModal.success = true;
      });
  }

  async authenticate(payload: AuthObject) {
    Auth.login(payload)
      .then((response: any) => {
        this.getSubscriptionFn(
          response.data.data,
          response.data.data.attributes.roles
        );
      })
      .catch((err: any) => {
        this.$refs.welcomeModal.notify = true;
        this.$refs.welcomeModal.success = false;
      });
  }
}
