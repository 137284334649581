
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import "@/assets/sass/main/index.scss";
function hasElement(className: string) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className: string) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import {Auth} from '@/services/core'
import Translations from './global-component/Translations.vue'
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import ConcetListing from "./ConceptListing.vue";
import {FadeTransition} from "vue2-transitions";
import {Component, Prop, Vue, Watch, Ref} from "vue-property-decorator";
import BNavbarBrand from "@/components/Navbar/BaseNav";
import {mapGetters, mapMutations} from "vuex";
import {Concept} from "@/interfaces/SOLO";
import {Menus} from "@/models";
import {ElNotification} from "element-ui/types/notification";
import VueRouter, {Route} from "vue-router";
import {translations} from '@/mixins';
import {Integrations} from '@/services/SOLO';

@Component<Home>({
  components: {
    BNavbarBrand,
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
    FadeTransition,
    ConcetListing,
    Translations
  },
  computed: {
    ...mapGetters({
      getUserRole: 'account/getUserRole',
      activeConcept: "account/activeConcept",
      getMenus: "menu/getMenus",
      getUser: 'account/getUser',
      getPage: "page/getPage",
      getDisabledPage: "page/getDisabledPage",
      getUserClient: 'account/getUserClient',
      getLocale: 'app/getLocale',
      getSubscription: 'account/getSubscription',
      getConceptSettings: 'account/getConceptSettings',
      getMoneyHash: 'page/getMoneyHash',
      getSoloLoyalty: 'page/getSoloLoyalty',
      getPartooGmb: 'page/getPartooGmb',
      getWhatsApp: 'page/getWhatsApp',
      getIsRefresh: 'page/getIsRefresh'
    })
  },
    methods: {
    ...mapMutations({
      logoutUser: 'account/logoutUser',
      setUserClient: 'account/setUserClient',
      setSubscription: 'account/setSubscription',
      setMoneyHash: 'page/setMoneyHash',
      setSoloLoyalty: 'page/setSoloLoyalty',
      setPartooGmb: 'page/setPartooGmb',
      setWhatsApp: 'page/setWhatsApp',
    })
  },
  mixins: [translations]
})
export default class Home extends Vue {
  getPage!: Array<typeof mapGetters>
  getDisabledPage!: Array<typeof mapGetters>
  activeConcept!: Concept;
  getMenus!: Array<Menus>;
  getUser: any
  logo: string = ''
  disableForConceptSettingsFreePackage: Boolean = true;
  $root!: any;
  $refs!: {
    menus: Array<HTMLFormElement>;
    menuPanel: HTMLFormElement;
  };
  menuRoutes: Array<any> = ["menus", "menu"];
  isOpen: Boolean = false
  $notify: any;
  $route!: Route;
  isDisplay: Boolean = false
  isFdics: Boolean = true
  logoutUser!: Function
  private setUserClient!: Function
  private getUserClient!: any;
  private getUserRole!: any;
  private getSubscription: any;
  private setSubscription!: Function;
  private getConceptSettings!: any;
  private setMoneyHash!: Function
  private getMoneyHash!: Function
  private setSoloLoyalty!: Function
  private setPartooGmb!: Function
  private getSoloLoyalty!: Function
  private getPartooGmb!: Function
  private getWhatsApp!: Function
  private setWhatsApp!: Function
  private moneyHash: any = {};
  private soloLoyalty: any = {};
  public partooGmb: any = {};
  public whatsApp: any = {};

  @Watch("getMenus", {immediate: true, deep: true})
  getMenusChanged(newVal: Array<Menus>, oldVal: Array<Menus>) {
    // this.updateMenuPanel();
  }

  @Watch("getConceptSettings", {deep: true, immediate: true})
  onChangeGetConceptSettings(data: any) {
    if(data) {
      if(this.getUserClient?.attributes?.label === 'Foodics Online') {
        this.isFdics = false;
        if(data.attributes['is-free-package']) {
          this.disableForConceptSettingsFreePackage = false;
        }
      }
    }
  }

  @Watch("getUserClient", {deep: true, immediate: true})
  onChangeGetUserClient(data: any) {

    if(data) {

      const api_theme = this.getUserClient.attributes.theme;
      const themeParse = JSON.parse(api_theme);

      let primary = document.documentElement;
      let header = document.documentElement;
      let secondary = document.documentElement;

      primary.style.setProperty('--primary-color', themeParse.primary_color)
      header.style.setProperty('--header-color', themeParse.header_bg_color)
      secondary.style.setProperty('--secondary-color', themeParse.secondary_color)
    }
  }

  @Watch("getMoneyHash", {immediate: true, deep: true})
  getMoneyHashChanged(newVal: any) {
    this.moneyHash = newVal;
  }

  @Watch("getSoloLoyalty", {immediate: true, deep: true})
  getSoloLoyaltyChanged(newVal: any) {
    this.soloLoyalty = newVal;
  }

  @Watch("getPartooGmb", {immediate: true, deep: true})
  getPartooGmbChanged(newVal: any) {
    this.partooGmb = newVal;
  }

  @Watch("getWhatsApp", {immediate: true, deep: true})
  getWhatsAppChanged(newVal: any) {
    this.whatsApp = newVal;
  }

  @Watch("getIsRefresh", { immediate: true, deep: true})
  getIsRefreshChanged(newVal: any) {
    // this.checkWhatsApp()
  }

  async mounted() {
    this.initScrollbar();
    this.getLogo();
    this.showIconLogout();
    this.checkMoneyHash();
    this.checkSoloLoyalty();
    this.checkPartooIntegration()
    this.checkWhatsApp()
  }

  hasGMBSubscription() {
    if (this.getSubscription) {
      return !!this.getSubscription.attributes['paid-features'].find((item: any) => item.feature.includes('online-presence'));
    }

    return false;
  }

  checkMoneyHash() {
    let provider = 'money-hash';
    Integrations.getSingleIntegration(provider)
      .then((response) => {
        this.setMoneyHash(response.data.data[0]);
        this.moneyHash = this.getMoneyHash;
      })
      .catch((err) => {
        console.log(err);
      })
  }

  checkPartooIntegration () {
    let provider = 'online-presence';
    Integrations.getSingleIntegration(provider)
      .then((response) => {
        this.setPartooGmb(response.data.data[0])
        this.partooGmb = this.getPartooGmb
      })
      .catch((err) => {
        console.log(err);
      })
  }

  checkSoloLoyalty() {
    let provider = 'solo-loyalty';
    Integrations.getSingleIntegration(provider)
      .then((response) => {
        this.setSoloLoyalty(response.data.data[0]);
        this.soloLoyalty = this.getSoloLoyalty;
      })
      .catch((err) => {
        console.log(err);
      })
  }

  checkWhatsApp() {
    let provider = 'whatsapp';
    Integrations.getSingleIntegration(provider)
      .then((response) => {
        this.setWhatsApp(response.data.data[0]);
        this.whatsApp = this.getWhatsApp;
      })
      .catch((err) => {
        console.log(err);
      })
  }

  showIconLogout() {
    if (!this.isMobile()) {
      this.isDisplay = false;
    } else {
      this.isDisplay = true;
    }
  }


  @Watch("isLoading", {immediate: true, deep: true})
  isLoadingState(newVal: boolean) {
    if (newVal)
      this.$notify({
        title: "INFO",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Loading data",
        type: "success",
        icon: "fas fa-spinner fa-spin",
      });
  }

  get isLoading(): boolean {
    return this.$root.isLoading;
  }

  isViewable(menu:any) {
    return this.getPage.includes(menu)
  }

  isDisabled(menu:any) {
    return !this.getDisabledPage.includes(menu)
  }

  getLogo() {
    Auth.getClient()
        .then((response) => {
          this.setUserClient(response.data.data)
          this.logo = response.data.data.attributes['logo-uri'];
        })
  }

  logout() {
    this.logoutUser()
    location.reload()
    // this.$router.go(0)
  }


  isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  updateMenuPanel() {
    setTimeout(() => {
      if (this.menuRoutes.includes(this.$route.name)) {
        console.log(this.$route.path, this.$refs.menus[0].link.path);
        if (this.$route.path != this.$refs.menus[0].link.path) {
          this.$router.push({path: this.$refs.menus[0].link.path});
        }
        this.$refs.menuPanel.collapsed = false;
      }
    }, 100);
  }

  initScrollbar() {
    let isWindows = navigator.platform.startsWith("Win");
    if (isWindows) {
      initScrollbar("sidenav");
    }
  }

  hasFeature(feature: string): boolean {
    let sub = this.getSubscription;

    if (! sub) {
      return false;
    }

    if (sub.attributes.features.length == 0) {
      return false;
    }

    return sub.attributes.features.includes(feature);
  }

  public get getLabel() {
    return this.getUserClient?.attributes?.label;
  }

  public get isFoodics() {
    return this.getUserClient?.attributes?.label === 'Foodics Online';
  }
}
