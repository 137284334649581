
import { Table, TableColumn, Button, Form, FormItem, Select, Option, Checkbox } from 'element-ui'
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Order } from '@/services/SOLO'
import { translations, notificationAlerts } from '@/mixins'
import Translations from './Translations.vue'
import flatPicker from "vue-flatpickr-component";
import moment from "moment";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import { mapGetters } from 'vuex';

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    ValidationProvider,
    flatPicker,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    })
  },
mixins: [translations, notificationAlerts],
})
export default class CustomerUpdateModal extends Vue {
  @Prop() openCreate!: boolean;
  @Prop() order!: any;
  public getLocale!: string;
  public translate!: Function;
  id: any;
  isOpen: boolean = false;
  modalForm: any =  {
    'promised-time': null,
    minutes: null,
  };
  roles: any = [];
  locations: any = [];
  private req: Function = (rule: any, value: any, callback: any) => {
    console.log('validator: ', value);
    if (!value) {
      callback(new Error('This field is required'));
    } else {
      callback();
    }
  };
  private reqValidator = [
    {
      validator: this.req,
      trigger: 'blur',
    }
  ];
  rules = {
    ['promised-time']: this.reqValidator,
  };
  successNotification!: Function;
  systemErrorNotification!: Function;
  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };
  outputDate: any = null;

  mounted() {
  }
  @Watch('openCreate', { deep: true })
  onOpenEditChanged(newVal: any) {
    if (newVal) {
      this.modalForm['promised-time'] = this.formatDate(this.order.attributes['promised-time'], 'h:mm A, MMM DD');
      this.isOpen = newVal;
    }
  }

  onDateChange(selectedDates: any, dateStr: any, instance: any) {
    console.log('selectedDates: ', selectedDates);
    console.log('dateStr: ', dateStr);
    console.log('instance: ', instance);
    this.outputDate = selectedDates[0];
  }

  formatDate(date: string, format: string = 'MMM D YYYY hh:mm A') {
    return date && moment
      .utc(date, "YYYY-MM-D hh:mm:ss")
      .locale(this.getLocale || 'en_US')
      .local()
      .format(format) || 'N/A';
  }

  handleClose() {
    this.clearForm();
    this.$emit('handleClose', false);
  }

  clearForm() {
    this.outputDate = null;
    this.modalForm['promised-time'] = null;
    this.modalForm.time = null;
  }

  onUpdate(e: HTMLFormElement, invalid: boolean) {
    if (!invalid) {
      const payload: any = { ...this.modalForm };

      if (this.outputDate) {
        if (payload.minutes) {
          payload['promised-time'] =
            moment(this.outputDate)
              .add(Number(payload.minutes), 'minutes')
              .utc()
              .format('YYYY-MM-DD HH:MM:SS');
        } else {
          payload['promised-time'] =
            moment(this.outputDate)
              .utc()
              .format('YYYY-MM-DD HH:MM:SS');
        }
      } else {
        console.log('gfs')
        payload['promised-time'] = this.order.attributes['promised-time'].replace('T', ' ');
      }

      console.log('original: ', this.order.attributes['promised-time']);
      console.log('outputDate: ', this.outputDate);
      console.log('payload: ', payload);

      Order.updatePromisedTime(this.order.id, payload)
        .then(() => {
          this.clearForm();
          this.$emit('handleClose', false);
          this.successNotification('PROMISED TIME!', 'Promised time successfully updated!');
        })
        .catch((err: any) => {
          console.log('response: ', err.response);
          let error = '';
          for (const prop in err.response.data.error) {
            error += err.response.data.error[prop] + '\n';
          }
          console.log('error: ', error);
          this.systemErrorNotification('UPDATE ERROR!', error);
        });
    }
  }
}
