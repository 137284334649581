
import { Select, Option } from "element-ui";
import { Menu as MenuApi, Location, Concept, Settings } from "@/services/SOLO";
import {
  Category as CategoryModel,
  Location as LocationModel,
  Application as ApplicationModel,
  AttrCustomField,
} from "@/models";
import { Imagetype } from "@/interfaces/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { eventHandler } from "@/mixins";
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
import { ElNotification } from "element-ui/types/notification";
import { required, email } from "vee-validate/dist/rules";
import { translations } from '@/mixins'
import menuRoles from '@/directives/menuRoles';
import { mapGetters } from "vuex";
interface objKey {
  [x: string]: string | number;
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
    ItemList: () => import("./itemPages/ItemList"),
    MenuUpdateCategory: () => import("./MenuUpdateCategory"),
    CategoryCustomFields: () => import("./categoryComponents/customfields/CategoryCustomFields"),
    CategoryAuditLogs: () => import("./categoryComponents/CategoryAuditLogs"),
    asd: () => import("./categoryComponents/TimeEvents"),
  },
  computed: {
    ...mapGetters({
      getUser: 'account/getUser',
      getConceptSettings: 'account/getConceptSettings',

    })
  },
  directives: {
    menuRoles
  },
  mixins: [eventHandler,translations],
})
export default class MenuCategory extends Vue {
  private menuId: string = this.$route.params.id;
  private categoryId: string = this.$route.params.catId;

  private arrayLang: Array<string> = [];
  private numOfLoadedData: number = 0;
  private categories: Array<CategoryModel> = [];
  getConceptSettings!: any;
  private itemCustomFields: Array<AttrCustomField> = [];
  private globalCustomFields: Array<AttrCustomField> = [];
  translate!: Function
  getLanguages!: Function
  tab: number = 1
  mounted() {
    if (this.$route.params.hasOwnProperty("tab")) {
      this.tab = Number(this.$route.params.tab);
    }

  }

  created() {
    // this.arrayLang = this.getLanguages()
        this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
    if (this.getConceptSettings.attributes['secondary-language']) {
      this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
    }
    this.initializeCategory();
    this.getGlobalCustomFields();
  }

  get dropDownText(): string {
    if (this.tab === 1) {
      return this.translate('Items')
    } else if (this.tab === 2) {
      return this.translate('Custom Fields')
    } else if (this.tab === 3) {
      return this.translate('Change Logs')
    } else if (this.tab === 4) {
      return this.translate('Time Events') 
    } else {
      return this.translate('Update Category')
    }
  }

  private onCustomFieldSaved(data:any) {
    if (data.type === 'create') {
      delete data.type;
      this.itemCustomFields.unshift(data);
    } else {
      const idx = this.itemCustomFields.findIndex((c: any) => {
        return c['custom-field-id'].toString() === data['custom-field-id'].toString()
      });
      console.log('idx: ', idx, data);
      delete data.type;
      if (idx > -1) {
        this.itemCustomFields[idx] = data;
      }
      const temp = [...this.itemCustomFields];
      this.itemCustomFields = [];
      // Fix for itemCustomFields not updating after saving
      setTimeout(() => {
        this.itemCustomFields = [...temp];
      }, 100);
      this.$forceUpdate();
    }
  }

  private onCustomFieldDeleted(data:any) {
    let deletedItemIndex = this.itemCustomFields.findIndex((item: any) => +item['custom-field-id'] === +data.id)
    console.log(deletedItemIndex)
    this.itemCustomFields.splice(deletedItemIndex, 1)
  }

  public initializeCategory() {
    for (let i in this.arrayLang) this.findMenu(this.arrayLang[i]);
  }

  private findMenu(lang: any) {
    MenuApi.find(this.menuId, this.categoryId, lang).then((response: any) => {
      let data = { ...response.data.data, ...{ lang: lang } };
      this.categories.push(data);

      if (this.numOfLoadedData === 1) {
        this.itemCustomFields = response.data.data.attributes.hasOwnProperty(
          "custom-fields"
        )
          ? response.data.data.attributes["custom-fields"]
          : [];

          console.log(this.itemCustomFields)
      }
      this.numOfLoadedData++;
    });
  }

  private getGlobalCustomFields() {
    Settings.customFields()
      .then((response: any) => {
        this.globalCustomFields = response.data.data;
      })
      .catch((err: any) => {
        console.error(err);
      });
  }
}
