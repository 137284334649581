
import moment from "moment";
import { Table, TableColumn, Button } from "element-ui";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { notificationAlerts, translations, eventHandler } from "@/mixins";
import { Promotion } from "@/services/SOLO";
import  Translations  from '../components/Translations.vue'

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button
  },
  mixins: [translations, notificationAlerts, eventHandler]
})
export default class SegmentsList extends Vue {
  @Prop() list!: Array<any>;
  //   @Prop() loading: boolean = false
  public translate!: Function;
  color: string = "white";
  successNotification!: Function;
  systemErrorNotification!: Function;
  confirm! : Function;

  segments: any[] = [];
  singleSegment: any = {};
  rows: number = 0;
  perPage: number = 50;
  currentPage: number = 1;
  loading: boolean = true;

  @Watch("loading", { deep: true })
  //   onLoadingChanged(newVal: any) {
  //     this.loading = newVal
  //   }
  standardDateFormat(value: any) {
    if (!value) return "---";
    return moment(value).format("DD/MM/YYYY");
  }
  //   updateList() {
  //     this.$emit('updateList', true);
  //   }

  created() {
    this.getSegments();
  }

  onViewCustomer(row: any) {
    this.$router.push({ name: 'SegmentsCustomerList', params: { id: row.id, name: row.name } });
  }

  async onDeteleSegment(row: any) {
    try {

      const isConfirmedDelete = await this.confirm(this.$bvModal, 'Are you sure to delete this segment?')

      if (isConfirmedDelete) {

        const deletedSegment = await Promotion.deleteSegment(row.id);

        console.log({deletedSegment});

        this.segments = this.segments.filter((segment: any) => segment.id !== row.id);

        this.successNotification('Deleted successfully', 'Segment has been deleted successfully.');
      }
    } catch (error) {
      console.error(error);
      this.systemErrorNotification('Error Occured', 'An error occured while deleting the segment.');
    }
  }

  paginate(page: number) {
    this.currentPage = page;
    this.getSegments();
  }

  private getSegments(): void {
    this.loading = true
    Promotion.fetchSegments(this.currentPage).then(response => {
      this.segments = response?.data?.data?.data;
      this.rows = response?.data?.data?.total;
      this.perPage = response?.data?.data?.per_page;
      this.currentPage = response?.data?.data?.current_page;
      this.loading = false
    });
  }
}
