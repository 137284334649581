
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { translations, notificationAlerts } from "@/mixins";
import ContitionsModal from "./ContitionsModal.vue"
import {
  WhatsApp as WhatsAppService
} from "@/services/SOLO";
import { Applications } from '@/services/SOLO';
import { mapGetters } from "vuex"

interface ApplicationAttributesObject {
  "application-type": string;
  "menu-only": boolean;
  label: string;
  hosts: string;
}

interface ApplicationObject {
  id: string;
  attributes: ApplicationAttributesObject;
}

interface Application {
  id: number;
}

@Component({
  components: {
    ContitionsModal
  },
  computed: {
    ...mapGetters({
      getWhatsApp: 'page/getWhatsApp',
      getConceptSettings: 'account/getConceptSettings',
    }),
  },
  mixins: [translations, notificationAlerts],
})
export default class Step1 extends Vue {
  @Prop() accountDetails!: any;
  translate!: Function;
  getWhatsApp! : Function
  getConceptSettings!: any
  formData: any = {
    "label": "",
    "mobile": "",
    "facebook-business-id": "",
    "monthly-conversation": "",
    "application-id": "",
  }
  submittingRequest: boolean = false
  subscriptionPlans: Array<Object> = [
    {
      text: '1000 Messages',
      value: '1000'
    },
    {
      text: '2000 Messages',
      value: '2000'
    },
    {
      text: '3000 Messages',
      value: '3000'
    },
  ]
  submittedRequest: boolean = false
  applications: Array<Application> = [];

  get labelState(): any {
    return this.formData.label === '' ? null : this.formData.label === null ? false : true
  }

  set labelState(label : any) {
    this.formData.label = label
  }

  get mobileState(): any {
    return this.formData.mobile === '' ? null : this.formData.mobile === null ? false : true
  }

  set mobileState(mobile : any) {
    this.formData.mobile = mobile
  }

  get facebookBusinessIdState(): any {
    return this.formData['facebook-business-id'] === '' ? null : this.formData['facebook-business-id'] === null ? false : true
  }

  set facebookBusinessIdState(id : any) {
    this.formData['facebook-business-id'] = id
  }

  get montlySubscriptionState(): any {
    return this.formData['monthly-conversation'] === '' ? null : this.formData['monthly-conversation'] === null ? false : true
  }

  set montlySubscriptionState(subscription : any) {
    this.formData['monthly-conversation'] = subscription
  }

  get applicationState() {
    return this.formData['application-id'] === '' ? null : this.formData['application-id'] === null ? false : true
  }

  set applicationState(applicationId: any) {
    this.formData['application-id'] = applicationId
  }

  @Watch("getWhatsApp", { immediate: true, deep: true })
  getWhatsAppChanged(newVal: any) {
    if (newVal) {
      /** Set Facebook Business Id and Mobile Field if newly installed integration */
      const { facebook_business_id, mobile } = newVal.attributes.options
      this.formData['facebook-business-id'] = facebook_business_id
      this.formData.mobile = mobile
    }
  }

  @Watch("accountDetails", { immediate: true, deep: true })
  onChangeAccountDetails(newVal: any) {
    if (newVal) {
      const { attributes } = newVal
      this.formData['facebook-business-id'] = attributes['facebook-business-id']
      this.formData.mobile = attributes.mobile
      this.formData.label = attributes.label
      this.formData['monthly-conversation'] = attributes['monthly-conversation'] + ''
      this.formData['facebook-business-id'] = attributes['facebook-business-id']
      this.formData.mobile = attributes.mobile
      this.submittedRequest = true
    } else {
      const { label } = this.getConceptSettings.attributes
      this.formData.label = label
      this.formData['monthly-conversation'] = '1000'
    }
  }

  @Watch('submittedRequest', { immediate: true, deep: true })
  onChangeSubmittedRequest(newVal: any) {
    if (newVal) {
      this.$emit('is-submitted-account-request', newVal)
    }
  }

  mounted() {
    this.getAppItems()
  }

  handleChange = (val: string[]) => {
    console.log(val);
  }

  onClickConditions = (e: any) => {
    console.log("pop up here", e);
  }

  async onSaveAccountDetails() {
    try {
      const isPayloadHasEmptyValues = this.checkPropertiesIfEmpty(this.formData)

      if (isPayloadHasEmptyValues) {
        for (const [key, value] of Object.entries(this.formData)) {
          if (value === null || value === '') {
            if (key === 'label') {
              this.labelState = null
            }
            if (key === 'mobile') {
              this.mobileState = null
            }
            if (key === 'facebook-business-id') {
              this.facebookBusinessIdState = null
            }
            if (key === 'monthly-conversation') {
              this.montlySubscriptionState = null
            }
            if (key === 'application-id') {
              this.applicationState = null
            }
          }
        }
        return true
      }
      this.submittingRequest = true
      const { id } = this.getConceptSettings
      await WhatsAppService.createAccount(this.formData, id)
      const notifyOptions: any = {
        title: 'Save Successfully.',
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message: 'Account Successfully Created',
        type: "success",
        icon: "fas fa-check"
      }
      this.$notify(notifyOptions);
      this.submittedRequest = true
    } catch (error) {
      if (error.response) { 
        if (Object.keys(error.response.data.messages)[0]) {
          const notifyOptions: any = {
            title: 'Error on Saving the Details',
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: error.response.data.messages[Object.keys(error.response.data.messages)[0]][0] || 'An Error Encounter',
            type: 'danger',
            icon: 'fas fa-bomb'
          }
          this.$notify(notifyOptions)
        } else {
            const { error: err } = error.response.data
            const notifyOptions: any = {
            title: 'Error on Saving the Details',
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: err[0].detail || 'An Error Encounter',
            type: 'danger',
            icon: 'fas fa-bomb'
          }
          this.$notify(notifyOptions)
        }
      }
    } finally {
      this.submittingRequest = false
    }
  }

  checkPropertiesIfEmpty(obj: any) {
    const values = Object.values(obj);
    if (values.some(value => value === null || value === '')) {
      return true
    }
    return false
  }

  get appOptions() {
    return this.applications.map((app: any) => {
      return {
        value: app.id, 
        text: app.attributes.label
      }
    })
  }

  getAppItems() {
    Applications.getAllApplications()
    .then((response: any) => {
      const applications = response.data.data;
      this.applications = applications.filter((app: ApplicationObject) => !app.attributes['menu-only'] && app.attributes['application-type'] === 'social-ordering');
      if (this.applications.length > 0) {
        this.formData['application-id'] = this.applications[0].id;
      }
    })
  }
}
