
import AppHeader from './components/NewAppHeader'
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import {Component, Vue} from "vue-property-decorator"

@Component({
  components: {
    RouteBreadCrumb,
    AppHeader
  }
})
export default class Apps extends Vue {

}
