
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { notificationAlerts, translations } from "../../../../../../../mixins";
import { Button, Table, TableColumn } from "element-ui";
import moment from "moment";
import { Settings } from "@/services/SOLO";

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
  mixins: [translations, notificationAlerts],
})
export default class ModgroupItemsList extends Vue {
  @Prop() items!: Array<Object>;
  @Prop() itemsAR!: Array<Object>;
  @Prop() meta!: Array<Object>;
  confirm!: Function;
  total: number = 0;
  currentPage: number = 1;
  perPage: number = 0;

  get deleteMsgBoxText(): String {
    return 'Are you sure you want to delete this item?'
  }

  formatDate(date: string) {
    return moment
      .utc(date, "YYYY-MM-D hh:mm:ss")
      .locale("en-us")
      .local()
      .format("MMM D YYYY hh:mm A");
  }
  remove(id: string, enabled: number) {
    this.confirm(this.$bvModal, this.deleteMsgBoxText)
      .then((value: boolean) => {
        if(value) {
          Settings.deleteItemModifier(this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, id)
            .then((response: any) => {
              this.$emit('modifier:update')
              this.$notify({
                title: "DATA DELETED",
                message: 'Modifier successfully deleted',
                type: "success",
              })
            })
            .catch((err: any) => {
              this.$notify({
                title: "SYSTEM ERROR!",
                message: "Something went wrong, please try again!",
                type: "error",
              });
            })
        }
      })
  }

  enable(id: string, enabled: number) {

    Settings.enableItemModifier({enabled: enabled ? 1 : 0}, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, id)
      .then((response: any) => {
        this.$emit('modifier:update')
        this.$notify({
          title: "DATA UPDATED",
          message: enabled ? 'Modifier Group Enabled!' : 'Modifier Group Disabled',
          type: "success",
        })
      })
      .catch((err: any) => {
        this.$notify({
          title: 'SYSTEM ERROR!',
          message: 'Something went wrong, please try again!',
          type: 'error',
        });
      });
  }

  rowClicked(row: any) {
    this.$router.push({ name: 'modifier', params: { modifierId: row.id }})
  }

  getNameAR(row: any) {
    const find: any = this.itemsAR.find((item: any) => Number(item.id) === Number(row.id));
    return find && find.attributes.name;
  }

  pageChange(data: any) {
    this.$emit('getItemList', data);
  }
}
