
import { Loyalty as LoyaltyModel } from "@/models";
import { Pagination } from "element-ui";
import { Component, Prop, Watch, Mixins, Vue } from "vue-property-decorator";
// import Translations from "@/mixins/translations";
import FlatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { translations } from '@/mixins';

@Component({
  components: {
    [Pagination.name]: Pagination,
    FlatPicker
  },
  mixins: [translations],
})
export default class LoyaltyCollapse extends Vue {
  @Prop() loyaltyMeta!: Array<LoyaltyModel>;
  translate!: Function
  name: string = "";
  mobile: string = "";
  type: string = "";
  from: string = "";
  to: string = "";
  order: string = "";
  nextPage: number = 0;
  hasMore: boolean = false;
  total: number = 0;
  currentPage: number = 1;
  perPage: number = 0;
  totalPage: number = 0;
  isLoaded: boolean = false;
  options: Array<Object> = [];
  $notify: any;

  @Watch("loyaltyMeta", { deep: true })
  onLoyaltyMetaChanged(newVal: any) {
    this.isLoaded = true;
    this.total = newVal.total;
    this.currentPage = newVal.current_page;
    this.perPage = newVal.per_page;
  }

  mounted() {
    this.options = [
      {
        value: "",
        text: this.translate("Select Type")
      },
      {
        value: "redeem",
        text: this.translate("Redeem")
      },
      {
        value: "collect",
        text: this.translate("Collect")
      },
      {
        value: "cancel",
        text: this.translate("Cancel")
      }
    ];
    console.log('called loyalty collapse');
  }

  pageChange(data: any) {
    this.$emit("getLoyalties", data);
  }

  searchLoyalty(name: string, mobile: string, type: string, from: string, to: string, order: string) {
    this.$emit("searchLoyalties", name, mobile, type, from, to, order);

    /* @ts-ignore */
    this.$root.$refs.SoloLoyalty.updateExportParams(name, mobile, type, from, to, order, true);
  }

  resetForm(data: any) {
    this.name = "";
    this.mobile = "";
    this.type = "";
    this.from = "";
    this.to = "";
    this.order = "";
    this.$emit("getLoyalties", data);

    /* @ts-ignore */
    this.$root.$refs.SoloLoyalty.updateExportParams(this.name, this.mobile, this.type, this.from, this.to, this.order, true);
  }
}
