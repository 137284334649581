
import Modal from '@/components/Modal.vue'
import {Select, Option} from 'element-ui'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import { Settings } from '@/services/SOLO';
import { Ingredient, ModifierGroupItem } from '@/models'
import Multiselect from 'vue-multiselect'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Multiselect,
  },
  mixins: [eventHandler, translations]
})
export default class ModgroupModal extends Vue {
  open: boolean = false
  selectedModifierGroup: any = []
  $notify: any
  @Prop() modifierGroups!: Array<ModifierGroupItem>
  @Prop() modifierGroupItems!: Array<ModifierGroupItem>
  @Prop() currentPageModifierGroups!: any
  @Prop() totalPageModifierGroups!: any

  btnLoading!: Function
  btnRestore!: Function
  translate!: Function
  options: any = []
  get selected(): Array<String> {
    return this.modifierGroupItems.map(item => { return item.id })
  }

  get filteredModifierGroups(): Array<ModifierGroupItem> {
    return this.modifierGroups.filter(option => {
      return !this.selected.includes(option.id)
    })
  }

  @Watch('modifierGroups', {immediate: true, deep: true})
  onSelectedComponentsChange(newVal: any) {
    this.options = newVal.filter((item: any) => { 
      return !this.selected.includes(item.id.toString())
    }).map((item: any) => {
      return {name: `${item.attributes.name} [modifier count: ${item.relationships.modifiers.data.length}]`, id: item.id}
    })
  }

  reachedEndOfList(reached: any) {
    if (reached) {
      console.log('reached', this.currentPageModifierGroups, this.totalPageModifierGroups);
      if (this.hasNextPage) {
        this.$emit('getModifierGroups', this.currentPageModifierGroups)
      }
    }
  }

  get hasNextPage() { 
    return this.currentPageModifierGroups <= this.totalPageModifierGroups 
  }

  get payload(): Object {
    return { 'modifier-group': this.selectedModifierGroup.map((item: any) => {return item.id}) }
  }

  close() {
     this.open = false;
  }

  addModGroup(e: any) {
    const defaultText = e.target.innerHTML
    this.btnLoading(e, '<i class="fas fa-spinner fa-spin"></i> Saving data...')
    Settings.addItemModifiers(this.payload, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId)
      .then((response: any) => {
        this.$emit('modgroup:added')
        this.selectedModifierGroup = []
        this.btnRestore(e, defaultText)
        this.$notify({
          title: "DATA SAVED",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Modifier has been added!",
          type: "success",
          icon: "fas fa-check",
        });
      })
      .catch((err: any) => {
        this.btnRestore(e, defaultText)
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Something went wrong, please try again!",
          type: "danger",
          icon: "fas fa-bomb",
        });
      })
  }
}
