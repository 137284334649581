
import { mapGetters, mapMutations } from "vuex";
import { eventHandler, translations } from "@/mixins";
import LangSwitcher from '@/components/LangSwitcher';
import {
  Component,
  Vue,
} from "vue-property-decorator";

@Component({
  components: {
    LangSwitcher
  },
  computed: {
    ...mapGetters({
      getUserClient: 'account/getUserClient'
    }),
  },
  methods: {
    ...mapMutations({}),
  },
  mixins: [eventHandler, translations],
})
export default class AccountProtected extends Vue {
  private getUserClient: any
  translate: any
  constructor() {
    super();
  }

  mounted() {
    
  }

  public get logo(): string {
    if(this.getUserClient) {
      return this.getUserClient.attributes['logo-uri']
    }
    return `https://cdn.getsolo.io/system/portal-logo.png`
  }
}
