
import {Select, Option, Checkbox} from "element-ui";
import {Component, Prop, Vue, Watch} from "vue-property-decorator"
import {Applications, Integrations} from '@/services/SOLO'
import {translations} from '@/mixins'
import {mapGetters} from "vuex";
interface PaymentDeliveryForm {
  'payment-gateway'?: any,
  'force-location'?: any,
  'allowed-order-types': any,
  'allowed-payments-pickup': any,
  'allowed-payments-deliver': any,
}

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    BetaBadge: () => import("@/views/Admin/global-component/BetaBadge.vue"),
  },
  filters: {
    capitalizeFirstLetter(str: any) {
      if (typeof str !== 'string') return ''
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  },
  mixins: [translations],
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    }),
  },
})
export default class PaymentDelivery extends Vue {
  @Prop() details!: any
  @Prop() app_type!: any
  saving: boolean = false
  foodicsFlag: boolean = false
  deliveryFlag: boolean = false;
  $notify: any
  form: PaymentDeliveryForm = {
    'payment-gateway':null,
    'force-location': 0,
    'allowed-order-types': [],
    'allowed-payments-pickup': [],
    'allowed-payments-deliver': [],
  }

  translate!: Function
  getLocale!: any

  isLoyaltyEnabled: boolean = false;
  payment_gateway: any = []

  allowed_order_types: any = [
    {text:'Deliver',value:'deliver'},
    {text:'Eat-in',value:'eat-in'},
    {text:'To-go',value:'to-go'},
    {text:'Curbside',value:'curbside'},
  ]

  allowed_payment_pickup: any = [
    {text:'Cash',value:'cash'},
    {text:'Card on Pickup',value:'card-on-pickup'},
    {text:'Card Online',value:'card'},
    {text:'Apple Pay',value:'apple-pay'},
  ]

  allowed_payment_pickup_1: any = [
    {text:'Cash',value:'cash'},
    {text:'Card on Pickup',value:'card-on-pickup'},
    {text:'Card Online',value:'card'},
    {text:'Apple Pay',value:'apple-pay'},
    {text: 'Alrajhi', value: 'alrajhi'}//override issue

  ]

  allowed_payment_pickup_kiosk: any = [
    {text:'Cash',value:'cash'},
    {text:'Card on Pickup',value:'card-on-pickup'},
    {text:'Card Online',value:'card'},
    {text:'Apple Pay',value:'apple-pay'},
  ]

  allowed_payment_pickup_4: any = [
    {text:'Cash',value:'cash'},
    {text:'Card on Pickup',value:'card-on-pickup'},
    {text:'Card Online',value:'card'},
    {text: 'Alrajhi', value: 'alrajhi'}//override issue

  ]

  allowed_payment_delivery_4: any = [
    {text:'Cash',value:'cash'},
    {text:'Card on Delivery',value:'card-on-delivery'},
    {text:'Card Online',value:'card'},
    {text: 'Alrajhi', value: 'alrajhi'}//override issue

  ]

  allowed_payment_pickup_2: any = [
    {text:'Cash',value:'cash'},
    {text:'Card on Pickup',value:'card-on-pickup'},
    {text:'Card Online',value:'card'},
    {text: 'Alrajhi', value: 'alrajhi'}//override issue

  ]

  allowed_payment_delivery_1: any = [
    {text:'Cash',value:'cash'},
    {text:'Card on Delivery',value:'card-on-delivery'},
    {text:'Card Online',value:'card'},
    {text:'Apple Pay',value:'apple-pay'},
    {text: 'Alrajhi', value: 'alrajhi'}//override issue

  ]

  allowed_payment_delivery_2: any = [
    {text:'Cash',value:'cash'},
    {text:'Card on Delivery',value:'card-on-delivery'},
    {text: 'Alrajhi', value: 'alrajhi'}//override issue

  ]

  allowed_payment_foodics: any = [
    {text:'Card Online',value:'card'},
    {text:'Apple Pay',value:'apple-pay'},
    {text:'Cash',value:'cash'},

  ]

  new_allowed_order_types: any = []
  new_allowed_payment_pickup_1: any = []
  new_allowed_payment_pickup_4: any = []


  @Watch("form", {immediate: true, deep: true})
  checkType(newVal: any) {
    console.log('check type from watch', newVal['allowed-order-types']);
    const found = newVal['allowed-order-types'].find((typ: any) => typ == 'deliver');
    console.log('check if naa', found);
    if(this.foodicsFlag) {
      if(found === 'deliver') {
        this.deliveryFlag = false;
        return;
      } else {
        this.deliveryFlag = true;
        return;
      } 

    }
  }
  @Watch("form", {immediate: true, deep: true})
  checkPayment(newVal: any) {
    if(newVal['payment-gateway'] == 'None') {

      console.log("checkkk kioskkk", this.app_type);

      this.allowed_payment_pickup = [];
      this.allowed_payment_pickup_1 = [];
      this.allowed_payment_pickup_2 = [];
      this.allowed_payment_pickup_4 = [];

      this.allowed_payment_delivery_1 = [];
      this.allowed_payment_delivery_4 = [];
      this.allowed_payment_delivery_2 = [];

      const exclusiveData = [
        { text: 'Cash', value: 'cash' },
        { text: 'Card on Pickup', value: 'card-on-pickup' }
      ];

      const exclusiveDataKiosk = [
        { text: 'Cash', value: 'cash' },
        { text: 'Card on Pickup', value: 'card-on-pickup' },
        { text: 'Card Online', value:'card'},
      ];
      const exclusiveDataKioskLoyalty = [
        { text: 'Cash', value: 'cash' },
        { text: 'Card on Pickup', value: 'card-on-pickup' },
        { text: 'Card Online', value:'card'},
        { text: 'Loyalty', value: 'loyalty' }
      ];

      const exclusiveDataDelivery = [
        { text: 'Cash', value: 'cash' },
        { text: 'Card on Delivery', value: 'card-on-delivery' }
      ];

      this.allowed_payment_pickup.push(...exclusiveData);
      if(this.app_type == 'kiosk') {
          if(!this.isLoyaltyEnabled) {
            this.allowed_payment_pickup_1.push(...exclusiveDataKiosk);
          } else {
            this.allowed_payment_pickup_1.push(...exclusiveDataKioskLoyalty);
          }
      } else {
        this.allowed_payment_pickup_1.push(...exclusiveData);
      }
      this.allowed_payment_pickup_2.push(...exclusiveData);
      this.allowed_payment_pickup_4.push(...exclusiveData);


      this.allowed_payment_delivery_1.push(...exclusiveDataDelivery);
      this.allowed_payment_delivery_2.push(...exclusiveDataDelivery);
      this.allowed_payment_delivery_4.push(...exclusiveDataDelivery);

    } else {

      this.allowed_payment_pickup = [];
      this.allowed_payment_pickup_1 = [];
      this.allowed_payment_pickup_2 = [];
      this.allowed_payment_pickup_4 = [];

      this.allowed_payment_delivery_1 = [];
      this.allowed_payment_delivery_2 = [];
      this.allowed_payment_delivery_4 = [];

      const allowed_payment_pickup = [
        {text:'Cash',value:'cash'},
        {text:'Card on Pickup',value:'card-on-pickup'},
        {text:'Card Online',value:'card'},
        {text:'Apple Pay',value:'apple-pay'},
      ]
      const allowed_payment_pickup_2 = [
        {text:'Cash',value:'cash'},
        {text:'Card on Pickup',value:'card-on-pickup'},
        {text:'Card Online',value:'card'},
        {text: 'Alrajhi', value: 'alrajhi'}//override issue

      ]
      const allowed_payment_pickup_1 = [
        {text:'Cash',value:'cash'},
        {text:'Card on Pickup',value:'card-on-pickup'},
        {text:'Card Online',value:'card'},
        {text:'Apple Pay',value:'apple-pay'},
        {text: 'Alrajhi', value: 'alrajhi'}//override issue

      ]

      

      const allowed_payment_pickup_4 = [
        {text:'Cash',value:'cash'},
        {text:'Card on Pickup',value:'card-on-pickup'},
        {text:'Card Online',value:'card'},
        {text: 'Alrajhi', value: 'alrajhi'}//override issue

      ]

      this.allowed_payment_pickup.push(...allowed_payment_pickup);
      this.allowed_payment_pickup_1.push(...allowed_payment_pickup_1);
      this.allowed_payment_pickup_2.push(...allowed_payment_pickup_2);
      this.allowed_payment_pickup_4.push(...allowed_payment_pickup_4);


    const allowed_payment_delivery_1 = [
        {text:'Cash',value:'cash'},
        {text:'Card on Delivery',value:'card-on-delivery'},
        {text:'Card Online',value:'card'},
        {text:'Apple Pay',value:'apple-pay'},
        {text: 'Alrajhi', value: 'alrajhi'}

      ]

      const allowed_payment_delivery_2 = [
        {text:'Cash',value:'cash'},
        {text:'Card on Delivery',value:'card-on-delivery'},
        {text: 'Alrajhi', value: 'alrajhi'}

      ]

      const allowed_payment_delivery_4 = [
        {text:'Cash',value:'cash'},
        {text:'Card on Delivery',value:'card-on-delivery'},
        {text:'Card Online',value:'card'},
        {text: 'Alrajhi', value: 'alrajhi'}//override issue

      ]

      this.allowed_payment_delivery_1.push(...allowed_payment_delivery_1);
      this.allowed_payment_delivery_2.push(...allowed_payment_delivery_2);
      this.allowed_payment_delivery_4.push(...allowed_payment_delivery_4);

      if (this.isLoyaltyEnabled) {
          this.addLoyaltyOption()
      }
    }
  }

  @Watch('details') onDetailsChanged(val: any) {
    if (! val) {
      return;
    }
    this.allowed_order_types.forEach((v: any) => {
     if(this.details.attributes['application-type'] == 'kiosk') {
        if (v.value !== 'curbside' && v.value !== 'deliver') {
          this.new_allowed_order_types.push(v)
        }
      } else {
        this.new_allowed_order_types.push(v)
      }
    });
    this.allowed_payment_pickup_1.forEach((v: any) => {
      if(this.details.attributes['application-type'] == 'kiosk') {
        if (v.value !== 'apple-pay' && v.value !== 'card-on-pickup' && v.value !== 'alrajhi') {
          this.new_allowed_payment_pickup_1.push(v)
        }
      } else {
        this.new_allowed_payment_pickup_1 = this.allowed_payment_pickup_1;
      }
    });

    this.allowed_payment_pickup_4.forEach((v: any) => {
      if(this.details.attributes['application-type'] == 'kiosk') {
        if (v.value !== 'apple-pay' && v.value !== 'card-on-pickup' && v.value !== 'alrajhi') {
          this.new_allowed_payment_pickup_4.push(v)
        }
      } else {
        this.new_allowed_payment_pickup_4 = this.allowed_payment_pickup_4;
      }
    });

    this.allowed_payment_pickup_1 = this.new_allowed_payment_pickup_1;
    this.allowed_payment_pickup_4 = this.new_allowed_payment_pickup_4;
    this.allowed_order_types = this.new_allowed_order_types

    this.processApplicantPayment(val.attributes)
  }

  mounted() {
    this.getIntegratedPayments();
    // if(window.location.host.includes('foodicsonline-dev.getsolo.io') || window.location.host.includes('foodicsonline-staging.getsolo.io') || window.location.host.includes('foodics.getsolo.io') || window.location.host.includes('online.foodics.com')) {
    //   this.foodicsFlag = true
    // }
  }

  processApplicantPayment(data: any) {
    console.log("check app data", data);
    this.payment_gateway.map((v: any) => {
      if (data['payment-gateway'] == v.attributes.provider) {
        this.form['payment-gateway'] = v.attributes.provider
      }
    })

    this.form['payment-gateway'] = data['payment-gateway'] == null ? 'None': data['payment-gateway']
    this.form['force-location'] = data['force-location'] ? true : false
    this.form['allowed-order-types'] = data['allowed-order-types'] ? data['allowed-order-types'].split(',') : [this.allowed_order_types[0].value] ? data['allowed-order-types'].split(',') : [this.allowed_order_types[0].value] ? data['allowed-order-types'].split(',') : [this.allowed_order_types[0].value]
    this.form['allowed-payments-pickup'] = data['allowed-payments-pickup'] ? data['allowed-payments-pickup'].split(',') : [this.allowed_payment_pickup[0].value] ? data['allowed-payments-pickup'].split(',') : [this.allowed_payment_pickup_1[0].value] ? data['allowed-payments-pickup'].split(',') : [this.allowed_payment_pickup_2[0].value] ? data['allowed-payments-pickup'].split(',') : [this.allowed_payment_pickup_4[0].value] ? data['allowed-payments-pickup'].split(',') : [this.allowed_payment_pickup_kiosk[0].value]
    this.form['allowed-payments-deliver'] = this.foodicsFlag ? (data['allowed-payments-deliver'] ? data['allowed-payments-deliver'].split(',') : []) : (data['allowed-payments-deliver'] ? data['allowed-payments-deliver'].split(',') : [this.allowed_payment_delivery_1[0].value] ? data['allowed-payments-deliver'].split(',') : [this.allowed_payment_delivery_4[0].value] ? data['allowed-payments-deliver'].split(',') : [this.allowed_payment_delivery_2[0].value])
  }

  async getIntegratedPayments() {
    await Integrations.getAllIntegrations()
        .then((response: any) => {
          this.payment_gateway = response.data.data.filter((payment: any) => payment.attributes.type === 'payment')
          this.payment_gateway.push({
            'attributes': {
              'provider': 'None'
            }
          });
          console.log("check payments", this.payment_gateway);
          this.form['payment-gateway'] = this.form['payment-gateway'] == null ? 'None' : this.form['payment-gateway']
          this.isLoyaltyEnabled = response.data.data.some((integration: any) => {
            return integration.attributes.provider == 'solo-loyalty' && integration.attributes.type == 'loyalty';
          });
          if (this.isLoyaltyEnabled) {
              this.addLoyaltyOption();
          }
        }).catch((err: any) => {})
  }

  addLoyaltyOption() {
    const loyaltyOption = { text: 'Loyalty', value: 'loyalty' };

    this.allowed_payment_pickup.push(loyaltyOption);
    this.allowed_payment_pickup_1.push(loyaltyOption);
    this.allowed_payment_pickup_2.push(loyaltyOption);
    this.allowed_payment_pickup_4.push(loyaltyOption);

    this.allowed_payment_delivery_1.push(loyaltyOption);
    this.allowed_payment_delivery_2.push(loyaltyOption);
    this.allowed_payment_delivery_4.push(loyaltyOption);
  }

  forceLocation(e: any) {
      this.form['force-location'] = e
  }

  updateAppPaymentDelivery() {

    this.saving = true
    let params: any = {}

    if(this.form['allowed-payments-pickup'].length === 0) {

        this.$notify({
            title: "ATTENTION!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: 'Please check atleast one payment for pickup!',
            type: "warning",
            icon: "fas fa-bomb",
          });
          this.saving = false
          return false;
    }

        if(this.app_type !== 'kiosk') {
          if(this.form['allowed-payments-deliver'].length === 0) {
                this.$notify({
                    title: "ATTENTION!",
                    verticalAlign: "bottom",
                    horizontalAlign: "left",
                    message: 'Please check atleast one payment for delivery!',
                    type: "warning",
                    icon: "fas fa-bomb",
                  });
                  this.saving = false
                  return false;
            }
        }

    let allowed_paymentPickup = this.form['allowed-payments-pickup'].filter((value: any) => value !== 'false').toString();
    let allowed_paymentDeliver = this.foodicsFlag ? this.form['allowed-payments-deliver'].filter((value: any) => value !== 'false').toString() : this.form['allowed-payments-deliver'].filter((value: any) => value !== 'false').toString()

    if(this.form['payment-gateway'] == 'None') {
      params['payment_gateway'] = null;
    } else {
      params['payment_gateway'] = this.form['payment-gateway'] ? this.form['payment-gateway'] : null
    }

    params['allowed_order_types'] = this.form['allowed-order-types'].filter((value: any) => value !== 'false').toString()
    params['allowed_payments_deliver'] = allowed_paymentDeliver.startsWith(',') ?  allowed_paymentDeliver.slice(1) : allowed_paymentDeliver
    params['allowed_payments_pickup'] = allowed_paymentPickup.startsWith(',') ?  allowed_paymentPickup.slice(1) : allowed_paymentPickup
    params['payment_gateway'] = this.form['payment-gateway'] ? this.form['payment-gateway'] : null
    params['force_location'] = this.form['force-location'] ? 1 : 0

    Applications.updateApplicationPutMethod(Number(this.$route.params.id), params)//for MR issue
        .then((response: any) => {
          this.$notify({
            title: "PAYMENT AND DELIVERY UPDATED!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: "Record has been updated successfully",
            type: "success",
            icon: "fas fa-check"
          })
          this.saving = false
        }).catch((err:any) => {
          let errMsg: any = []
          for (let error in err.response.data.errors) {
            errMsg = err.response.data.errors[error]
          }
          this.$notify({
            title: "SYSTEM ERROR!",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            message: errMsg[0],
            type: "danger",
            icon: "fas fa-bomb",
          });
          this.saving = false
    })
  }

}
