import state from './tfa.state';
import getters from './tfa.getters';
import mutations from './tfa.mutations';
import actions from './tfa.actions';

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
