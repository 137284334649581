
import CustomFieldTable from './components/customfields/CustomFieldTable.vue';
import GlobalModifierGroupTable from './components/modifiergroups/GlobalModifierGroupTable.vue';
import Ingredients from './components/ingredients/Ingredients.vue';
import { Settings } from '@/services/SOLO';
import { Component, Vue } from 'vue-property-decorator';
import { translations } from '@/mixins';
import Translations from '../Translations.vue';

interface CustomFieldAttributes {
  name: String;
  type: Boolean;
}

interface CustomField {
  id: String
  attributes: CustomFieldAttributes
}

@Component({
  components: {
    CustomFieldTable,
    GlobalModifierGroupTable,
    Ingredients,
    Translations,
  },
  mixins: [translations],
})
export default class Globals extends Vue {
  tab: number = 1
  itemLimit: Number = 100
  customFields: Array<CustomField> = []
  modifierGroups: Array<any> = []
  ingredients: Array<Object> = []
  public translate!: Function;
  mounted () {
    this.getCustomFields()
    this.getModifierGroups()
    this.getIngredients()
  }

  getCustomFields() {
    Settings.customFields().then((response: any) => {
      this.customFields = response.data.data
    })
  }

  getModifierGroups() {
    Settings.modifierGroups().then((response: any) => {
      this.modifierGroups = response.data.data
    })
  }

  getList(data: any) {
    if (data.type === 'create') {
      this.modifierGroups.unshift(data.data);
    } else if (data.type === 'update') {
      const idx = this.modifierGroups.findIndex((mg: any) => mg.id === data.id);
      this.modifierGroups[idx] = data.data;
    }
  }

  getIngredients() {
    Settings.ingredients().then((response: any) => {
      this.ingredients = response.data.data;
      console.log(response.data.data);
    });
  }
  get dropDownText(): string {
    if (this.tab === 1) {
      return this.translate('Custom Fields')
    } else if (this.tab === 2) {
      return this.translate('Global Modifier Groups')
    } else {
      return this.translate('Global Ingredients')
    }                   
  }
}
