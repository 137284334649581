
import { Customers } from "@/models";
import { Pagination } from "element-ui";
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Customer } from '@/services/SOLO'
import { translations } from '@/mixins'
import Translations from './Translations.vue'


@Component({
  components: {
    [Pagination.name]: Pagination,
  },
  mixins: [translations],
})
export default class CustomerCollapse extends Vue {
  @Prop() customerMeta!: Array<Customers>;
  public translate!: Function
  filterDate: string = "";
  name: string = "";
  email: string = "";
  mobile: string = "";
  nextPage: number = 0;
  hasMore: boolean = false;
  total: number = 0;
  currentPage: number = 1;
  perPage: number = 0;
  totalPage: number = 0;
  isLoaded: boolean = false;
  customers: Array<any> = [];
  $notify: any;

  @Watch("customerMeta", { deep: true })
  oncustomerMetaChanged(newVal: any) {
    this.isLoaded = true;
    this.total = newVal.total;
    this.currentPage = newVal.current_page;
    this.perPage = newVal.per_page;
  }
  // filteredcustomers() {
  //   this.$emit("findcustomers", this.search);
  // }
  pageChange(data: any) {
    this.$emit("getCustomers", data);
  }

  validateName(name: string): boolean {
    return name.trim().length >= 3;
  }

  validateEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.trim());
  }

  validateMobile(mobile: string): boolean {
    return /^\d+$/.test(mobile.trim());
  }

  validateDate(filterDate: string): boolean {
    const currentDate = new Date();
    const enteredDate = new Date(filterDate);
    return enteredDate.getTime() <= currentDate.getTime();
  }

  // Calculate if button should be disabled
  get isButtonDisabled(): boolean {
    const { name, email, mobile, filterDate } = this;
    return !(this.validateName(name) || this.validateEmail(email) || this.validateMobile(mobile) || this.validateDate(filterDate));
  }

  searchFeedback(name: string, email: string, mobile: string, date: string) {
    if (!this.isButtonDisabled) {
      this.$emit("searchCustomers", name, email, mobile, date);
    }
    if (this.isButtonDisabled) {
      this.$notify({
        title: "An error occurred",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate('Please enter customer detail.'),
        type: "danger",
        icon: "fas fa-bomb",
      });
    }
  }

  resetForm(data: any) {
    this.name = "";
    this.email = "";
    this.mobile = "";
    this.$emit("getCustomers", data);
    this.filterDate = "";
  }

}
