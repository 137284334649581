
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import moment from "moment";

@Component

export default class CountdownTimer extends Vue {
  @Prop() promisedTime!: any
  @Prop() finishedTime!: any
  status: string = ''
  remainingTime: string = ''

  mounted() {
    this.remainingTime = '0:00';
    this.getTimer();
  }

  getTimer() {
    let end = (this.finishedTime ? moment.utc(this.finishedTime).local() : moment().utc().local());
    let start = moment.utc(this.promisedTime);
    
    let duration = moment.duration(start.diff(end));

    this.getStatus(duration);

    if (this.finishedTime && (end < start)) {
      const inMilliseconds = duration.asMilliseconds();

      // "m:ss" print minutes and seconds
      this.remainingTime = moment.utc(inMilliseconds).format("m:ss");
      return;
    }
    if (duration.asSeconds() <= 0) {
      return;
    }

    const intervalId = setInterval(() => {
      duration.subtract(1, "s");

      const inMilliseconds = duration.asMilliseconds();

      // "m:ss" print minutes and seconds
      this.remainingTime = moment.utc(inMilliseconds).format("m:ss");
      this.getStatus(duration);

      if (!this.shouldTime(duration)) {
        clearInterval(intervalId);
      }

      if (inMilliseconds !== 0) return;

      clearInterval(intervalId);
    }, 1000);
  }

  getStatus(duration: any) {
    if (duration.asSeconds() <= 1) {  // 0 minutes
      this.status = 'danger';
    } else if (duration.asSeconds() > 1 && duration.asSeconds() <= 180) { // 1-3 minutes
      this.status = 'warning';
    } else {
      this.status = 'success';
    }
  }

  shouldTime(duration: any) {
    if (duration.asSeconds() <= 1) {
      return false;
    }

    return true;
  }
}
