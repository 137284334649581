
import moment from 'moment'
import CustomerUpdateModal from './CustomerUpdateModal'
import Events from "./Events.vue"
import CustomerAddress from './CustomerAddress'
import CustomerOrders from './CustomerOrders'
import CustomerLoyalties from './CustomerLoyalties'
import {Table, TableColumn, Button, Form, FormItem, Select, Option} from 'element-ui'
import {Component, Prop, Watch, Vue} from "vue-property-decorator"
import { Customer } from '@/services/SOLO'
import { translations } from '@/mixins'
import Translations from './Translations.vue'
import { CustomerAddress as Address } from "@/models"
import { CustomerOrders as Orders } from "@/models"
import { Loyalty as LoyaltyService } from '@/services/SOLO'
import { Loyalty as LoyaltyModel } from "@/models"

interface CustomerDetails {
  'account-type'?: string,
  'code'?: string,
  'created-at'?: string,
  'email'?: string,
  'first-name'?: string,
  'last-name'?: string,
  'mobile'?: string,
  'pincode'?: number,
  'status'?: string,
  'updated-at'?: string,
  'last-order-id'? : number
}

@Component({
  components: {
    CustomerAddress,
    CustomerOrders,
    CustomerUpdateModal,
    CustomerLoyalties,
    Events,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  filters: {
      standardDateFormat(value: any)  {
        if (! value) return '---';
        return moment(value).format('DD/MM/YYYY');
      }
  },
  mixins: [translations],
})
export default class CustomerSingle extends Vue {
  loading: any = false
  openEdit: boolean = false
  id: any
  tab: number = 1
  singleCustomer: CustomerDetails = {}
  customerAddress: any = {}
  customerOrders: any = {}
  loyalties: Array<LoyaltyModel> = []
  page: number = 1
  public translate!: Function
  customerLoyalty: any = {}
  logs: any = []
  mounted() {
    let customerId = this.$route.params.id
    this.getSingleCustomer(customerId)
    this.getCustomerAddress(customerId)
    this.getCustomerOrders(customerId)
    this.getCustomerLoyalty(customerId)
    this.getLogs();
    this.getCustomerLoyaltyPoints(customerId)
  }

  handleEdit() {
    this.openEdit = true
  }

  handleClose(value: any) {
    this.openEdit = value
  }

  async getLogs() {
    this.loading = true;
    await Customer.getCustomerLogs(this.$route.params.id)
      .then((response: any) => {

         let e = response.data.data;

         this.logs = e.sort((a: any, b: any) => {
            return new Date(b.attributes['created-at']).getTime() - new Date(a.attributes['created-at']).getTime();
         });
         this.loading = false;
        console.log("check logs", response.data.data);
      })
  }

  async getCustomerLoyalty(customerId: any) {
    try {
      const response = await Customer.getCustomerLoyalty(customerId)
      this.customerLoyalty = response.data.data.attributes
    } catch (error) {
      console.error('Error in getting Customer Loyalty', error)
    }
  }

  async getCustomerLoyaltyPoints(customerId: any) {
    try {
      const response = await Customer.getCustomerLoyaltyPoints(customerId)
      this.loyalties = response.data.data
    } catch (error) {
      console.error('Error in getting Customer Loyalty Points', error)
    }
  }

  async getSingleCustomer(id: any) {
    try {
      const response = await Customer.find(id)
      this.singleCustomer = response.data.data.attributes
      // const { mobile } = this.singleCustomer
      // if (mobile) {
      //   const getLoyaltiesCustomer = await LoyaltyService.search('', mobile + '' , '', '', '', '')
      //   this.loyalties = getLoyaltiesCustomer.data.data
      // } else {
      //   this.loyalties = []
      // }
    } catch (error) {
      console.error('Error in getting Customer', error)
    }
  }

  async getCustomerOrders(id: any) {
    try {
      const response = await Customer.getOrders(id)
      this.customerOrders = response.data.data
    } catch (error) {
      console.error('Error in getting Customer Orders', error)  
    }
  }

  async getCustomerAddress(id: any) {
    try {
      const response = await Customer.getAddresses(id)
      this.customerAddress = response.data.data
    } catch (error) {
      console.error('Error in getting Customer Address', error)
    }
  }

  get dropDownText(): string {
     if (this.tab === 1) {
       return this.translate('Addresses')
     } else {
       return this.translate('Orders')
     }                   
  }
}
