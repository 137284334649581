
import Modal from '@/components/Modal.vue'
import {Select, Option, Form} from 'element-ui'
import AddCustomFieldModal from './AddCustomFieldModal.vue'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import {Menu as MenuApi, Settings, Item, Location, Employee, Category as CategoryApi} from '@/services/SOLO';
import {Ingredient, Location as LocationModel, ModifierGroupItem} from '@/models'

interface nameObj {
  [x: string]: any
}

interface ModgroupItem {
  name: nameObj
  'display-order': string
  label: string
  code: string
  type: string
  minimum: string
  maximum: string
  'maximum-modifier': any
  'free-modifiers-count': any
  modifiers: any
  enabled: number
  'target-type': string
  'target-id': string
  'target-is': string
  'image-uri': string
  'initial-value': any
}

@Component({
  components: {
    AddCustomFieldModal,
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationObserver,
    ValidationProvider,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      userRole: 'account/getUserRole',
      getConceptSettings: 'account/getConceptSettings',

    }),
  },
  mixins: [eventHandler, translations]
})
export default class ModgroupModal extends Vue {
  getConceptSettings!: any
  private openCustomField: Boolean = false
  private visibleWhen: number = 0
  private arrayLang: Array<string> = [];
  private imageType: number = 0;
  private modifiers: Array<any> = []
  private globalCustomFields: Array<any> = []
  private customFields: Array<any> = []
  private id: string = ''
  private selectedFields: Array<any> = []
  private imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Upload Image",
    },
    {
      value: 2,
      text: "Stock Images",
    },
  ]
  private itemId: string = ''
  private customFieldId: string = ''
  private customFieldsValue: string = ''
  private item: ModgroupItem = {
    name: {},
    'display-order': '',
    label: '',
    code: '',
    type: '',
    minimum: '',
    maximum: '',
    'maximum-modifier': null,
    'free-modifiers-count': null,
    modifiers: [],
    enabled: 1,
    'image-uri': '',
    'target-type': '',
    'target-id': '',
    'target-is': '',
    'initial-value': ''
  }

  $refs!: {
    file: any
  }
  getLocale!: any
  getLang!: Function
  getLanguages!: Function
  modifierGroups: Array<any> = [];
  selectedModifierGroupId: string = '';
  targets: Array<any> = [];
  selectedLocations: any = [];
  locations: Array<LocationModel> = [];
  employeeLocations: any = [];
  userRole!: any;
  getUser!: any;
  overrideMsg: String = '';
  searchStock: any = '';
  isLoading: Boolean = false;
  stockImages: any = [];
  freeModCount: any = null;
  maxModOption: any = null;
  validMaxModOption: Boolean = true;
  validfreeModCount: Boolean = true;

  @Prop() private modifierGroupItems!: Array<Object>
  @Prop() private open!: Boolean;

  constructor() {
    super();
  }

  @Watch("maxModOption", {immediate: true, deep: true})
  isMaxModOptionChanged(newVal: any) {
    if (newVal < 0 || newVal > this.item.modifiers.length) {
      this.validMaxModOption = false;
    } else {
      this.validMaxModOption = true;
    }
  }
  @Watch("freeModCount", {immediate: true, deep: true})
  isfreeModCountChanged(newVal: any) {
    if (newVal < 0 || newVal > this.item.modifiers.length) {
      this.validfreeModCount = false;
    } else {
      this.validfreeModCount = true;
    }
  }

  @Watch('item.type', {immediate: true, deep: true})
  isItemTypeChanged(newVal: any) {
    if (newVal == 'sizes') {
      this.overrideMsg = 'Warning: Size modifiers override the product price.';
    } else {
      this.overrideMsg = '';
    }
  }

  @Watch("imageType", {deep: true, immediate: true})
  onChangeImageType(data: any) {
    if(data === 2) {
      this.getStockImages();
    }
  }

  created() {
    /* @ts-ignore */
        this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
    /* @ts-ignore */
    if (this.getConceptSettings.attributes['secondary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
    }
    this.getLocations();
  }

  mounted() {
    const promiseArray: any = [];
    const promiseArray2: any = [];
    const { id, catId, itemId, modifierId } = this.$route.params;

    if (modifierId == 'create') {
      return;
    }

    this.arrayLang.forEach((lang: string) => {
      promiseArray.push(MenuApi.getItemModifierGroup(id, catId, itemId, modifierId, lang));
      promiseArray2.push(MenuApi.getItemModifierGroups(id, catId, itemId, lang));
    });

    Promise.all(promiseArray)
      .then((response: any) => {
        let data: any = response[0].data.data;

        for (let i = 0; i < this.arrayLang.length; i++) {
          this.item.name[this.arrayLang[i]] = response[i].data.data.attributes.name;
        }

        if (response[0].data.included) {
          this.modifiers = response[0].data.included.filter((include: any) => { return include.type === 'modifier' });
        }

        if (data.attributes['custom-fields']) {
          this.customFields = data.attributes['custom-fields'];
        }

        this.customFields = data.attributes['custom-fields'];
        this.item['display-order'] = data.attributes['display-order'];
        this.item['image-uri'] = data.attributes['image-uri'];
        this.item.label = data.attributes.label;
        this.item.code = data.attributes.code;
        this.item.type = data.attributes.type;
        this.item.minimum = data.attributes.minimum;
        this.item.maximum = data.attributes.maximum;
        this.item['maximum-modifier'] = data.attributes['maximum-modifier'];
        this.item.modifiers = data.attributes.modifiers;
        this.item.enabled = data.attributes.enabled;
        this.item['free-modifiers-count'] = data.attributes['free-modifiers-count'];
        
        this.freeModCount = this.item['free-modifiers-count'] ?? null;
        this.maxModOption = this.item['maximum-modifier'] ?? null;

        console.log('This is free Count: ',data.attributes);
        console.log('This is free Count -maxModOption value: ', this.maxModOption);
        console.log('This is free Count -freeModCount value: ', this.freeModCount);
        if (data.attributes['visibility-config']) {
          this.item['initial-value'] = data.attributes['visibility-config']['initial-value'];
          this.item['target-type'] = data.attributes['visibility-config'].rules[0]['target-type'];
          this.item['target-id'] = data.attributes['visibility-config'].rules[0]['target-id'];
          this.item['target-is'] = data.attributes['visibility-config'].rules[0]['target-is'];
          this.loadTargets(false);
        }
      });
    Promise.all(promiseArray2)
      .then((response: any) => {
        const inc = response[0]?.data?.included;
        this.modifierGroups = response[0]?.data?.data;
        this.modifierGroups = this.modifierGroups.map((group: any, i: number) => {
          group.attributes.modifiers = group.attributes.modifiers.map((mod: any) => {
            const find: any = inc.find((i: any) => Number(i.id) === Number(mod.id));
            if (find) {
              return {
                id: find.id,
                name: find.attributes.name,
              };
            }
            return mod;
          });
          return group;
        });
        this.loadTargets();
      });
  }

  get isEnabled() {
    return this.item.enabled === 1 ? true : false
  }

  set isEnabled(newValue: Boolean) {
    this.item.enabled = newValue ? 1 : 0
  }

  get defaultVisibility() {
    return this.item['initial-value'] === 'hidden' ? false : true
  }

  set defaultVisibility(newValue: Boolean) {
    this.item['initial-value'] = !newValue ? 'hidden' : ''
  }

  get isOpen(): Boolean {
    return this.open
  }

  set isOpen(newVal: Boolean) {
    this.$emit('modal:update', newVal)
    if(!newVal) {
      this.item.name = {}
      this.item['display-order'] = ''
      this.item.label = ''
      this.item.code = ''
      this.item.type = ''
      this.item.minimum = ''
      this.item.maximum = ''
      this.item['maximum-modifier'] = null
      this.item['free-modifiers-count'] = null
      this.item.modifiers = []
      this.item.enabled = 1
      this.item['initial-value'] = ''
      this.item['target-type'] = ''
      this.item['target-id'] = ''
      this.item['target-is'] = ''
    }
  }

  @Watch('isOpen', { immediate: true, deep: true })
  isModalOpen() {
    if(this.isOpen) {

    }
  }

  public loadTargets(emptyTargetId = false) {

    if (emptyTargetId) {
      this.item['target-id'] = '';
    }

    switch (this.item['target-type']) {
      case 'modifier-group':
        this.targets = this.modifierGroups.map(mg => {
          return {
            id: mg.id,
            label: mg.attributes.name
          }
        });
        break;
      case 'modifier':
        let fnMapModifiers = (mg: any) => {
          return {
            label: mg.name,
            id: mg.id,
          };
        };
        if (!this.selectedModifierGroupId && this.item['target-id']) {
          let modifierGroup = this.modifierGroups.find(mg => {
            let modifier = mg.attributes.modifiers.find((m: any) => m.id == this.item['target-id']);
            return modifier != null;
          });
          this.selectedModifierGroupId = modifierGroup.id;
          this.targets = modifierGroup.attributes.modifiers.map(fnMapModifiers);
        } else if (this.selectedModifierGroupId) {
          let modifierGroup = this.modifierGroups.find(mg => mg.id == this.selectedModifierGroupId);
          this.targets = modifierGroup.attributes.modifiers.map(fnMapModifiers);
        } else {
          this.selectedModifierGroupId = this.modifierGroups[0].id;
          this.targets = this.modifierGroups[0].attributes.modifiers.map(fnMapModifiers);
        }
        break;
    }
  }

  private updateCustomFieldModal(open: Boolean) {
    this.openCustomField = open
  }

  private refreshSelectedCustomFields() {
    const { id, catId, itemId, modifierId } = this.$route.params;
    MenuApi.getItemModifierGroup(id, catId, itemId, modifierId).then(response => {
      let data: any = response.data.data
      if(data.attributes['custom-fields']) {
        this.customFields = data.attributes['custom-fields']
      }else {
        this.customFields = []
      }
    })
  }

  private updateCustomField(customFieldId: string, value: string) {
    Item.getCustomFields()
      .then((response: any) => {
        this.globalCustomFields = response.data.data
        this.itemId = this.id
        this.selectedFields = this.customFields
        this.customFieldId = customFieldId
        this.customFieldsValue = value
        this.updateCustomFieldModal(true)
      })
  }

  private removeCustomField(customFieldId: string)  {
    let payload: Object = {
      entity: "modifier-group",
      'entity-id': this.id,
    }
    Item.deleteCustomFields(payload, customFieldId)
      .then((response: any) => {
        this.refreshSelectedCustomFields()
      })
  }

  private getCustomFields() {
    Item.getCustomFields()
      .then((response: any) => {
        this.globalCustomFields = response.data.data
        this.selectedFields = this.customFields
        this.itemId = this.id
        this.customFieldId = ''
        this.customFieldsValue = ''
        this.updateCustomFieldModal(true)
      })
  }

  checkSize(text: any) {
    if(this.getLocale === 'en_US') {
      if(text === 'The image field size must be less than 2000KB') {
        return 'The image file size must be less than 2MB'
      }
    }
    if(text === 'The image field size must be less than 2000KB') {
      return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }
  }

  selectStockImage(stockImage: any) {
    this.item['image-uri'] = stockImage;
  }
  searchStockImages(e: any) {
    this.isLoading = true;
      CategoryApi.searchStockImgs(this.searchStock)
      .then((response) => {
        this.stockImages = response.data.data;
        this.isLoading = false;
        console.log("search stock images", this.stockImages);
      })
  }
  getStockImages() {
    CategoryApi.getStockImgs()
      .then((response) => {
        this.stockImages = response.data.data;
        console.log("stock images", this.stockImages);
      })
  }

  private updateData() {

    let form = new FormData();
    Object.keys(this.item.name).map((key: any) => {
      form.append(`name[${key}]`, this.item.name[key]);
    })

    let locations = this.selectedLocations;
    if (this.isMenuRestaurant) {
      // locations = [...this.selectedLocations, ...this.notBelongLocations];
    }

    this.item['free-modifiers-count'] = this.freeModCount ?? null;
    this.item['maximum-modifier'] = this.maxModOption ?? null;
    
    console.log('Free Mod Counts: ',this.item['free-modifiers-count'])

    form.append(`_method`, 'PATCH');
    form.append(`display-order`, this.item['display-order']);
    form.append(`label`, this.item.label);
    form.append(`code`, this.item.code);
    form.append(`type`, this.item.type);
    form.append(`minimum`, this.item.minimum);
    form.append(`maximum`, this.item.maximum);
    form.append(`maximum-modifier`, this.item['maximum-modifier']);
    form.append(`free-modifiers-count`, this.item['free-modifiers-count']);
    form.append(`enabled`, this.item.enabled.toString());
    form.append(`visibility-config-rules[0][target-type]`, this.item['target-type']);
    form.append(`visibility-config-rules[0][target-id]`, this.item['target-id']);
    form.append(`visibility-config-rules[0][target-is]`, this.item['target-is']);
    form.append(`visibility-config-initial-value`, !this.defaultVisibility ? 'hidden' : '');
    console.log('Max Mods: ', form.append(`maximum-modifier`, this.item['maximum-modifier']))
   

    locations.forEach(function (location: Blob|string) {
      form.append('locations', location);
    })
    if (this.imageType != 1) {
        form.append(`image-uri`, this.item['image-uri']);
    } else if (this.imageType) {
        form.append(`image`, this.$refs.file.files[0]);
    }
    this.updateItem(form)
  }

  private updateItem(payload: FormData) {
    const { modifierId } = this.$route.params;
    console.log('Update Payload: ', payload)
    MenuApi.updateItemModifierGroup(payload, this.$route.params.itemId, modifierId).then((response: any) => {
      // location.reload()
      this.$emit('modifier:update')
      this.isOpen = false
      /* @ts-ignore */
      this.$router.push({ name: 'itemModifier', params: { itemId: this.$route.params.itemId, tab: 1 } })
    })
  }

  getEmployeeLocations() {
    if (!this.employeeLocations.length) {
      return Employee.find(this.getUser.id)
        .then((response) => {
          if (response.data.included) {
            this.employeeLocations = response.data.included;
          }
        });
    }
    return Promise.resolve();
  }

  private get isMenuRestaurant(): boolean {
    const filterRole = ['menu', 'restaurant'];
    const roles = this.userRole.filter((role: string) => filterRole.find((frole: string) => role === frole));
    return !!roles?.length;
  }

  getLocations() {
    Location.fetchLocations().then((response: any) => {
      // filter locations for menu and restaurant
      // SPO-94
      // [Web] Users :: Restaurant and Menu - Disable Locations
      this.locations = response.data.data;
      if (this.isMenuRestaurant) {
        this.getEmployeeLocations()
          .then(() => {
            this.locations = this.locations.filter((l: any) => this.employeeLocations.find((lo: any) => l?.id === lo?.id));
          });
      }
    });
  }
}
