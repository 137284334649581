export default {
    setPage(state: { page_allowed: Array<any> }, data: Array<any>) {
        state.page_allowed = data;
    },
    setDisabledPage(state: { page_disabled: Array<any> }, data: Array<any>) {
      console.log('setDisabledPage - data: ', data);
      state.page_disabled = data;
    },
    setMoneyHash(state: { money_hash: any }, data: any) {
      console.log('setMoneyHash - data: ', data);
      state.money_hash = data;
    },
    setSoloLoyalty(state: { solo_loyalty: any }, data: any) {
      console.log('setSoloLoyalty - data: ', data);
      state.solo_loyalty = data;
    },
    setPartooGmb(state: { partoo_gmb : any }, data: any) {
      console.log('setPartooGmb - data: ', data);
      state.partoo_gmb = data
    },
    setWhatsApp(state: { whats_app : any }, data: any ) {
      console.log('setWhatsApp - data', data)
      state.whats_app = data
    },
    setIsRefresh(state: { is_refresh : any }, data: any) {
      console.log('setIsRefresh - data', data)
      state.is_refresh += data
    },
    setIsWhatsAppVerified(state: { is_whatsapp_verified : any }, data: any) {
      console.log('setIsWhatsAppVerified - data', data)
      state.is_whatsapp_verified = data;
    },
    setIsWhatsAppOnBoarded(state: { is_whatsapp_onboarded : any }, data: any) {
      console.log('setIsWhatsAppOnBoarded - data', data)
      state.is_whatsapp_onboarded = data;
    }
}
