
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { translations } from '@/mixins'

@Component({
  components: {},
  mixins: [translations],
})
export default class FeedbackRatings extends Vue {
  @Prop() openEdit: boolean = false
  @Prop() ratings: any

  get isOpen(): Boolean {
    return this.openEdit;
  }

  set isOpen(newVal: Boolean) {
    this.$emit("showRating", newVal);
    if (!newVal) {
    }
  }

  @Watch('openEdit', { deep: true })
  onOpenEditChanged(newVal: any) {
    this.isOpen = newVal
  }
  
  @Watch('ratings', { deep: true })
  onRatingsChanged(newVal: any) {
    if (newVal) {
    //   console.log('ratings', newVal)
    }
  }

  handleClose() {
    this.$emit('handleClose', false)
  }
}
