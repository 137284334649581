

import GlobalIngredientsModal from './GlobalIngredientsModal.vue'
import GlobalIngredientsList from './GlobalIngredientsList.vue'
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import { Settings } from '@/services/SOLO';
import { GlobalIng, Ingredient, ModifierGroupItem } from '@/models'

@Component({
  components: {
    GlobalIngredientsModal,
    GlobalIngredientsList
  },
  mixins: [eventHandler, translations]
})
export default class GlobalIngredients extends Vue {
  
  open: Boolean = false
  itemIngredients: Array<GlobalIng> = []
  globalIngredients: Array<GlobalIng> = []
  translate!: Function

  mounted() {
    this.getItemIngredients()
    this.getGlobalIngredients()
  }

  get mappedItemIngredients(): Array<String> {
    return this.itemIngredients.map(ing => { return ing.attributes['ingredient-id'].toString() })
  }

  modalOpenState(open: Boolean = true) {    
    this.open = open
  }
  
  getItemIngredients() {
    Settings.getItemIngredients(this.$route.params.id, this.$route.params.catId, this.$route.params.itemId)
      .then((response: any) => {
        this.itemIngredients = response.data.data                
      })
      .catch((err: any) => {
        console.error(err)
      })
  }

  getGlobalIngredients() {
    Settings.ingredients()
      .then((response: any) => {
        this.globalIngredients = response.data.data        
      })
      .catch((err: any) => {
        console.error(err)
      })
  }
}
