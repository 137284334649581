
import {eventHandler, translations} from '@/mixins'
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import {Popup as PopupApi} from "@/services/SOLO"

@Component({
  mixins: [eventHandler, translations]
})
export default class Popups extends Vue {
  popups: any = [];
  confirm!: Function
  $notify: any
  getLocale: any
  translate!: Function

  mounted() {
    this.getPopups()
    console.log('check lang', this.getLocale)
  }

  async getPopups() {
    await PopupApi.all().then((response: any) => {
      this.popups = response.data.data
      console.log('popups', this.popups)
    }).catch((err: any) => {
      console.log(err.response)
    });
  }

  get deleteMsgBoxTextSlide(): String {
    return "Are you sure you want to delete this popup?";
  }

  removePopup(popupID: any) {

    this.confirm(this.$bvModal, this.deleteMsgBoxTextSlide).then(
      (value: boolean) => {
        if(value) {
          PopupApi.removePopup(
            popupID,
          ).then((response) => {
            this.getPopups();
            this.$notify({
              title: "POPUP DELETED!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Popup has been deleted successfully",
              type: "success",
              icon: "fas fa-check",
            });
          })
          .catch((err) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Something went wrong, please try again!",
              type: "danger",
              icon: "fas fa-bomb",
            });
          });
        }
      }
    );
    }
}
