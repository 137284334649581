
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { SettingsFacilities } from "@/services/SOLO";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { Facility } from "@/models";
import FacilitiesFormModal from "./components/facilities/FacilitiesFormModal.vue";
import { translations } from '@/mixins';
import Translations from '../Translations.vue';

@Component({
  components: {
    FacilitiesItems: () => import("./components/facilities/FacilitiesItems"),
    LoadingPanel: () => import("@/components/LoadingPanel"),
    FacilitiesFormModal,
    Translations,
  },
  mixins: [translations],
})
export default class Globals extends Vue {
  translate!: Function;
  itemLimit: Number = 100;
  loading: Boolean = false;
  facilities: Array<Facility> = [];
  activeFacility: Facility = {
    id: "",
    attributes: {}
  };
  $notify: any
  $refs!: {
    facilitiesFormModal: FacilitiesFormModal;
  };


  mounted() {
    this.getFacilities(true);
  }

  onModalClose() {
    this.activeFacility = {
      id: "",
      attributes: {}
    };
  }

  showEditForm(item: Facility) {
    this.activeFacility = item;
    console.log(this.activeFacility);
    this.$refs.facilitiesFormModal.open = true;
  }

  async getFacilities(showLoading: boolean = true) {
    if (showLoading) {
      this.loading = true;
    }

    try {
      const response = await SettingsFacilities.all();
      this.facilities = response.data.data;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb"
      });
    }
  }
}
