import store from '@/store'

export default function auth({ to, from, next, router }) {

  // if (from.path === '/' && !store.getters['account/isLoggedIn']) {
  //   // return router.push({ name: 'login' })
  //   next('/login')
  // } else if (from.path && to.path === '/' && store.getters["account/isLoggedIn"]) {
  //   router.push('/dashboard')
  // } else if (from.path === '/' && store.getters['account/isLoggedIn']) {
  //   next()
  // } else if (from.path === '/login' && store.getters['account/isLoggedIn']) {
  //   next()
  // }
  // next()

  if (from.path === '/' && !store.getters['account/isLoggedIn']) {
    next('/login')
  // } else if (store.getters['account/isLoggedIn'] && store.getters['account/getConceptSettings']['attributes']['is-2fa-enabled'] && !store.getters['account/getUser']['attributes']['tfa-factors'].length) {
  //   next('/login')
  } else if (from.path && to.path === '/' && store.getters["account/isLoggedIn"]) {
    router.push({name: 'dashboard'})
  } else if (from.path === '/' && store.getters['account/isLoggedIn']) {
    next()
  } else if (from.path === '/login' && store.getters['account/isLoggedIn']) {
    next()
  }
  next()
}
