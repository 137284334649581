
import { translations } from '@/mixins'
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
@Component({
  mixins: [translations]
})
export default class DashboardFigures extends Vue {
  @Prop() title!: String;
  @Prop() subTitle!: String;
  @Prop() revenue!: number;
  @Prop() average!: number;
  @Prop() orders!: number;
  @Prop() revpct!: number;
  @Prop() avgpct!: number
  @Prop() orderspct!: number
  @Prop() disableIcon!: Boolean

  private getGradient(num: any) {
    if(num.toFixed(1) >= 0) {
      return 'gradient-green'
    } else if (num.toFixed(1) < 0) {
      return 'gradient-red'
    }
  }
}
