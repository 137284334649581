

import { Component, Mixins, Watch } from "vue-property-decorator";
import Translations from "@/mixins/translations";

@Component
export default class SoloConnect extends Mixins(Translations) {
  showCreateModal: boolean = false;

  onCreate() {
    this.showCreateModal = true;
  }

  updateModal(value: boolean) {
    this.showCreateModal = value;
  }
}
