
import { translations } from '@/mixins'
import { Concept as ConceptApi, Subscription, Menu, Device as DeviceService } from '@/services/SOLO'
// import Stripe from 'stripe';
import { ValidationObserver, configure, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import {loadStripe} from '@stripe/stripe-js';
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import { Select, Option, Form, FormItem } from "element-ui";
import Translations from "./Translations.vue";

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    ValidationObserver,
    Translations,
  },
  computed: {
    ...mapGetters({
      activeConcept: 'account/activeConcept',
      getAppQueryParams: 'app/getAppQueryParams',
      getSubscription: 'account/getSubscription',
    })
  },
  methods: {
    ...mapMutations({
      setConceptSettings: 'account/setConceptSettings',
      setSubscription: 'account/setSubscription',
      setSubscriptionPaymentId: 'account/setSubscriptionPaymentId',
      setMenus: 'menu/setMenus',
    })
  },
  filters: {
    formatDate(date: string) {
        return moment
          .utc(date, "YYYY-MM-D hh:mm:ss")
          .locale("en-us")
          .local()
          .format("D/MM/YYYY");
    },
    currency(value: number|bigint, currencyCode: any) {
        if (typeof value !== "number") {
            return value;
        }
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currencyCode,
            minimumFractionDigits: 2
        });
        return formatter.format(value);
    }
  },
  mixins: [translations],
})
export default class PaymentDescription extends Vue {

  private getSubscription: any
  private activeConcept: any
  branchCount: any = 1
  private kioskCount: number = 0
  private cardHolder: string = ''
  private withDeliverySystem: Boolean = false
  private withLoyaltySubscription: Boolean = false
  private withGoogleMyBusinessSubscription: Boolean = false
  private billingCycle: any = ''
  private couponCode: string = ''
  private upcomingSubscription: Object = {}
  private getAppQueryParams: any
  private isLoading: Boolean = false
  private card: Object = {
    number: '',
    exp: '',
    code: ''
  }

  private paymentIntent: any
  private cardElement: any = null
  private stripe: any = null
  private paymentMethod: any = null
  translate!: Function;

  @Prop() private planId: any
  @Prop() private subscription: any
  @Prop() private isCurrentPlan!: Boolean
  @Prop() private billng: any
  @Prop() private branchcnt: any
  @Prop() private flagOpenModal: any
  public currencies = [
    { label: 'USD', value: 'usd' },
    { label: 'SAR', value: 'sar' },
    { label: 'AED', value: 'aed' },
    { label: 'EUR', value: 'eur' },
    { label: 'GPB', value: 'gpb' },
    { label: 'EGP', value: 'egp' },
  ];
  public currency: string = 'usd';

  $notify: any;
  $refs!: {
    cardExpiry: any
    cardCvc: any
    cardNumber: any
  }
  private setConceptSettings!: Function
  private setSubscription!: Function
  private setSubscriptionPaymentId!: Function
  private setMenus!: Function
  private loaded: Boolean = false
  mounted() {
    this.getDevices()
    this.branchCount = this.subscription == null ? 1 : this.subscription?.attributes?.['branch-quantity'];
    console.log("sub bran count", this.branchCount);
    this.initStripe()
    this.getPaymentIntent()
    this.onSubscritionChange()

  }

  @Watch('planId', { immediate: true, deep: true })
  onPlanIdChange() {
    if (this.loaded) {
      this.getInvoice()
    }
  }

  @Watch('branchCount', { immediate: true, deep: true })
  onBranchCountChange() {
    // this.getInvoice()
  }

  @Watch('billng', { immediate: true, deep: true })
  onBillingCycleChange() {

    this.billingCycle = this.billng;

    if (this.loaded) {
      this.getInvoice()
    }
  }

  @Watch('withDeliverySystem', { immediate: true, deep: true })
  onDeliverySystemChange() {
    if (this.loaded) {
      this.getInvoice()
    }
  }

  @Watch('withLoyaltySubscription', { immediate: true, deep: true })
  onLoyaltySubscriptionChange() {
    if (this.loaded) {
      this.getInvoice()
    }
  }

  @Watch('withGoogleMyBusinessSubscription', { immediate: true, deep: true })
  onGoogleSubscriptionChange() {
    if (this.loaded) {
      this.getInvoice()
    }
  }

  @Watch('loaded', { immediate: true, deep: true })
  onLoadedChange(newVal: any) {
    if (newVal) {
      this.getInvoice()
    }
  }

  @Watch('subscription', { immediate: true, deep: true })
  onSubscritionChange() {
    if(this.subscription) {
      if(this.subscription.hasOwnProperty('attributes')) {
        this.billingCycle = this.subscription.attributes['billing-cycle'] === 'month' ? false : true
        this.branchCount = Number(this.subscription.attributes['branch-quantity'])
        this.withDeliverySystem = this.subscription.attributes.features.includes('delivery-system')
        this.withLoyaltySubscription = this.subscription.attributes.features.includes('loyalty')
        this.withGoogleMyBusinessSubscription = this.subscription.attributes.features.includes('online-presence')
      }
    } else {
      // this.billingCycle = this.getAppQueryParams === 'month' ? true : false
      this.billingCycle = this.billng;

    }
  }



  private async getDevices() {
    await DeviceService.all().then((response: any) => {
      this.kioskCount = response.data?.meta?.pagination?.total
      this.loaded = true
    });
  }

  get defaultBillingCycle() {
    return this.billingCycle
  }

  set defaultBillingCycle(isMonthly: Boolean) {
    this.billingCycle = isMonthly
  }

  get defaultBranchCount() {
    return this.branchCount
  }

  set defaultBranchCount(count: any) {
    this.branchCount = count
    console.log(this.branchCount)
  }

  get defaultWithDeliverySystem() {
    return this.withDeliverySystem
  }

  set defaultWithDeliverySystem(isWithDelivery: Boolean) {
    this.withDeliverySystem = isWithDelivery
  }

  get defaultWithLoyaltySubscription() {
    return this.withLoyaltySubscription
  }

  set defaultWithLoyaltySubscription(isWithLoyalty: Boolean) {
    this.withLoyaltySubscription = isWithLoyalty
  }

  get defaultGoogleMyBusinessSubscription() {
    return this.withGoogleMyBusinessSubscription
  }

  set defaultGoogleMyBusinessSubscription(isWithGoogle: Boolean) {
    this.withGoogleMyBusinessSubscription = isWithGoogle
  }

  get isDefaultWithDeliverySystem() {
    if(this.subscription) {
      if(this.subscription.hasOwnProperty('attributes')) {
        if(this.subscription.attributes.features.includes('delivery-system') && this.withDeliverySystem) {
          return true
        } else if(!this.subscription.attributes.features.includes('delivery-system') && !this.withDeliverySystem) {
          return true
        }
        return false
      }
    }
    return false
  }

  get isDefaultWithLoyaltySubscription() {
    if(this.subscription) {
      if(this.subscription.hasOwnProperty('attributes')) {
        if(this.subscription.attributes.features.includes('loyalty') && this.withLoyaltySubscription) {
          return true
        } else if(!this.subscription.attributes.features.includes('loyalty') && !this.withLoyaltySubscription) {
          return true
        }
        return false
      }
    }
    return false
  }

    get isDefaultWithGoogleMyBusinessSubscription() {
    if(this.subscription) {
      if(this.subscription.hasOwnProperty('attributes')) {
        if(this.subscription.attributes.features.includes('online-presence') && this.withGoogleMyBusinessSubscription) {
          return true
        } else if(!this.subscription.attributes.features.includes('online-presence') && !this.withGoogleMyBusinessSubscription) {
          return true
        }
        return false
      }
    }
    return false
  }

  get isDefaultBillingCycle() {
    if(this.subscription) {
      if(this.subscription.hasOwnProperty('attributes')) {
        console.log("is default mbill cycle", this.subscription.attributes['billing-cycle'] === 'month', this.defaultBillingCycle)
        if(this.subscription.attributes['billing-cycle'] === 'month' && this.defaultBillingCycle) {
          return true
        } else if(this.subscription.attributes['billing-cycle'] === 'year' && !this.defaultBillingCycle) {
          return true
        }
        return false
      }
    }
    return false
  }

  get isDefaultBranchCount() {
    if(this.subscription) {
      if(this.subscription.hasOwnProperty('attributes')) {
        if(this.subscription.attributes['branch-quantity'] === Number(this.branchCount)) {
          return true
        }
        return false
      }
    }
    return false
  }

  private async initStripe() {
    let pubKey: any = process.env.VUE_APP_STRIPE_KEY
    this.stripe = await loadStripe(pubKey);
    if (!this.subscription) {
      const elements = this.stripe.elements();
      this.cardElement = elements.create('card', {hidePostalCode: true});
      this.cardElement.mount('#card-element');
    }
  }

  private async subscribe(e: any) {
    let defaultText = e.target.innerHTML
    e.target.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'
    e.target.disabled = true

    if(!this.upcomingSubscription.hasOwnProperty('attributes')) {
      e.target.innerHTML = defaultText
      e.target.disabled = false
      return this.$notify({
        title: "INFO",
        verticalAlign: "top",
        horizontalAlign: "right",
        message: "No plan selected!",
        type: "danger",
        icon: "fas fa-spinner fa-spin",
      });
    }


    if (!this.subscription) {
      const {setupIntent, error} = await this.setupCard();
      if (error) {
        e.target.innerHTML = defaultText
        e.target.disabled = false
        return this.$notify({
          // title: `${'INFO: '} ${error.code.replace(/_/g, ' ').split(' ').map( (w: any) =>  w.substring(0,1).toUpperCase()+ w.substring(1)).join(' ')}`,
          title: error.code.toUpperCase().replace(/_/g, ' '),
          verticalAlign: "top",
          horizontalAlign: "right",
          message: `${this.translate('Please try another card.')} ${error.message}`,
          type: "danger",
          icon: "fas fa-spinner fa-spin",
        });
      } else {
        this.paymentMethod = setupIntent.payment_method;
      }
    }

    let feature = {
      'features': [
          `kiosk:${this.kioskCount}`
        ]
    }

    if(this.withDeliverySystem) {
      feature['features'].push('delivery-system')
    }
    if(this.withLoyaltySubscription) {
      feature['features'].push('loyalty')
    }
    if(this.withGoogleMyBusinessSubscription) {
      feature['features'].push('online-presence')
    }

    let payload: any = {
      plan: this.planId,
      quantity: Number(this.branchCount),
      'billing-cycle': !this.billng ? 'month' : 'year',
      ...feature,
      coupon: this.couponCode
    }

    if(this.paymentMethod) {
      payload['payment-method'] = this.paymentMethod;
    }

    Subscription.subscribe(payload).then((response: any) => {
      this.setSubscription(response.data.data)
      this.$notify({
        title: "INFO",
        verticalAlign: "top",
        horizontalAlign: "right",
        message: `Your are now subscribed to ${response.data.data.attributes.name} plan!`,
        type: "success",
        icon: "fas fa-spinner fa-spin",
      });
      this.getAccountConcept()
        .then(res => {
          this.setConceptSettings(res.data.data)
          this.getApps()
          this.$router.push({ name: 'dashboard', query: { subscribed: 'true' } });
        })
      // this.$router.push({name: 'dashboard'})
      e.target.innerHTML = defaultText
      e.target.disabled = false
    }).catch(err => {
      e.target.innerHTML = defaultText
      e.target.disabled = false
      console.log(err.response.data, err.response.data.error[0].code)
      if (err.response.data.error[0].code === 1122) {
        this.setSubscriptionPaymentId(err.response.data.messages.id)
        this.$router.push({name: 'stripePayment', params: { id: err.response.data.messages.id }})
      } else {
        this.$notify({
          title: "INFO",
          verticalAlign: "top",
          horizontalAlign: "right",
          message: err.response.data.error[0].detail,
          type: "danger",
          icon: "fas fa-spinner fa-spin",
        });
      }
    })

  }


  getApps () {
    Menu.all().then((response: any) => {
      this.setMenus(response.data.data)
    }).catch((err: any) => {
      console.log(err)
    })
  }

  private async setupCard() {
    return await this.stripe.confirmCardSetup(this.paymentIntent.attributes['client-secret'], {
        payment_method: {
          card: this.cardElement,
          billing_details: {name: this.cardHolder}
        }
      }
    )
  }


  private getPaymentIntent() {
    Subscription.getPaymentIntent().then((response: any) => {
      this.paymentIntent = response.data.data
      console.log(this.paymentIntent)
    })
  }

  private getInvoice() {
    console.log("payment bill cycle", this.billingCycle);
    this.isLoading = true
    console.log("payment planId route", this.$route.params.planId); 
    console.log("payment planId", this.planId); 
    console.log("query billing Cycle", this.$route.query['billing-cycle']);
    console.log("branch count", this.branchCount); 

    if( (this.planId || (this.$route.params.planId && this.$route.query['billing-cycle']) ) && (this.branchCount ?? this.getSubscription.attributes['branch-quantity'])) {

      // let feature = {}

      // if(this.withDeliverySystem) {
      //   feature = {
      //     'features[0]': ['delivery-system'],
      //     'features[1]': [`kiosk:${this.kioskCount}`]
      //   }
      // } else {
      //   feature = {
      //     'features[0]': [`kiosk:${this.kioskCount}`]
      //   }
      // }

      let feature = {
        'features[0]': [`kiosk:${this.kioskCount}`]
      }

      if(this.withDeliverySystem && this.withLoyaltySubscription && this.withGoogleMyBusinessSubscription) {
        /* @ts-ignore */
        feature['features[1]'] = ['delivery-system']
        /* @ts-ignore */
        feature['features[2]'] = ['loyalty']
      } else if (this.withDeliverySystem) {
        /* @ts-ignore */
        feature['features[1]'] = ['delivery-system']
      } else if (this.withLoyaltySubscription) {
        /* @ts-ignore */
        feature['features[1]'] = ['loyalty']
      } else if (this.withGoogleMyBusinessSubscription) {
         /* @ts-ignore */
         feature['features[1]'] = ['online-presence']
      }
      console.log('feature', feature)


      // if(this.subscription) {
      //   if(this.subscription.hasOwnProperty('attributes')) {
      //     this.billingCycle = this.subscription.attributes['billing-cycle'] === 'month' ? false : true
      //   }
      // } else {
      //   this.billingCycle = this.billng;
      // }
      if(!this.flagOpenModal) {
        if(this.$route.query['billing-cycle'] != undefined) {
          this.billingCycle = this.$route.query['billing-cycle'];
        }
      }

      let queryParams: Object = {
        plan: this.planId, 
        quantity: this.branchCount ?? this.getSubscription.attributes['branch-quantity'],
        'billing-cycle': this.billingCycle == true || this.billingCycle == 'year' ? 'year' : 'month',
        ...feature,
        coupon: this.couponCode
      }

      Subscription.getUpcomingInvoice(queryParams).then(response => {
        this.upcomingSubscription = response.data.data;
        console.log("upComing Subscription", this.upcomingSubscription);
        this.isLoading = false
      }).catch((err: any) => {
        this.isLoading = false
        const errors: any = [];

        if (err.response.data
          && err.response.data.error
        ) {
          err.response.data.error.forEach((e: any) => {
            errors.push(e.detail);
          })
        }


        this.$notify({
          title: 'An error occurred',
          verticalAlign: "top",
          horizontalAlign: "right",
          message: errors.length && errors.join('\n') || '',
          type: "danger",
          icon: "fas fa-spinner fa-spin",
        });
      })
    } else {
      this.upcomingSubscription = {}
      this.isLoading = false
    }
  }

  private async getAccountConcept() {
    return await ConceptApi.get(this.activeConcept.id)
  }
}
