
import _ from "lodash";
import {
  Component,
  Watch,
  Prop,
  PropSync,
  Mixins
} from "vue-property-decorator";
import { Autocomplete, Form, Select, Option } from "element-ui";
import Translations from "@/mixins/translations";
import {
  Connect as ConnectService,
  Location as LocationService
} from "@/services/SOLO";
import { Location as LocationModel, Item as ItemModel } from "@/models";
import { AxiosError } from "axios";
import { debounce } from "vue-debounce";

@Component<ConnectionCustomer>({
  components: {
    [Form.name]: Form,
    [Select.name]: Select,
    [Option.name]: Option,
    [Autocomplete.name]: Autocomplete
  }
})
export default class ConnectionCustomer extends Mixins(Translations) {
  @PropSync("connection", { type: Object, default: null })
  syncedConnection!: any;

  searchedCustomers = [];
  oldQuery: string = "";
  customer = null;
  selectedCustomer: any = null;

  saving: boolean = false;
  isOpen: boolean = false;

  form: any = {
    "first-name": '',
    "last-name": '',
    mobile: '',
  };

  onFetch2(queryString: string, cb: Function) {
    console.log("queryString: ", queryString);
    const filters: any = [];

    if (!queryString || (queryString && queryString.length < 3)) {
      return;
    }

    if (this.oldQuery == queryString) {
      cb(this.searchedCustomers);
      return;
    }
    this.oldQuery = queryString;
    console.log("oldQuery", this.oldQuery);

    if (!isNaN(parseInt(this.oldQuery))) {
      filters["mobile"] = this.oldQuery;
      filters["customer-id"] = this.oldQuery;
    } else if (this.oldQuery.includes("@")) {
      filters["email"] = this.oldQuery;
    } else {
      filters["customer"] = this.oldQuery;
    }

    debounce(() => {
      ConnectService.searchByCustomers(filters)
        .then(response => {
          this.searchedCustomers = response?.data?.data.map((customer: any) => {
            return {
              value: `${customer.attributes["first-name"]} ${
                customer.attributes["last-name"]
              } - ${customer.attributes["email"]} - ${
                customer.attributes["mobile"] == null
                  ? "No phone number"
                  : customer.attributes["mobile"]
              }`,
              id: customer.id
            };
          });
          cb(this.searchedCustomers);
        })
        .catch((error: AxiosError) => {
          this.$notify({
            title: "System Error",
            verticalAlign: "bottom",
            horizontalAlign: "left",
            type: "error",
            message: error?.response?.data?.message || "Something went wrong!"
          } as any);
          return [];
        });
    }, 700)();
  }

  async handleSelect(customer: any) {
    // try {
    // let { data } = await ConnectService.getCustomerDetails(parseInt(customer.id));

    // if (this.syncedConnection.attributes.customer) {
    //   this.syncedConnection.attributes.customer.id = data.data.id;
    //   this.syncedConnection.attributes.customer["first-name"] = data.data.attributes["first-name"];
    //   this.syncedConnection.attributes.customer["last-name"] = data.data.attributes["last-name"];
    //   this.syncedConnection.attributes.customer.mobile = data.data.attributes.mobile;
    // } else {
    //   this.syncedConnection.attributes.customer = {
    //     id: data.data.id,
    //     "first-name": data.data.attributes["first-name"],
    //     "last-name": data.data.attributes["last-name"],
    //     mobile: data.data.attributes.mobile
    //   }
    // }
    // } catch (e) {
    //   console.log(e);
    // }

    this.selectedCustomer = customer.id;
  }

  async updateConnection() {
    this.saving = true;

    try {
      let { data } = await ConnectService.updateConnection(
        this.syncedConnection.id,
        {
          "customer-id": parseInt(this.selectedCustomer)
        }
      );

      this.syncedConnection.attributes.customer = data.data.attributes.customer;

      this.$notify({
        title: `UPDATE DATA`,
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: `Connection data was updated successfully!`,
        type: "success"
      } as any);

      this.selectedCustomer = null;
      this.customer = null;
    } catch (e) {
      console.log(e);
      this.$notify({
        title: `UPDATE DATA`,
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: (e as any).response.data.error[0].detail,
        type: "danger"
      } as any);
    } finally {
      this.saving = false;
    }
  }

  newDummyCustomer() {
    this.isOpen = true;
  }

  async onSave() {
    this.saving = true;

    try {
      let { data } = await ConnectService.createCustomer({
        ...this.form,
        "account-type": 'guest',
        'username': this.form.mobile,
      });

      this.$notify({
        title: `CUSTOMER CREATED`,
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: `Customer data was created successfully!`,
        type: "success"
      } as any);

      this.closeModal();
      this.resetForm();
    } catch (e) {
      console.log(e);
      this.$notify({
        title: `CUSTOMER ERROR`,
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: (e as any).response.data.error[0].detail,
        type: "danger"
      } as any);
    } finally {
      this.saving = false;
    }
  }

  resetForm() {
    this.form["first-name"] = '';
    this.form["last-name"] = '';
    this.form.mobile = '';
  }

  closeModal() {
    this.isOpen = false;
  }
}
