
import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import { translations } from '@/mixins'
import Modal from '@/components/Modal.vue'
import { mapGetters, mapMutations } from 'vuex'
import Translations from "../../../Translations.vue"
import { ToggleButton } from 'vue-js-toggle-button'
import { Concept as ConceptApi } from '@/services/SOLO'
Vue.component('ToggleButton', ToggleButton)

@Component({
  components: { Modal, Translations },
  computed: {
    ...mapGetters({
      activeConcept: 'account/activeConcept',
      getSubscription: 'account/getSubscription',
    })
  },
  mixins: [translations],
  methods: {
    ...mapMutations({
      setSubscription: 'app/setSubscription',
      setQueryParams: 'app/setQueryParams',
      setConceptSettings: 'account/setConceptSettings',
    })
  }
})
export default class billingCyleModal extends Vue {
  
  @Prop() private open!: Boolean
  @Prop() private planId!: number
  @Prop() private plans!: Array<any>
  @Prop() private billng!: any
  @Prop() private flagOpenModal: any

  private activeConcept: any
  private setSubscription!: Function
  private setQueryParams!: Function
  private setConceptSettings!: Function
  private getSubscription!: any
  toggle: Boolean = false;
  firstLoad: Boolean = true
  // computed() {
  //     if(this.getSubscription) {
  //       if(this.getSubscription.hasOwnProperty('attributes')) {
  //         this.toggle = this.getSubscription.attributes['billing-cycle'] === 'month' ? false : true
  //       }
  //     }
  //     if(this.flagOpenModal) {
  //       console.log("from bill", this.flagOpenModal);
  //       alert(this.flagOpenModal); return;
  //       if(this.$route.query['billing-cycle'] != undefined) {
  //         this.toggle = this.$route.query['billing-cycle'] == 'year' ? true : false;
  //       }
  //     }

  // }

    @Watch('firstLoad', { immediate: true, deep: true })
    onDeliverySystemChange(newVal: any) {
      if(this.getSubscription) {
        if(this.getSubscription.hasOwnProperty('attributes')) {
          this.toggle = this.getSubscription.attributes['billing-cycle'] == 'year' ? true : false;
        }
      } else {
          this.toggle = this.$route.query['billing-cycle'] == 'year' ? true : false;

      }


    }

  get isOpen() {
    return this.open
  }

  get currentPlanId() {
    return this.planId - 1
  }

  get getFilteredPlans() {
    return this.plans;
   
  }

  set isOpen(newVal: Boolean) {       
    this.$emit('modal:update', newVal)
  }

  private setPlanId(planId: number, billingCycle: any) {
    console.log("check planID", planId);

    this.$emit('plan:update', planId, billingCycle)
    this.$emit('modal:update', false)
  }

  close() {
    this.$emit('plan:update', this.planId, this.toggle)
    this.$emit('modal:update', false)
  }
  private upgrade(cycle: string) {    
    this.setSubscription(this.planId)
    this.setQueryParams(cycle)    
    this.$router.push({name: 'customer-subscription'})
  }

  private updateConcept() {
    ConceptApi.updateConcept({'is-free-package': true}, this.activeConcept.id)
    .then(res => {
      this.setConceptSettings(res.data.data)
      setTimeout(() => {
        this.$router.push({ name: 'list' });
      }, 1000)
    })
  }
}
