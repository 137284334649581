
import CustomerCollapse from "./components/CustomerCollapse.vue"
import CustomerList from "./components/CustomerList.vue"
import Events from "./components/Events.vue"
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { Component, Vue } from "vue-property-decorator"
import {Customer as CustomerApi} from '@/services/SOLO'
import Translations from './components/Translations.vue'
import { translations } from '@/mixins'

@Component({
  components: {
    RouteBreadCrumb,
    CustomerList,
    CustomerCollapse,
    Events
  },
  mixins: [translations]

})

export default class Customers extends Vue {
public translate!: Function

  $notify: any
  tab: any = 1

  exportAllCustomer() {


      CustomerApi.exportAllCustomer()
          .then(response => {
            // window.open(response.data.data.attributes['csv-uri'], '_blank');
            console.log('check message',response.data.message);
                  this.$notify({
                    title: "PLEASE WAIT!",
                    horizontalAlign: "right",
                    message: response.data.message,
                    type: "success",
                    icon: "fas fa-spinner fa-spin",
                  })

          }) 

    

  }

  get dropDownText(): string {
    if (this.tab === 1) {
      return this.translate('Customers')
    } else {
      return this.translate('Events')
    }                   
  }


}
