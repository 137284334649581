
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { translations } from "@/mixins";
@Component({
  mixins: [translations]
})
export default class PromotionsHeader extends Vue {
  translate!: Function;

  redirectToAddPromo() {
    this.$router.push({
      name: "PromotionsCreate"
    });
  }
}
