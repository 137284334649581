
import moment from "moment";
import { mapGetters } from "vuex";
import { Select, Option, Form, FormItem } from "element-ui";
import { translations, eventHandler } from "@/mixins";
import StoreTimes from "../components/StoreTimes.vue";
// import StoreTimes from "@/views/Admin/pages/Locations/components/StoreTimes.vue";
import Translations from "../components/Translations.vue";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import {
  Subscription as SubscriptionApi,
  Menu,
  Category,
  Promotion,
  Item,
  Location,
  Integrations
} from "@/services/SOLO";
import { mixins } from "vue-class-component";
// import BaseInput from "@/components/Inputs/BaseInput.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

@Component({
  components: {
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    Translations,
    StoreTimes,
    // BaseInput,
    ValidationProvider
  },
  filters: {
    formatDate(date: string) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("D/MM/YYYY");
    }
  },
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
      getUser: "account/getUser",
      getLocale: "app/getLocale",
      getSubscription: "account/getSubscription"
    })
  },
  mixins: [translations, eventHandler]
})
export default class PromotionsCreate extends Vue {

  private selected: number = 1;
  private scopeSelected: number = 1;
  
  translate!: Function;
  hasIntegration: any = null;
  getLocale!: any;
  getSubscription!: any;
  isSubmitting: boolean = false;
  isShow: boolean = false;
  totalCheckOrder: any;
  totalCheckCustomer: any;
  totalCheckType: any;
  $notify: any;
  loading: boolean = false;

  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
    file: any;
  };

  redemption_customer: any;
  redemption_global: any;

  item: any = {
    menu: null,
    category: null,
    itemId: null
  };

  eligibilityItems: any = [];
  totalMenuCategories: any = [];
  
  qualifyingItems: any = [];
  showQualifyingItems: boolean = false;

  minimumCartAmount: any = '0';

  posDiscount: string = "";

  appTypes: any = {
    web: {
      value: "web",
      model: false
    },
    app: {
      value: "app",
      model: false
    },
    kiosk: {
      value: "kiosk",
      model: false
    },
    all: {
      value: "all",
      model: false
    }
  }

  orderTypes: any = {
    delivery: {
      value: "deliver",
      model: false
    },
    eatIn: {
      value: "eat-in",
      model: false
    },
    toGo: {
      value: "to-go",
      model: false
    },
    pickup: {
      value: "pickup",
      model: false
    },
    curbside: {
      value: "curbside",
      model: false
    },
    all: {
      value: "all",
      model: false
    }
  };

  accountTypes: any = {
    full: {
      value: "full",
      model: false
    },
    guest: {
      value: "guest",
      model: false
    },
    new: {
      value: "new",
      model: false
    },
    returning: {
      value: "returning",
      model: false
    },
    all: {
      value: "all",
      model: false
    }
  };

  openingHours: any = [
    {
      open: "12:00 AM",
      closed: "11:59 PM",
      day: "sun",
      selected: false
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM",
      day: "mon",
      selected: false
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM",
      day: "tue",
      selected: false
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM",
      day: "wed",
      selected: false
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM",
      day: "thu",
      selected: false
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM",
      day: "fri",
      selected: false
    },
    {
      open: "12:00 AM",
      closed: "11:59 PM",
      day: "sat",
      selected: false
    }
  ];

  dataForm: any = {
    type: "discount",
    name: {
      "en-us": "",
      "ar-sa": ""
    },
    duration: 1,
    "item-id": null,
    "item-code": null,
    "item-quantity": 0,
    "item-price": 0,
    "discount-type": "fixed",
    "discount-code": null,
    "discount-value": null,
    "discount-max": '',
    "subscription-amount": 0,
    "redemption-limit-global": 100000,
    "redemption-limit-customer": 100,
    "eligibility-rules": null,
    qualification: "order",
    "availability-rules": null,
    "cart-rules": null,
    "segment-id": null,
  };
  menus: any = [];
  categories: any = [];
  posDiscounts: any = [];
  items: any = [];
  menuId: string | number = "";
  categoryId: string | number = "";
  matchDay = [
    {
      idx: 0,
      day: "sun"
    },
    {
      idx: 1,
      day: "mon"
    },
    {
      idx: 2,
      day: "tue"
    },
    {
      idx: 3,
      day: "wed"
    },
    {
      idx: 4,
      day: "thu"
    },
    {
      idx: 5,
      day: "fri"
    },
    {
      idx: 6,
      day: "sat"
    }
  ];
  public enable247: boolean = true;
  public isLoadingQualifying: boolean = false;
  public advancedSettings: boolean = false;
  public getConceptSettings!: any;

  countCheckedApps: number = 0;
  countCheckedOrderTypes: number = 0;
  countCheckedCustomerTypes: number = 0;

  locations: any = [];
  segments: any = [];
  selectedLocations: any = [];
  selectedAllLocations: boolean = true;

  selectedSegment: any = null
  discountAttributes: any = null
  onChangeCategory: boolean = false;

  loadingGettingCategoriesByMenu: boolean = false
  loadingGettingItemsByCateogory: boolean = false

  loadingGettingCategoriesByMenuQualfication: boolean = false
  loadingGettingItemsByCategoryQualfication: boolean = false

  qualificationLineMenuArr: any = []
  qualificationLineCategoriesArr: any = []
  qualificationLineItemsArr: any = []
  qualifyingItemsArr: any = []

  @Watch('qualifyingItemsArr', { deep: true })
  onChangeQualifyingItemsArr(newVal: any) {
    console.log('onChangeQualifyingItemsArr', newVal)
    this.getMenusForQualification()
  }

  /** App Types */
  @Watch('appTypes.web', { deep: true })
  onChangesWeb(newVal: any) {
    this.checkAllApps()
  }

  @Watch('appTypes.kiosk', { deep: true })
  onChangesKiosk(newVal: any) {
    this.checkAllApps()
  }

  @Watch('appTypes.app', { deep: true })
  onChangesApp(newVal: any) {
    this.checkAllApps()
  }
  /** App Types */

  /** Order Types */
  @Watch('orderTypes.delivery', { deep: true })
  onChangeDeliveryOrderType(newVal: any) {
    this.checkAllOrderTypes()
  }

  @Watch('orderTypes.eatIn', { deep: true })
  onChangeEatInOrderType(newVal: any) {
    this.checkAllOrderTypes()
  }

  @Watch('orderTypes.toGo', { deep: true })
  onChangeToGoOrderType(newVal: any) {
    this.checkAllOrderTypes()
  }

  @Watch('orderTypes.pickup', { deep: true })
  onChangePickupOrderType(newVal: any) {
    this.checkAllOrderTypes()
  }

  @Watch('orderTypes.curbside', { deep: true })
  onChangeCurbsideOrderType(newVal: any) {
    this.checkAllOrderTypes()
  }
  /** Order Types */

  /** Customer/Account Types */
  @Watch('accountTypes.full', { deep: true })
  onChangeFullCustomerType(newVal: any) {
    this.checkAllCustomerTypes()
  }

  @Watch('accountTypes.guest', { deep: true })
  onChangeGuestCustomerType(newVal: any) {
    this.checkAllCustomerTypes()
  }

  @Watch('accountTypes.new', { deep: true })
  onChangeNewCustomerType(newVal: any) {
    this.checkAllCustomerTypes()
  }

  @Watch('accountTypes.returning', { deep: true })
  onChangeReturningCustomerType(newVal: any) {
    this.checkAllCustomerTypes()
  }
  /** Customer/Account Types */

  @Watch("item.itemId", { deep: true })
  onItemId(newVal: boolean) {
    this.dataForm['item-id'] = newVal;
  }

  @Watch("advancedSettings", { deep: true })
  onChangeAdvancedSettings(newVal: boolean) {
    if (!newVal) {
      this.dataForm['redemption-limit-global'] = 100000;
      this.dataForm['redemption-limit-customer'] = 100;
      this.dataForm['cart-rules'] = null;
      this.dataForm['availability-rules'] = null;
      this.dataForm['eligibility-rules'] = null;
    } else {
      this.dataForm['redemption-limit-global'] = this.redemption_global;
      this.dataForm['redemption-limit-customer'] = this.redemption_customer;
      this.dataForm['cart-rules'] = {
        items: {},
        categories: {},
        menu: {},
        total: '',
        // "payment_method": '',
        "order-type": '',
      };
      this.dataForm['availability-rules'] = {
        schedule: {
          mon: {
            from: "0000",
            to: "2359",
            nextDay: false
          },
          tue: {
            from: "0000",
            to: "2359",
            nextDay: false
          },
          wed: {
            from: "0000",
            to: "2359",
            nextDay: false
          },
          thu: {
            from: "0000",
            to: "2359",
            nextDay: false
          },
          fri: {
            from: "0000",
            to: "2359",
            nextDay: false
          },
          sat: {
            from: "0000",
            to: "2359",
            nextDay: false
          },
          sun: {
            from: "0000",
            to: "2359",
            nextDay: false
          }
        },
      };
      this.dataForm['eligibility-rules'] = {
        "account-type": '',
        type: ''
      };
    }
  }

  @Watch("dataForm.type", { immediate: true, deep: true })
  onChangeDataFormType(newVal: any) {
    console.log("ON CHANGE DATA FORM TYPE --- MENU", this.menus)
    console.log("ON CHANGE DATA FORM TYPE --- CATEGORIES", this.categories)
    console.log("ON CHANGE DATA FORM TYPE --- ITEMS", this.items)
  }

  @Watch("selectedLocations", { immediate: false })
  onChangeSelectedLocations(newVal: any) {
    if (newVal && newVal.length === this.locations.length) {
      this.selectedAllLocations = true
    } else {
      this.selectedAllLocations = false
    }
  }

  @Watch("selectedAllLocations", { immediate: false })
  onChangeSelectedAllLocations(newVal: any) {
    if (newVal) {
      this.selectedLocations = this.locations.map((location: any) => location.id)
    }
  }

  @Watch("locations", { immediate: true })
  onChangeLocations(newVal: any) {
    if (this.selectedLocations?.length === newVal?.length) {
      this.selectedAllLocations = true
    }
    if (newVal.length && this.selectedAllLocations) {
      this.selectedLocations = newVal.map((location: any) => location.id)
    } 
  }

  created() {
    this.getMenusForQualification()
    this.getMenus()
    this.getBranchLocations(1)
    this.getSegments()
    this.checkIntegrationIfExists()
    this.getPOSDiscounts();

    if (this.$route.params.id) {
      Promotion.getPromotion(this.$route.params.id).then(async (response) => {
        const data = response.data.data;
        this.menuId = data.attributes['cart-rules']?.menu ? Object.keys(response.data.data.attributes['cart-rules']?.menu)[0] : 0;

        this.redemption_customer = data.attributes['redemption-limit-customer'];
        this.redemption_global = data.attributes['redemption-limit-global'];

        // this.dataForm.name['en-us'] = data.attributes['name']
        if (
          data.attributes
          && data.attributes.type === 'item'
        ) {
          Item.getItem(data.attributes['item-id'])
            .then((response) => {
              const dt = response.data.data;
              dt.id = Number(dt.id);
              this.items.push(dt);
              this.item.itemId = data.attributes['item-id'];
              this.dataForm['item-id'] = data.attributes['item-id'];
              this.dataForm['item-code'] = data.attributes['item-code'];
              this.dataForm['item-quantity'] = data.attributes['item-quantity'];
              this.dataForm['item-price'] = data.attributes['item-price'];
            });

            this.item.menu = response.data.included[0].attributes.menu_id;
            this.getCategoriesByMenu(this.item.menu);
            this.item.category = response.data.included[1].attributes['category-id'];

        }

        if (
          data.attributes
          && (data.attributes['cart-rules']
          // || data.attributes['eligibility-rules']
          || data.attributes['availability-rules'])
        ) {
          this.advancedSettings = true;
        }

        if (
          data.attributes
          && data.attributes['cart-rules']
          && data.attributes['cart-rules'].categories
        ) {
          const catPromiseArray: any = [];
          const itmPromiseArray: any = [];
          const { menu } = data.attributes['cart-rules'];
          const { categories } = data.attributes['cart-rules'];
          const { items } = data.attributes['cart-rules'];

          for (const id in menu) {
            await this.getCategoriesByMenu(id);
          }

          for (const id in items) {
            itmPromiseArray.push(!id.includes('null') ? Item.getItem(id) : items[id]);
          }

          if (itmPromiseArray.length) {
            Promise.all(itmPromiseArray)
              .then((res: any) => {
                res.forEach((item: any) => {
                  if (item.hasOwnProperty('quantity')) {
                    this.eligibilityItems.push({
                      menu: item.menuId + '',
                      categories: item.categoryId + '',
                      category: item.categoryId + '',
                      item: null,
                      quantity: item.quantity.substring(4),
                    });
                  } else {
                    const links = item.data.data.links.self;
                    const menuId = links.split('/menus/')[1].split('/')[0];
                    const categoryId = links.split('/categories/')[1].split('/')[0];
                    this.items.push(item.data.data);
                    this.eligibilityItems.push({
                      menu: menuId,
                      categories: categoryId,
                      category: categoryId,
                      item: item.data.data.id,
                      quantity: (items[item.data.data.id].quantity).slice(4),
                    });
                  }
                  this.isShow = true;
                });
              });
          }
        }

        if (data.attributes && data.attributes['cart-rules']) {
          if (data.attributes['cart-rules'].location) {
            let locationId = data.attributes['cart-rules'].location.split(":")
            if (locationId.length > 1) {
              this.selectedLocations = locationId[1].split(",")
              this.selectedAllLocations = false
            } 
          } else {
            this.selectedAllLocations = true
          }
        }

        if (data.attributes && data.attributes['cart-rules']) {
          const total = data.attributes['cart-rules']['total'];
          const orderType = data.attributes['cart-rules']['order-type'];
          // const paymentMethod = data.attributes['cart-rules']['payment_method'];
          let s = JSON.stringify(total);
          let p = JSON.parse(s);

          this.minimumCartAmount = p.slice(4); //get total only

          if (orderType) {
            // if (orderType === "in:") {
            //   this.orderTypesChecker(false)
            // }
            // if (orderType.includes('all')) {
            //   this.orderTypesChecker(true)
            // }
            // if (!orderType.includes('all') && orderType !== "in:") {
              this.orderTypes.delivery.model = orderType && orderType.includes('deliver');
              this.orderTypes.eatIn.model = orderType && orderType.includes('eat-in');
              this.orderTypes.toGo.model = orderType && orderType.includes('to-go');
              this.orderTypes.pickup.model = orderType && orderType.includes('pickup');
              this.orderTypes.curbside.model = orderType && orderType.includes('curbside');
              // this.orderTypes.all.model = orderType.includes('all');
              if (orderType.includes('deliver') && orderType.includes('eat-in') && orderType.includes('to-go') && orderType.includes('pickup') && orderType.includes('curbside')) {
                this.orderTypes.all.model = true;
              }
              if (!orderType.includes('deliver') && !orderType.includes('eat-in') && !orderType.includes('to-go') && !orderType.includes('pickup') && !orderType.includes('curbside')) {
                this.orderTypesChecker(true)
              }
            // }
          } else {
            this.orderTypesChecker(true)
          }
        }
        
        if (data.attributes && data.attributes['eligibility-rules']) {
          const account_type = data.attributes['eligibility-rules']['account-type'];
          const type = data.attributes['eligibility-rules']['type'];
          const app_type = data.attributes['eligibility-rules']['app'];
          if (app_type) {
            // if (app_type === "in:") {
            //   this.appsChecker(false)
            // }
            // if (app_type.includes('all')) {
            //   this.appsChecker(true)
            // }
            // if (!app_type.includes('all') && app_type !== "in:") {
              this.appTypes.web.model = app_type && app_type.includes('web');
              this.appTypes.app.model = app_type && app_type.includes('app');
              this.appTypes.kiosk.model = app_type && app_type.includes('kiosk');
              // this.appTypes.all.model = app_type.includes('all');
              if (app_type.includes('web') && app_type.includes('app') && app_type.includes('kiosk')) {
                this.appTypes.all.model = true;
              }
              if (!app_type.includes('web') && !app_type.includes('app') && !app_type.includes('kiosk')) {
                this.appsChecker(true)
              }
            // }
          } else {
            this.appsChecker(true)
          }

          if (account_type || type) {
          //   if (account_type === "in:" || type === "in:") {
          //     this.customerTypesChecker(false)
          //   }
          //   if (account_type.includes('all') || type.includes('all')) {
          //     this.customerTypesChecker(true)
          //   }
            // if ((!account_type.includes('all') && account_type !== "in:") && (!type.includes('all') && type !== "in:")) {
              this.accountTypes.full.model = account_type && account_type.includes('full');
              this.accountTypes.guest.model = account_type && account_type.includes('guest');
              this.accountTypes.new.model = type && type.includes('new');
              this.accountTypes.returning.model = type && type.includes('returning');
              // this.accountTypes.all.model = account_type.includes('all');
              if (account_type.includes('full') && account_type.includes('guest') && type.includes('new') && type.includes('returning')) {
                this.accountTypes.all.model = true;
              }
              if (!account_type.includes('full') && !account_type.includes('guest') && !type.includes('new') && !type.includes('returning')) {
                this.customerTypesChecker(true)
              }
            // } else {
            //   this.customerTypesChecker(true)
            // }
          }

          /** for types */
          // this.accountTypes.new.model = type ? type.includes('new') : false;
          // this.accountTypes.returning.model = type ? type.includes('returning') : false;
        }

        if (data.attributes && data.attributes.items) {
          const { items } = data.attributes
          const itemsPromiseArr: any = []

          items.forEach(async (val : any) => {
            await this.getCategoriesByMenu(val.menu);
          });

          items.forEach(async (val : any) => {
            await itemsPromiseArr.push(Item.getItem(val.item));
          });

          if (itemsPromiseArr.length) {
            Promise.all(itemsPromiseArr)
              .then((i: any) => {
                i.forEach((itm: any) => {
                  const links = itm.data.data.links.self;
                  const menuId = links.split('/menus/')[1].split('/')[0];
                  const categoryId = links.split('/categories/')[1].split('/')[0];
                  this.items.push(itm.data.data);
                  this.qualifyingItems.push({
                    menu: +menuId,
                    category: +categoryId,
                    item: +itm.data.data.id,
                    items: +itm.data.data.id,
                  });
                });
              });
          }
        } else {
          if (!['item', 'delivery'].includes(data.attributes.type)) {
            this.getQualifyingItems()
          }
        }
        this.loadPromotionFormData(data)
      });
    }
  }

  /** Locations */
  handleOnChangeAllLocations(event: any) {
    if (!event) {
      if (this.selectedLocations.length === this.locations.length) {
        this.selectedLocations = []
      }
    }
  }

  loadPromotionFormData(data:any) {
    if(data.attributes['availability-rules'] && this.advancedSettings) {
      this.enable247 = false;
      this.openingHours.forEach((openingHour:any) => {
        for (const [key, value] of Object.entries(data.attributes['availability-rules'].schedule)) {
          if (openingHour.day === key) {
            const { from, to }: any = value
            openingHour.open = from
            openingHour.closed = to
            openingHour.selected = true
          }
        }
      });
    }
    
    this.dataForm = {
      type: data.attributes.type,
      name: {
        'en-us': data.attributes.name,
      },
      duration: 1,
      "discount-type": data.attributes['discount-type'],
      "discount-code": data.attributes['discount-code'],
      "discount-value": data.attributes['discount-value'],
      "discount-max": data.attributes['discount-max'],
      "subscription-amount": data.attributes['subscription-amount'],
      "redemption-limit-global": data.attributes['redemption-limit-global'],
      "redemption-limit-customer": data.attributes['redemption-limit-customer'],
      "eligibility-rules": data.attributes['eligibility-rules'],
      "qualification": data.attributes['qualification'] || 'order',
      "cart-rules": data.attributes['cart-rules'],
      "item-price": data.attributes['item-price'],
      "item-quantity": data.attributes['item-quantity'],
      "segment-id": data.attributes['segment-id']
    };
    setTimeout(() => {
      this.dataForm['redemption-limit-global'] = data.attributes['redemption-limit-global'];
      this.dataForm['redemption-limit-customer'] = data.attributes['redemption-limit-customer'];
    }, 200)
  }

  /** Customer/Account Types */
  onInputAllCustomerTypes(value: any) {
    this.customerTypesChecker(value)
  }

  customerTypesChecker (value: boolean) {
    for (const [key] of Object.entries(this.accountTypes)) {
      this.accountTypes[key].model = value
    }
  }

  checkAllCustomerTypes() {
    this.countCheckedCustomerTypes = 0
    for (const [key] of Object.entries(this.accountTypes)) {
      if (key != 'all') {
        if (this.accountTypes[key].model) {
          this.countCheckedCustomerTypes++
        }
      }
    }
    Object.entries(this.accountTypes).length - 1 === this.countCheckedCustomerTypes && this.customerTypesChecker(true)
  }
  /** Customer/Account Types */

  /** Order Types */
  orderTypesChecker (value: boolean) {
    for (const [key] of Object.entries(this.orderTypes)) {
      this.orderTypes[key].model = value
    }
  }

  onInputAllOrderTypes(value: any) {
    this.orderTypesChecker(value)
  }

  checkAllOrderTypes() {
    this.countCheckedOrderTypes = 0
    for (const [key] of Object.entries(this.orderTypes)) {
      if (key !== 'all') {
        if (this.orderTypes[key].model) {
          this.countCheckedOrderTypes++
        }
      }
    }
    Object.entries(this.orderTypes).length - 1 === this.countCheckedOrderTypes && this.orderTypesChecker(true)
  }
  /** Order Types */

  /** App Types */
  appsChecker (value: boolean) {
    for (const [key] of Object.entries(this.appTypes)) {
      this.appTypes[key].model = value
    }
  }
  
  onInputAllApps(value: any) {
    this.appsChecker(value)
  }

  checkAllApps() {
    this.countCheckedApps = 0
    for (const [key] of Object.entries(this.appTypes)) {
      if (key !== 'all') {
        if (this.appTypes[key].model) {
          this.countCheckedApps++
        }
      }
    }
    Object.entries(this.appTypes).length - 1 === this.countCheckedApps && this.appsChecker(true)
  }
  /** App Types */

  async refreshPromotionsList(){
    try {
      this.loading = true
      const response = await Promotion.refreshPOSDiscounts();
      this.posDiscounts = response.data.data;
    } catch (error) {
      this.$notify({
        title: 'Refresh POS Discounts',
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message: (error as any).response.data.message || 'Error in refreshing promotion discount dropdown',
        type: 'warning',
        icon: 'fas fa-exclamation-triangle',
      })
    } finally {
      this.loading = false
    }
  }

  public get defaultPos(): string {
    return this.getConceptSettings?.attributes?.['default-pos'];
  }

  async getBranchLocations(page: 1){
    try {
      const lang = this.getLocale
      const response = await Location.fetchLocations(lang, page)
      this.locations = [...this.locations, ...response?.data?.data]

      if (
          response.data.meta.pagination.current_page <
          response.data.meta.pagination.total_pages
      ) {
        this.getBranchLocations(response.data.meta.pagination.current_page + 1);
      }
    } catch (error) {
      console.error('error', error)
    }
  }

  async getSegments(){
    try {
      const lang = this.getLocale
      const response = await Promotion.fetchSegments(lang)
      this.segments = response?.data?.data?.data;
    } catch (error) {
      console.error('error', error)
    }
  }
  
  async getMenus() {
    try {
      const menuResponse = await Menu.all();
      this.menus = [...menuResponse.data.data];
    } catch (error) {
      this.$notify({
        title: 'Fetch Menu',
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message: (error as any).response.data.message || 'An Error Occured in Fetching Menu',
        type: 'warning',
        icon: 'fas fa-exclamation-triangle',
      })
    }
  }

  async getCategoriesByMenu(menuId: string | number) {
    try {
      this.loadingGettingCategoriesByMenu = true
      const response = await Category.all(menuId, 1);
      this.categories = [...response.data.data];
    } catch (error) {
      this.$notify({
        title: 'Get Categories By Menu',
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message: (error as any).response.data.message || 'An Error Occured in Getting Categories By Menu',
        type: 'warning',
        icon: 'fas fa-exclamation-triangle',
      })
    } finally {
      this.loadingGettingCategoriesByMenu = false
    }
  }

  async getItemsByCategory(menuId: string | number, categoryId: string | number) {
    try {
      this.loadingGettingItemsByCateogory = true
      const response = await Category.items(menuId, categoryId, 1);
      this.onChangeCategory = true
      this.items = [...response.data.data];
    } catch (error) {
      this.$notify({
        title: 'Get Items By Category',
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message: (error as any).response.data.message || 'An Error Occured in Getting Items By Category',
        type: 'warning',
        icon: 'fas fa-exclamation-triangle',
      })
    } finally {
			this.loadingGettingItemsByCateogory = false
    }
  }

  async getPOSDiscounts() {
    try {
      const response = await Promotion.getPOSDiscounts();
      this.posDiscounts = response.data.data;
    } catch (error) {
      this.$notify({
        title: 'Get POS Discounts',
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message: (error as any).response.data.message || 'An Error Occured in Getting POS Discounts',
        type: 'warning',
        icon: 'fas fa-exclamation-triangle',
      })
    }
  }

  onMenuChange(id: string | number) {
    // console.log("check menu id", id);
    this.menuId = id;
    // empty categories and items list
    this.categories = [];
    this.items = [];
    // set null to category and item
    this.item.itemId = null;
    this.dataForm['item-id'] = null;
    this.item.category = null;
    this.getCategoriesByMenu(id);
  }

  onMenuQualificationChange(id: string|number, index: number) {
    this.menuId = id;
    this.getCategoriesForQualification(id, index);
  }

  onCategoryQualificationChange(id: string|number, index:any) {
    this.getItemsForQualification(this.menuId, id, index);
  }

  onMenuEligibilityChange(id: string|number) {
    this.menuId = id;
    this.getCategoriesByMenu(id);
  }

  onCategoryChange(id: string|number) {
    this.categoryId = id;
    // empty items list
    this.items = [];
    // set null to items
    this.item.itemId = null;
    this.dataForm['item-id'] = null;
    this.getItemsByCategory(this.menuId, id);
  }

  onCategoryEligibilityChange(id: string|number) {
    this.getItemsByCategory(this.menuId, id);
  }

  openAdvanceSettings() {
    if(this.advancedSettings == false) {
      this.advancedSettings = true;
    } else {
      this.advancedSettings = false;
    }
  }

  savePromo() {
    this.isSubmitting = true

    if (this.advancedSettings) {
      const hasSelectedOrderTypes = Object.entries(this.orderTypes).some(([key, value]: any) => { // return true if atleast one is selected, else false
        if (value.model) {
          return true
        }
      })

      if (!hasSelectedOrderTypes) {
        this.$notify({
          title: `ORDER TYPE ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please check at least one Order Type!',
          type: 'danger',
          icon: 'fas fa-bomb',
        })
        this.isSubmitting = false
        return false
      }

      const hasSelectedCustomerType = Object.entries(this.accountTypes).some(([key, value]: any) => { // return true if atleast one is selected, else false
        if (value.model) {
          return true
        }
      })

      if (!hasSelectedCustomerType) {
        this.$notify({
          title: `ORDER TYPE ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please check at least one Customer Type!',
          type: 'danger',
          icon: 'fas fa-bomb',
        })
        this.isSubmitting = false
        return false
      }

      const hasSelectedAppType = Object.entries(this.appTypes).some(([key, value]: any) => { // return true if atleast one is selected, else false
        if (value.model) {
          return true
        }
      })

      if (!hasSelectedAppType) {
        this.$notify({
          title: `ORDER TYPE ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please check at least one App Type!',
          type: 'danger',
          icon: 'fas fa-bomb',
        })
        this.isSubmitting = false
        return false
      }
    }

    /**
     * if Foodics POS - require Foodics Discount
     * if there is no POS - do not require Foodics Discount
     * if there is POS but not Foodics - do not require Foodics Discount
    */

    if (['discount', 'subscription'].includes(this.dataForm['type'])) {
      if (this.hasIntegration.length > 0) {
        const [ currentIntegrationInstalled ] = this.hasIntegration
        if (currentIntegrationInstalled.label === 'foodics-f5' && this.dataForm['discount-code'] === null) {
          this.$notify({
            title: `Discount is Required`,
            verticalAlign: 'bottom',
            horizontalAlign: 'left',
            message: 'Please select a discount',
            type: 'danger',
            icon: 'fas fa-bomb',
          })
          this.isSubmitting = false
          return false
        }
      }
    }

    if(this.totalCheckType === 2) {
        this.$notify({
          title: `TYPE ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: 'Please check at least one Type!',
          type: 'danger',
          icon: 'fas fa-bomb',
        })      
        this.isSubmitting = false
        return false;
    }

    if (this.dataForm["item-id"]) {
      const item = this.items.find(
        (i: any) => i.id === this.dataForm["item-id"]
      );
      if (item) {
        this.dataForm["item-code"] = item.attributes.code;
      }
    }

    if (!['item', 'delivery'].includes(this.dataForm['type'])) {
      if (this.dataForm['qualification'] === 'item') {
        this.qualifyingItemsArr = this.qualifyingItemsArr.map((element: any) => {
          const { items, menu, category } = element
          if (items.length) {
            return items.map((item: any) => {
              return {
                "item": item,
                "category": category,
                "menu": menu
              }
            })
          }
        })
        const mergedArray = this.mergeArrays(...this.qualifyingItemsArr);
        this.dataForm["items"] = mergedArray
        this.dataForm["categories"] = []
      }

      if (this.dataForm['qualification'] === 'category') {
        this.qualifyingItemsArr = this.qualifyingItemsArr.map((element: any) => {
          const { menu, categories } = element
          if (categories.length) {
            return categories.map((category: any) => {
              return {
                "category": category,
                "menu": menu
              }
            })
          }
        })
        const mergedArray = this.mergeArrays(...this.qualifyingItemsArr);
        this.dataForm["categories"] = mergedArray
        this.dataForm["items"] = []
      }

      if (this.dataForm['qualification'] === 'order') {
        this.qualifyingItemsArr = []
        this.dataForm["items"] = []
        this.dataForm["categories"] = []
      }
    }

    
    this.checkEligibilityRules(this.orderTypes, 'order-type', 'cart-rules')

    // Get account types
    let arr = [];
    for (let x in this.accountTypes) {
      if (x != 'all') {
        if (this.accountTypes[x].model) {
          arr.push(this.accountTypes[x].value);
        }
      }
    }
    
    // if (!this.includesAny(arr, ['all'])) {
      if (this.dataForm["eligibility-rules"]) {
        let account_type_arr = arr.filter((acc: any) => ['full', 'guest'].includes(acc))
        let type_arr = arr.filter((acc: any) => ['new', 'returning'].includes(acc))
        
        /** account type */
        if (account_type_arr.length) {
          let n = 'in:';
          let l = n + account_type_arr;
          let convertToArray = l.split(" ");
          this.dataForm["eligibility-rules"]["account-type"] = convertToArray.length ? convertToArray.join() : "";
        } else {
          // delete this.dataForm["eligibility-rules"]['account-type']
        }

        /** type */
        if (type_arr.length) {
          let n = 'in:';
          let l = n + type_arr;
          let convertToArray = l.split(" ");
          this.dataForm["eligibility-rules"]["type"] = convertToArray.length ? convertToArray.join() : "";
        } else {
          // delete this.dataForm["eligibility-rules"]['type']
        }
      }
    // } else {
    //   if (this.dataForm["eligibility-rules"]?.['account-type'] === '') {
    //     this.dataForm["eligibility-rules"] = null;
    //   }
    //   if (this.dataForm["eligibility-rules"]?.['type'] === '') {
    //     this.dataForm["eligibility-rules"] = null;
    //   }
    //   if (this.dataForm["cart-rules"]?.['order-type'] === '') {
    //     delete this.dataForm["cart-rules"]["order-type"];
    //   }
    // }

    this.checkEligibilityRules(this.accountTypes, 'account-type', 'eligibility-rules')
    this.checkEligibilityRules(this.appTypes, 'app', 'eligibility-rules')
    
    const schedule: any = {};
    let isValidateCount = 0;

    this.openingHours.forEach((d: any, idx: number) => {
      const found: any = this.matchDay.find((m: any) => m.idx === idx);
      if (d.open && d.closed) {
        if (d.selected) {
          schedule[found.day] = {
            from: this.enable247 ? '00:00' : d.open.split(':').join(''),
            to: this.enable247 ? '23:59' : d.closed.split(':').join(''),
            nextDay: false,
            available: d.selected,
          };
        }
      } else {
        isValidateCount = isValidateCount + 1;
      }
    });

    if (isValidateCount) {
      alert('Schedule dates should be filled in!');
      return;
    }

    if (this.dataForm["availability-rules"] && Object.entries(schedule).length) {
      this.dataForm["availability-rules"].schedule = schedule;
    }

    if(this.enable247 === true || !Object.entries(schedule).length) {
      this.dataForm["availability-rules"] = null;
    }

    const cartRules: any = {
      items: {},
      categories: {},
      menu: {}
    };

    this.eligibilityItems.forEach((cartItem: any, ind: any) => {
      const { item, categories, menu, quantity } = cartItem

      cartRules.menu[menu] = {
        quantity: `gte:${quantity}`
      }

      cartRules.categories[categories] = {
        quantity: `gte:${quantity}`
      }

      if (item) {
        cartRules.items[item] = { quantity: `gte:${quantity}` }
      } else {
        cartRules.items[`${item}-${ind}`] = { quantity: `gte:${quantity}`, categoryId: categories, menuId: menu}
      }
    })

    if (this.dataForm["cart-rules"]) {
      this.dataForm["cart-rules"].items = cartRules.items;
      this.dataForm["cart-rules"].categories = cartRules.categories;
      this.dataForm["cart-rules"].menu = cartRules.menu;
      this.dataForm["cart-rules"].total = 'gte:' + this.minimumCartAmount.toString();
      this.dataForm["cart-rules"].location = this.selectedLocations.length ? `in:${this.selectedLocations.join(",")}` : null;
    }

    if (this.dataForm.type === 'delivery') {
      this.dataForm['discount-type'] = 'percentage';
      this.dataForm['discount-value'] = 100;
    }

    this.dataForm['redemption-limit-global'] = Number(this.dataForm['redemption-limit-global']);
    this.dataForm['redemption-limit-customer'] = Number(this.dataForm['redemption-limit-customer']);

    let promise: any = null;
    const { id } = this.$route.params;

    if (id) {
      promise = Promotion.updatePromotion(id, this.dataForm);
    } else {
      promise = Promotion.savePromotion(this.dataForm);
    }

    promise
      .then(() => {
        if (!id) {
          /* @ts-ignore */
          analytics.track('create_promotion', {
            offer_type: this.dataForm.type,
            discount_qualification: this.dataForm['qualification'],
            discount_type: this.dataForm['discount-type'],
            applied_advanced_settings: this.advancedSettings,
          });
        }
        this.$notify({
          title: id ? 'UPDATE PROMOTION' : 'ADD PROMOTION',
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: `Promotion successfully ${id ? 'updated' : 'added'}`,
          type: "success",
          icon: "fas fa-check"
        });
        this.$router.push({ name: "PromotionsList" });
      })
      .catch((err: any) => {
        let errMsg: any = ''
        err.response.data.error.forEach((e: any) => {
          errMsg += e.detail + '\n';
        });
        this.$notify({
          title: `${id ? 'UPDATE' : 'ADD'} PROMOTION ERROR`,
          verticalAlign: 'bottom',
          horizontalAlign: 'left',
          message: errMsg,
          type: 'danger',
          icon: 'fas fa-bomb',
        })
      }).finally(() => {
        this.isSubmitting = false
      });
  }

  /** Sample usage includesAny(arr, ['test', 'test2']), will return true if values found in the array, else false */
  includesAny = (arr: any, values: any) => values.some((v: any) => arr.includes(v)); 

  checkEligibilityRules(rules: any, rulesAttr: string, formAttr: string) {
    /** rules params could be accountTypes, Types, Apps, OrderTypes and rulesAttr is their attribute name e.g account-type, type, app, order-type */
    let temp_arr = [];

    for (let x in rules) {
      if (x != 'all') {
        if (rules[x].model) {
          temp_arr.push(rules[x].value);
        }
      }
    }

    // const isAllSelected = temp_arr.includes('all')
    // if (!isAllSelected) {
      if (this.dataForm[formAttr]){
        let n = 'in:';
        let l = n + temp_arr; // add prefix
        let convertToArray = l.split(" ");
        this.dataForm[formAttr][rulesAttr] = convertToArray.length ? convertToArray.join() : "";
      }
    // } else {
    //   if (this.dataForm?.[formAttr]?.[rulesAttr]) {
    //     delete this.dataForm[formAttr][rulesAttr]
    //   }
    // }
  }

  convertTime12to24(time12h: any) {
    const [time, modifier] = time12h.split(" ");

    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}`;
  }

  onAddEligibilityItem() {
    this.isShow = !false;
    this.eligibilityItems.push({
      menu: null,
      categories: null,
      item: null,
      quantity: null,
    });
  }

  onDeleteEligibilityItem(index: number) {
    this.eligibilityItems.splice(index, 1);
  }

  cancelPromo() {
    this.$router.push({ name: "PromotionsList" });
  }

  onDeleteQualificationItem(index: number) {
    if (this.qualifyingItemsArr.length === 1) {
      this.$notify({
        title: 'Unable to remove an item.',
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message: "Promotion must have atleast 1 item.",
        type: 'warning',
        icon: 'fas fa-exclamation-triangle',
      })
    } else {
      this.qualifyingItemsArr.splice(index, 1);
    }
  }

  onAddQualifyingItem() {
    this.qualifyingItemsArr.push({
      menu: null,
      category: null,
      categories: null,
      items: null,
    });
  }

  onChangeQualification(val : any){
    this.showQualifyingItems = (val === 'item' || val === 'category') ? true : false
  }
  
  getOptionLabel (nameObj: any): any {
    if (this.getLocale === 'en_US') {
      return nameObj['en']
    }

    if (this.getLocale === 'ar_SA' && nameObj['ar']) {
      return nameObj['ar']
    }

    return nameObj['en']
  }

  onChangeDiscountCode(e : any) {
    if (e) {
      
      const onChangeIteration = new Promise((resolve, reject) => {
        for (const [key, value] of Object.entries(this.posDiscounts)) {
          if (this.posDiscounts[key].id == e) {
            resolve(value)
          }
        }
      })

      onChangeIteration.then((val : any ) => {
        this.dataForm['discount-value'] = val['discount-amount'] ? val['discount-amount'] : (val['discount_amount'] ? val['discount_amount'] : 0);
        this.dataForm['discount-type'] = val['discount-type'] ? val['discount-type'] : (val['discount_type'] ? val['discount_type'] : 'fixed');
        this.dataForm['discount-max'] = val['maximum-discount-amount'] ? val['maximum-discount-amount'] : (val['maximum_discount_amount'] ? val['maximum_discount_amount'] : 0);
        this.minimumCartAmount = val['minimum-order-amount'] ? val['minimum-order-amount'] : (val['minimum_order_amount'] ? val['minimum_order_amount'] : 0);
      })
    }
  }

  async checkIntegrationIfExists() {
    try {
      const { data: integration } = await Integrations.logisticsPartners('pos')
      this.hasIntegration = integration.data
    } catch (error) {
      console.error('Error Occured', error)
    }
  }

  async getQualifyingItems() {
    try {
      this.isLoadingQualifying = true
      const response = await Promotion.getPromotionQualifyingItems(this.$route.params.id)
      const { data: items } = response
      if (items.length) {
        let index = 0
        let qualifyingItems = []
        let isCategory = true

        const groupedItems = items.reduce((acc:any, item:any) => {
          if (!acc[item['category-id']]) {
            acc[item['category-id']] = [];
          }
          acc[item['category-id']].push(item);
          return acc;
        }, {});

        for (const category in groupedItems) {
          let items:any = []
          let categories:any = []
          let menu_id:any = null
          groupedItems[category].forEach((item:any) => {
            menu_id = item['menu-id']
            items.push(item['item-id'])
            categories.push(category.toString())
          });
          if (menu_id) {
            await this.getMenusForQualification()
            await this.getCategoriesForQualification(menu_id, index);
            await this.getItemsForQualification(menu_id, category, index);
            await Category.items(menu_id, category, 1);
            /**
             * Have to comment this line because not sure what this is for - Karl's MR
              const response = await Category.items(menu_id, category, 1);
              if (items.length !== response.data.data.length) {
                isCategory = false
              }
            */
            qualifyingItems.push({
              menu: menu_id,
              category: +category,
              items: items,
            });
          }
          index += 1
        }
        this.qualifyingItemsArr = qualifyingItems
        /**
         * Have to comment this line because not sure what this is for - Karl's MR
          if (!isCategory) {
            this.qualifyingItemsArr = qualifyingItems
          }
        */
      }
    } catch (error) {
      this.$notify({
        title: 'Fetch Qualifying Items',
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message: (error as any).response.data.message || 'An Error Occured in Fetching Qualifying Items',
        type: 'warning',
        icon: 'fas fa-exclamation-triangle',
      })
    } finally {
      this.isLoadingQualifying = false
    }
  }

  mergeArrays(...arrays: any) {
    return arrays.reduce((acc: any, curr: any) => acc.concat(curr), []);
  }

  async getMenusForQualification() {
    try {
      const response = await Menu.all();
      const index = this.qualifyingItemsArr.length - 1
      if (this.qualificationLineMenuArr[index] !== undefined && this.qualificationLineMenuArr[index] !== null) {
        this.qualificationLineMenuArr.splice(index, 1, response.data.data);
      } else {
        this.qualificationLineMenuArr = [...this.qualificationLineMenuArr, response.data.data]
      }
    } catch (error) {
      this.$notify({
        title: 'Fetch Menu',
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message: (error as any).response.data.message || 'An Error Occured in Fetching Menu',
        type: 'warning',
        icon: 'fas fa-exclamation-triangle',
      })
    }
  }

  async getCategoriesForQualification(menuId: string | number, index: any = null) {
    try {
      this.loadingGettingCategoriesByMenuQualfication = true
      const response = await Category.all(menuId, 1);
      if (this.qualificationLineCategoriesArr[index] !== undefined && this.qualificationLineCategoriesArr[index] !== null) {
        this.qualifyingItemsArr[index].category = null
        this.qualifyingItemsArr[index].items = null
        this.qualificationLineCategoriesArr.splice(index, 1, response.data.data);
      } else {
        this.qualificationLineCategoriesArr = [...this.qualificationLineCategoriesArr, response.data.data]
      }
      console.log("qualificationLineCategoriesArr", this.qualificationLineCategoriesArr)
    } catch (error) {
      this.$notify({
        title: 'Fetch Category',
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message: (error as any).response.data.message || 'An Error Occured in Fetching Category',
        type: 'warning',
        icon: 'fas fa-exclamation-triangle',
      })
    } finally {
      this.loadingGettingCategoriesByMenuQualfication = false
    }
  }

  async getItemsForQualification(menuId: string | number, categoryId: string | number, index: any = null) {
    try {
      this.loadingGettingItemsByCategoryQualfication = true
      const response = await Category.items(menuId, categoryId, 1);
      if (this.qualificationLineItemsArr[index] !== undefined && this.qualificationLineItemsArr[index] !== null) {
        this.qualifyingItemsArr[index].items = []
        this.qualificationLineItemsArr.splice(index, 1, response.data.data);
      } else {
        this.qualificationLineItemsArr = [...this.qualificationLineItemsArr, response.data.data]
      }
    } catch (error) {
      this.$notify({
        title: 'Get Items By Category',
        verticalAlign: 'bottom',
        horizontalAlign: 'left',
        message: (error as any).response.data.message || 'An Error Occured in Getting Items By Category',
        type: 'warning',
        icon: 'fas fa-exclamation-triangle',
      })
    } finally {
      this.loadingGettingItemsByCategoryQualfication = false
    }
  }
}
