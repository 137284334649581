export default {
  getPage(state: { page_allowed: Array<any> }) {
    return state.page_allowed;
  },
  getDisabledPage(state: { page_disabled: Array<any> }) {
    return state.page_disabled;
  },
  getMoneyHash(state: { money_hash: any }) {
    return state.money_hash;
  },
  getSoloLoyalty(state: { solo_loyalty: any }) {
    return state.solo_loyalty;
  },
  getPartooGmb(state: { partoo_gmb: any }) {
    return state.partoo_gmb;
  },
  getWhatsApp(state: { whats_app: any }) {
    return state.whats_app;
  },
  getIsRefresh(state: { is_refresh : any }) {
    return state.is_refresh
  },
  getIsWhatsAppVerified(state: { is_whatsapp_verified : any }) {
    return state.is_whatsapp_verified;
  },
  getIsWhatsAppOnBoarded(state: { is_whatsapp_onboarded : any }) {
    return state.is_whatsapp_onboarded;
  }
}
