
import draggable from "vuedraggable";
import moment from "moment";
import { mapGetters } from "vuex";
import { Select, Option, Form, FormItem, Switch } from "element-ui";
import { translations, eventHandler, notificationAlerts } from "@/mixins";
import StoreTimes from "@/views/Admin/pages/Locations/components/StoreTimes.vue";
import Translations from "../components/Translations.vue";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import {
  Topic,
} from "@/services/SOLO";
import { mixins } from "vue-class-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { v4 as uuidv4 } from "uuid";
import StarRatingsEditModal from "@/views/Admin/pages/Messaging/components/StarRatings/StarRatingsEditModal.vue";

@Component({
  components: {
    StarRatingsEditModal,
    RouteBreadCrumb,
    [Select.name]: Select,
    [Option.name]: Option,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Switch.name]: Switch,
    Translations,
    StoreTimes,
    ValidationProvider,
    draggable,
  },
  filters: {
    formatDate(date: string) {
      return moment
        .utc(date, "YYYY-MM-D hh:mm:ss")
        .locale("en-us")
        .local()
        .format("D/MM/YYYY");
    }
  },
  computed: {
    ...mapGetters({
      getUser: "account/getUser",
      getLocale: "app/getLocale",
      getSubscription: "account/getSubscription"
    })
  },
  mixins: [translations, eventHandler, notificationAlerts]
})
export default class StarRatings extends Vue {
  public toggleDelivery: any = [];
  public togglePickup: any = [];
  public deliveryTopics: any = [];
  public topics: any = [];
  public pickupTopics: any = [];
  public openEdit: boolean = false;
  public topic: any = {};
  public getLanguages!: Function;
  public confirm!: Function;
  public translate!: Function;
  public arrayLang: Array<string> = [];
  public idsDeliver: any = [];
  public idsPickup: any = [];
  public successNotification!: Function;
  public isOpenDeleteTopic: boolean = false;
  public topicNumber: Number = 0;
  public topicType: string = '';
  public topicId: any;
  public editTopicIndex: any;

  mounted() {
    this.loadTopics();
  }

  public sortDeliveryTopic() {
    const promiseArr: any = [];
    this.deliveryTopics.forEach((topic: any, index: any) => {
      Topic.updateTopic(topic.id, {'display-order': index+1});
    });
  }

  public sortPickupTopic() {
    const promiseArr: any = [];
    this.pickupTopics.forEach((topic: any, index: any) => {
      Topic.updateTopic(topic.id, {'display-order': index+1});
    });
  }

  public get dTopics() {
    return this.topics.filter((topic: any) => topic.type === 1);
  }

  public get pTopics() {
    return this.topics.filter((topic: any) => topic.type === 2);
  }

  private loadTopics() {
    // Topic
    //   .getTopics()
    //   .then((response: any) => {
    //     console.log('response: ', response);
    //     this.deliveryTopics = response.data.data.filter((topic: any) => topic.attributes.type === 'delivery');
    //     this.pickupTopics = response.data.data.filter((topic: any) => topic.attributes.type === 'non-delivery');

    //     console.log('deliveryTopics: ', this.deliveryTopics);
    //     console.log('pickupTopics: ', this.pickupTopics);

    //     if (!this.deliveryTopics.length) {
    //       this.onAddTopic(1);
    //     }

    //     if (!this.pickupTopics.length) {
    //       this.onAddTopic(2);
    //     }
    //   });

    this.topics = [];
    
    this.deliveryTopics = [];
    this.pickupTopics = [];

    this.arrayLang = this.getLanguages();

    const promiseArr: any = [];
    this.arrayLang.forEach((lang: string) => {
      promiseArr.push(Topic.getTopics(lang));
    });

    Promise.all(promiseArr)
      .then((response: any) => {
        /* @ts-ignore */
        analytics.track('create_star_rating', {
          
        });
        console.log('check response topics', response);
        if(this.arrayLang.length > 1) {
          if ((response[0] && response[0].data && response[0].data.data) && response[1] && response[1].data && response[1].data.data) {
          // this.deliveryTopics = response[0].data.data.filter((topic: any) => topic.attributes.type === 'delivery');
          // this.pickupTopics = response.data.data.filter((topic: any) => topic.attributes.type === 'non-delivery');
          
          this.idsDeliver = [];
          // for primary language and delivery type
          response[0].data.data.forEach((element: any) => {
            if (element.attributes.type == 'delivery') {
              if (!this.idsDeliver.includes(parseInt(element.id))) {
                this.deliveryTopics.push(element);
                this.deliveryTopics.forEach((item: any) => {
                  if (parseInt(item.id) == parseInt(element.id)) {
                    item['attributes']['name'] = {
                      [this.arrayLang[0]]: element.attributes.name
                    }
                    return {item}
                  }
                });

                this.idsDeliver.push(parseInt(element.id));
                this.toggleDelivery.push(element.attributes.enabled ? true : false);
              } else {
                this.deliveryTopics.forEach((item: any) => {
                  if (parseInt(item.id) == parseInt(element.id)) {
                    item['attributes']['name'] = {
                      ...item['attributes']['name'],
                      [this.arrayLang[0]]: element.attributes.name
                    }
                    return {item}
                  }
                });
              }
            }
          });

          // for secondary language and delivery type
          response[1].data.data.forEach((element: any) => {
            if (element.attributes.type == 'delivery') {
              if (!this.idsDeliver.includes(parseInt(element.id))) {
                this.deliveryTopics.push(element);
                this.deliveryTopics.forEach((item: any) => {
                  if (parseInt(item.id) == parseInt(element.id)) {
                    item['attributes']['name'] = {
                      [this.arrayLang[1]]: element.attributes.name
                    }
                    return {item}
                  }
                });

                this.idsDeliver.push(parseInt(element.id));
                this.toggleDelivery.push(element.attributes.enabled ? true : false);
              } else {
                this.deliveryTopics.forEach((item: any) => {
                  if (parseInt(item.id) == parseInt(element.id)) {
                    item['attributes']['name'] = {
                      ...item['attributes']['name'],
                      [this.arrayLang[1]]: element.attributes.name
                    }
                    return {item}
                  }
                });
              }
            }
          });

          this.idsPickup = [];
          // for primary language and non-delivery type
          response[0].data.data.forEach((element: any) => {
            if (element.attributes.type == 'non-delivery') {
              if (!this.idsPickup.includes(parseInt(element.id))) {
                this.pickupTopics.push(element);
                this.pickupTopics.forEach((item: any) => {
                  if (parseInt(item.id) == parseInt(element.id)) {
                    item['attributes']['name'] = {
                      [this.arrayLang[0]]: element.attributes.name
                    }
                    return {item}
                  }
                });

                this.idsPickup.push(parseInt(element.id));
                this.togglePickup.push(element.attributes.enabled ? true : false);
              } else {
                this.pickupTopics.forEach((item: any) => {
                  if (parseInt(item.id) == parseInt(element.id)) {
                    item['attributes']['name'] = {
                      ...item['attributes']['name'],
                      [this.arrayLang[0]]: element.attributes.name
                    }
                    return {item}
                  }
                });
              }
            }
          });

          // for secondary language and non-delivery type
          response[1].data.data.forEach((element: any) => {
            if (element.attributes.type == 'non-delivery') {
              if (!this.idsPickup.includes(parseInt(element.id))) {
                this.pickupTopics.push(element);
                this.pickupTopics.forEach((item: any) => {
                  if (parseInt(item.id) == parseInt(element.id)) {
                    item['attributes']['name'] = {
                      [this.arrayLang[1]]: element.attributes.name
                    }
                    return {item}
                  }
                });

                this.idsPickup.push(parseInt(element.id));
                this.togglePickup.push(element.attributes.enabled ? true : false);
              } else {
                this.pickupTopics.forEach((item: any) => {
                  if (parseInt(item.id) == parseInt(element.id)) {
                    item['attributes']['name'] = {
                      ...item['attributes']['name'],
                      [this.arrayLang[1]]: element.attributes.name
                    }
                    return {item}
                  }
                });
              }
            }
          });
          console.log('ids: ', this.idsDeliver, this.idsPickup);
          }
        } else {

          this.idsDeliver = [];

          response[0].data.data.forEach((element: any) => {
            if (element.attributes.type == 'delivery') {
              if (!this.idsDeliver.includes(parseInt(element.id))) {
                this.deliveryTopics.push(element);
                this.deliveryTopics.forEach((item: any) => {
                  if (parseInt(item.id) == parseInt(element.id)) {
                    item['attributes']['name'] = element.attributes.name
                    
                    return {item}
                  }
                });

                this.idsDeliver.push(parseInt(element.id));
                this.toggleDelivery.push(element.attributes.enabled ? true : false);
              } else {
                this.deliveryTopics.forEach((item: any) => {
                  if (parseInt(item.id) == parseInt(element.id)) {
                    item['attributes']['name'] = {
                      ...item['attributes']['name']
                    }
                    return {item}
                  }
                });
              }
            }
          });

          this.idsPickup = [];
          // for primary language and non-delivery type
          response[0].data.data.forEach((element: any) => {
            if (element.attributes.type == 'non-delivery') {
              if (!this.idsPickup.includes(parseInt(element.id))) {
                this.pickupTopics.push(element);
                this.pickupTopics.forEach((item: any) => {
                  if (parseInt(item.id) == parseInt(element.id)) {
                    item['attributes']['name'] = element.attributes.name
                    
                    return {item}
                  }
                });

                this.idsPickup.push(parseInt(element.id));
                this.togglePickup.push(element.attributes.enabled ? true : false);
              } else {
                this.pickupTopics.forEach((item: any) => {
                  if (parseInt(item.id) == parseInt(element.id)) {
                    item['attributes']['name'] = {
                      ...item['attributes']['name']
                    }
                    return {item}
                  }
                });
              }
            }
          });

        }

        
        console.log('deliveryTopics: ', this.deliveryTopics);
        console.log('pickupTopics: ', this.pickupTopics);
      });
  }

  public handleClose(value: any): void {
    this.openEdit = value;
    this.loadTopics();
  }

  onAddTopic(topicType: number): void {
    if (topicType == 1) {
      this.idsDeliver.length += 1;
    } else if (topicType == 2) {
      this.idsPickup.length += 1;
    }
    
    this.topics.push({
      id: uuidv4(),
      type: topicType,
      name: {
        [this.arrayLang[0]]: '',
        [this.arrayLang[1]]: '',
      }
    });
  }

  onEditTopic(topic: any, index: any): void {
    console.log('onEdit - topic: ', topic);
    this.topic = topic;
    this.openEdit = true;
    this.editTopicIndex = index;
  }

  onUpdateTopic(id: any, status: any, type: any, index: any) {
    const payload = {
      enabled: status,
      type: type
    };
    console.log('onUpdateTopic', payload);
    Topic.updateTopic(id, payload)
      .then((response) => {
        if (type == 1) {
          this.toggleDelivery[index] = response.data.data.attributes.enabled;
        } else if (type == 2) {
          this.togglePickup[index] = response.data.data.attributes.enabled;
        }
        // this.successNotification(`${type == 1 ? 'DELIVERY' : 'PICKUP'} TOPIC UPDATED!`, 'Topic successfully updated!');
      })
  }

  onRemoveTopic(id: any): void {
    console.log('onRemoveTopic: ', id);
    const idx = this.topics.findIndex((topic: any) => topic.id === id);

    let topic = this.topics.find((topic: any) => topic.id === id);

    if (topic.type == 1) {
      this.idsDeliver.length -= 1;
    } else if (topic.type == 2) {
      this.idsPickup.length -= 1;
    }

    if (idx > -1) {
      this.topics.splice(idx, 1);
    }
  }

  onDeleteTopic(id: string, type: string, index: Number) {
    this.isOpenDeleteTopic = true;
    this.topicNumber = index;
    this.topicType = type;
    this.topicId = id;
  }

  deleteTopic() {
    Topic.deleteTopic(this.topicId)
      .then(() => {
        if (this.topicType === 'delivery') {
          const idx = this.deliveryTopics.findIndex((t: any) => t.id === this.topicId);
          this.idsDeliver.length -= 1;
          if (idx > -1) {
            this.deliveryTopics.splice(idx, 1);
          }
        } else if (this.topicType === 'pickup') {
          const idx = this.pickupTopics.findIndex((t: any) => t.id === this.topicId);
          this.idsPickup.length -= 1;
          if (idx > -1) {
            this.pickupTopics.splice(idx, 1);
          }
        }

        this.loadTopics();
        this.isOpenDeleteTopic = false;
      });
  }

  onSubmit() {
    let validateReqCount = 0;
    let promiseArr: any = [];

    console.log('this.topics: ', this.topics);

    this.topics.forEach((topic: any) => {
      if (topic.name[this.arrayLang[0]] && topic.name[this.arrayLang[1]]) {
        delete topic.id;
        promiseArr.push(Topic.addTopic(topic));
      } else {
        validateReqCount = validateReqCount += 1;
      }
    });

    if (!validateReqCount) {
      console.log('validateReqCount');
      Promise.all(promiseArr)
        .then(() => {
          this.loadTopics();
        });
    } else {
      promiseArr = [];
      alert('You need to fill in all fields in order to proceed!');
    }
  }
}
