
import { Table, TableColumn } from "element-ui"
import IngredientFormModal from './IngredientFormModal.vue'
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator"
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    IngredientFormModal,
    Translations,
  },
  mixins: [translations],
})
export default class Modifiers extends Vue {
  open: boolean = false
  translate!: Function;
  @Prop() ingredients!: Array<Object>
  @Prop() active!: Boolean

  $refs!: {
    formModal: IngredientFormModal
  }

  updateListing() {
    this.$emit('ingredient:created')
  }

  initUpdate(row: any) {

  }

  openModal() {
    this.$refs.formModal.open = true
  }

  updateModal(open: boolean) {
    console.log(open)
  }
}
