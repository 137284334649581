

import GlobalAllergensModal from './GlobalAllergensModal.vue'
import GlobalAllergensList from './GlobalAllergensList.vue'
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import { Settings } from '@/services/SOLO';
import { GlobalIng, GlobalAllergens as GlobalAllergensModel, ModifierGroupItem } from '@/models'

@Component({
  components: {
    GlobalAllergensModal,
    GlobalAllergensList
  },
  mixins: [eventHandler, translations]
})
export default class GlobalAllergens extends Vue {
  
  open: Boolean = false
  itemAllergens: Array<GlobalAllergensModel> = []
  globalAllergens: Array<GlobalAllergensModel> = []
  translate!: Function

  mounted() {
    this.getItemAllergens()
    this.getGlobalAllergens()
  }

  get mappedItemAllergens() {    
    return this.itemAllergens.map(ing => { return ing.id.toString() })
  }

  modalOpenState(open: Boolean = true) {    
    this.open = open
  }
  
  getItemAllergens() {
    Settings.getItemAllergens(this.$route.params.itemId)
      .then((response: any) => {
        this.itemAllergens = response.data.data                
      })
      .catch((err: any) => {
        console.error(err)
      })
  }

  getGlobalAllergens() {
    Settings.allergens()
      .then((response: any) => {
        this.globalAllergens = response.data.data        
      })
      .catch((err: any) => {
        console.error(err)
      })
  }
}
