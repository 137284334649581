var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"stats d-flex flex-column"},[_c('h3',{staticClass:"stats__header"},[_vm._v(_vm._s(_vm.subTitle))]),_c('section',{staticClass:"stats__total-container d-flex flex-row justify-content-center text-center"},[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.toLocale(_vm.revenue)))]),_c('p',[_vm._v(_vm._s(_vm.translate('Revenue'))+" "),_c('span',{class:{
        'high': _vm.revpct.toFixed(1) >= 1,
        'low': _vm.revpct.toFixed(1) < 1,
        'zero': _vm.revpct === 0
      }},[_vm._v("("+_vm._s(_vm.revpct.toFixed(1))+"%"),_c('i',{class:{
        'fa fa-arrow-up': _vm.revpct.toFixed(1) >= 1,
        'fa fa-arrow-down': _vm.revpct.toFixed(1) < 0,
        'fal fa-arrows-v': _vm.revpct === 0,
      }}),_vm._v(")")])])]),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.toLocale(_vm.average)))]),_c('p',[_vm._v(_vm._s(_vm.translate('Avg Check'))+" "),_c('span',{class:{
        'high': _vm.avgpct.toFixed(1) >= 0,
        'low': _vm.avgpct.toFixed(1) < 0,
        'zero': _vm.avgpct === 0
      }},[_vm._v("("+_vm._s(_vm.avgpct.toFixed(1))+"%"),_c('i',{class:{
        'fa fa-arrow-up': _vm.avgpct.toFixed(1) >= 0,
        'fa fa-arrow-down': _vm.avgpct.toFixed(1) < 0,
        'fal fa-arrows-v': _vm.avgpct === 0,
      }}),_vm._v(")")])])]),_c('div',[_c('h3',[_vm._v(_vm._s(_vm.toLocale(_vm.orders)))]),_c('p',[_vm._v(_vm._s(_vm.translate('Orders'))+" "),_c('span',{class:{
        'high': _vm.orderspct.toFixed(1) >= 0,
        'low': _vm.orderspct.toFixed(1) < 0,
        'zero': _vm.orderspct === 0
      }},[_vm._v("("+_vm._s(_vm.orderspct.toFixed(1))+"%"),_c('i',{class:{
        'fa fa-arrow-up': _vm.orderspct.toFixed(1) >= 0,
        'fa fa-arrow-down': _vm.orderspct.toFixed(1) < 0,
        'fal fa-arrows-v': _vm.orderspct === 0,
      }}),_vm._v(")")])])])]),_c('div',{staticClass:"stats__details"},[_c('table',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.translate('SOURCE')))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.translate('REVENUE')))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.translate('AVG CHECK')))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.translate('ORDERS')))])]),_vm._l((_vm.dataPerSource),function(item,i){return _c('tr',{key:i},[_c('td',{staticClass:"text-center"},[_c('el-tooltip',{attrs:{"content":_vm.translate(item[_vm.selectedStats]),"placement":"top"}},[_c('img',{attrs:{"src":'/img/order icons/' + item[_vm.selectedStats].toLowerCase() + '.png',"width":"32","alt":""}})])],1),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.toLocale(item.t_revenue)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.toLocale(item.t_avg_check)))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.toLocale(item.t_orders)))])])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }