
import Modal from "@/components/Modal.vue";
import { translations, notificationAlerts } from '@/mixins'
import Translations from './Translations.vue'
import { Category as CategoryApi } from "@/services/SOLO";
import { CatAttributes, CatDesc, Category, CatName } from "@/models";
import { Select, Option } from "element-ui";
import {
  ValidationObserver,
  configure,
  ValidationProvider,
} from "vee-validate";
import {
  Component,
  ProvideReactive,
  Prop,
  Vue,
  Watch,
  Emit,
} from "vue-property-decorator";
import { mapGetters } from 'vuex'

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationProvider,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
      getConceptSettings: 'account/getConceptSettings',
    }),
  },
  mixins: [translations, notificationAlerts]
})
export default class MenuCategoryModal extends Vue {
  arrayLang: Array<string> = [];
  stockImages: any = [];
  getConceptSettings!: any;
  searchStock: any = '';
  catName: CatName = {};
  catDesc: CatDesc = {};
  imageType: number = 1;
  isLoading: Boolean = false;
  imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Upload Image",
    },
    {
      value: 2,
      text: "Stock Images",
    },
  ];
  category: CatAttributes = {
    code: "",
    "display-order": 1,
    enabled: 1,
    name: {},
    description: {},
    "image-uri": "",
  };
  $notify: any;
  $refs!: {
    file: any;
  };
  getLocale!: any;
  translate!: Function;
  successNotification!: Function;
  systemErrorNotification!: Function;


  @Prop() open!: Boolean;

  @Watch("imageType", { deep: true, immediate: true })
  onChangeGetSotckImages(data: any) {
    if (data === 2) {
      this.getStockImages();
    }
  }

  mounted() {
    /* @ts-ignore */
    this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
    /* @ts-ignore */
    if (this.getConceptSettings.attributes['secondary-language']) {
      /* @ts-ignore */
      this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
    }
  }

  get isEnabled(): boolean {
    return this.category.enabled === 1 ? true : false;
  }

  set isEnabled(newVal: boolean) {
    this.category.enabled = newVal ? 1 : 0;
  }

  get isOpen(): Boolean {
    return this.open;
  }

  set isOpen(newVal: Boolean) {
    this.$emit("modal:update", newVal);
    if (!newVal) {

      this.category.code = ''
      this.category["display-order"] = 1
      this.category.enabled = 1
      this.category['image-uri'] = ''
      for (let i in this.arrayLang) {
        this.catName[this.arrayLang[i]] = ''
        this.catDesc[this.arrayLang[i]] = ''
      }
      this.category.name = this.catName;
      this.category.description = this.catDesc;

    }
  }

  constructor() {
    super();
    for (let i in this.arrayLang) {
      this.catName[this.arrayLang[i]] = "";
      this.catDesc[this.arrayLang[i]] = "";
    }
    this.category.name = this.catName;
    this.category.description = this.catDesc;
  }

  get categoryData(): CatAttributes {
    return this.category;
  }

  get categoryForm(): FormData {
    let formData = new FormData()
    formData.append('code', this.category.code)
    formData.append('display-order', this.category['display-order'].toString())
    formData.append('enabled', this.category.enabled.toString())
    for (let i in this.arrayLang) {
      formData.append(`name[${this.arrayLang[i]}]`, this.category.name[this.arrayLang[i]])
      formData.append(`description[${this.arrayLang[i]}]`, this.category.description[this.arrayLang[i]])
    }
    formData.append('image', this.$refs.file.files[0])
    return formData
  }

  close() {
    this.$emit("modal:close", false);
  }

  selectStockImage(stockImage: any) {
    this.category['image-uri'] = stockImage;
  }

  checkSize(text: any) {

    if (this.getLocale === 'en_US') {
      if (text === 'The image field size must be less than 2000KB') {
        return 'The image file size must be less than 2MB'
      }
    }
    if (text === 'The image field size must be less than 2000KB') {
      return 'يجب أن يكون حجم ملف الصورة أقل من 2 ميغا بايت'
    }

  }

  searchStockImages(e: any) {
    this.isLoading = true;
    CategoryApi.searchStockImgs(this.searchStock)
      .then((response) => {
        this.stockImages = response.data.data;
        this.isLoading = false;
        console.log("search stock images", this.stockImages);
      })
  }

  getStockImages() {
    CategoryApi.getStockImgs()
      .then((response) => {
        this.stockImages = response.data.data;
        console.log("stock images", this.stockImages);
      })
  }


  createCategory(e: any, reset: any) {
    let text = this.translate('Creating Category')
    let defaultText = e.target.innerHTML
    e.target.innerHTML = `<i class="fas fa-spinner fa-spin"></i> ${text}`
    e.target.disabled = true
    if (!this.imageType || this.imageType === 2) {
      CategoryApi.createCategory(this.categoryData, this.$route.params.id)
        .then((response) => {
          this.successNotification('CATEGORY SAVED!', 'Category has been saved successfully')
          e.target.innerHTML = defaultText;
          e.target.disabled = false;
          this.$emit("category:created");
          this.$emit("modal:update", false);
          reset();
        })
        .catch((err) => {
          e.target.innerHTML = defaultText;
          e.target.disabled = false;
          this.systemErrorNotification()
        });
    } else {
      CategoryApi.createCategoryForm(this.categoryForm, this.$route.params.id)
        .then(response => {
          this.successNotification('CATEGORY SAVED!', 'Category has been saved successfully')
          e.target.innerHTML = defaultText
          e.target.disabled = false
          this.$emit('category:created')
          this.$emit('modal:update', false);
          reset()
        })
        .catch(err => {
          e.target.innerHTML = defaultText
          e.target.disabled = false
          this.systemErrorNotification()
        })
    }
  }
}
