
import FeedbackCollapse from "./components/FeedbackCollapse.vue"
import FeedbackList from "./components/FeedbackList.vue"
import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb'
import { Component, Vue } from "vue-property-decorator"

@Component({
  components: {
    RouteBreadCrumb,
    FeedbackList,
    FeedbackCollapse
  }
})

export default class Feedback extends Vue {
  mounted() {
    /* @ts-ignore */
    analytics.track('view_feedback', {
      
    });
  }
}
