
import { translations } from "@/mixins";
import VueTimepicker from "vue2-timepicker";
import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import moment from "moment";
@Component({
  components: {
    VueTimepicker,
  },
  computed: {

  },
  mixins: [translations]
})
export default class StoreTimes extends Vue {
  days: Array<any> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  @Prop() openingHours!: Array<any>;
  @Prop() openingHoursDelivery!: Array<any>;
  @Prop() openingHoursPickup!: Array<any>;
  toggle: Boolean = false;
  translate!: any

  @Watch('openingHours', { immediate: true, deep: true })
  onOpeningHoursChanged(hours: any) {
    if (hours?.length) {
      hours.map(
        (item: any) => {
          item.open = moment(`${item.open}`, "hh:mm A").format("HH:mm");
          item.closed = moment(`${item.closed}`, "hh:mm A").format("HH:mm");

          return item;
        }
      );
    }
  }

  @Watch('openingHoursDelivery', { immediate: true, deep: true })
  onOpeningHoursDeliveryChanged(hours: any) {
    if (hours?.length) {
      hours.map(
        (item: any) => {
          item.open = moment(`${item.open}`, "hh:mm A").format("HH:mm");
          item.closed = moment(`${item.closed}`, "hh:mm A").format("HH:mm");

          return item;
        }
      );
    }
  }

  @Watch('openingHoursPickup', { immediate: true, deep: true })
  onOpeningHoursPickupChanged(hours: any) {
    if (hours?.length) {
      hours.map(
        (item: any) => {
          item.open = moment(`${item.open}`, "hh:mm A").format("HH:mm");
          item.closed = moment(`${item.closed}`, "hh:mm A").format("HH:mm");
          
          return item;
        }
      );
    }
  }

  get hasDeliveryAndPickup(): Boolean {
    return this.toggle
  }

  set hasDeliveryAndPickup(value: Boolean) {
    this.toggle = value
  }
}
