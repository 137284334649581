
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import { Option, Select } from "element-ui";
import { translations } from "@/mixins";

@Component({
  components: {
    vSelect,
    DatePicker,
    [Select.name]: Select,
    [Option.name]: Option
  },
  mixins: [translations]
})
export default class CriteriaInput extends Vue {
  @Prop() criteriaType!: any;
  @Prop() htmlType!: string;
  @Prop() id!: number;
  @Prop() condition!: any;
  @Prop() choices!: Array<any>;
  @Prop() value!: any;
  @Prop() menus!: Array<any>;
  @Prop() menuChanged!: boolean;

  selectedValue: any = null;
  choicesLists: any = [];

  @Watch("criteriaType", { immediate: true, deep: true })
  criteriaTypeHandler(val: any) {
    if (val) {
      this.selectedValue = null
      const type = val.replace(/\\/g, '').toLowerCase()

      if (type === 'criterialastorderdate' || type === 'criteriaorderdate') {
        this.selectedValue = {
          since: {
            value: 1,
            unit: "y"
          }
        };
      }
    }
  }

  @Watch("selectedValue", { immediate: true, deep: true })
  valueHandler(val: any) {
    if (val) {
      this.$emit("update-value", val);
    }
  }
  
  @Watch("choices", { immediate: true, deep: true })
  choicesHandler(val: any) {
    if (val.length) {
      this.mapChoices(val)
    }
  }

  @Watch("menuChanged", { immediate: true, deep: true })
  menuChangedHandler(val: any) {
    if (val) {
      this.selectedValue = null
    }
  }

  mapChoices(choices:any) {
    let choicesLists = choices.map((choice: any) => {
      return {
        id: choice.id,
        value: choice.id,
        text: choice.text
      };
    });
    
    this.choicesLists = choicesLists
    
    this.loadOldData()
  }

  isNumber(value:any) {
    return !isNaN(parseFloat(value)) && isFinite(value);
  }

  loadOldData() {
    if (this.value) {
      if (Array.isArray(this.value)) {
        if (this.isNumber(this.value[0]) && this.value.length === 1) {
          this.selectedValue = this.value[0]
        } else {
          if (this.choicesLists.length) {
            this.selectedValue = this.value
          }
          // this.selectedValue = this.value
        }
      }
      else if (typeof this.value === 'object' && this.value !== null) {
        this.selectedValue = this.value
      }
      else {
        this.selectedValue = this.value
      }
    }
  }

  mounted() {
    this.loadOldData()
  }
}
