import { Concept } from '@/interfaces/SOLO'
import { Language } from '@/interfaces/SOLO'

export default {
    setUser(state: { user: any }, data: any) {
        state.user = data
    },
    setUser2(state: { user2: any }, data: any) {
        state.user2 = data
    },
    setFoodicsPay(state: { isFoodicsPay: any }, data: any) {
        state.isFoodicsPay = data
    },
    setUserClient(state: { client: any }, data: any) {
        state.client = data
    },
    setUserRole(state: { userRole: string }, data: string) {
        state.userRole = data
    },
    setUserConcept(state: { concept: Concept }, data: Concept) {
        state.concept =  data
    },
    setUserLanguage(state: { language: Language }, data: Language) {
        state.language =  data
    },
    setConceptSettings(state: { conceptSettings: any }, data: any) {
        state.conceptSettings = data
    },
    setSubscriptionPaymentId(state: { paymentId: any }, data: any) {
        state.paymentId = data
    },
    setSubscription(state: { subscription: any }, data: any) {
        state.subscription = data
    },
    logoutUser(state: { paymentId: any, user: null, user2: null, userRole: Array<any>, isUserAllowed: Boolean, concept: any, applications: any, dashboard: number, conceptSettings: any, subscription: any }) {
        state.user = null
        state.user2 = null
        state.userRole = []
        state.isUserAllowed = false
        state.concept = {}
        state.applications = {}
        state.dashboard = 5
        state.conceptSettings = null
        state.subscription = null
        state.paymentId = null
    },
    setMetabaseDashboard(state: { dashboard: number }, data: number) {
        state.dashboard = data;
    }
}
