
import { mapGetters, mapMutations } from "vuex";
import { eventHandler, translations } from "@/mixins";
import LangSwitcher from '@/components/LangSwitcher';
import { Auth, FoodicsAuth } from "@/services/core";
import {
  Component,
  Vue,
} from "vue-property-decorator";

@Component({
  components: {
    LangSwitcher,
    QRTFA: () => import("./components/QRTFA.vue"),
  },
  computed: {
    ...mapGetters({
      getQRUri: 'tfa/getQRUri',
      getSecretKey: 'tfa/getSecretKey',
      getUser2: 'account/getUser2',
    }),
  },
  methods: {
    ...mapMutations({
      setQRUri: 'tfa/setQRUri',
      setSecretKey: 'tfa/setSecretKey',
      setTfaToken: 'tfa/setTfaToken',
      setUser: "account/setUser",
    }),
  },
  mixins: [eventHandler, translations],
})
export default class AuthenticationStep2 extends Vue {
  setQRUri!: Function
  setSecretKey!: Function
  setTfaToken!: Function
  setUser!: Function
  private getUser2!: any
  translate: any
  private getQRUri!: any
  private getSecretKey!: any
  qr_uri: string = ''
  secret_key: string = ''
  active: string = 'qr'
  constructor() {
    super();
  }

  async mounted() {
    await this.setUser(this.getUser2)
    await Auth.getTOTP().then((response: any) => {
      // this.setQRUri(response.data['qr-uri'])
      // this.setSecretKey(response.data['secret-key'])
      this.setTfaToken(response.data['tfa-token'])
      this.qr_uri = response.data['qr-uri']
      this.secret_key = response.data['secret-key']
    }).catch((err: any) => {
      console.log(err)
    })
    // this.qr_uri = this.getQRUri
    // this.secret_key = this.getSecretKey
  }

  chooseActive(active: string) {
    this.active = active
  }

  copySecret() {
    console.log('secret-key', this.secret_key)
    
    let secret: any = document.getElementById("secret_key")
    secret.select()
    secret.setSelectionRange(0, 99999)
    document.execCommand("copy")
  }
}
