
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { eventHandler, translations } from "@/mixins";
import CouponGroupsCreateModal from './CouponGroupsCreateModal';
import {Banner as BannerApi} from "@/services/SOLO";
@Component({
  components: {
    CouponGroupsCreateModal,
  },
  mixins: [eventHandler, translations]
})
export default class CouponsHeader extends Vue {
  translate!: Function;
  confirm!: Function;
  openCreate: boolean = false

  onNew() {
    this.openCreate = true;
  }

  onCreateCoupon() {
    if (this.$route.query.digital) {
      this.$router.push({ path: `/digital-coupons/create?digital=true` });
    } else {
      this.$router.push({ path: `/coupon-groups/coupon-create/${this.$route.params.id}` });
      // this.confirm(this.$bvModal, 'Do you want to generate bulk coupons?').then(
      //   (value: boolean) => {
      //     if (value) {
      //       this.$router.push({ path: `/coupon-groups/coupon-create/${this.$route.params.id}?bulk=true` });
      //     } else {
      //       this.$router.push({ path: `/coupon-groups/coupon-create/${this.$route.params.id}` });
      //     }
      //   }
      // );
    }
  }

  handleClose(value: any) {
    this.openCreate = value;
  }
}
