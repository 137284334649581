
import itemCreateModal from './itemCreateModal.vue'
import { notificationAlerts, translations } from '@/mixins'
import { Category as CategoryApi, Location, Menu, Integrations } from "@/services/SOLO";
import { Category as CategoryModel } from "@/models";
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import { eventHandler } from '@/mixins'
import { Pagination, Select, Option } from "element-ui";
import menuRoles from '@/directives/menuRoles';
import { mapGetters } from 'vuex';
import LoadingPanel from "@/components/LoadingPanel";
import draggable from "vuedraggable";
import DisableCategoryItemModal from './DisableCategoryItemModal';
@Component({
  components: {
    itemCreateModal,
    [Pagination.name]: Pagination,
    [Select.name]: Select,
    [Option.name]: Option,
    LoadingPanel,
    draggable,
    DisableCategoryItemModal
  },
  directives: {
    menuRoles
  },
  mixins: [eventHandler, translations, notificationAlerts],
  computed: {
    ...mapGetters({
      getMenus: 'menu/getMenus',
      getLocale: 'app/getLocale',
      userRole: 'account/getUserRole',
      getUserClient: 'account/getUserClient',
    })
  },
})
export default class ItemList extends Vue {
  getLocale!: any;
  private getMenus!: any;
  private getUserClient!: any;
  @Prop() menuId!: string;
  @Prop() categoryId!: string;
  @Prop() active!: any
  open: Boolean = false
  logo: string = '/img/menu_default.png';
  defaultIMG: string = "https://solo.skylinedynamics.com/images/default-image.png";
  page: number = 1
  items: Array<any> = []
  ItemsMeta: Array<any> = []
  total: number = 0;
  currentPage: number = 1;
  perPage: number = 0;
  selectedLocationList: any = []
  selectedLocation?: any = ''
  integrations?: any = ''
  hasRevelIntegration?: boolean = false
  confirm!: Function
  translate!: Function
  successNotification!: Function
  $notify: any;
  loadingConcept: Boolean = true;
  userRole!: any;
  isDisableCategoryItemOpen: Boolean = false;
  itemId: any = 0;
  showGripIconItem: any = null;
  uploadImgItemId: any = 0;
  indexImgItemId: any = 0;
  $refs!: {
    file: any;
  };
  get deleteMsgBoxText(): String {
    return this.translate('Are you sure you want to delete this Item?')
  }

  mounted() {
    this.getCategoryItems(this.page)
    this.showActiveLocations()
    this.getIntegrations()
  }

  onHoverCard(ind: any){
    this.showGripIconItem = ind
  }

  uploadImage(itemId: any, index: any) {
    this.uploadImgItemId = itemId;
    this.indexImgItemId = index;
    /* @ts-ignore */
    document.getElementById('upload').click();
  }

  filesUploadChange() {
    let formData = new FormData();
    formData.append('image', this.$refs.file.files[0]);
    Menu.updateItemFormdata(
      formData,
      this.$route.params.id,
      this.$route.params.catId,
      this.uploadImgItemId
    )
    .then((response: any) => {
      this.items[this.indexImgItemId].attributes['image-uri'] = response.data.data.attributes['image-uri'];
      this.$notify({
        title: "UPDATES SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Image have been upload successfully",
        type: "success",
        icon: "fas fa-check",
      });
    })
    .catch((err: any) => {
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb",
      });
    });
  }

  sortItems() {
    const promiseArr: any = [];
    let dynamicIndex = ((this.currentPage - 1) * this.perPage) + 1;
    this.items.forEach((item: any) => {
      Menu.updateItem({'display-order': dynamicIndex}, this.$route.params.id, this.$route.params.catId, item.id);
      dynamicIndex += 1;
    });
  }

  public get isFoodics() {
    return this.getUserClient?.attributes?.label === 'Foodics Online';
  }

  pageChange(data: any) {
    this.getCategoryItems(data);
    this.page = data;
  }

  checkScreenWith() {
    if(window.screen.width == 1366) {
      return 3;
    }
      return 2;
  }

  modalOpenState(open: Boolean = true) {
    this.open = open
  }

  async showActiveLocations() {
    await Location.getAllLocations().then((response: any) => {
      this.selectedLocationList = response.data.data
    });
  }

  async loadItemsByLocation(event: any) {
    let menuId = this.getMenus[0].id
    let catId = parseInt(this.$route.params.catId)
    this.items = []
    await Menu.findItemsByLocation(menuId, catId, event).then((response: any) => {
      this.items = response.data.data
      this.total = response.data.meta.pagination.total;
      this.currentPage = response.data.meta.pagination.current_page;
      this.perPage = response.data.meta.pagination.per_page;
    })
  }

    async getIntegrations() {
    await Integrations.getAllIntegrations()
      .then((response: any) => {
        this.hasRevelIntegration = response.data.data.some((i: any) => i.attributes.provider === 'revel');
      })
  }

  confirmationMessageTitle(toDelete: any) {
    const h = this.$createElement
    const message = h('div', 
      { domProps: 
        { innerHTML: this.translate('Are you sure') + ` <span class="font-weight-bold">${toDelete.attributes['name']}${this.translate('?')}</span><br><br>${this.translate('Please note')}`}
      })
    
    return message
  }

  async removeCategoryItem(item: any) {
    try {
      const isDelete = await this.confirm(this.$bvModal, this.confirmationMessageTitle(item),
        {
          title: this.translate('Please Confirm'),
          okTitle: this.translate('OK'),
          cancelTitle: this.translate('Cancel'),
          footerClass: this.getLocale === 'ar_SA' ? 'd-flex flex-row-reverse flex-gap' : '',
        }
      )
      if (isDelete) {
        await CategoryApi.removeCategoryItem(this.$route.params.id, this.$route.params.catId, item.id)
        await this.findAndUpdateItem(item.id, 'remove');
        this.$notify({
          title: this.translate("CATEGORY DELETED"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate("Category Successfully Deleted."),
          type: "success",
          icon: "fas fa-check",
        });
      }
    } catch (error) {
      this.$notify({
        title: this.translate("SYSTEM ERROR!"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate("Something went wrong, please try again!"),
        type: "danger",
        icon: "fas fa-bomb",
      });
    }
  }

  enableDisableCategoryItem(enabled: Boolean, itemId: string) {
    let payload = {
      enabled: !enabled
    }
    console.log(payload)
    CategoryApi.enableCategoryItem(payload, this.$route.params.id, this.$route.params.catId, itemId)
      .then(response => {
        if (payload.enabled) {
          this.successNotification('ITEM UPDATED!', 'Item status successfully enabled!');
        } else {
          this.successNotification('ITEM UPDATED!', 'Item status successfully disabled!');
        }
        this.findAndUpdateItem(itemId, 'enableDisable', payload.enabled);
      })
       .catch((err) => {
          this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Franchisers has no permission on this request!",
              type: "danger",
              icon: "fas fa-bomb",
            });
        });
  }

  enableNonAdminItem(itemId: string) {
    let payload = {
      'schedule-disabled-hours': '__enabled__',
    }
    CategoryApi.enableCategoryItem(payload, this.$route.params.id, this.$route.params.catId, itemId)
      .then(response => {
        this.successNotification('ITEM UPDATED!', 'Item status successfully enabled!');
        this.findAndUpdateItem(itemId, 'enableNonAdminItem', true);
      })
       .catch((err) => {
          this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Franchisers has no permission on this request!",
              type: "danger",
              icon: "fas fa-bomb",
            });
        });
  }

  disableCategoryItemModal(open: any, itemId: string) {
    this.isDisableCategoryItemOpen = !!open;
    this.itemId = itemId;

    if (!open) {
      this.items.forEach(element => {
        if (element.id == itemId) {
          element.attributes['is-enabled-for-employee'] = open;
        }
      });
    }
  }

  findAndUpdateItem(itemId: string, action: string, value: Boolean = true) {
    switch (action) {
      case 'enableDisable':
        this.items.forEach(element => {
          if (element.id == itemId) {
            element.attributes.enabled = (value == true ? 1 : 0);
          }
        });
        break;
      case 'remove':
        this.items.forEach((element: any, index: any) => {
          if (element.id == itemId) {
            this.items.splice(index, 1);
          }
        });
        break;
      case 'enableNonAdminItem':
        this.items.forEach(element => {
          if (element.id == itemId) {
            element.attributes['is-enabled-for-employee'] = true;
          }
        });
        break;
    }
  }

  closeCategoryItemModal(close: any) {
    this.isDisableCategoryItemOpen = !!close;
  }

  getCategoryItems(page: any) {
    CategoryApi.items(this.$route.params.id, this.$route.params.catId, page).then((response: any) => {
      this.loadingConcept = false;
      this.items = response.data.data;
      this.total = response.data.meta.pagination.total;
      this.currentPage = response.data.meta.pagination.current_page;
      this.perPage = response.data.meta.pagination.per_page;
    })
  }

  onAddItem() {
    this.$router.push({
      name: "itemCreate"
    })
  }
}
