
import Modal from '@/components/Modal.vue'
import selectedComponentItems from './SelectedComponentItems.vue'
import AvailableComponentItems from './AvailableComponentItems.vue'
import { Select, Option } from 'element-ui'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler, translations } from '@/mixins'
import { Menu as MenuApi, Settings } from '@/services/SOLO';
import { GlobalIng, GlobalAllergens as GlobalAllergensModel, ModifierGroupItem, ComboComponent, ComponentName } from '@/models'
import BaseButton from '@/components/BaseButton.vue'

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    selectedComponentItems,
    ValidationProvider,
    AvailableComponentItems,
    BaseButton
  },
  mixins: [eventHandler, translations]
})
export default class ComboMealsModal extends Vue {       
  refreshKeyComponentItems: number = 0 
  imageType: number = 0
  arrayLang: Array<string> = []
  selectedComponentItems: Array<Object> = []
  itemComponentsAvailable: Array<Object> = []
  selectedItemIsLoading: Boolean = false
  availableItemIsLoading: Boolean = false
  componentId: string = ''
  component: ComboComponent = {
    label: '',
    name: {},
    display_order: 1,
    code: '',
    minimum: 0,
    maximum: 1,
    'image-uri': ''
  }
  componentName: ComponentName = {}
  imageTypes: Array<Object> = [
    {
      value: 0,
      text: "Image URL",
    },
    {
      value: 1,
      text: "Image",
    },
  ]

  $refs!: {  
    file: any
  }

  constructor() {
    super()
    for(let i in this.arrayLang)
      this.componentName[this.arrayLang[i]] = ''
  }

  getLang!: Function
  getLanguages!: Function

  @Prop() open!: Boolean
  @Prop() updateData!: any
  @Prop() isUpdate!: Boolean
  $notify: any
  translate!: Function  

  created() {
    this.arrayLang = this.getLanguages()
  }

  @Watch('updateData', {immediate: true, deep: true})
  onUpdateDate(newVal: any) {    
    if(newVal.id) { 
      this.componentId = newVal.id               
      this.component.label= newVal.attributes.label,
      this.component.code= newVal.attributes.code
      this.component['display_order']= newVal.attributes['display-order']
      this.component['image-uri']= newVal.attributes['image-uri']
      this.component.label= newVal.attributes.label
      this.component.maximum= newVal.attributes.maximum
      this.component.minimum= newVal.attributes.minimum
      this.getComponentItems(newVal.id)
      this.getAvailableComponentItems()
      for(let i in this.arrayLang) {
        this.getComboItem(newVal.id, this.arrayLang[i])
      }
    }    
  }

  get componentData(): ComboComponent {
    this.component.name = this.componentName
    return this.component
  }

  get isOpen(): Boolean {    
    return this.open
  }

  close() {
     this.$emit('modal:close', false)
  }

  set isOpen(newVal: Boolean) {    
    this.$emit('modal:update', newVal)
    if(!newVal) {
      this.component = {
        label: '',
        name: {},
        display_order: 1,
        code: '',
        minimum: 0,
        maximum: 1,
        'image-uri': ''
      }
      this.componentId = ''
      this.itemComponentsAvailable = []
      this.selectedComponentItems = []
      this.selectedItemIsLoading = false
      this.availableItemIsLoading = false
      for(let i in this.arrayLang)
        this.componentName[this.arrayLang[i]] = ''
    }
  }

  getComponentData() {
    this.getComponentItems(this.componentId);
  }

  async getComponentItems(id: string) {    
    try {
      this.selectedItemIsLoading = true
      const response = await MenuApi.getComboComponentItems(id)
      this.selectedComponentItems = response.data.data
    } catch (error) {
      console.error('Error in getting component items', error)
    } finally {
      this.selectedItemIsLoading = false      
    }
  }

  async getAvailableComponentItems(){
    try {
      this.availableItemIsLoading = true
      const response = await MenuApi.searchComboItems(this.$route.params.id)
      this.itemComponentsAvailable = response.data.data
      this.refreshKeyComponentItems++
    } catch (error) {
      console.error('Error in getting available items', error)
    } finally {
      this.availableItemIsLoading = false
    }
  }
  
  async getComboItem(id: string, lang: string) {
    try {
      const response = await MenuApi.getComboItem(this.$route.params.id, this.$route.params.itemId, id, lang)
      this.componentName[lang] = response.data.data.attributes.name
    } catch (error) {
      console.error('Error in getting combo item', error)
    }
  }  

  async saveComponent(e: any, reset: any) {
    let target = e.target
    let defaultText = target.innerHTML
    target.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Saving component'  
    target.disabled = true
    try {
      if (!this.isUpdate) {
        await MenuApi.createComboComponent(this.componentData, this.$route.params.itemId)
          .then((response: any) => {
            this.$emit('component:added', response.data.data)
            this.$emit('component:refresh');
            this.$notify({
              title: this.translate("CREATED SUCCESSFULLY!"),
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: this.translate("Component has been created successfully"),
              type: "success",
              icon: "fas fa-check"
            })
          })
          .catch((err: any) => {
            console.log(err.response);
            this.$notify({
              title: this.translate("SYSTEM ERROR!"),
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: err.response.data.error.detail,
              type: "danger",
              icon: "fas fa-bomb",
            });
        });
      } else {
        await MenuApi.updateComboComponent(this.componentData, this.$route.params.itemId, this.componentId)
        this.$emit('component:refresh')
      }
    } catch (error) {
      console.error(`Error in ${this.isUpdate ? 'updating' : 'saving'} component`, error)
    } finally {
      setTimeout(() => {
      target.innerHTML = defaultText;
      target.disabled = false;
      this.$emit('modal:update', false);
    }, 9000);   
    }
  }
}
