import * as Ably from 'ably';
import {Howl, Howler} from 'howler';
import store from '@/store'

const ablyInstances: Ably.Realtime[] = [];
const ablyKey = process.env.VUE_APP_ABLY_KEY;

for (let i = 0; i < 5; i++) {
  ablyInstances.push(new Ably.Realtime(ablyKey));
}

let index = 0;

function getAbly(): Ably.Realtime {
  let currentInstance = ablyInstances[index];

  index++;

  if (index >= ablyInstances.length) {
    index = 0;
  }

  return currentInstance;
}

let eventHandler = {
  methods: {
    waiting(e: any, loadingString: string) {
      e.target.innerHTML = loadingString
      e.target.disabled = true
    },
    btnLoading(e: any, loadingString: string) {
      console.log(e.target)
      e.target.innerHTML = loadingString
      e.target.disabled = true
    },
    restore(e: any, loadingString: string) {
      e.target.innerHTML = loadingString
      e.target.disabled = false
    },
    btnRestore(e: any, loadingString: string) {
      e.target.innerHTML = loadingString
      e.target.disabled = false
    },
    confirm(modal: any, msg: string, config: Object = {}) {
      return modal.msgBoxConfirm(msg, {...this.modalConfig(), ...config})
    },
    confirm1(modal: any, msg: string, config: Object = {}) {
      return modal.msgBoxConfirm(msg, {...this.modalConfig1(), ...config})
    },
    modalConfig() {
      return {
        centered: true,
        title: 'Please Confirm'
      }
    },
    modalConfig1() {
      return {
        centered: true,
        title: 'Warning!'
      }
    },
    broadcastedSoloOrder(conceptId: string) {
      return getAbly().channels.get(`solo:merchant-${conceptId}:order:create`);
    },
    broadcastedSoloOrderNonAdmin(conceptId: string, branchId: string) {
      return getAbly().channels.get(`solo:merchant-${conceptId}:location-${branchId}:order:create`);
    },
    broadcastedSoloOrderStatus(conceptId: string, orderId: string) {
      return getAbly().channels.get(`solo:merchant-${conceptId}:order-${orderId}:update`);
    },
    broadcastedSoloMenu(conceptId: string) {
      return getAbly().channels.get(`solo:merchant-${conceptId}:menu:update`);
    },
    broadcastedDriverAssign(conceptId: string, employeeId: string) {
      return getAbly().channels.get(`solo:merchant-${conceptId}:employee-${employeeId}:driver:assign`);
    },
    broadcastedOrderAssign(conceptId: string, orderId: string) {
      return getAbly().channels.get(`solo:merchant-${conceptId}:order-${orderId}:driver:assign`);
    },
    notif(sound: string) {
      var audio = new Audio();
      audio.src = sound
      return audio
    },
    isDisabled(role: string): Boolean {
      return store.getters['account/getUser'].attributes.roles.includes(role)
    }
  }
}

export default eventHandler

