
  import AppDetails from './components/AppDetails'
  import PaymentDelivery from './components/PaymentDelivery'
  import Theme from './components/Theme'
  import ChangeLogs from './components/ChangeLogs'
  import Content from './components/Content'

  import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
  import {Applications} from '@/services/SOLO'
  import { mapGetters } from 'vuex'
  import { translations } from '@/mixins' 
    
  @Component({
    components: {
      AppDetails,
      PaymentDelivery,
      Theme,
      ChangeLogs,
      Content
    },
    mixins: [translations],
    computed: {
    ...mapGetters({
      getConceptSettings: "account/getConceptSettings",
    }),
   
  },
  })
  export default class EditAppItem extends Vue {
    getConceptSettings!: Array<typeof mapGetters>
    tab: number = 1
    id: any
    theme: Array<Object> = []
    details: any = ''
    popups: any = ''
    paymentGateway: any = ''
    getPackageType: any = {}
    isFreePackage: any = {}
    translate!: Function
    app_type: any = ''

    mounted() {
      this.getPackageType = this.getConceptSettings
      this.isFreePackage = this.getPackageType.attributes['is-free-package']
      this.id = this.$route.params.id
      this.getApplicationItem(this.id)
    }

    async getApplicationItem(id:any) {
      await Applications.getApplication(id)
        .then((response: any) => {
          this.details = response.data.data
          console.log("detailsss", this.details);
          this.popups = response.data.included
          this.app_type = response.data.data.attributes['application-type']
        })
    }

    get dropDownText(): string {
      if (this.tab === 1) {
        return this.translate('App Details')
      } else if (this.tab === 2) {
        return this.translate('Payment and Delivery')
      } else if (this.tab === 3) {
        return this.translate('Customize')
      } else if (this.tab === 4)  {
        return this.translate('Change Logs')
      } else {
        return this.translate('Content')
      }                  
    }

  }
