
import { Select, Option } from 'element-ui';
import { mapGetters } from 'vuex';
import { Integrations as IntegrationService, Order as OrderApi } from '@/services/SOLO';
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
import { eventHandler, translations } from '@/mixins';
import CancelByEmployeeModal from './CancelByEmployeeModal.vue'

@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    CancelByEmployeeModal,
  },
  computed: {
    ...mapGetters({
      getCurrentLocale: 'app/getLocale',
      // activeLanguage: 'account/activeLanguage',
    })
  },
  mixins: [eventHandler, translations]
})
export default class OrderStatus extends Vue {
  confirm!: Function;
  translate!: Function;
  getCurrentLocale!: Array<typeof mapGetters>
  code: string = ''
  description: string = ''
  updateStatus: any = ''
  convertStatuses: any = ''
  currentLocale: any = ''
  @Prop() orderId!: string;
  @Prop() statuses!: Array<Object>;
  @Prop() orderStatusCode!: string;
  @Prop() orderType!: string;
  @Prop() isMobile: any;

  $notify!: any;
  selectedCode: string = '';
  isCancelOpen: Boolean = false;

  mounted() {
    this.selectedCode = this.orderStatusCode;
    this.filterStatuses()
    this.convertLocale()
  }

  @Watch("orderStatusCode", { deep: true })
  onStatusesCodeChanged(newVal: string, oldVal: string) {
    this.selectedCode = newVal
  }

  public isCancelled(selectedCode: string) {
    return selectedCode.toLowerCase().includes('cancel');
  }

  convertLocale() {
    this.currentLocale = this.getCurrentLocale
    this.currentLocale = this.currentLocale
        .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map((x: any) => x.toLowerCase())
        .join('-')
    console.log('currentLocale', this.currentLocale);
  }

  filterStatuses() {
    let someStatuses
    if (this.orderType !== 'deliver') {
      someStatuses = this.statuses.filter((status:any) => !['dispatched', 'delivery-in-progress', 'arrived-at-customer'].includes(status.attributes.code) && status.attributes['is-visible-pickup'])

      this.updateStatus = someStatuses
    } else {
      this.updateStatus = this.statuses.filter((status:any) => status.attributes['is-visible-deliver'])
    }
  }

  updateStatusChange(payload: any) {
    OrderApi.updateStatus(payload, this.orderId)
      .then((response: any) => {
        this.$notify({
          title: "STATUS CHANGED!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: "Status have been successfully changed",
          type: "success",
          icon: "fas fa-check",
        });
      })
      .catch((err: any) => {
        const message = JSON.parse(err?.response?.data?.messages)?.errors?.status[0]
          || JSON.parse(err?.response?.data?.messages)?.message
          || 'Something went wrong, please try again!';
        console.log('err:' , JSON.parse(err?.response?.data?.messages));
        this.selectedCode = this.orderStatusCode;
        this.$notify({
          title: "SYSTEM ERROR!",
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message,
          type: "danger",
          icon: "fas fa-bomb",
        });
      });
  }

  cancelByEmployeeModal(open: any, selectedCode: string = '') {
    this.isCancelOpen = open;
  }
  
  closeModal(close: any) {
    this.isCancelOpen = close;
    this.selectedCode = this.orderStatusCode;
  }


  updateOrderStatus(data: any) {
    if (data == 'cancelled-by-employee') {
      this.cancelByEmployeeModal(true);

      return;
    }

    // let selectedCode = {}

    this.selectedCode = data

    this.updateStatus.filter((code: any) => {
      if (code.attributes.code === data) {
        this.selectedCode = code.attributes.code;
      }
    });

    let payload = {
        'order-status': this.selectedCode,
    };

    if (this.isMobile && (this.selectedCode === 'cancelled-by-customer' || this.selectedCode === 'cancelled-by-employee')) {
      this.confirm(
        this.$bvModal,
        this.translate('Are you sure you want to do this?'),
        {
          title: 'Attention!',
          okTitle: 'YES',
          cancelTitle: 'NO',
          okVariant: 'danger',
        },
      ).then(async (value: boolean) => {
        if (value) {
          this.updateStatusChange(payload);
        } else {
          this.selectedCode = this.orderStatusCode;
        }
      });
    } else {
      this.updateStatusChange(payload);
    }
  }

  convertToKebabCase(string: any) {
    return string.replace(/\s+/g, '-').toLowerCase();
  }
}
