
import { Settings } from '@/services/SOLO';
import { Component, Prop, Vue, Watch, Emit, Ref } from "vue-property-decorator";
import { eventHandler, translations } from '@/mixins'
import { mapGetters } from 'vuex';

@Component({
  mixins: [eventHandler, translations],
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    })
  }
})

export default class GlobalIngredientsList extends Vue {
  
  @Prop() items!: Array<Object>
  
  btnLoading!: Function
  btnRestore!: Function
  confirm!: Function
  $notify: any
  translate!: Function
  getLocale!: any

  enableMsgBoxText(enabled: number) {
    return enabled ? 'Proceed enabling this item?' : 'Proceed disabling this item?'
  }

  get deleteMsgBoxText(): String {
    return 'Are you sure you want to delete this Ingredient?'
  }

  confirmationMessageTitle(toDelete: any) {
    const h = this.$createElement
    const message = h('div', 
      { domProps: 
        { innerHTML: this.translate('Are you sure') + ` <span class="font-weight-bold">${toDelete.attributes['name']}${this.translate('?')}</span><br><br>${this.translate('Please note')}`}
      })
    
    return message
  }

  async remove(item: any) {
    try {
      const isDelete = await this.confirm(this.$bvModal, this.confirmationMessageTitle(item),
        {
          title: this.translate('Please Confirm'),
          okTitle: this.translate('OK'),
          cancelTitle: this.translate('Cancel'),
          footerClass: this.getLocale === 'ar_SA' ? 'd-flex flex-row-reverse flex-gap' : '',
        }
      )
      if (isDelete) {
        await Settings.deleteIngredient(this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, item.id)
        this.$emit('ingredient:changed')
        this.$notify({
          title: this.translate("INGREDIENT DELETED"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate("Ingredient successfully deleted."),
          type: "success",
          icon: "fas fa-check",
        });
      }
    } catch (error) {
      this.$notify({
        title: this.translate("SYSTEM ERROR!"),
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: this.translate("Something went wrong, please try again!"),
        type: "danger",
        icon: "fas fa-bomb",
      });
    }
  }

  enable(id: string, enabled: number) {
    this.confirm(this.$bvModal, this.enableMsgBoxText(enabled))
      .then((value: boolean) => {
        if(value) {
          Settings.updateIngredient({enabled: enabled ? 1 : 0}, this.$route.params.id, this.$route.params.catId, this.$route.params.itemId, id)
          .then((response: any) => {                        
            this.$emit('ingredient:changed')
            this.$notify({
              title: "DATA SAVED",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: enabled ? 'Ingredient enabled!' : 'Ingredient disabled',
              type: "success",
              icon: "fas fa-check",
            })
          })
          .catch((err: any) => {                
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Something went wrong, please try again!",
              type: "danger",
              icon: "fas fa-bomb",
            });
          })
        }
      })
  }
}
