
import { mapGetters, mapMutations } from "vuex"
import { eventHandler, translations } from "@/mixins"
import LangSwitcher from '@/components/LangSwitcher'
import {
  Component,
  Vue,
  Watch
} from "vue-property-decorator"
import { Auth } from "@/services/core"
import { Subscription, SettingsConcepts, Concept as ConceptApi, Menu } from "@/services/SOLO"
import { Concept } from "@/models"

@Component({
  components: {
    LangSwitcher
  },
  computed: {
    ...mapGetters({
      getUserClient: 'account/getUserClient',
      getTfaToken: 'tfa/getTfaToken',
      activeConcept: 'account/activeConcept',
    }),
  },
  methods: {
    ...mapMutations({
      setUserConcept: 'account/setUserConcept',
      setUser: "account/setUser",
      setUserRole: "account/setUserRole",
      setConceptSettings: 'account/setConceptSettings',
      setSubscription: 'account/setSubscription',
      setUserLanguage: 'account/setUserLanguage',
      setMenus: 'menu/setMenus',
    }),
  },
  mixins: [eventHandler, translations],
})
export default class AccountProtected extends Vue {
  private getUserClient: any
  private getTfaToken!: any
  translate: any
  digits: any = []
  invalid: boolean = true
  waiting!: Function
  restore!: Function
  errVerify: string = ''
  setUserConcept!: Function
  setUser!: Function
  setUserRole!: Function
  activeConcept!: Concept
  setConceptSettings!: Function
  setSubscription!: Function
  setUserLanguage!: Function
  setMenus!: Function
  constructor() {
    super();
  }

  mounted() {}

  @Watch("digits", { immediate: true, deep: true })
  onDigitChanged(val: any) {
    var filtered = val.filter(function (el: any) {
      return el != null && el !== ''
    })
    console.log(filtered.length, filtered)
    if (filtered.length > 5) {
      this.invalid = false
    } else {
      this.invalid = true
    }
  }

  public get logo(): string {
    if(this.getUserClient) {
      return this.getUserClient.attributes['logo-uri']
    }
    return `https://cdn.getsolo.io/system/portal-logo.png`
  }

  async verify(e: any) {
    const defaultText = e.target.innerHTML
    this.waiting(e, '<i class="fas fa-spinner fa-spin"></i> Verifying')
    this.errVerify = ''
    let payload = {
      'type': 'totp',
      'tfa-token': this.getTfaToken,
      'otp-code': this.digits.join('')
    }
    Auth.authenticate(payload).then((response: any) => {
      this.getSubscriptionFn(response.data.data, response.data.data.attributes.roles)
      this.getConcepts()
      this.restore(e, defaultText)
      // this.setQRUri(null)
      // this.setSecretKey(null)
    }).catch((err: any) => {
      this.restore(e, defaultText)
      if (err.response.data.error[0]) {
        this.errVerify = err.response.data.error[0].detail
      } else {
        this.errVerify = err.response.data.error.detail
      }
    })
  }

  private getSubscriptionFn(data: any, roles: any) {
    this.setUserConcept(data.attributes.concepts[0])
    this.setUser(data)
    this.setUserRole(roles)
    Subscription.getSubscription().then(response => {
      this.getAccountConcept(data,roles, response.data.data)
    }).catch(err => {
      this.getAccountConcept(data,roles, null)
    })
  }

  getConcepts() {
    SettingsConcepts.get().then((response: any) => {
      this.getLanguages(response.data.data);
    }).catch((err: any) => {
      console.log(err)
    })
  }

  private getAccountConcept(data: any, roles: any, subscription: any) {
    ConceptApi.get(this.activeConcept.id).then(response => {
      console.log(response.data.data)
      this.setConceptSettings(response.data.data)
      this.setSubscription(subscription)
      this.getApps()
      this.$router.push({name: 'dashboard'})
    })
  }

  private getLanguages(data: any) {
      this.setUserLanguage(data.attributes.languages[1])
  }

  getApps () {
    Menu.all().then((response: any) => {
      this.setMenus(response.data.data)
    }).catch((err: any) => {
      console.log(err)
    })
  }

  focusnext(tab = 0, e: any) {
    const input = String.fromCharCode(e.keyCode)
    const currInput:any = document.querySelectorAll('input[type="text"]')
    const nextInput:any = document.querySelectorAll('input[type="text"]')
    if (/[0-9]/.test(input)) {
      currInput[tab-1].value = e.key
      nextInput[tab].focus()
    } else {
      currInput[tab-1].value = ''
    }
  }

  focusverify(tab = 0, e: any) {
    const currInput:any = document.querySelectorAll('input[type="text"]')
    currInput[tab-1].value = e.key;
    const verify:any = document.querySelector('#login_button')
    verify.focus()
  }
}
