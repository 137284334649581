
import { mapGetters, mapMutations } from "vuex"
import { eventHandler, translations } from "@/mixins"
import LangSwitcher from '@/components/LangSwitcher'
import {
  Component,
  Vue,
  Watch
} from "vue-property-decorator"
import { ValidationObserver, configure } from "vee-validate"
import { Auth } from "@/services/core"
import { Subscription, SettingsConcepts, Concept as ConceptApi, Menu } from "@/services/SOLO"
import { Concept } from "@/models"

@Component({
  components: {
    LangSwitcher
  },
  computed: {
    ...mapGetters({
      getTfaToken: 'tfa/getTfaToken',
      getUser: 'account/getUser',
      getUser2: 'account/getUser2',
      getUserRole: 'account/getUserRole',
      activeConcept: 'account/activeConcept',
    }),
  },
  methods: {
    ...mapMutations({
      setUserConcept: 'account/setUserConcept',
      setUser: "account/setUser",
      setUser2: "account/setUser2",
      setUserRole: "account/setUserRole",
      setConceptSettings: 'account/setConceptSettings',
      setSubscription: 'account/setSubscription',
      setUserLanguage: 'account/setUserLanguage',
      setMenus: 'menu/setMenus',
      setQRUri: 'tfa/setQRUri',
      setSecretKey: 'tfa/setSecretKey',
    }),
  },
  mixins: [eventHandler, translations],
})
export default class AuthenticationStep3 extends Vue {
  setQRUri!: Function
  setSecretKey!: Function
  private getTfaToken!: any
  private getUser!: any
  private getUser2!: any
  private getUserRole!: any
  translate: any
  digits: any = []
  $refs!: {
    formValidator: InstanceType<typeof ValidationObserver>;
  };
  invalid: boolean = true
  waiting!: Function
  restore!: Function
  errVerify: string = ''
  setUserConcept!: Function
  setUser!: Function
  setUser2!: Function
  setUserRole!: Function
  activeConcept!: Concept
  setConceptSettings!: Function
  setSubscription!: Function
  setUserLanguage!: Function
  setMenus!: Function
  constructor() {
    super();
  }

  mounted() {}

  @Watch("digits", { immediate: true, deep: true })
  onDigitChanged(val: any) {
    var filtered = val.filter(function (el: any) {
      return el != null && el !== ''
    })
    console.log(filtered.length, filtered)
    if (filtered.length > 5) {
      this.invalid = false
    } else {
      this.invalid = true
    }
  }

  async verify(e: any) {
    const defaultText = e.target.innerHTML
    this.waiting(e, '<i class="fas fa-spinner fa-spin"></i> Verifying')
    this.errVerify = ''
    let payload = {
      'type': 'totp',
      'tfa-token': this.getTfaToken,
      'otp-code': this.digits.join('')
    }
    await this.setUser(this.getUser2)
    Auth.verifySetup(payload).then(async(response: any) => {
      // await this.setQRUri(null)
      // await this.setSecretKey(null)
      const refreshToken = await Auth.refreshToken()
      const user = await this.getUser2
      user.attributes.token = refreshToken.data.token
      await this.setUser(user)
      await this.setUser2(null)
      await this.getSubscriptionFn(this.getUser, this.getUserRole)
      this.getConcepts()
      this.restore(e, defaultText)
    }).catch(async(err: any) => {
      await this.setUser(null) 
      this.restore(e, defaultText)
      this.errVerify = err.response.data.error[0].detail
    })
  }

  async getSubscriptionFn(data: any, roles: any) {
    Subscription.getSubscription().then(response => {
      this.getAccountConcept(data,roles, response.data.data)
    }).catch(err => {
      this.getAccountConcept(data,roles, null)
    })
  }

  getConcepts() {
    SettingsConcepts.get().then((response: any) => {
      this.getLanguages(response.data.data);
    }).catch((err: any) => {
      console.log(err)
    })
  }

  private getAccountConcept(data: any, roles: any, subscription: any) {
    ConceptApi.get(this.activeConcept.id).then(response => {
      console.log(response.data.data)
      this.setConceptSettings(response.data.data)
      this.setSubscription(subscription)
      this.getApps()
      this.$router.push('/dashboard')
    })
  }

  private getLanguages(data: any) {
      this.setUserLanguage(data.attributes.languages[1])
  }

  getApps () {
    Menu.all().then((response: any) => {
      this.setMenus(response.data.data)
    }).catch((err: any) => {
      console.log(err)
    })
  }

  focusnext(tab = 0, e: any) {
    const input = String.fromCharCode(e.keyCode)
    const currInput:any = document.querySelectorAll('input[type="text"]')
    const nextInput:any = document.querySelectorAll('input[type="text"]')
    if (/[0-9]/.test(input)) {
      currInput[tab-1].value = e.key
      nextInput[tab].focus()
    } else {
      currInput[tab-1].value = ''
    }
  }

  focusverify(tab = 0, e: any) {
    const currInput:any = document.querySelectorAll('input[type="text"]')
    currInput[tab-1].value = e.key;
    const verify:any = document.querySelector('#login_button')
    verify.focus()
  }
}
