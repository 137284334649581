
import Modal from "@/components/Modal.vue";
import { Category, Settings, SettingsFacilities } from "@/services/SOLO";
import { Select, Option, Menu } from "element-ui";
import { ValidationObserver, configure, extend, validate } from "vee-validate";
import { Facility } from "@/models";
import {
  Component,
  ProvideReactive,
  Prop,
  Vue,
  Watch,
  Emit
} from "vue-property-decorator";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';
import {mapGetters} from "vuex";
import _ from 'lodash';

const FacilitiesFormModalProps = Vue.extend({
  props: {
    model: {
      type: Object,
      default: () => {
        attributes: {
        }
      }
    }
  }
});

@Component({
  components: {
    ImageUrlOrFileUpload: () =>
      import("@/views/Admin/global-component/ImageUrlOrFileUpload.vue"),
    Translations,
  },
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings',
    }),
  },
  mixins: [translations],
})
export default class FacilitiesFormModal extends FacilitiesFormModalProps {
  translate!: Function;
  open: boolean = false;
  parent: any;
  saving: boolean = false;
  data: any = {
    icon: "",
    icon_file: "",
    image: "",
    image_file: ""
  };
  $notify: any
  $refs!: {
    formValidator: any;
  };
  getConceptSettings!: any;
  arrayLang: Array<string> = [];
  numOfLoadedData: number = 0;
  namePrimary: String = '';
  primaryLang: String = '';
  nameSecondary: String = '';
  secondaryLang: String = '';

  @Watch("model", { immediate: true, deep: true })
  onModelChanged(val: Facility, oldVal: Facility) {
    this.initializeForms();
  }

  // created() {
  //   this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
  //   if (this.getConceptSettings.attributes['secondary-language']) {
  //     this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
  //   }
  //   for (let i in this.arrayLang) {
  //     this.name[this.arrayLang[i]] = '';
  //   }

  //   this.initializeData();
  // }

  mounted() {
    this.arrayLang.push(this.getConceptSettings.attributes['primary-language']);
    this.primaryLang = this.getConceptSettings.attributes['primary-language'];
    if (this.getConceptSettings.attributes['secondary-language']) {
      this.arrayLang.push(this.getConceptSettings.attributes['secondary-language']);
      this.secondaryLang = this.getConceptSettings.attributes['secondary-language'];
    }

    this.initializeForms();
  }

  initializeForms() {
    this.numOfLoadedData = 0;

    this.namePrimary = "";
    this.nameSecondary = "";
    this.data.icon = "";
    this.data.icon_file = "";
    this.data.image = "";
    this.data.image_file = "";
    for (let i in this.arrayLang) {
      if (this.model.id) {
        this.findFacility(this.arrayLang[i], i);
      } else {
        this.numOfLoadedData = this.arrayLang.length;
      }
    }
  }

  findFacility(lang: string, index: any) {
    SettingsFacilities.find(
      this.model.id,
      lang
    ).then((response: any) => {
      let data = response.data.data;
      
      if (index == 0) {
        this.namePrimary = data.attributes.name;
      }
      if (index == 1) {
        this.nameSecondary = data.attributes.name;
      }
      this.data.icon = data.attributes.icon;
      this.data.icon_file = data.attributes.icon_file;
      this.data.image = data.attributes.image;
      this.data.image_file = data.attributes.image_file;

      this.numOfLoadedData++;
    });
  }

  onIconFileUploadChange(data: any) {
    this.data.icon_file = data.file;
    this.data.icon = data.name;
  }

  onImageFileUploadChange(data: any) {
    this.data.image_file = data.file;
    this.data.image = data.name;
  }

  closeMe() {
    this.open = false;
    this.$emit("close");
  }

  async submit(e: any) {
    const isValid = await this.$refs.formValidator.validate();
    if (!isValid || this.saving) {
      return;
    }

    this.saving = true;
    try {
      if (this.model.id) {
        const response = await SettingsFacilities.update(
          this.model.id,
          this.formData()
        );
      } else {
        const response = await SettingsFacilities.create(this.formData());
      }

      this.$emit("successfulFormSubmit");
      this.$notify({
        title: "RECORDS SAVED!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Record have been saved successfully",
        type: "success",
        icon: "fas fa-check"
      });

      this.saving = false;
      this.closeMe();
    } catch (error) {
      this.saving = false;
      this.$notify({
        title: "SYSTEM ERROR!",
        verticalAlign: "bottom",
        horizontalAlign: "left",
        message: "Something went wrong, please try again!",
        type: "danger",
        icon: "fas fa-bomb"
      });
    }
  }

  formData() {
    let formData = new FormData();
    /* @ts-ignore */
    formData.append(`name[${this.primaryLang}]`, this.namePrimary);
    if (this.secondaryLang) {
      /* @ts-ignore */
      formData.append(`name[${this.secondaryLang}]`, this.nameSecondary);
    }

    formData.append(
      "icon",
      this.data.icon_file ? this.data.icon_file : this.data.icon
    );
    formData.append(
      "image",
      this.data.image_file ? this.data.image_file : this.data.image
    );
    return formData;
  }

  // initializeData() {
  //   if (this.model.id) {
  //     this.data = {
  //       name: this.model.attributes.name,
  //       name_ar_sa: "",
  //       icon: this.model.attributes.icon,
  //       icon_file: "",
  //       image: this.model.attributes.image,
  //       image_file: ""
  //     };

  //     this.getRaSaName();
  //   } else {
  //     this.data = {
  //       name: "",
  //       name_ar_sa: "",
  //       icon: "",
  //       icon_file: "",
  //       image: "",
  //       image_file: ""
  //     };
  //   }
  // }
}
