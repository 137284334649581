import { render, staticRenderFns } from "./PromotionsList.vue?vue&type=template&id=026fef83&scoped=true"
import script from "./PromotionsList.vue?vue&type=script&lang=ts"
export * from "./PromotionsList.vue?vue&type=script&lang=ts"
import style0 from "./PromotionsList.vue?vue&type=style&index=0&id=026fef83&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "026fef83",
  null
  
)

export default component.exports