
import { translations } from '@/mixins'
import { ValidationObserver, configure } from 'vee-validate'
import { Component, Prop, Vue, Watch, Emit } from "vue-property-decorator";
import {mapGetters} from "vuex";

@Component({
    computed: {
    ...mapGetters({
      getLocale: "app/getLocale",
      // getAllowedButton: "button/getButton",
    }),
  },
  mixins: [translations]
})

export default class ConfirmCard extends Vue {

  private areaName: String = ''

  @Prop() isSaving!: Boolean
  @Prop() activeDeliveryArea!: Object
  @Prop() deliveryAreaIsUpdating!: Boolean
  @Prop() isAdding!: Boolean

  private updateCoordinates(bol: Boolean = true) {
    this.$emit('edit:coordinates', bol)
  }

  private updateName() {
    this.$emit('edit:name')
  }

  private updateArea() {
    this.$emit('update:coordinates')
  }

  private submitArea() {
    this.$emit('submit:area', this.areaName)
  }

  private cancel() {
    this.$emit('cancel:updates')
  }

  private deleteArea(activeDeliveryArea: any) {
    this.$emit('delete:coordinates', activeDeliveryArea);
  }
}
