
  import BreadCrumb from './Breadcrumb';
  import BreadCrumbItem from './BreadcrumbItem';
  import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';
  import {translations} from '@/mixins';
  import Translations from './Translations';

  @Component({
    components: {
      BreadCrumb,
      BreadCrumbItem,
      Translations
    },
    mixins: [translations]
  })

  export default class RouteBreadcrumb extends Vue {
    translate!: Function

    constructor() {
      super()
    }

    getBreadName(route: any) {
      return route.name;
    }

    displayName(name: any, destination: boolean) {
      // if (name.includes('_')) {
      //   name = name.split('_')[1];
      // }
      // return name.toUpperCase();

      name = this.capitalizeEveryWord(name);
      console.log('capitalizeEveryWord', name);
      if (name == 'Menucategory') {
        name = 'Categories';
      }
      if (name == 'Itemmodifier') {
        name = 'Items';
      }
      if (name == 'Modifier') {
        name = 'Modifier Groups';
      }
      if (name == 'Modifiergroupitem') {
        name = 'Modifier Options';
      }

      if (destination) {
        /* @ts-ignore */
        analytics.track('visit_page', {
          title: name,
        });
      }
      
      return name;
    }

    capitalizeEveryWord(name: any) {
      var splitStr = name.toLowerCase().split("_");
      
      if (splitStr.length == 1) {
        name = splitStr[0].charAt(0).toUpperCase() + splitStr[0].substring(1);
      } else {
        let newStr = [];
        for (var i = 0; i < splitStr.length; i++) {
          if (i < splitStr.length) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
          }
          name = splitStr.join(" ");
        }
      }

      return name;
    }
  }

  // export default {
  //   name: 'route-breadcrumb',
  //   components: {
  //     BreadCrumb,
  //     BreadCrumbItem
  //   },
  //   methods: {
  //     getBreadName(route) {
  //       return route.name;
  //     },
  //     displayName(name) {
  //       if (name.includes('_')) {
  //         name = name.split('_')[1];
  //       }
  //       return name.toUpperCase();
  //     }
  //   }
  // };
