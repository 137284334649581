
import { translations } from "@/mixins";
import { Component, Vue } from "vue-property-decorator";
import { mapGetters } from 'vuex'

import OnBoarding from "./Onboarding.vue"
import Dashboard from "./Dashboard.vue"


@Component({
  components: {
    OnBoarding,
    Dashboard
  },
  computed: {
    ...mapGetters({
      getIsWhatsAppOnBoarded: 'page/getIsWhatsAppOnBoarded',
    }),
  },
  mixins: [translations]
})
export default class WhatsApp extends Vue {
  translate!: Function;
  getIsWhatsAppOnBoarded!: any
  showDashboard: boolean = false;

  mounted() {
    this.showDashboard = this.getIsWhatsAppOnBoarded
  }

  goToDashboard(val: boolean) {
    this.showDashboard = val
  }
}
