
import { Component, Prop, Vue } from "vue-property-decorator";
import * as jwt from "jsonwebtoken";

@Component
export default class IFrameContainer extends Vue {
  @Prop({ type: String, required: true }) readonly merchantUuid!: string;
  @Prop({ type: Number, default: 21 }) readonly dashboard!: number;

  mounted() {
    // console.log(this.getMetabaseLoyaltyReportURL);
  }

  get getMetabaseLoyaltyReportURL() {
    if (this.merchantUuid) {
      const payload = {
        resource: { dashboard: 21 },
        params: {
          merchant_id: this.merchantUuid
        },
        exp: Math.round(Date.now() / 1000) + 10 * 60 // 10 minute expiration
      };
      const token = jwt.sign(payload, process.env.VUE_APP_METABASE_SECRET_KEY);

      return (
        process.env.VUE_APP_METABASE_SITE_URL +
        "/embed/dashboard/" +
        token +
        "#bordered=true&titled=true"
      );
    }
    return null;
  }
}
