
import Modal from '@/components/Modal.vue'
import {Select, Option} from 'element-ui'
import { translations } from '@/mixins'
import { ValidationObserver, configure, ValidationProvider } from "vee-validate"
import { Component, ProvideReactive, Prop, Vue, Watch, Emit } from "vue-property-decorator"
import { ElNotification } from "element-ui/types/notification";
import { mapGetters } from 'vuex'
import { eventHandler } from '@/mixins'
import { Menu as MenuApi, Settings, Item } from '@/services/SOLO';
import { Ingredient, ModifierGroupItem } from '@/models'


@Component({
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ValidationObserver,
    ValidationProvider
  },
  mixins: [eventHandler, translations]
})
export default class ModgroupModal extends Vue {  
  private customFieldId: string = ''
  private customFieldValue: string = ''
  
  @Prop() private selectedCustomFieldsValue!: string
  @Prop() private selectedCustomFieldId!: string
  @Prop() private open!: Boolean;
  @Prop() private entityId!: string
  @Prop() private customFields!: Array<any>
  @Prop() private selectedFields!: Array<any>

  constructor() {
    super();    
  }
  
  @Watch('selectedCustomFieldId', {immediate: true, deep: true})
  onCustomFieldId(string: string = '') {
    this.customFieldId = string
  }

  @Watch('selectedCustomFieldsValue', {immediate: true, deep: true})
  onCustomFieldValue(string: string = '') {
    this.customFieldValue = string
  }

  get isOpen(): Boolean {
    return this.open
  }

  set isOpen(newVal: Boolean) {    
    this.$emit('modal:update', newVal)    
  } 
  
  get customFieldData(): any {
    return {
      'entity': 'modifier-group',
      'entity-id': this.entityId,
      'value': this.customFieldValue
    }
  }

  private submit() {    
    Item.updateCategoryCustomField(this.customFieldData, this.customFieldId)
      .then((response: any) => {
        this.$emit('update:customFields')
        this.isOpen = false
      })
  }
}
