import BaseService from '../base.service'
import store from '@/store'

let locationService = class LocationService extends BaseService {
    url!: string
    constructor() {
        super()
    }

    getAllLocations(page: number = 1) {
        this.url = `${this.baseURL}locations?page=${page}`;
        return super.get(this.url)
    }

    newLocationFormData(payload = {}, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.headers['Content-Type'] = 'multipart/form-data'
        let url = this.baseURL + `locations`

        return super.post(url, payload);
    }

    newLocation(payload: Object = {}) {
        let url = this.baseURL + `locations`

        return super.post(url, payload)
    }

    updateLocation(payload: Object = {}, locationId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        let url = this.baseURL + `locations/${locationId}`

        return super.patch(url, payload)
    }


    updateLocationFormData(payload: Object = {}, locationId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        this.headers['Content-Type'] = 'multipart/form-data'
        
        let url = this.baseURL + `locations/${locationId}`

        return super.post(url, payload)
    }

    removeLocation(locationId: string) {
        let url = this.baseURL + `locations/${locationId}`

        return super.remove(url, {})
    }

    fetchLocation(locationId: string, lang: string = 'en-us') {
        this.headers['Accept-Language'] = lang
        let url = this.baseURL + `locations/${locationId}?include=delivery-areas`
        return super.get(url, {}, this.headers);
    }

    fetchLocations(lang: string = 'en-us', page: number = 1) {
        this.headers['Accept-Language'] = lang
        // let url = this.baseURL + `locations?filter[status]=active&include=delivery-areas&limit=50&page=${page}`;
        let url = this.baseURL + `locations?filter[delivery]=all&filter[enabled]=all&include=delivery-areas&limit=100&page=${page}`;

        return super.get(url)
    }

    fetchLocationsActive(lang: string = 'en-us', page: number = 1) {
        this.headers['Accept-Language'] = lang
        // let url = this.baseURL + `locations?filter[status]=active&include=delivery-areas&limit=50&page=${page}`;
        let url = this.baseURL + `locations?filter[delivery]=all&include=delivery-areas&limit=100&page=${page}`;

        return super.get(url)
    }

    validateLocation(lat: string | number, lng: string | number) {

        let url = this.baseURL + 'locations?filter[delivery-area.lat]=' + lat + '&filter[delivery-area.long]=' + lng

        return super.get(url)
    }


    fetchNearestLocations(lat: string | number, lng: string | number) {
        let url = this.baseURL + 'locations?_lat=' + lat + '&_long=' + lng

        return super.get(url)
    }

    fetchDrivers(locationId: string) {
        let url = this.baseURL + `employees`
        return super.get(url, {
            'filter[location]': locationId
        })
    }

    assignDriver(payload: Object = {}, employeeId: string) {
        let url = this.baseURL + `employees/${employeeId}/orders`

        return super.post(url, payload)
    }

    deliveryAreas(storeId: string, page: number=1, perPage: number=15) {
        let url = this.baseURL + `locations/${storeId}/delivery-areas?page=${page}&per-page=${perPage}`

        return super.get(url)
    }

    updateDeliveryArea(payload: Object = {}, storeId: String, areaId: String) {
        let url = this.baseURL + `locations/${storeId}/delivery-areas/${areaId}`

        return super.patch(url, payload)
    }

    deleteDeliveryArea(storeId: String, areaId: String) {
        let url = this.baseURL + `locations/${storeId}/delivery-areas/${areaId}`

        return super.remove(url, {});
    }

    createArea(payload: Object = {}, storeId: string) {
        let url = this.baseURL + `locations/${storeId}/delivery-areas`

        return super.post(url, payload)
    }

    disableLocation(payload: Object = {}, storeId: string) {
        let url = this.baseURL + `locations/${storeId}/inactive-items`;

        return super.post(url, payload);
    }
    removeDisabledLocation(storeId: string, itemId: string) {
        let url = this.baseURL + `locations/${storeId}/inactive-items/${itemId}`;

        return super.remove(url, {});
    }
    locationInactiveModifiers(payload: Object = {}, storeId: string|number) {
      const url = `${this.baseURL}locations/${storeId}/inactive-modifiers`;

      return super.post(url, payload);
    }
    removeInactiveModifiers(storeId: string|number, itemId: string|number) {
      const url = `${this.baseURL}locations/${storeId}/inactive-modifiers/${itemId}`;

      return super.remove(url, {});
    }
};

export default locationService
