
import {eventHandler, translations} from '@/mixins'
import NewPage from './components/NewPageModal'
import EditPage from './components/EditPageModal'
import PageList from './components/PageList'
import {Component, Prop, Vue, Watch, Emit} from "vue-property-decorator"
import {mapGetters} from "vuex"
import {Page as PageApi} from "@/services/SOLO"
import { Table, TableColumn, Button } from "element-ui"

@Component({
  components: {
    NewPage,
    EditPage,
    PageList,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale'
    })
  },
  mixins: [eventHandler, translations]
})
export default class Pages extends Vue {
  isPageOpen: Boolean = false
  isEditOpen: Boolean = false
  translate!: Function
  $notify: any
  confirm!: Function
  public getLocale: any
  list: any
  listMeta: any
  color: string = "white"
  loading: boolean = false
  label: string = ""
  editId: any
  page: number = 1
  size: number = 10

  mounted() {
    this.getPages(this.page, this.size)
  }

  async getPages(page: number, size: number) {
    this.loading = true
    await PageApi.all(page, size)
      .then((response: any) => {
        this.list = response.data.data
        this.listMeta = response.data.meta.pagination
        this.loading = false
        this.label = ""
      })
  }

  addNewPage(open: any) {
    this.isPageOpen = open
  }

  closeNewPage(close: any) {
    this.isPageOpen = close
  }

  openEditPage(open: any, id: any) {
    this.isEditOpen = open
    this.editId = id
  }

  get deletePages(): String {
    return "Are you sure you want to delete this page?";
  }

  deletePage(pageId: any) {
    this.confirm(this.$bvModal, this.deletePages).then(
      (value: boolean) => {
        if(value) {
          PageApi.deletePage(
            pageId,
          ).then((response) => {
            this.getPages(this.page, this.size)

            this.$notify({
              title: "PAGE DELETED!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Page has been deleted successfully",
              type: "success",
              icon: "fas fa-check",
            });
          })
          .catch((err) => {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: "Something went wrong, please try again!",
              type: "danger",
              icon: "fas fa-bomb",
            });
          });
        }
      }
    );
  }

  closeEditPage(close: any) {
    this.isEditOpen = close
  }

  updatePage(data: any) {
    this.list.forEach((item: any, index: any) => {
      if (item.id == this.editId) {
        this.list[index] = data
      }
    });
  }

  async searchPage(label: string) {
    this.loading = true
    await PageApi.search(label)
      .then((response: any) => {
        this.list = response.data.data
        this.listMeta = response.data.meta.pagination
        this.loading = false
      })
  }

  resetForm(data: any) {
      this.label = ""
      this.getPages(this.page, this.size)
  }
}
