import CustomerService from './customer.service'
import ConceptService from './concept.service'
import LocationService from './location.service'
import MenuService from './menu.service'
import CategoryService from './category.service'
import BannerService from './banner.service'
import ItemService from './item.service'
import OrderService from './order.service'
import SliderService from './slider.service'
import ConfigService from './config.service'
import CmsService from './cms.service'
import CheckoutService from './checkout.service'
import SettingsService from './settings.service'
import FeedbackService from './feedback.service'
import LoyaltyService from './loyalty.service'
import SettingsFacilitiesService from './settings.facilities.service'
import SettingsOrderStatusesService from './settings.order-statuses.service'
import IntegrationService from './Integrations.service'
import EmployeeService from './employee.service'
import SettingsOrderConceptsService from './settings.concepts.service'
import ApplicationsService from '@/services/SOLO/applications.service'
import SettingsQueueBusterService from '@/services/SOLO/settings.queue-buster.service';
import DashboarService from './dashboard.service'
import MessagingService from './messaging.service';
import SubscriptionService from './subscription.service'
import BaseService from '../base.service';
import LocationFacilitiesService from './location-facilities.service';
import CustomFieldsService from './custom-fields.service';
import DeviceService from './device.service';
import PromotionService from './promotion.service';
import CouponService from './coupons.service';
import PaymentService from './payment-cash.service';
import UpsellService from './upsell.service';
import SubscriptionMealsService from "@/services/SOLO/subscription-meals.service";
import TopicService from './topic.service';
import SmilesService from './smiles.service';
import PageService from './page.service';
import AuditLogService from './audit-log.service';
import ConnectService from './connect.service'
import PopupService from './popup.service'
import GMBService from './gmb.service'
import WhatsAppService from './whats.app.service'
import GainsightService from './gainsight.service'

let Payment = new PaymentService();
let Customer = new CustomerService();
let Concept = new ConceptService();
let Location = new LocationService();
let Menu = new MenuService();
let Category = new CategoryService();
let Banner = new BannerService();
let Item = new ItemService()
let Order = new OrderService()
let Slider = new SliderService()
let Config = new ConfigService()
let Cms = new CmsService()
let Checkout = new CheckoutService()
let Settings = new SettingsService()
let Feedback = new FeedbackService()
let Loyalty = new LoyaltyService()
let SettingsFacilities = new SettingsFacilitiesService()
let SettingsOrderStatuses = new SettingsOrderStatusesService()
let Integrations = new IntegrationService()
let Applications = new ApplicationsService()
let Employee = new EmployeeService()
let SettingsConcepts = new SettingsOrderConceptsService()
let Messaging = new MessagingService();
let QueueBuster = new SettingsQueueBusterService()
let Dashboard = new DashboarService()
let Subscription = new SubscriptionService()
let Base = new BaseService()
let LocationFacilities = new LocationFacilitiesService()
let CustomFields = new CustomFieldsService()
let Device = new DeviceService()
let Promotion = new PromotionService()
let Coupon = new CouponService()
let Upsell = new UpsellService()
let SubscriptionMeals = new SubscriptionMealsService()
let Topic = new TopicService()
let Smiles = new SmilesService()
let Page = new PageService()
let AuditLog = new AuditLogService()
let Connect = new ConnectService()
let Popup = new PopupService()
let GMB = new GMBService()
let WhatsApp = new WhatsAppService()
let Gainsight = new GainsightService()

export {
  Payment,
  Base,
  Dashboard,
  Customer,
  Concept,
  Location,
  Menu,
  Category,
  Banner,
  Item,
  Order,
  Slider,
  Config,
  Cms,
  Checkout,
  Settings,
  Feedback,
  Loyalty,
  SettingsFacilities,
  SettingsOrderStatuses,
  Integrations,
  Applications,
  Employee,
  SettingsConcepts,
  Messaging,
  QueueBuster,
  Subscription,
  LocationFacilities,
  CustomFields,
  Device,
  Promotion,
  Coupon,
  Upsell,
  SubscriptionMeals,
  Topic,
  Smiles,
  Page,
  AuditLog,
  Connect,
  Popup,
  GMB,
  WhatsApp,
  Gainsight
}
