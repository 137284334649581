export default {
  setQRUri(state: { qr_uri: string }, data: string) {
    state.qr_uri = data;
  },
  setSecretKey(state: { secret_key: string }, data: string) {
    state.secret_key = data;
  },
  setTfaToken(state: { tfa_token: string }, data: string) {
    state.tfa_token = data;
  },
}
