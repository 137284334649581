
import { Component, Prop, Vue, Watch, Ref } from 'vue-property-decorator'
import { translations } from '@/mixins'
import Modal from '@/components/Modal.vue'
import { mapMutations } from 'vuex'
@Component({
  components: { Modal },
  mixins: [translations],
  methods: {
    ...mapMutations({
      setSubscription: 'app/setSubscription',
      setQueryParams: 'app/setQueryParams'
    })
  }
})
export default class billingCyleModal extends Vue {
  
  @Prop() private open!: Boolean
  @Prop() private planId!: number

  private setSubscription!: Function
  private setQueryParams!: Function

  get isOpen() {
    return this.open
  }

  set isOpen(newVal: Boolean) {       
    this.$emit('modal:update', newVal)
  }

  private closeModal() {
    this.$emit('modal:update', false)
  }

  private upgrade(cycle: string) {    
    this.setSubscription(this.planId)
    this.setQueryParams(cycle)    
    this.$router.push({name: 'customer-subscription'})
  }
}
