
// npm packages
import { Component, Watch, Vue } from "vue-property-decorator";
import { mapGetters, mapMutations } from "vuex";

// our components
import IFrameLoyaltyContainer from "@/views/Admin/pages/Reports/components/IFrameLoyaltyContainer";

@Component({
  components: {
    IFrameLoyaltyContainer
  },
  computed: {
    ...mapGetters({
      getSoloLoyalty: "page/getSoloLoyalty"
    })
  }
})
export default class ReportsLoyalty extends Vue {
  private soloLoyalty: any = {};

  @Watch("getSoloLoyalty", {immediate: true, deep: true})
  getSoloLoyaltyChanged(newVal: any) {
    this.soloLoyalty = newVal;
  }

  get merchantUuid(): string {
    return this.soloLoyalty.attributes.options['merchant-uuid'];
  }

  mounted() {
    /* @ts-ignore */
    analytics.track('view_loyalty_report', {
      
    });
  }
}
