
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import CriteriaInput from "./CriteriaInput";
import { translations } from "@/mixins";
import { Menu } from "@/services/SOLO";

@Component({
  components: {
      CriteriaInput,
  },
  mixins: [translations]
})
export default class Criteria extends Vue {
  @Prop() criteriaList!: any;
  @Prop() criterion!: any;
  @Prop() id!: number;
  @Prop() condition!: string;
  @Prop() index!: number;

  private isSetup: boolean = false;
  isShowMenu: boolean = false;
  menuChanged: boolean = false;
  getLocale!: any;
  selectedMenu: any = null;
  criteriaType: any = null;
  oldSelectedValues: any = [];
  menus: any = [];
  html_type = this.criterion.html_type;
  choices = this.criterion.choices;
  conditionsList: any = [];
  filterdChoices: any = [];

  @Watch('criterion.provider', {immediate: true, deep: true})
  handler(provider: any) {
    if (this.isSetup) {
      this.criterion.value = null;
    }

    this.setConditionList(provider);
    this.setChoices(provider);

    if (provider === "\\Criteria\\ItemPurchased" || provider === "Criteria\\ItemPurchased") {
      this.isShowMenu = true

      if (this.menus.length) {
        if (!this.criterion.value) {
          this.selectedMenu = this.menus[0].value
        }

        this.filterMenu(this.selectedMenu)
      }
    } else {
      this.filterdChoices = this.criterion.choices
      this.isShowMenu = false
    }
  }

  get getAppLocale(): any {
    if (this.getLocale === 'en_US') {
      return 'en-us'
    }
    if (this.getLocale === 'ar_SA') {
      return 'ar-sa'
    }
    if (this.getLocale === 'fr_FR') {
      return 'fr'
    }
  }

  onChangeMenu(event:any) {
    this.menuChanged = true
    this.filterMenu(event.target.value)
  }

  filterMenu(menuId:any) {
    this.filterdChoices = this.criterion.choices.filter((choice: any) => {
      return choice.menu_id == menuId
    })
  }

  async getMenus() {
    const response = await Menu.all();

    if (response.data.data.length) {
      this.menus = response.data.data.map((menu: any) => {
        return {
          value: menu.id,
          text: menu.attributes.label
        };
      });

      
      if (this.menus.length) {
        if (this.criterion.value && this.criterion.value.length) {
          let oldChoice:any = this.criterion.choices.find((choice: any) => {
            return choice.id == this.criterion.value[0]
          });

          if (oldChoice) {
            this.selectedMenu = oldChoice.menu_id
          } else {
            this.selectedMenu = this.menus[0].value
          }
        } else {
          this.selectedMenu = this.menus[0].value
        }

        
        this.filterMenu(this.selectedMenu)
      }
    }
  }

  setConditionList(provider: any) {
    this.conditionsList = this.getByProvider(provider).conditions;
    /* tslint:disable-next-line */
    if (!this.conditionsList.includes(this.criterion.condition)) {
      this.criterion.condition = this.conditionsList[0];
    }
  }
  setChoices(provider: any) {
    let criteria = this.getByProvider(provider);
    this.criterion.choices = criteria.choices.map((choice: any) => {
      let name: any = ''

      if (
          typeof choice.name === 'object' &&
          !Array.isArray(choice.name) &&
          choice.name !== null
      ) {
        name = choice.name[this.getAppLocale]
      } else {
        name = choice.name
      }

      return {
        id: choice.id,
        menu_id: choice.menu_id,
        text: choice.text || name,
      };
    });

    this.criterion.html_type = criteria.html_type;
  }
  setHtmlType(provider: any) {
    let criteria = this.getByProvider(provider);
    this.criterion.html_type = criteria.html_type;
  }
  getByProvider(provider: any) {
    return this.criteriaList.find(
      (criterion: any) => criterion.provider === provider
    );
  }
  updateCriterionValue(val: any) {
    this.$emit('criterion-val', val);
  }

  onChangeCriteria(data:any) {
    this.criteriaType = data
  }

  created() {
    this.setConditionList(this.criterion.provider);
    this.setChoices(this.criterion.provider);
    this.setHtmlType(this.criterion.provider);
    this.getMenus()
  }

  mounted() {
    setTimeout(() => {
      this.isSetup = true;
    }, 1000);
  }

  
};
