
import {
  Alert,
  Button,
  Checkbox,
  CheckboxGroup,
  Form,
  FormItem,
  Input,
  InputNumber,
  Option,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableColumn,
  Upload,
} from 'element-ui'
import {Component, Vue} from "vue-property-decorator"
import SubscriberItemList from "./components/SubscriberItemList"
import {SubscriptionMeals} from '@/services/SOLO'
import {mapGetters} from 'vuex'

@Component({
  components: {
    [Form.name]: Form,
    [Radio.name]: Radio,
    [Alert.name]: Alert,
    [Switch.name]: Switch,
    [Input.name]: Input,
    [Button.name]: Button,
    [Upload.name]: Upload,
    [Select.name]: Select,
    [Option.name]: Option,
    [FormItem.name]: FormItem,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [RadioGroup.name]: RadioGroup,
    [InputNumber.name]: InputNumber,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    SubscriberItemList
  },
  computed: {
    ...mapGetters({
      getConceptSettings: 'account/getConceptSettings'
    })
  }
})
export default class SubscriberItems extends Vue {
  subscribers: any = []
  subscriberMeta: any = {}
  loading: boolean = false
  getConceptSettings!: Array<typeof mapGetters>
  currentConcept: any
  searchForm: any = {
    order_id: '',
    customer_mobile: '',
    customer_name: ''
  }
  page: number = 1
  subscriberItem: any = {}
  subscriberItemArr: any = []
  subscriptionPlanName: any = ''

  mounted() {
    this.currentConcept = this.getConceptSettings
    this.getSubscribers(this.page, this.currentConcept.id);
  }

  async getSubscribers(page: number, concept: any) {
    this.loading = true
    await SubscriptionMeals.getSubscribers(page)
        .then((response: any) => {

          response.data.data.map((a: any) => {
            let customerMealId = a.attributes['plan-id']
            let customerId = a.attributes['customer-id']
            let premadePlan = response.data.included.filter((e: any) => e.type === 'premade-plan')
            let customer = response.data.included.filter((e: any) => e.type === 'customer')
            customer.map((c: any) => {
              if (c.id == customerId) {
                this.subscriberItem =  c
              }
            })

            premadePlan.map((p: any) => {
              if (p.id == customerMealId) {
                this.subscriberItem.subscription = p.attributes['plan-name']
              }
            })
            this.subscribers.push(this.subscriberItem)
          })

          this.subscriberMeta = response.data.meta.pagination
          this.loading = false

        })
  }

  async searchSubscribers(form: any) {
    // this.loading = true
    // await SubscriptionMeals.search(form.customer_name, form.customer_mobile).then((response: any) => {
    //   this.subscribers = response.data.data
    //   this.loading = false
    // });

  }

  resetForm(data: any) {
      this.searchForm = {
        order_id: '',
        customer_mobile: '',
        customer_name: ''
      }
      this.$emit("getCustomers", data);
  }

}
