
import { FoodicsAuth, Auth } from "@/services/core";
import { Component, Vue, Ref, Mixins } from "vue-property-decorator";
import authService from "@/services/core/auth.service";
import { translations, notificationAlerts } from "@/mixins";

interface AuthObject {
  provider: number;
  token: string;
}

@Component({
  mixins: [translations, notificationAlerts],
})
export default class IntegrationProviderRedirect extends Vue {
  translate!: Function;
  systemErrorNotification!: Function;
  logo: string = "";

  constructor() {
    super();
  }

  async created() {
    // document.body.classList.add("bg-solo");
    this.getLogo();

    if (this.$route.params.provider) {
      if (this.$route.params.provider === "foodics") {
        FoodicsAuth.redirect();
      }

      this.$router.push({ name: "login" });
    } else {
    }
  }

  getLogo() {
    Auth.getClient().then((response: any) => {
      this.logo = response.data.data.attributes["logo-uri"];
    });
  }
}
